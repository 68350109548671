import SaysoonMatchDev from "../abis_dev/SaysoonMatch.json";
// import SaysoonMatchStg from "../abis_stg/SaysoonMatch.json";
import SaysoonMatchPre from "../abis_pre_prod/SaysoonMatch.json";
import SaysoonMatchProd from "../abis_prod/SaysoonMatch.json";
// import SaysoonMatchProd from "../abis_prod/SaysoonMatch.json";
import SaysoonAuctionDev from "../abis_dev/SaysoonAuction.json";
import SaysoonAuctionFactoryDev from "../abis_dev/SaysoonAuctionFactory.json";
import WETHdev from "../abis_dev/WETH9.json";

import { ethereum } from "../constants/AppSettings";
import { getContractBasedOnEnv } from "../services/serviceHelpers";

export const getSaysoonMatchContract = () => {

    switch (ethereum.environment) {
        case "dev":
            return SaysoonMatchDev;
        case "stg":
            return SaysoonMatchDev;
        case "pre":
            return SaysoonMatchPre;
        case "prod":
            return SaysoonMatchProd;
    }
}

export const getContractArtifact = (name, env = "dev") => {
    switch (name) {
        case "WETH":
            return WETHdev;
        case "SaysoonAuction":
            return SaysoonAuctionDev;
        case "SaysoonAuctionFactory":
            return SaysoonAuctionFactoryDev;
        case "SaysoonMatch":
            return getSaysoonMatchContract();
        case "DigitalAsset":
            return getContractBasedOnEnv(name);
        case "MultiToken":
            return getContractBasedOnEnv(name);
        default:
            throw "Artifact no found";
    }
}