import { userSocialIcons } from "../constants/socials";

export const isObject = (data) =>
  typeof data === "object" && !Array.isArray(data) && data !== null;

export const objectSelector = (
  model,
  data,
  defaultPropValue,
  skipEmpty = false
) =>
  isObject(model)
    ? isObject(data)
      ? Object.keys(model).reduce(
          (acc, modelKey) =>
            skipEmpty
              ? !!data[modelKey]
                ? { ...acc, [modelKey]: data[modelKey] }
                : acc
              : { ...acc, [modelKey]: data[modelKey] || defaultPropValue },
          {}
        )
      : model
    : {};

export const getValidUrl = (path) =>
  path.startsWith("http") || path.startsWith("https") ? path : `//${path}`;

export const getItemSymbol = (blockchain = "") => {
  switch (blockchain) {
    case "saysoon":
      return "SAY";
    case "ethereum":
    case "ropsten":
    case "rinkeby":
    case "goerli":
      return "ETH";
    case "polygon":
    case "mumbai":
      return "MATIC";
    default:
      return "N/A";
  }
};

export const sortByDate = (array, dateProp = "createdAt", sortBy = "DESC") => {
  const getDiff = (aDate, bDate) => new Date(aDate) - new Date(bDate);

  return array.sort((a, b) =>
    sortBy === "DESC"
      ? getDiff(b[dateProp], a[dateProp])
      : getDiff(a[dateProp], b[dateProp])
  );
};
