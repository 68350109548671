import { objectSelector } from "../../helpers/utils";
import { initialFilters, categoryValues } from "./constants";

export const constructFilterParams = ({ search, sortBy, category }) => {
  const filtersWithValues = objectSelector(
    initialFilters,
    {
      search,
      sortBy,
      category: category && category.length > 0 ? category : null,
    },
    undefined,
    true
  );

  return filtersWithValues;
};

const categoriesToParam = (category = []) =>
  category.length > 0 ? category : null;

const sortByToParam = (sortBy) =>
  sortBy !== initialFilters.sortBy ? sortBy : "";

export const filtersToParams = (filters) => ({
  ...filters,
  category: categoriesToParam(filters.category),
  sortBy: sortByToParam(filters.sortBy),
});

export const getValidParamCategories = (category) => {
  if (!category) return [];

  const categoriesArray = category.split(",");
  return categoriesArray.filter((category) =>
    categoryValues.includes(category)
  );
};
