import React from "react";
import { useTranslation } from "react-i18next";

const ProcessingLoader = ({
  text = "item-details-page.wizard.loading.process-text",
}) => {
  const { t } = useTranslation();

  return (
    <div className="text-center my-5">
      <div
        className="spinner-border"
        style={{ width: "3rem", height: "3rem", color: "#4BA4E3" }}
        role="status"
      >
        <span className="sr-only">Loading...</span>
      </div>

      <div className="w_step__text mt-3">{t(text)}</div>
    </div>
  );
};

export default ProcessingLoader;
