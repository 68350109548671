import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import FormikForm from "../../components/form/FormikForm";
import {
    TextInput,
    SingleSelect,
} from "../../components/form/FormikFields";
import { categoryOptions } from "../../constants/items";
import { retrieveCollections } from "../../store/CollectionSlice";
import ItemMedia from "../../pages/NewCertification/ItemMedia";
import { log } from "../../helpers/logger";

const validationSchema = Yup.object().shape({
    title: Yup.string().required("validation.required.item-name"),
    description: Yup.string().required("validation.required.description"),
    category: Yup.string()
        .oneOf(
            categoryOptions.map((d) => d.value),
            "validation.required.category"
        )
        .required("validation.required.category"),
    files: Yup.array()
        .min(1, "validation.required.min-one-picture")
        .required("validation.required.min-one-picture"),
});

const formModel = {
    title: "",
    link: "",
    description: "",
    category: "",
    collectionId: "",
    files: [],
};
const filterCollections = (collections, authUserId) => {
    return collections
        .filter((collection) => collection.ownerId.userId._id === authUserId)
        .map((collection) => ({ value: collection._id, label: collection.title }));
};

const ItemForm = ({ onNextStep }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { authInfo } = useSelector((state) => state.auth);
    const [collectionOptions, setCollectionOptions] = useState([]);
    const [mediaType, setMediaType] = useState("none");
    const [thumbnail, setThumbnail] = useState(undefined);

    const getType = (value) => {
        setMediaType(value);
    }

    const getThumbnail = (value) => {
        setThumbnail(value);
    }

    const handleSubmit = (values) => {
        log(values);
        values.type = mediaType;
        values.thumbnail = thumbnail;
        onNextStep(values);
    };

    const fetchCollections = useCallback(() => {
        dispatch(retrieveCollections({}))
            .unwrap()
            .then((response) => {
                // console.log(response);
                setCollectionOptions(
                    filterCollections(response.body.data, authInfo._id)
                );
            })
            .catch((rejectedValueOrSerializedError) => {
                log(rejectedValueOrSerializedError);
            });
    }, [authInfo, dispatch]);

    useEffect(() => {
        fetchCollections();
    }, [fetchCollections]);

    return (
        <FormikForm
            initialValues={formModel}
            validationSchema={validationSchema}
            formClasses="sign__form--create"
            enableReinitialize
            onSubmit={handleSubmit}
        >
            <div className="w-100 mb-4">
                <ItemMedia subtitle={"new-item-page.upload-image-subtitle"} typeCallback={getType} thumbnailCallback={getThumbnail} />
            </div>

            <h4 className="sign__title">
                {t("new-certification-page.item.item-details")}
            </h4>

            <Row className="w-100">
                <Col className="col-12 col-lg-6">
                    <TextInput
                        name="title"
                        label="form.required.item-name"
                        placeholder={t(
                            "new-certification-page.item.form.placeholder.item-name"
                        )}
                    />
                </Col>

                <Col className="col-12 col-lg-6">
                    <TextInput
                        name="link"
                        label="form.item-external-url"
                        placeholder={t(
                            "new-certification-page.item.form.placeholder.item-external-url"
                        )}
                    />
                </Col>

                <Col className="col-12">
                    <TextInput
                        name="description"
                        label="form.required.item-description"
                        placeholder={t(
                            "new-certification-page.item.form.placeholder.item-description"
                        )}
                        inputClass="sign__textarea"
                        as="textarea"
                        rows="4"
                    />
                </Col>

                <Col className="col-12 col-lg-6">
                    <SingleSelect
                        name="category"
                        label="form.required.category"
                        emptyText="new-certification-page.item.form.placeholder.category"
                        options={categoryOptions}
                        optionIdPrefix="category"
                        // withoutTranslation={true}
                    />
                </Col>

                <Col className="col-12 col-lg-6">
                    <SingleSelect
                        name="collectionId"
                        label="form.collection"
                        emptyText="new-certification-page.item.form.placeholder.collection"
                        options={collectionOptions}
                        optionIdPrefix="collection"
                        withoutTranslation={true}
                    />
                </Col>
            </Row>

            <Row className="w-100">
                <Col className="col-12 col-xl-3">
                    <Button className="sign__btn" variant="light" type="submit">
                        {t("actions.next")}
                    </Button>
                </Col>
            </Row>
        </FormikForm>
    );
};

export default ItemForm;
