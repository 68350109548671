import React from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { TextInput } from "./../../components/form/FormikFields";

const ProfileDetailsForm = () => {
  const { t } = useTranslation();

  return (
    <Row>
      <Col className="col-12">
        <h4 className="sign__title pl-2">
          {t("user-details-page.profile-details")}
        </h4>
      </Col>

      <Col className="col-12 col-md-6">
        <div className="pl-1 pr-1">
          <TextInput
            name="nickname"
            label="form.username"
            placeholder={t("user-details-page.form-placeholder.username")}
          />
        </div>
      </Col>

      <Col className="col-12 col-md-6">
        <div className="pl-1 pr-1">
          <TextInput
            name="email"
            label="form.email"
            placeholder={t("user-details-page.form-placeholder.email")}
          />
        </div>
      </Col>

      <Col className="col-12 col-md-6">
        <div className="pl-1 pr-1">
          <TextInput
            name="firstname"
            label="form.first-name"
            placeholder={t("user-details-page.form-placeholder.first-name")}
          />
        </div>
      </Col>

      <Col className="col-12 col-md-6">
        <div className="pl-1 pr-1">
          <TextInput
            name="lastname"
            label="form.last-name"
            placeholder={t("user-details-page.form-placeholder.last-name")}
          />
        </div>
      </Col>

      <Col className="col-12 col-md-12 col-lg-12 col-xl-12">
        <div className="pl-1 pr-1">
          <TextInput
            inputClass="sign__textarea"
            as="textarea"
            rows="4"
            name="description"
            label="form.description"
            placeholder={t("user-details-page.form-placeholder.description")}
          />
        </div>
      </Col>
    </Row>
  );
};

export default ProfileDetailsForm;
