import React from "react";
import ListingItem from "./ListingItem";
import { useAssetSelector } from "./provider";

const AssetTabListings = () => {
    const { asset } = useAssetSelector();

    return (
        <div className="asset__actions asset__actions--scroll">

            {asset?.listings.length > 0 &&
                <hr
                    style={{
                        backgroundColor: "#1B5579",
                        height: 1
                    }}
                />}
            {asset?.listings.map((listing, index) => (
                <ListingItem key={index} item={listing} asset={asset} />
            ))}
        </div>
    );
};

export default React.memo(AssetTabListings);
