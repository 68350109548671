import React, { useCallback, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import BidCreateStep from "./BidCreateStep";
import BidProcessingStep from "./BidProcessingStep";
import BidCompletedStep from "./BidCompletedStep";
import { useAssetDispatch, useAssetSelector } from "../provider";
import { useNotify } from "../../../providers/notify";
import { createBid, retrieveBids } from "../../../store/BidSlice";
import { SET_BIDS } from "../provider/actions";
import { initializeContract, bid, approveWeth } from "../../../store/EthereumSlice";

const wizardSteps = {
  STEP_1: "CREATE",
  STEP_2: "PROCESSING",
  STEP_3: "COMPLETED",
};

const BidWizard = ({ onClose }) => {
  const dispatch = useDispatch();
  const assetDispatch = useAssetDispatch();
  const { showNotification } = useNotify();
  const { authInfo } = useSelector((state) => state.auth);
  const { assetId, asset } = useAssetSelector();
  const [currentStep, setCurrentStep] = useState(1);
  const [newBid, setNewBid] = useState(0.0);

  const handleBidCreation = useCallback(
    async ({ bid }) => {
      try {
        const parsedBid = parseFloat(bid, 10);

        setNewBid(parsedBid);
        setCurrentStep((step) => step + 1);

        const payload = {
          assetId,
          bidderId: authInfo._id,
          price: parsedBid,
          date: "Today",
        };

        // approve weth
        // await dispatch(initializeContract({contractName: 'WETH'})).unwrap();
        // await dispatch(approveWeth({operator: asset.auctionAddress, amount: newBid})).unwrap();

        // place a bid
        // await dispatch(initializeContract({contractName: 'SaysoonAuction', at: asset.auctionAddress})).unwrap();
        // await dispatch(bid({funds: newBid})).unwrap();

        await dispatch(createBid(payload)).unwrap();
        const bidsResponse = await dispatch(retrieveBids()).unwrap();
        const bidsResponseData = bidsResponse.body.data;

        assetDispatch({ type: SET_BIDS, bids: bidsResponseData, assetId });
        setCurrentStep((step) => step + 1);
      } catch (error) {
        setCurrentStep((step) => step - 1);
        showNotification({
          type: "error",
          message: "error-msg.default",
        });
      }
    },
    [assetId, authInfo, assetDispatch, dispatch]
  );

  return (
    <div className="asset__wizard">
      <Tabs
        activeKey={wizardSteps[`STEP_${currentStep}`]}
        transition={false}
        style={{ borderBottom: "0" }}
      >
        <Tab eventKey={wizardSteps.STEP_1}>
          <BidCreateStep onBidCreation={handleBidCreation} onClose={onClose} />
        </Tab>

        <Tab eventKey={wizardSteps.STEP_2}>
          <BidProcessingStep bid={newBid} />
        </Tab>

        <Tab eventKey={wizardSteps.STEP_3}>
          <BidCompletedStep bid={newBid} onClose={onClose} />
        </Tab>
      </Tabs>
    </div>
  );
};

export default BidWizard;
