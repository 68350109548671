import React, { useEffect, useState } from "react";
import { log } from "../../helpers/logger";

const CollectionItemAsset = ({ asset, index }) => {
  log(index);
  const [assetCover, setAssetCover] = useState("");

  useEffect(() => {
    if (asset && asset?.files[index]?.file?.sizes) setAssetCover(asset?.files[index]?.file?.sizes["1000"]?.url);
  }, [asset]);

  return (
    <div className="col-auto pl-3" style={{paddingRight: "0.5%"}}>
      <img
        src={assetCover}
        className="token__card__new"
        style={{ width: "58.6px", height: "60.67px", objectFit: 'cover' }}
        alt="asset_cover"
      />
    </div>
  );
};

export default CollectionItemAsset;
