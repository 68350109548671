import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Col } from "react-bootstrap";
import { useAssetDispatch } from "./provider";
import { SET_BUY_MODAL_OPEN, SET_LISTING } from "./provider/actions";
import { chainIdMap } from "../../constants/items";
import { useSelector } from "react-redux";

const ListingItem = ({ item, asset }) => {
    const { t } = useTranslation();
    const { chainId } = useSelector(state => state.ethereum);
    const { isAuthorized, userId, authEthereumAddress } = useSelector(state => state.auth);
    const [disable, setDisable] = useState(false);
    const [symbol, setSymbol] = useState("SAY");
    const assetDispatch = useAssetDispatch();


    const handlePayment = (event, item) => {
        event.preventDefault();

        assetDispatch({
            type: SET_LISTING,
            listing: item
        });

        assetDispatch({ type: SET_BUY_MODAL_OPEN, open: true });

    }

    const shouldDisable = useCallback(() => {

        let currentChain = parseInt(chainId, 16);
        let match = chainIdMap.find((el) => el.value === currentChain);

        let isOwner = item?.ownerId ? userId === item?.ownerId : authEthereumAddress === item?.ownerInfo?.ethAddress;
        if ((match?.label !== asset?.blockchain) || !isAuthorized || isOwner) {
            return true;
        }

        return false;
    }, [asset?.blockchain, chainId, isAuthorized, authEthereumAddress, userId]);

    useEffect(() => {
        setDisable(shouldDisable());
        setSymbol(asset?.blockchain === "polygon" ? "MATIC" : "SAY");
    }, [shouldDisable]);

    return (
        <React.Fragment>
            {item?.saleType === "PutOnSale" &&
                <>
                    <div className="asset__action">

                        <Col className="col-3">

                            <p>
                                Owner<Link className="hyperlinks__color" to={`/user-details/${item?.ownerId}`}> {item?.ownerInfo?.username}</Link>
                            </p>
                        </Col>
                        <Col className="col-3">

                            <p>
                                Supply of {item?.supply}
                            </p>
                        </Col>
                        <Col className="col-3">
                            <p>
                                {item?.price} {symbol} each
                            </p>
                        </Col>

                        <Col className="col-3">
                            <Button disabled={disable} className="sign__btn" variant="light" onClick={(event) => { handlePayment(event, item) }}>{disable ? "On Sale" : `Buy ${item?.price} ${symbol}`}</Button>
                        </Col>

                    </div >
                    <hr
                        style={{
                            backgroundColor: "#1B5579",
                            height: 1
                        }}
                    />
                </>
            }
        </React.Fragment >
    );
};

export default ListingItem;
