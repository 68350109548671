import React, { useEffect, useState } from "react";
import { getAssetMedia, getAssetTitle } from "../../helpers/asset";
import { useLoader } from "../../providers/loader";

const AssetItemAudio = ({ asset, modal = false }) => {
  const [assetTitle, setAssetTitle] = useState("");
  const [audioSrc, setAudioSrc] = useState("");
  const { setLoading, setText } = useLoader();

  useEffect(() => {
    setAssetTitle(getAssetTitle(asset));
    setAudioSrc(getAssetMedia(asset));
  }, [asset]);

  const startLoading = () => {
    setLoading(true);
    setText("Loading Audio");
  }

  const stopLoading = () => {
    setLoading(false);
  }

  return (
    <div className="asset__img">
      <audio
        src={audioSrc}
        onLoadedData={stopLoading}
        onLoadStart={startLoading}
        title={assetTitle}
        style={{
          float: "left",
          backgroundSize: "cover",
          maxHeight: `${modal ? "300px" : "550px"}`,
          maxWidth: `${modal ? "380px" : "auto"}`
        }}
        controls
      >
      </audio>
    </div>
  );
};

export default AssetItemAudio;
