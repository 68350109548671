import { useCallback } from "react";
import { useHistory } from "react-router";

import { useNotify } from "../providers/notify";
import useLogout from "./useLogout";

export const useError = () => {
  const history = useHistory();
  const { showNotification } = useNotify();
  const {handleLogout} = useLogout();

  const handleError = useCallback(
    ({ message = "", status = 0 }) => {
      switch (status) {
        case 401:
          showNotification({ type: "error", message, status });
          //history.push("/login", { isNotifyPersist: true });
          handleLogout();
          break;
        default:
          showNotification({ type: "error", message, status });
          break;
      }
    },
    [history, showNotification]
  );

  return { handleError };
};

export default useError;
