
const MAX_THUMBNAILS = 4;
const CAPTURE_DELAY = 200;

export const VIDEO_DURATION_PERCENTAGE_STEP = 1 / MAX_THUMBNAILS;
export const MAX_VIDEO_DURATION_PERCENTAGE = 1 - VIDEO_DURATION_PERCENTAGE_STEP;

export const videoInitialState = {
    metadataLoaded: false,
    dataLoaded: false,
    suspended: false,
};

export const urltoFile = (url, filename, mimeType) => {
    return (fetch(url)
        .then(function (res) { return res.blob(); })
        .then(function (blob) { return new File([blob], filename, { type: mimeType }); })
    );
}

export const videoReducer = (state, action) => {
    switch (action.type) {
        case "RESET":
            return { ...videoInitialState };
        case "SET_METADATA_LOADED":
            return { ...state, metadataLoaded: action.metadataLoaded };
        case "SET_DATA_LOADED":
            return { ...state, dataLoaded: action.dataLoaded };
        case "SET_SUSPENDED":
            return { ...state, suspended: action.suspended };
        default:
            throw new Error(`Action ${action.type} is not supported`);
    }
};

const loadVideoAtTime = (videoRef, time = 0) =>
    new Promise(async (resolve, reject) => {
        videoRef.currentTime = time;
        videoRef.play();
        // if (time === 0) videoRef.play();
        // else videoRef.currentTime = time;

        const errorHandler = () => {
            videoRef.removeEventListener("error", errorHandler);
            reject(new Error("failed to load video"));
        };
        const seekedHandler = () => {
            videoRef.removeEventListener("seeked", seekedHandler);
            videoRef.pause();
            setTimeout(() => {
                resolve(videoRef);
            }, CAPTURE_DELAY);
        };

        videoRef.addEventListener("error", errorHandler);
        videoRef.addEventListener("seeked", seekedHandler);
    });

export const takeSnapshot = async (videoRef, canvasRef, time) => {
    const updatedVideoRef = await loadVideoAtTime(videoRef, time);

    canvasRef.width = updatedVideoRef.videoWidth;
    canvasRef.height = updatedVideoRef.videoHeight;
    const context = canvasRef.getContext("2d");

    if (!context) {
        throw new Error("error creating canvas context");
    }

    context.drawImage(updatedVideoRef, 0, 0, canvasRef.width, canvasRef.height);
    const dataURL = canvasRef.toDataURL();

    return dataURL;
};