import React from "react";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { Container, Row, Col, Button } from "react-bootstrap";
import * as Yup from "yup";
import get from "lodash/get";
import { useDispatch } from "react-redux";

import FormikForm from "./../../components/form/FormikForm";
import { TextInput, Checkbox } from "./../../components/form/FormikFields";

import { useNotify } from "../../providers/notify";
import { useLoader } from "../../providers/loader";

import { ReactComponent as FbIcon } from "./../../assets/img/icons/fb.svg";
import { ReactComponent as TwIcon } from "./../../assets/img/icons/tw.svg";
import { ReactComponent as GplusIcon } from "./../../assets/img/icons/gplus.svg";
import { createUser } from "../../store/UserSlice";
import { app } from "../../constants/AppSettings";
import { privacyPolicy } from "../../constants/footerLinkItems";

const getErrorMessage = (error) => {
  const { status, code } = get(error, "body.error", {
    status: null,
    code: null,
  });

  if (status && code) {
    if (status === 400) {
      switch (code) {
        case 560:
          return "error-msg.email-exists";
        default:
          break;
      }
    }
  }

  return "error-msg.default";
};

const validationSchema = Yup.object().shape({
  username: Yup.string().required("validation.required.name").max(15, 'validation.invalid.username'),
  email: Yup.string().required("validation.required.email"),
  agree: Yup.bool().oneOf([true], "validation.required.terms"),
});

const formModel = { username: "", email: "", agree: false, role: "consumer" };

const Register = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { showNotification } = useNotify();
  const { setLoading } = useLoader(true);

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const usernameTokens = values.username.split(" ");
      await dispatch(
        createUser({
          username: values.username,
          email: values.email,
          role: values.role,
          profile: {
            nickname: values.username,
            firstname: "",
            lastname: "",
            type: "collector"
          }
        })
      ).unwrap();

      showNotification({
        type: "success",
        message: "success-msg.register",
      });
    } catch (error) {
      showNotification({
        type: "error",
        message: getErrorMessage(error),
      });
    } finally {
      setLoading(false);
    }
  };
  const SocialLogins = () => {

    return (
      <React.Fragment>
        <div className="sign__social">
          <Link className="fb" to="#">
            <FbIcon />
          </Link>
          <Link className="tw" to="#">
            <TwIcon />
          </Link>
          <Link className="gl" to="#">
            <GplusIcon />
          </Link>
        </div>
      </React.Fragment>

    )
  }

  return (
    <Container>
      <Row>
        <Col>
          <div className="sign">
            <div className="sign__content">
              <FormikForm
                initialValues={formModel}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                <div className="mb-5">
                  <h3 style={{ color: "white", fontWeight: '400' }}>{t('register-page.signup')}</h3>
                </div>

                <TextInput name="username" placeholder={t("form.username")} />
                <div className="ml-1 mb-3 infoRegister" style={{ color: '#BDBDBD', fontSize: '15px', width: '340px' }}>
                  <Trans
                    t={t}
                    i18nKey="register-page.username"
                    components={{
                      span: <span />
                    }}
                  />
                  <div className="mt-2" style={{ fontSize: '10px' }}>
                    {t('register-page.dont')}
                  </div>
                </div>

                <TextInput name="email" placeholder={t("form.email")} />

                <Checkbox label="form.terms-agree.agree" name="agree">
                  <span className="sign__text">
                    <Link to="#" onClick={()=>{window.open(privacyPolicy, '_blank')}}>
                      {t("form.terms-agree.terms")}
                    </Link>
                  </span>
                </Checkbox>

                <Button
                  block
                  variant="light"
                  size="lg"
                  type="submit"
                  className="sign__btn"
                >
                  {t("register-page.send_confirmation")}
                </Button>

                <span className="sign__delimiter">{t("register-page.or")}</span>

                {((app.environment != "prod") && (app.environment != "pre")) ? <SocialLogins /> : null}

                <span className="sign__text">
                  {t("register-page.with-account")}{" "}
                  <Link to="/login">{t("register-page.signin")}</Link>
                </span>
              </FormikForm>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Register;
