import React, { useCallback, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useNotify } from "../../providers/notify";
import { useLoader } from "../../providers/loader";
import { createAsset, createAssetFile, retrieveAsset, updateAsset } from "../../store/AssetSlice";
import ItemForm from "../../components/AssetItem/ItemForm";
import Steps from "../../components/Steps/Steps";
import MarketForm from "../../components/AssetItem/MarketForm";
import { initializeContract, mintAsset, isApprovedForAll, approveForAll, mintMultiAsset, setTokenRoyalty, createAuction, getDefaultRoyalty } from "../../store/EthereumSlice";
import { getErrorMessage } from "./error";
import { ethereum } from "../../constants/AppSettings";
import { log } from "../../helpers/logger";
import { getUserLevel, hasAppliedForRole } from "../../helpers/user";
import KycModal from "../../components/KycModal";
import EmailConfirmationModal from "../../components/KycModal/emailConfirmation";
import { retrieveAuthUser } from "../../store/AuthSlice";


const initialFormData = {
  title: "",
  link: "",
  description: "",
  category: "",
  collectionId: "",
  file: [],
};

const initialFileFormData = {
  name: "",
  description: "",
  type: "",
  file: "",
  thumbnail: ""
}

const wizardSteps = {
  STEP_1: "itemForm",
  STEP_2: "saleForm",
};

const NewItemForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { showNotification } = useNotify();
  const { setLoading, setText } = useLoader();
  const { userId, authInfo, authProfile, authEthereumAddress } = useSelector((state) => state.auth);
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState(initialFormData);
  const [fileFormData, setFileFormdata] = useState(initialFileFormData);
  const [showModalKYC, setShowModalKYC] = useState(false);
  const [showModalEmail, setShowModalEmail] = useState(false);


  const closeModalKYC = () => {
    setShowModalKYC(false);
  }

  const closeModalEmail = () => {
    setShowModalEmail(false);
  }

  const hanldeSubmit = async (values) => {
    setText("Uploading the asset");
    setLoading(true);

    await dispatch(retrieveAuthUser({userId})).unwrap();
    let finalFormData = formData;

    if (finalFormData.collectionId === "") delete finalFormData.collectionId;

    const payload = {
      ...finalFormData,
      blockchain: values?.blockchains || "saysoon",
      price: values?.price || 0,
      saleType: values?.saleType || "NotOnSale",
      standard: values?.ERCstandard || "NotMinted",
      totalSupply: values?.supply || 1
    };


    if (!getUserLevel(authInfo, "kyc") && payload.standard != "NotMinted") {
      setLoading(false);

      if(hasAppliedForRole(authInfo, "kyc")) {
        setShowModalEmail(true);
      }else{
        setShowModalKYC(true);
        showNotification({ type: "error", message: "Need to be KYC member" });
      }

    } else {
      dispatch(createAsset(payload))
        .unwrap()
        .then(async (result) => {

          setText("Uploading media");
          await dispatch(createAssetFile({ assetId: result.body.data._id, data: fileFormData })).unwrap();

          if (payload.saleType !== "NotOnSale") {

            const assetId = result.body.data._id;
            const contractName = payload.standard == "erc721" ? "DigitalAsset" : "MultiToken";
            log('Contract Name: ', contractName);
            await dispatch(initializeContract({ contractName })).unwrap();
            // @todo: should eventually call aproveForAll on wyvern proxy registration
            const isApproved = await dispatch(isApprovedForAll({ operatee: authEthereumAddress })).unwrap();

            if (!isApproved) {
              setText("Initializing wallet");
              await dispatch(approveForAll({})).unwrap();
            }

            let res;
            try {
              setText("Creating the NFT");
              if (payload.standard == "erc721") {
                res = await dispatch(mintAsset({ id: assetId })).unwrap();
              } else {
                res = await dispatch(mintMultiAsset({ id: assetId, amount: payload.totalSupply })).unwrap();
              }
              log('token res: ', res);
            } catch (e) {
              log('Minting error:', e);
              showNotification({
                type: "error",
                message: "Minting error",
              });
              await dispatch(updateAsset({
                assetId,
                data: { saleType: 'NotOnSale', standard: 'NotMinted' }
              })).unwrap();
              setLoading(false);
              return history.push("/home", { isNotifyPersist: true });
            }

            // set the initial royalties for the asset
            try {
              await dispatch(initializeContract({ contractName: "SaysoonMatch", platform: true })).unwrap();
              const standardId = payload.standard == "erc721" ? 721 : 1155;
              const royalties = await dispatch(getDefaultRoyalty({})).unwrap();
              log('default royalties', royalties);
              setText("Finalizing");
              await dispatch(setTokenRoyalty({
                ERCStandard: standardId,
                assetId,
                receiverOwner: authEthereumAddress,
                receiverCreator: authEthereumAddress,
                receiverPlatform: ethereum.platformAddress,
                feeNumeratorOwner: royalties[0],
                feeNumeratorCreator: royalties[1],
                feeNumeratorPlatform: royalties[2]
              })).unwrap();
              setText("Created the NFT");
            } catch (e) {
              log('Minting error:', e);
              showNotification({
                type: "error",
                message: "Royalties error",
              });
              // should it be put not on sale? not sure what happens here
              // await dispatch(updateAsset({
              //   assetId,
              //   data: { saleType: 'NotOnSale', standard: 'NotMinted' }
              // })).unwrap();
              setLoading(false);
              return history.push("/home", { isNotifyPersist: true });
            }


          }

          if (payload.saleType === "PutOnBid") {
            setText("Creating Auction");
            await dispatch(initializeContract({ contractName: "SaysoonAuctionFactory" })).unwrap();
            const asset = await dispatch(retrieveAsset({ assetId: result.body.data._id })).unwrap();
            //biddingTimeInDays, ERCstandard, nftId, assetId, listingId, amount
            const receipt = await dispatch(createAuction({ biddingTimeInDays: 1, ERCstandard: 721, nftId: asset.body.data.tokenId, assetId: result.body.data._id, listingId: "1", amount: 1 })).unwrap();
            const auctionContractAddress = receipt.events.AuctionCreated.returnValues.auctionContract;


            // approve the Auction to sell the asset
            setText("Approve asset");
            await dispatch(initializeContract({ contractName: payload.standard == "erc721" ? "DigitalAsset" : "MultiToken" })).unwrap();
            await dispatch(approveForAll({ operator: auctionContractAddress })).unwrap();

            // save newAuctionContractAddress here or in the backend?
          }

          showNotification({
            type: "success",
            message: "Item created",
          });
          history.push("/home", { isNotifyPersist: true });
        })
        .catch((error) => {
          console.error("Error in new item form: ", error);
          showNotification({
            type: "error",
            message: getErrorMessage(error),
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }


  };

  const handleNextStep = useCallback((values) => {
    setFormData({
      owner: authProfile.nickname,
      title: values.title,
      // ["files[]"]: values.files,
      description: values.description,
      collectionId: values.collectionId || "",
      category: values.category || "",
      likes: "0",
      // creatorId: authInfo._id,
      type: 'digital'
    });

    if (values.type === "image") {
      values.type = "jpg";
    }

    setFileFormdata({
      name: values.title,
      description: values.description,
      type: values.type || "none",
      file: values.files[0],
      thumbnail: values.thumbnail || ""
    })

    log('values: ', values);

    setCurrentStep((currentStep) => currentStep + 1);
    window.scrollTo(0, 0);
  }, [authInfo.username]);

  return (
    <React.Fragment>
      <KycModal show={showModalKYC} closeCallback={closeModalKYC} />
      <EmailConfirmationModal show={showModalEmail} closeCallback={closeModalEmail} />
      <Tabs
        activeKey={wizardSteps[`STEP_${currentStep}`]}
        transition={false}
        style={{ borderBottom: "0" }}
      >
        <Tab eventKey={wizardSteps.STEP_1}>
          <div className="main__title main__title--create ">
            <h2>
              {t("new-item-page.title")}
              <Steps step={1} />
            </h2>

          </div>

          <ItemForm onNextStep={handleNextStep}></ItemForm>

        </Tab>

        <Tab eventKey={wizardSteps.STEP_2}>
          <div className="main__title main__title--create">
            <h2>
              {t("new-item-page.title")}
              <Steps step={2} />
            </h2>
          </div>
          <div className="market__form  mt-4" style={{ maxWidth: "100%" }}>
            <MarketForm handleAssetCreation={hanldeSubmit}></MarketForm>
          </div>

        </Tab>
      </Tabs>

    </React.Fragment>
  );
};

export default NewItemForm;
