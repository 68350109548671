import swaggerProps from "../plugins/swagger-client";

export default class UserAuthService {
  confirmUser(confirmationService, data) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Auth.confirmUser(
          { confirmationService },
          swaggerProps.buildRequest(data),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error(error);
        return Promise.reject(error.response);
      });
  }

  upgradeUserLevel(level) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Auth.upgradeUserLevel(
          level,
          swaggerProps.buildRequest(),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  }

  downgradeUserLevel(level) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Auth.downgradeUserLevel(
          level,
          swaggerProps.buildRequest(),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  }
}