import React from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useLoader } from "../../providers/loader";
import { useNotify } from "../../providers/notify";
import { deleteAsset } from "../../store/AssetSlice";

const AssetDeleteButton = ({ asset }) => {
  const { showNotification } = useNotify();
  const history = useHistory();
  const { setLoading, setText } = useLoader();
  const dispatch = useDispatch();

  const deleteSubmit = async () => {
    try {
      setText("Deleting Asset...");
      setLoading(true);
      await dispatch(deleteAsset({ assetId: asset._id })).unwrap();
      showNotification({
        type: "success",
        message: "Asset was successfully deleted",
      });
      return history.push("/home", { isNotifyPersist: true });
    } catch (e) {
      showNotification({
        type: "error",
        message: e?.message || "Can not delete asset",
      });
    }finally{
      setLoading(false);
    }
  }

  return (
    <div>
      <Button className="home__btn home__btn--clr"
        variant="light"
        color="danger"
        size="lg"
        onClick={deleteSubmit}
      >Delete Asset</Button>
    </div>
  );
}

export default AssetDeleteButton;