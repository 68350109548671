import React, { useEffect } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AssetTabBid from "./AssetTabBid";
import AssetTabDetails from "./AssetTabDetails";
import AssetTabHistory from "./AssetTabHistory";
import AssetTabListings from "./AssetTabListings";
import AssetTabList from "./AssetTabList";
import { useAssetMethods } from "./provider";

const tabList = [
  {
    id: "bid",
    title: "bid",
    component: AssetTabBid,
  },
  {
    id: "history",
    title: "history",
    component: AssetTabHistory,
  },
  {
    id: "listings",
    title: "listings",
    component: AssetTabListings
  },
  {
    id: "list",
    title: "list",
    component: AssetTabList
  },
  {
    id: "details",
    title: "details",
    component: AssetTabDetails,
  }
];

const AssetTabs = ({ asset, disableList }) => {
  const { t } = useTranslation();
  const { fetchAsset } = useAssetMethods();

  const isAssetStandardERC1155 = asset?.standard == "erc1155" ? true : false;
  let finalTabList = tabList;

  if (!isAssetStandardERC1155 && finalTabList.find(el => el.id == "listings")) {
    finalTabList = finalTabList.filter(el => el.id != "listings");
  }

  if (!isAssetStandardERC1155 && finalTabList.find(el => el.id == "list")) {
    finalTabList = finalTabList.filter(el => el.id != "list");
  }
  // if (isAssetStandardERC1155 && disableList && finalTabList.find(el => el.id == "list")) {
  //   finalTabList = finalTabList.filter(el => el.id != "list");
  // }

  if (asset.saleType !== "PutOnBid" && finalTabList.find(el => el.id == "bid")) {
    finalTabList = finalTabList.filter(el => el.id != "bid");
  }


  const update = () => {
    fetchAsset(asset._id);
  }

  return (
    <Tabs
      className="asset__tabs"
      defaultActiveKey="history"
      id="details-page-tabs"
    >
      {finalTabList.map(({ id, title, component: Component }, index) => (
        <Tab
          key={index}
          eventKey={id}
          title={t(`item-details-page.tabs.${title}`)}
          onEnter={update}
        >
          <Component />
        </Tab>
      ))}
    </Tabs>
  );
};

export default React.memo(AssetTabs);
