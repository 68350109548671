import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import Item from "../../components/item/Item";
import { retrieveAssets } from "../../store/AssetSlice";

const filterAssetsByUser = (assets, id) =>
  assets.filter((item) => item.ownerId.userId._id === id);

const CreatedList = ({ currentUserProfile }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [assetList, setAssetList] = useState([]);

  useEffect(() => {
    const fetchAssets = async () => {
      try {
        const originalPromiseResult = await dispatch(
          retrieveAssets({ creatorId: currentUserProfile })
        ).unwrap();

        const responseDataJSON = JSON.parse(originalPromiseResult.data);
        setAssetList(filterAssetsByUser(responseDataJSON.data, id));
      } catch (rejectedValueOrSerializedError) {
        console.error(rejectedValueOrSerializedError);
      }
    };

    fetchAssets();
  }, [id, dispatch]);

  return (
    <Row className="row--grid">
      {assetList.map((data, index) => (
        <Col key={index} className="col-12 col-sm-6 col-lg-4">
          <Item itemId={data._id} item={data} />
        </Col>
      ))}
    </Row>
  );
};

export default CreatedList;
