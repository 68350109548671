import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Col, Row } from "react-bootstrap";
import FormikForm from "../../components/form/FormikForm";
import { SingleSelect } from "../../components/form/FormikFields";
import { useLoader } from "../../providers/loader";
import { useAssetDispatch } from "./provider";
import useError from "../../hooks/useError";
import { retrieveAsset, updateAsset } from "../../store/AssetSlice";
import { SET_ASSET, TOGGLE_COLLECTION_EDITING } from "./provider/actions";
import { getApiRejectedStatus, getApiSuccessBody } from "../../helpers/api";
import { retrieveCollections } from "../../store/CollectionSlice";
import { log } from "../../helpers/logger";

const initialFormModel = {
  collectionId: "",
};

const filterCollections = (collections, authUserId) => {
  return collections
    .filter((collection) => collection.ownerId.userId._id === authUserId)
    .map((collection) => ({ value: collection._id, label: collection.title }));
};


const AssetCollectionForm = ({ asset, onSuccess }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const assetDispatch = useAssetDispatch();
  const { setLoading } = useLoader();
  const { handleError } = useError();
  const [formModel, setFormModel] = useState(initialFormModel);
  const { authInfo } = useSelector((state) => state.auth);
  const [collectionOptions, setCollectionOptions] = useState([]);


  const handleSubmit = useCallback(
    async (values) => {
      try {
        setLoading(true);

        await dispatch(
          updateAsset({ assetId: asset._id, data: values })
        ).unwrap();

        const retrieveResponse = await dispatch(
          retrieveAsset({ assetId: asset._id })
        ).unwrap();

        assetDispatch({
          type: SET_ASSET,
          asset: getApiSuccessBody(retrieveResponse),
        });
        onSuccess();
      } catch (rejected) {
        handleError({ status: getApiRejectedStatus(rejected) });
      } finally {
        setLoading(false);
      }
    },
    [asset, onSuccess, setLoading, handleError, assetDispatch, dispatch]
  );

  const fetchCollections = useCallback(() => {
    dispatch(retrieveCollections({}))
      .unwrap()
      .then((response) => {
        // console.log(response);
        setCollectionOptions(
          filterCollections(response.body.data, authInfo._id)
        );
      })
      .catch((rejectedValueOrSerializedError) => {
        log(rejectedValueOrSerializedError);
      });
  }, [authInfo, dispatch]);

  const closeEdit = useCallback(() => {
    assetDispatch({
      type: TOGGLE_COLLECTION_EDITING,
    });
  }, [assetDispatch]);

  useEffect(() => {
    setFormModel((data) => ({
      ...data,
      collectionId: asset?.collectionId._id || "",
    }));
    fetchCollections();
  }, [asset, fetchCollections]);

  return (
    <FormikForm
      initialValues={formModel}
      formClasses="sign__form--profile"
      enableReinitialize
      onSubmit={handleSubmit}
    >

      <SingleSelect
        name="collectionId"
        label="form.collection"
        emptyText="new-certification-page.item.form.placeholder.collection"
        options={collectionOptions}
        optionIdPrefix="collection"
        withoutTranslation={true}
      />

      <Row>
        <Col className="col-6">
          <Button className="sign__btn" variant="light" type="submit">
            {t("save")}
          </Button>
        </Col>

        <Col className="col-6">
          <Button className="sign__btn" variant="light" onClick={closeEdit} >
            {t("cancel")}
          </Button>
        </Col>
      </Row>
    </FormikForm>
  );
};

export default AssetCollectionForm;
