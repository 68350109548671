import React from "react";
import { Row, Col } from "react-bootstrap";

const AudioGrid = ({ audioFiles, onRemove }) => {

  // console.log('audio files');
  return (
    <>
      <Row className="no-gutters ml-n2">
        {audioFiles.map((audio, index) => (
          <Col key={index} className="pl-2 mt-3 col-12 col-md-4">
            <div
              style={{
                border: "2px solid white",
                position: "relative",
                height: "105px",
                width: "304px"
                //   background: `url(${picture}) center center / cover no-repeat`,
              }}
            >
              <audio src={audio.blob} controls></audio>
              <button
                style={{
                  // position: "absolute",
                  // right: 0,
                  // top: 0,
                  cursor: "pointer",
                  color: "white",
                  // borderBottom: "2px solid white",
                  // borderLeft: "2px solid white",
                  width: "100%",
                  padding: "4px",
                }}
                type="button"
                onClick={() => onRemove(index)}
              >
                Change file
              </button>
            </div>
          </Col>
        ))}
      </Row>
      <Row className="no-gutters ml-n2">
        <Col className="pl-2 mt-3 col-12 col-md-12 text-white">
          <div style={{ fontSize: "18px", lineHeight: "19px", paddingBottom: "2%", paddingTop: "1%" }}>Thumbnail</div>
          <div style={{ width: "70%", fontSize: "16px", lineHeight: "24px", paddingBottom: "1%" }}>
            <span className="text-muted"> Upload your preferred thumbnail for your video. This thumbnail will be the first image shown of your new asset to other users. </span>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default AudioGrid;
