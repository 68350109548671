import { useState } from "react";
import { Button, Form, Navbar } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DropdownProfile from "../dropdown/DropdowProfile";
import Notification from "../notifications/Notifications";
import NavPageList from "./NavPageList";
import logo from "./../../assets/img/Esex_Logo_payoff_bianco.svg";
import { ReactComponent as Search } from "../../assets/img/icons/search.svg";
import { ReactComponent as SignIn } from "../../assets/img/icons/sign-in.svg";
import NavBarStatus from "../Metamask/NavBarStatus";
import MetamaskMenuStatus from "../Metamask/MenuStatus";
import OutsideAlerter from "./useOutsideClick";
import { useLoader } from "../../providers/loader";

const NavBar = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const {setLoading, setText} = useLoader();
  const { isAuthorized } = useSelector((state) => state.auth);
  const [btnToggleClass, setBtnToggleClass] = useState("header__btn");
  const [menuToggleClass, setMenuToggleClass] = useState("header__menu");
  const [searchToggleClass, setSearchToggleClass] = useState("header__search");
  const [query, setQuery] = useState("");

  const toggleMenu = () => {
    if (btnToggleClass === "header__btn") {
      setBtnToggleClass("header__btn header__btn--active");
      setMenuToggleClass("header__menu header__menu--active");
    } else {
      setBtnToggleClass("header__btn");
      setMenuToggleClass("header__menu");
    }
  };

  const toggleSearch = (value) => {
    if (searchToggleClass === "header__search")
      setSearchToggleClass("header__search header__search--active");
    else setSearchToggleClass("header__search");
  };

  const isSearchOpen = () => {
    if (searchToggleClass === "header__search header__search--active")
      return true;

    return false;
  }

  const closeSearch = () => {
    setSearchToggleClass("header__search");
  }

  const onSearch = (e) => {
    e.preventDefault();
    history.push({ pathname: "/search", search: `?query=${query}` });
  };

  const goToSaysoon = () => {
    setLoading(true);
    setText("Loading...");
    window.open("https://saysoon.net", "_self");
  }

  return (
    <OutsideAlerter callback={closeSearch} isSearchOpen={isSearchOpen}>
      <Navbar className="header">
        <div className="header__content">
          <Navbar.Brand className="header__logo">
            <Link to="#" onClick={() => { goToSaysoon() }}>
              <img src={logo} alt="logo" />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />

          <Form
            onSubmit={onSearch}
            className={searchToggleClass}
            style={{ zIndex: 2 }}
          >
            <Form.Control
              type="text"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              placeholder={t("search-page.input-placeholder")}
              style={{ borderRadius: 0 }}
            />
            <Button variant="empty" type="submit" onClick={onSearch}>
              <Search />
            </Button>
          </Form>

          <Navbar.Collapse className={menuToggleClass} id="basic-navbar-nav">
            {isAuthorized && <MetamaskMenuStatus />}

            <NavPageList />
          </Navbar.Collapse>


          <div className="header__actions">

            <div className="header__action header__action--search">
              <button
                className="header__action-btn"
                type="button"
                onClick={toggleSearch}
              >
                <Search />
              </button>
            </div>
            {isAuthorized && <Notification />}

            <div
              className={
                isAuthorized
                  ? "header__action header__action--profile d-md-block d-lg-block d-xl-block"
                  : "header__action header__action--signin d-md-block d-lg-block d-xl-block"
              }
            >
              {isAuthorized ? (
                <DropdownProfile />
              ) : (
                <Link
                  className="header__action-btn header__action-btn--signin"
                  to="/login"
                >
                  <span>{t("sign-in")}</span>
                  <SignIn />
                </Link>
              )}
            </div>
            {isAuthorized && <NavBarStatus />}
          </div>


          <button className={btnToggleClass} type="button" onClick={toggleMenu}>
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>

      </Navbar>
    </OutsideAlerter>

  );
};

export default NavBar;
