import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  TextInput,
  SingleSelect,
} from "../../components/form/FormikFields";
import { Row, Col, Button } from "react-bootstrap";
import { blockchainsDev, blockchainsProd, ERCstandards } from "../../constants/items";
import { handleChainChange, } from "../../store/EthereumSlice";
import information from "../../assets/img/information 1.png";
import * as Yup from "yup";
import FormikForm from "../../components/form/FormikForm";
import { isFunction } from "lodash";
import BaseModal from "../modal/Base";
import { app } from "../../constants/AppSettings";
import { log } from "../../helpers/logger";

const blockchains = ((app.environment === "prod") || (app.environment === "pre")) ? blockchainsProd : blockchainsDev;

const formModel = {
  price: "",
  saleType: "PutOnSale",
  blockchains: "",
  supply: 1,
  ERCstandard: "erc721"
};

const SellNowForm = ({ callback, isMinted = false, standard = "erc721", chain }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const { symbol } = useSelector((state) => state.ethereum);
  const [isERC721selected, setIsERC721selected] = useState(true);

  const validationSchema = Yup.object().shape({
    price: Yup.number().required("validation.required.size").moreThan(0),
    supply: Yup.number().required("validation.required.supply")
      .min(isERC721selected ? 1 : 2, "validation.required.supply-greater-than-1"),
    blockchains: Yup.string().required("validation.required.blockchain")
  });

  const promptNetworkChange = async (chain) => {
    await dispatch(handleChainChange({ chain })).unwrap();
  };

  const handleAssetCreation = (values) => {
    log("values", values);
    // return;
    if (values.ERCstandard === "erc721") {
      values.supply = 1;
    }
    callback(values);
  };

  // this evaluation occurs in the AssetMarket component. 
  // It is for selling the asset at a later time
  const evaluateBlockchains = () => {
    return (
      isMinted ?
        [
          {
            value: chain,
            label: (chain.charAt(0).toUpperCase() + chain.slice(1))
          }
        ]
        : blockchains
    );
  }

  const evaluateStandards = () => {
    const contractStandard = standard == "erc721" ? { value: "erc721", label: "ERC721" } : { value: "erc1155", label: "ERC1155" };
    return (isMinted ? [contractStandard] : ERCstandards);
  }

  const finalBlockchains = evaluateBlockchains();
  const finalERCStandards = evaluateStandards();

  if (finalBlockchains.length == 1)
    formModel.blockchains = finalBlockchains[0].value;

  if (finalERCStandards.length == 1)
    formModel.ERCstandard = finalERCStandards[0].value;

  const openModal = () => {
    setShow(true);
  }

  const hideModal = () => {
    setShow(false);
  }

  const standardChanged = (standard) => {
    if (standard == "erc1155") {
      setIsERC721selected(false);
    } else {
      setIsERC721selected(true);
    }
  }

  useEffect(() => {
    setIsERC721selected(isMinted && finalBlockchains.length === 1 && finalBlockchains[0].value === 'erc1155' ? false : true);
  }, [isMinted, finalBlockchains])

  return (
    <React.Fragment>
      <FormikForm
        initialValues={formModel}
        validationSchema={validationSchema}
        formClasses="sign__form--create"
        enableReinitialize
        onSubmit={handleAssetCreation}
      >
        <Row className="w-100">
          <Col className="col-6">

            {isMinted && <div className="sign__label pl-3 pb-3"> Asset minted in network <b>{finalBlockchains[0].label}</b> with standard <b>{finalERCStandards[0].label}</b></div>}
            { !isMinted &&
              <React.Fragment>
                <div className="sign__label pl-3">{t("new-item-page.form.blockchain-label")}</div>
                <h6 className="text-muted pl-3" style={{ lineHeight: "1.5" }}>
                  {t("new-item-page.form.blockchain-explanation")}
                  <img
                    style={{ marginLeft: "0.5rem", width: "1rem", height: "1rem" }}
                    src={information}
                    alt="pic"
                  />
                </h6>

                <SingleSelect
                  name="blockchains"
                  disabledtext={isMinted ? finalBlockchains[0].label : ""}
                  disabled={isMinted}
                  options={finalBlockchains}
                  optionIdPrefix="blockchains"
                  withoutTranslation={true}
                  onChange={async (chain) => {
                    promptNetworkChange(chain);
                  }}
                />
              </React.Fragment>
            }
          </Col>

        </Row >
        <Row className="w-100">
          <Col className="col-6">
            <div className="sign__label pl-3">{isERC721selected ? t("form.price") : t("form.pricePerItem")}</div>
            <h6 className="text-muted pl-3" style={{ lineHeight: "1.5" }}>
              {t("new-item-page.form.price-explanation")}
              <img
                style={{ marginLeft: "0.5rem", width: "1rem", height: "1rem" }}
                src={information}
                alt="pic"
              />
            </h6>
            <TextInput
              name="price"
              // label={isERC721selected ? "form.price" : "form.pricePerItem"}
              placeholder={`10 ${symbol}`}
            />
          </Col>
        </Row>
        <Row className="w-100">
          <Col className="col-3">
          { !isMinted && <SingleSelect
              name="ERCstandard"
              label="Standard"
              options={finalERCStandards}
              disabledtext={isMinted ? finalERCStandards[0].label : ""}
              disabled={isMinted}
              optionIdPrefix="ERCstandards"
              withoutTranslation={true}
              onChange={(standard) => { standardChanged(standard) }}
            />}
          </Col>
        </Row>
        <Row className="w-100">
          <BaseModal size="md" centered show={show} onHide={hideModal} titleFont={"bg"} title={t("new-item-page.form.supply-modal-title")}>
            <div className="text-white p-4" >
              <Row>
                <p className="asset__modal__text">
                  {t("new-item-page.form.supply-modal-text1")}
                </p>

                <p className="asset__modal__text">
                  {t("new-item-page.form.supply-modal-text2")}
                </p>
              </Row>
            </div>
          </BaseModal>
          {(!isERC721selected && !isMinted) && <Col className="col-3">
            {isFunction(openModal) &&
              <div onClick={openModal} >
                <div className="sign__label pl-3">{t('form.supply')}</div>
                <h6 className="text-muted pl-3" style={{ lineHeight: "1.5" }}>
                  {t("new-item-page.form.supply-explanation")}
                  <img
                    style={{ marginLeft: "0.5rem", width: "1rem", height: "1rem" }}
                    src={information}
                    alt="info"
                  />
                </h6>
              </div>
            }
            <TextInput
              disabled={(isERC721selected || isMinted)}
              name="supply"
              placeholder={t("new-item-page.form.placeholder.supply")}
            />
          </Col>}
        </Row>

        <Col className="col-12 col-xl-3">
          <Button className="sign__btn" variant="light" type="submit">
            {t("create-item")}
          </Button>
        </Col>

      </FormikForm>
    </React.Fragment>
  );

}

export default SellNowForm;