import React, { useCallback } from "react";
import BuyWizard from "./wizards/Buy";
import TransactionModal from "./TransactionModal";
import { useAssetDispatch, useAssetSelector } from "./provider";
import { SET_BUY_MODAL_OPEN } from "./provider/actions";

const BuyModal = () => {
  const assetDispatch = useAssetDispatch();
  const { buyModalOpen } = useAssetSelector();

  const closeModal = useCallback(() => {
    assetDispatch({ type: SET_BUY_MODAL_OPEN, open: false });
  }, [assetDispatch]);

  return (
    <TransactionModal show={buyModalOpen}>
      <BuyWizard onClose={closeModal} />
    </TransactionModal>
  );
};

export default BuyModal;
