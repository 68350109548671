import { useMemo } from "react";
import { useHistory } from "react-router";
import queryString from "query-string";
import { filtersToParams, getValidParamCategories } from "./helpers";
import { initialFilters } from "./constants";

const updateParamsDispatch = (historyReplace) => (filters) => {
  const query = queryString.stringify(filtersToParams(filters), {
    skipNull: true,
    skipEmptyString: true,
    arrayFormat: "separator",
    arrayFormatSeparator: ",",
  });

  historyReplace({ search: query });
};

const useParams = () => {
  const params = useMemo(() => {
    const { search, sortBy, category } = queryString.parse(
      window.location.search,
      {
        skipNull: true,
        skipEmptyString: true,
      }
    );

    return {
      search: search || initialFilters.search,
      sortBy: sortBy || initialFilters.sortBy,
      category: category
        ? getValidParamCategories(category)
        : initialFilters.category,
    };
  }, [window.location.search]);

  return params;
};

const useQueryParams = () => {
  const { replace: historyReplace } = useHistory();
  const queryParams = useParams();
  const updateQueryParams = updateParamsDispatch(historyReplace);

  return { queryParams, updateQueryParams };
};

export default useQueryParams;
