import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import UserBanner from "../../components/user/Banner";
import UserInfoCard from "../../components/card/UserInfo";
import AuthUserBanner from "./AuthUserBanner";
import AuthUserInfoCard from "./AuthUserInfoCard";
import Profile from "./Profile";
import TabProvider from "./provider";
import { retrieveUser } from "../../store/UserSlice";

const UserDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { authInfo } = useSelector((state) => state.auth);
  const [user, setUser] = useState({});
  const [profile, setProfile] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        setLoading(true);
        setError(null);

        const userResponse = await dispatch(retrieveUser(id)).unwrap();
        const userData = userResponse.body.data;
        let profileData = {};
        if (userData.profiles.length > 0) {
          profileData = userData.profiles[0].profileId;
        }

        setUser(userData);
        setProfile(profileData);
      } catch (error) {
        console.error(error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, [id, dispatch]);

  return (
    <>
      {loading && <div className="text-white h2">Loading...</div>}

      {!loading && error && <div className="text-error h2">User not found</div>}

      {!loading && !error && (
        <TabProvider>
          {user._id === authInfo._id ? (
            <AuthUserBanner />
          ) : (
            <UserBanner userProfile={profile} />
          )}

          <Container>
            <Row className="row--grid">
              <Col className="col-12 col-xl-3">
                {user._id === authInfo._id ? (
                  <AuthUserInfoCard user={user}/>
                ) : (
                  <UserInfoCard
                    user={user}
                    profile={profile}
                    isUserCilckable={false}
                  />
                )}
              </Col>

              <Col className="col-12 col-xl-9">
                <Profile currentUserId={user._id} currentUserProfile={user.profiles[0].profileId._id} />
              </Col>
            </Row>
          </Container>
        </TabProvider>
      )}
    </>
  );
};

export default UserDetails;
