import React from "react";

const CertificationGalleryCarouselItem = ({ image, setActive }) => {

  return (
    <div
      className="me-4 text-center cursor__pointer"
      style={{ width: 220, height: 150, position: "relative" }}
    >
      <img
        style={{
          maxHeight: "100%",
          maxWidth: "100%",
          top: "50%",
          position: "relative",
          transform: "translateY(-50%)",
        }}
        src={image.file.sizes[1000]?.url}
        alt="galleryImage"
        onClick={() => {
          setActive(image.file.sizes[1000]?.url);
        }}
      />
    </div>
  );
};

export default CertificationGalleryCarouselItem;
