import React from "react";
import { Col, Button } from "react-bootstrap";

const SellLaterForm = ({ callback }) => {
    return (
        // <p className="text-light text-center">You can sell your item later</p>
        <Col className="col-12 col-xl-3 pt-3">
            <Button className="sign__btn" variant="light" onClick={callback}>
                create item
            </Button>
        </Col >
    );
}

export default SellLaterForm;