import React from "react";
import HistoryItem from "./HistoryItem";
import { useAssetSelector } from "./provider";

const AssetTabHistory = () => {
  const { asset, transactions } = useAssetSelector();

  return (
    <div className="asset__actions asset__actions--scroll">
      {transactions.map((transaction, index) => (
        <HistoryItem key={index} item={transaction} asset={asset} />
      ))}
    </div>
  );
};

export default React.memo(AssetTabHistory);
