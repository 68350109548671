
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useField } from "formik";
import SimpleUploadFile from "../../components/uploadFile/Simple";
import PicturesGrid from "./PicturesGrid";
import AudioGrid from "./AudioGrid";
import VideoGrid from "./VideoGrid";
import PDFGrid from "./PDFGrid";
import ThumbnailPicker from "../../components/uploadFile/Thumbnail";
import { log } from "../../helpers/logger";

function getFileType(file) {

  if (file?.type.match('image/gif'))
    return 'gif';

  if (file?.type.match('image.*'))
    return 'image';

  if (file?.type.match('video.*'))
    return 'video';

  if (file?.type.match('audio.*'))
    return 'audio';

  if (file?.type.match('application.*'))
    return 'pdf';

  return 'other';
}


const ItemMedia = ({ name = "files", subtitle, comes, typeCallback = null, thumbnailCallback = null }) => {
  const { t } = useTranslation();
  const [field, meta, helpers] = useField({ name });
  const [media, setMedia] = useState([]);

  const handleSelect = (file) => {
    log('file type: ', getFileType(file));
    if (typeCallback != null) typeCallback(getFileType(file));
    // console.log('file: ', file);
    setMedia((media) => [...media, { blob: URL.createObjectURL(file), type: getFileType(file) }]);
    // console.log(media);
    // console.log(field);
    helpers.setValue([...field.value, file]);
  };


  const handleRemove = (index) => {
    const cpMedia = [...media];
    cpMedia.splice(index, 1);
    setMedia(cpMedia);
    const cpFieldValue = [...field.value];
    cpFieldValue.splice(index, 1);
    helpers.setValue(cpFieldValue);
    // on audio and video should trigger 
    // resellection instead of removing it
  };

  return (
    <React.Fragment>
      <h4 className="sign__title">
        {comes == "Collection" ? "Banner image" : t("new-certification-page.item.upload-images-title")}

      </h4>

      <p className="text-muted text">
        <Trans
          t={t}
          i18nKey={subtitle || "new-certification-page.item.upload-images-subtitle"}
          components={{
            br: <br />,
          }}
        />
      </p>

      <SimpleUploadFile onSelect={handleSelect} />

      {meta.touched && meta.error && (
        <div
          className="d-block"
          style={{
            color: "var(--error)",
            marginLeft: "20px",
            marginTop: "0.25rem",
            fontSize: "80%",
          }}
        >
          {t(meta.error)}
        </div>
      )}

      {/* {(getFileType(media?.[0]) === "gif") &&
        <React.Fragment>
          <PicturesGrid pictures={media} onRemove={handleRemove} />
        </React.Fragment>
      } */}
      {((media?.[0]?.type === "image") || (media?.[0]?.type === "gif")) &&
        <PicturesGrid pictures={media} onRemove={handleRemove} />
      }
      {(media?.[0]?.type === "audio") &&
        <React.Fragment>
          <AudioGrid audioFiles={media} onRemove={handleRemove} />
          <div className="pt-2">
            <ThumbnailPicker onSelect={thumbnailCallback}></ThumbnailPicker>
          </div>
        </React.Fragment>
      }
      {(media?.[0]?.type === "video") &&
        <React.Fragment>
          <VideoGrid videoFiles={media} onRemove={handleRemove} onSelect={thumbnailCallback} />
          <div className="pt-2">
          </div>
        </React.Fragment>
      }
      {(media?.[0]?.type === "pdf") &&
        <React.Fragment>
          <PDFGrid pdfs={media} onRemove={handleRemove} />
          <div className="pt-2">
            <ThumbnailPicker selected={true} onSelect={thumbnailCallback}></ThumbnailPicker>
          </div>
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default ItemMedia;
