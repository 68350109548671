import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Dropzone from "../../components/uploadFile/Dropzone";
import { useNotify } from "../../providers/notify";
import { useLoader } from "../../providers/loader";
import { useTabSelector } from "./provider";
import {
  retrieveAuthUser,
  createAuthProfile,
  updateAuthProfile,
} from "../../store/AuthSlice";
import bannerDefaultImage from "../../assets/img/bg/poster.jpg";
import camera from "../../assets/img/icons/cameraWithoutBackground.svg";

const AuthUserBanner = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { setLoading } = useLoader();
  const { showNotification } = useNotify();
  const { isSettingsClicked } = useTabSelector();
  const { userId, authProfile } = useSelector((state) => state.auth);
  const [bannerImage, setBannerImage] = useState(null);

  const updateBanner = useCallback(
    async (newBanner) => {
      try {
        setLoading(true);

        if (authProfile._id) {
          await dispatch(
            updateAuthProfile({
              userId,
              profileId: authProfile._id,
              payload: { banner: newBanner },
            })
          ).unwrap();
        } else {
          await dispatch(
            createAuthProfile({
              userId,
              payload: { banner: newBanner },
            })
          ).unwrap();
        }

        await dispatch(retrieveAuthUser({ userId })).unwrap();
      } catch (error) {
        console.error(error);
        showNotification({
          type: "error",
          message: "error-msg.default",
        });
      } finally {
        setLoading(false);
      }
    },
    [userId, authProfile, setLoading, showNotification, dispatch]
  );

  const handleUpload = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length === 1) {
        updateBanner(acceptedFiles[0]);
      } else {
        showNotification({
          type: "error",
          message: "Please select one image to upload",
        });
      }
    },
    [updateBanner, showNotification]
  );

  useEffect(() => {
    setBannerImage(
      authProfile.banner
        ? authProfile.banner.sizes["1200"].url
        : bannerDefaultImage
    );
  }, [authProfile]);

  return (
    <React.Fragment>
      <section
        className="main__author bg-img"
        style={{
          background: `url(${bannerImage}) center center / cover no-repeat`,
        }}
      >
        {isSettingsClicked && (
          <Dropzone
            accept="image/*"
            className="dropzone "
            onUpload={handleUpload}
            style={{
              width: "100%",
              height: "100%",
              position: "relative",
              cursor: "pointer",
            }}
          >
            <div
              className="px-3 py-1 text-white justify-content-center banner__container__xs"
              style={{
                position: "absolute",
                bottom: "20px",
                right: "20px",
                backgroundColor: "#4BA4E3",
                borderRadius: "0px",
              }}
            >
              <img style={{
                marginBottom: "1%"
              }} src={camera} className="banner__icon__xs" alt="bannerIcon" />
              <span style={{ fontSize: "12px" }} className="text-white ml-2 banner__hide">
                {t("actions.change-banner-image")}
              </span>
            </div>
          </Dropzone>
        )}
      </section>
    </React.Fragment>
  );
};

export default AuthUserBanner;
