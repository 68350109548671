export const SET_ASSET = "SET_ASSET";
export const SET_LISTING = "SET_LISTING";
export const SET_BIDS = "SET_BIDS";
export const SET_BID_MODAL_OPEN = "SET_BID_MODAL_OPEN";
export const SET_BUY_MODAL_OPEN = "SET_BUY_MODAL_OPEN";
export const SET_DESCRIPTION_EDITING = "SET_DESCRIPTION_EDINTING";
export const TOGGLE_DESCRIPTION_EDITING = "TOGGLE_DESCRIPTION_EDITING";
export const TOGGLE_TITLE_EDITING = "TOGGLE_TITLE_EDITING";
export const TOGGLE_COLLECTION_EDITING = "TOGGLE_COLLECTION_EDITING";
export const TOGGLE_PRICE_EDITING = "TOGGLE_PRICE_EDITING";
export const RESET = "RESET";
