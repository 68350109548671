import { v4 as uuidv4 } from "uuid";
import { getAuthToken } from "./auth";
import { getLanguage } from "./locale";
import { app } from "../constants/AppSettings";

export const constructRequestHeaders = (request) => {
  const authToken = getAuthToken();
  if (authToken) {
    request.headers.Authorization = authToken;
  }

  request.headers["X-Application-Id"] = app.id;
  request.headers["X-Request-Id"] = uuidv4();
  request.headers["Accept-Language"] = getLanguage();

  return request;
};