import React, { useCallback, useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
// import { useTranslation } from "react-i18next";
import UserBanner from "../../components/user/Banner";
import UserInfoCard from "../../components/card/UserInfo";
import NewItemForm from "./NewItemForm";
import InvalidAccount from "../../components/Metamask/InvalidAccount";
import { ethereumAccountStatus } from "../../helpers/auth";
// import { getUserLevel } from "../../helpers/user";

const NewItem = () => {
  // const { t } = useTranslation();
  const { ethereumAccountAddress } = useSelector((state) => state.ethereum);
  const { authInfo, authProfile } = useSelector((state) => state.auth);
  // const [kycUser, setKycUser] = useState(false);

  const shouldDisplay = useCallback(() => {

    const status = ethereumAccountStatus(ethereumAccountAddress, authInfo.identities);
    if (status === 2) {
      return true;
    }
    return false;

  }, [authInfo.identities, ethereumAccountAddress]);

  // useEffect(() => {
  //   if (getUserLevel(authInfo, 'kyc')) {
  //     setKycUser(true);
  //   }
  // }, [authInfo])


  return (
    <React.Fragment>
      <UserBanner userProfile={authProfile} />

      <Container >
        <Row className="row--grid">
          <Col className="col-12 col-xl-3">
            <UserInfoCard user={authInfo} profile={authProfile} />
          </Col>
          <Col className="col-12 col-xl-9">
            {(shouldDisplay()) ? <NewItemForm /> : <InvalidAccount validMetamaskAccount={shouldDisplay()} />}
          </Col>
        </Row>

      </Container>
    </React.Fragment>
  );
};

export default NewItem;
