import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import services from "../services";

export const createPaypalPayment = createAsyncThunk(
  "paypal/createPayment",
  async (payload, { getState, rejectWithValue }) => {
    try {

      const response = await services.PaypalService.createPayment(payload);
      return response?.body?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getPaypalPayment = createAsyncThunk(
  "paypal/getPaypalPayment",
  async ({ paymentId }, { getState, rejectWithValue }) => {
    try {

      const response = await services.PaypalService.getPayment(paymentId);
      return response?.body?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const paypalPaymentStatus = createAsyncThunk(
  "paypal/paymentStatus",
  async ({ paymentId }, { getState, rejectWithValue }) => {
    try {

      const response = await services.PaypalService.getPaymentStatus(paymentId);
      return response?.body?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const paypalPaymentCallback = createAsyncThunk(
  "paypal/paymentCallback",
  async (payload, { getState, rejectWithValue }) => {
    try {

      const response = await services.PaypalService.paymentCallback(payload);
      return response?.body?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

let initialState = {
  paymentId: "",
  paymentStatus: "",
  callbackResponse: "",
  state: "",
  error: {},
};

const paypalSlice = createSlice({
  name: "paypal",
  initialState,
  reducers: {},
  extraReducers: {
    /* ---- CREATE_PAYMENT ---- */
    [String(createPaypalPayment.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(createPaypalPayment.fulfilled)]: (state, action) => {
      state.state = "success";
      state.paymentId = action.payload.paymentId;
    },
    [String(createPaypalPayment.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
    /* ---- GET_PAYMENT ---- */
    [String(getPaypalPayment.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(getPaypalPayment.fulfilled)]: (state, action) => {
      state.state = "success";
      state.paymentStatus = action.payload;
    },
    [String(getPaypalPayment.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
    /* ---- PAYMENT_STATUS ---- */
    [String(paypalPaymentStatus.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(paypalPaymentStatus.fulfilled)]: (state, action) => {
      state.state = "success";
      state.paymentStatus = action.payload;
    },
    [String(paypalPaymentStatus.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
    /* ---- PAYMENT_CALLBACK ---- */
    [String(paypalPaymentCallback.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(paypalPaymentCallback.fulfilled)]: (state, action) => {
      state.state = "success";
      state.callbackResponse = action.payload;
    },
    [String(paypalPaymentCallback.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    }
  }
});

export default paypalSlice.reducer;