import React from "react";
import { useTranslation } from "react-i18next";
import HistoryList from "../../components/history/List";

const CertificationHistory = ({ transactions, asset }) => {
  const { t } = useTranslation();

  return (
    <div className="card__wrapper mt-4">
      <h1 className="certification__section__title text-truncate">
        {t("certification-details-page.transactions")}
      </h1>

      <HistoryList list={transactions} asset={asset} containerHeight="380px" />
    </div>
  );
};

export default CertificationHistory;
