import React, { useContext, useCallback, useReducer } from "react";
import { useDispatch } from "react-redux";
import StateContext, { DispatchContext, MethodsContext } from "./context";
import initialState from "./initialState";
import reducer from "./reducer";
import { SET_ASSET } from "./actions";
import { retrieveAsset } from "../../../store/AssetSlice";

const AssetDetailsProvider = ({ children }) => {
  const reduxDispatch = useDispatch();
  const [state, dispatch] = useReducer(reducer, initialState);

  const fetchAsset = useCallback(
    async (assetId) => {
      try {
        const response = await reduxDispatch(
          retrieveAsset({ assetId })
        ).unwrap();

        const responseData = response.body.data;

        responseData?.listings.forEach(item => {
          item.ownerInfo = {
            profileId: item?.ownerId?._id,
            username: item?.ownerId?.nickname,
            ethAddress: item?.ownerId?.userId?.identities?.[1]?.identityId
          }
          item.ownerId = item?.ownerId?.userId?._id;

        });

        dispatch({ type: SET_ASSET, asset: responseData });
      } catch (rejectedValueOrSerializedError) {
        console.error(rejectedValueOrSerializedError);
      }
    },
    [reduxDispatch]
  );

  return (
    <DispatchContext.Provider value={dispatch}>
      <MethodsContext.Provider value={{ fetchAsset }}>
        <StateContext.Provider value={state}>{children}</StateContext.Provider>
      </MethodsContext.Provider>
    </DispatchContext.Provider>
  );
};

export const useAssetDispatch = () => useContext(DispatchContext);

export const useAssetMethods = () => useContext(MethodsContext);

export const useAssetSelector = () => useContext(StateContext);

export default AssetDetailsProvider;
