import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { getUserBanner, bannerDefaultImage } from "../../helpers/user";
import bannerProfileImage from "../../assets/img/bg/poster.jpg";

const getDefaultBanner = (routePath) => {
  switch (routePath) {
    case "/user-details/:id/:routeTab?":
      return bannerProfileImage;
    case "/new-item":
    case "/new-collection":
    case "/new-certification":
    default:
      return bannerDefaultImage;
  }
};

const UserBanner = ({ userProfile }) => {
  const { path: routePath } = useRouteMatch();
  const [banner, setBanner] = useState("");

  useEffect(() => {
    setBanner(
      getUserBanner(userProfile, undefined, false) ||
        getDefaultBanner(routePath)
    );
  }, [userProfile, routePath]);

  return (
    <div
      className="main__author bg-img"
      style={{ background: `url(${banner}) center center / cover no-repeat` }}
    ></div>
  );
};

export default UserBanner;
