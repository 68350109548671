import React, { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import Pagination from "../../components/pagination/Pagination";
import UserFilters from "./UserFilters";
import UserItem from "./UserItem";
import InfiniteScroll from "react-infinite-scroll-component";
import { retrieveUsers } from "../../store/UserSlice";

const usersPerPage = 4;

const Users = () => {
  const dispatch = useDispatch();
  const [totalRenderedAssets, setTotalRenderedAssets] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [userList, setUserList] = useState([]);
  const [renderedUsers, setRenderedUsers] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [renderedAssets, setRenderedAssets] = useState([]);
  const [skip, setSkip] = useState(0);
  const [currentPosts, setCurrentPosts] = useState([]);

  useEffect(() => {
    if (totalRenderedAssets <= userList.length) {
      setRenderedAssets(userList.slice(0, totalRenderedAssets));
    } else {
      setRenderedAssets(userList);
    }
  }, [userList, totalRenderedAssets]);

  const fetchUsers = useCallback(
    (skip, userList, currentPosts) => {
      dispatch(retrieveUsers({ limit: 5, skip: skip }))
        .unwrap()
        .then((response) => {
          const responseData = response.body.data;
          setUserList(userList.concat(responseData));
          setCurrentPosts(responseData);
          //setTotalUsers(userList.length);
        })
        .catch((rejectedValueOrSerializedError) => {
          console.error(rejectedValueOrSerializedError);
        });
    },
    [dispatch]
  );
  {
    /*
  useEffect(() => {
    if (userList.length > 0) {
      const startIndex = (currentPage - 1) * usersPerPage;
      const endIndex = currentPage * usersPerPage;
      setRenderedUsers(userList.slice(startIndex, endIndex));
    }
  }, [userList, currentPage]);
*/
  }

  useEffect(() => {
    if (currentPosts.length != 0 || skip == 0) {
      fetchUsers(skip, currentPosts, userList);
    }
  }, [fetchUsers, skip]);

  const loadMore = () => {
    setSkip(skip + 5);
    currentPosts.length = 0;
    setTotalRenderedAssets((total) => total + 5);
  };

  return (
    <Container>
      <Row className="row--grid">
        <Col className="col-12">
          <div className="main__title main__title--page">
            <h1>Users</h1>
          </div>
        </Col>

        <Col className="col-12">
          <UserFilters />
        </Col>
      </Row>

      <InfiniteScroll
        dataLength={totalRenderedAssets}
        hasMore={true}
        style={{ overflowX: "hidden" }}
        next={loadMore}
      >
        <Row className="justify-content-center ml-n3 row--grid">
          {renderedAssets.map((u, index) => (
            // <Col key={index} className="">
            <div key={index} className="mr-2 pl-3">
              <UserItem user={u} />
            </div>
            // </Col>
          ))}
        </Row>
      </InfiniteScroll>

      {/* 
      <Row className="row row--grid">
        <Col className="col-12">
          <Pagination
            page={currentPage}
            sizePerPage={usersPerPage}
            totalSize={totalUsers}
            onChange={handlePaginateChange}
          />
        </Col>
      </Row>
      */}
    </Container>
  );
};

export default Users;
