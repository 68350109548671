import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ListItem from "./ListItem";
import { useAssetSelector } from "./provider";

const AssetTabList = () => {
    const { asset } = useAssetSelector();
    const { userId } = useSelector(state => state.auth);
    const [listingToDisplay, setListingsToDisplay] = useState([]);

    const populateListings = useCallback(() => {
        let finalListings = [];

        const listings = asset?.listings;
        for (let listing of listings) {
            if (listing.ownerId === userId) {
                finalListings.push(listing);
            }
        }
        return finalListings;
    }, [asset.listings]);

    useEffect(() => {
        setListingsToDisplay(populateListings());
    }, [populateListings]);

    return (
        <React.Fragment>
            {listingToDisplay.length > 0 ?
                <div className="asset__actions asset__actions--scroll">
                    {listingToDisplay?.map((listing, index) => (
                        <ListItem key={index} item={listing} asset={asset} />
                    ))}
                </div>
                :
                <div>
                    <p className="p-2 text-white">
                        You don't own any Multi Tokens of this Asset
                    </p>
                </div>
            }
        </React.Fragment>
    );
};

export default React.memo(AssetTabList);
