import React, { useCallback, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Col } from "react-bootstrap";
import { chainIdMap } from "../../constants/items";
import { useSelector } from "react-redux";

const ListItem = ({ item, asset }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { userId } = useSelector(state => state.auth);

    const handleSale = (event, item) => {
        event.preventDefault();

        history.push(`/place-on-market/${asset?._id}/${item?._id}`, { isNotifyPersist: true });
    }

    return (
        <React.Fragment>
            {(userId == item?.ownerId) &&
                <>
                    <div className="asset__action">

                        <Col className="col-4">

                            <p>
                                Owner<Link className="hyperlinks__color" to={`/user-details/${item?.ownerId}`}> {item?.ownerInfo?.username}</Link>
                            </p>
                        </Col>
                        <Col className="col-4">

                            <p>
                                Supply of {item?.supply}
                            </p>
                        </Col>

                        <Col className="col-4">
                            <Button disabled={item?.saleType === "PutOnSale"} className="sign__btn" variant="light" onClick={(event) => { handleSale(event, item) }}>{item?.saleType === "NotOnSale" ? "Sell" : "On Sale"}</Button>
                        </Col>

                    </div >
                    <hr
                        style={{
                            backgroundColor: "#1B5579",
                            height: 1
                        }}
                    />
                </>
            }
        </React.Fragment >
    );
};

export default ListItem;
