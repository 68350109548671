import { log } from "../helpers/logger";
import swaggerProps from "../plugins/swagger-client";

export default class NotificationService {
 
  createNotification(payload) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Notification.createNotification(
          {},
          swaggerProps.buildRequest(payload),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  retrieveNotifications(params = {}) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Notification.retrieveNotifications(
          params,
          swaggerProps.buildRequest(),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  retrieveNotification(notificationId) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Notification.retrieveNotification(
          { notificationId },
          swaggerProps.buildRequest(),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  deleteNotification(notificationId) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Notification.deleteNotification(
          { notificationId },
          swaggerProps.buildRequest(),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  updateNotification(notificationId,info) {
    log(info);
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Notification.updateNotification(
          {notificationId:notificationId},
          swaggerProps.buildRequest(info),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}
