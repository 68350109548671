import React from "react";
import Asset from "./Asset";
import AssetDetailsProvider from "./provider";

import "./Details.scss";

const ItemDetails = () => {
  return (
    <AssetDetailsProvider>
      <Asset />
    </AssetDetailsProvider>
  );
};

export default ItemDetails;
