import Web3 from "web3";
// import ContractRegistry from "../abis/ContractsRegistry.json";
import HDWalletProvider from "@truffle/hdwallet-provider"
import { ethereum, explorers } from "../constants/AppSettings";
import _ from "lodash";
import { getContractAddressBasedOnEnv, getContractBasedOnEnv } from "./serviceHelpers";
import { getContractArtifact, getSaysoonMatchContract } from "../helpers/ethereum";
import { log } from "../helpers/logger";
export default class EthereumService {


  // ---------------------------- Generic Methods ----------------------------

  async loadWeb3() {
    try {
      if (window.ethereum) {
        // window.web3 = new Web3(window.ethereum);
        await window.ethereum.enable();
        return Promise.resolve();
      } else if (window.web3) {
        window.web3 = new Web3(window.web3.currentProvider);
        return Promise.resolve();
      } else {
        // removing alert all together
        // window.alert(
        //   "Non-Ethereum browser detected. You should consider trying MetaMask!"
        // );
        return Promise.reject('Metamask is not installed');
      }
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getChain() {
    // await this.loadWeb3();
    const web3 = new Web3(window.ethereum);
    const networkId = await web3.eth.net.getId();
    return networkId;
  }

  async promptChainChange(chainId, rpcUrl) {
    // await this.loadWeb3();
    return new Promise(async (resolve, reject) => {

      const ethereum = window.ethereum;

      // prompt the user to change network
      try {

        await ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: chainId }],
        });

        resolve(true);

      } catch (e) {
        // if networkId was not found in Metamask, add it!
        // console.log('code: ', e.code);
        if (e.code === 4902) {

          let chainPayload = {};
          _.set(chainPayload, 'chainId', chainId);
          _.set(chainPayload, 'rpcUrls', [rpcUrl]);

          // no need to handle ropsten, rinkeby or goerli since metamask always has them pre configured
          if (chainId === '0x1194') {
            _.set(chainPayload, 'chainName', 'Saysoon Network');
            _.set(chainPayload, 'nativeCurrency.symbol', 'SAY');
            _.set(chainPayload, 'nativeCurrency.decimals', 18);
            _.set(chainPayload, 'blockExplorerUrls', [explorers.saysoon]);
          } else if (chainId === '0x13881') {
            _.set(chainPayload, 'chainName', 'Mumbai Network');
            _.set(chainPayload, 'nativeCurrency.symbol', 'MATIC');
            _.set(chainPayload, 'nativeCurrency.decimals', 18);
            _.set(chainPayload, 'blockExplorerUrls', [explorers.mumbai]);
          } else if (chainId === '0x89') {
            _.set(chainPayload, 'chainName', 'Polygon Mainnet');
            _.set(chainPayload, 'nativeCurrency.symbol', 'MATIC');
            _.set(chainPayload, 'nativeCurrency.decimals', 18);
            _.set(chainPayload, 'blockExplorerUrls', [explorers.polygon]);
          }

          log('payload: ', chainPayload);
          await ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [chainPayload],
          });

          resolve(true);
        } else {

          console.error('chain change error: ', e);
          reject(e);
        }

      }
    });

  }

  async initializeContract(platform = false, contractName = "DigitalAsset", chainId = null, at = null) {
    return new Promise(async (resolve, reject) => {

      // fetching the network id metamask is currently using
      const chainID = chainId || await this.getChain();
      let contract;
      const artifact = getContractArtifact(contractName);

      log("ETH Environment ", ethereum.environment);
      log("Network id is: ", chainID);
      let networkData;

      // using address from truffle deployment or specified at address 
      if (at) {
        networkData.address = at;
      } else {
        networkData = artifact.networks[chainID];
      }

      log("Networks: ", artifact.networks);

      if (networkData) {
        const abi = artifact.abi;
        const address = networkData.address;
        log("Contract being used: ", address);

        contract = await this.createContractObject(networkData.address, abi, platform, chainID);

        resolve(contract);
        // const totalSupply = await contract.methods.totalSupply().call();
      } else {
        reject("Smart contract not deployed to detected network.");
      }
    });

  }

  // @todo: check the 'faucet' balance before sending funds
  // @todo: make this method like not 100 lines long
  async fundAccount(addressToFund, amount) {

    const chain = await this.getChain();
    let provider;

    log('Chains is ', chain);
    if (chain === 10) {
      provider = ethereum.saysoonUrl;
    } else {
      provider = ethereum.ropstenUrl;
    }

    const platformWeb3 = new Web3(
      new Web3.providers.HttpProvider(
        provider
      )
    );

    const account = platformWeb3.eth.accounts.privateKeyToAccount(
      '0x' + ethereum.platformPK
    );

    platformWeb3.eth.accounts.wallet.add(account);
    platformWeb3.eth.defaultAccount = account.address;

    const platformAccount = account;

    log(`from ${platformAccount.address} to ${addressToFund}`);

    return new Promise((resolve, reject) => {
      platformWeb3.eth
        .sendTransaction({
          from: platformAccount,
          to: addressToFund,
          value: amount, // in wei 250000000000000000 -> 0.25 ether
          gas: '500000',
          chainId: chain,
        })
        .on('transactionHash', (hash) => {
          log(hash);
        })
        .on('receipt', (receipt) => {
          log(receipt);
          resolve(receipt);
        })
        .on('error', (err) => {
          console.error(err);
          reject(err);
        });
    });

  }

  async getDefaultAccounts() {
    // this.loadWeb3();
    const web3 = new Web3(window.ethereum);
    return (await web3.eth.getAccounts());
  }

  async getEthBalance(accountAddress, chainId = "metamask") {
    // this.loadWeb3();
    let provider;
    if (chainId === "metamask") {
      provider = new Web3(window.ethereum);
    } else {
      provider = await this.initializePlatformWeb3(Number(chainId));
    }

    const balance = await provider.eth.getBalance(accountAddress);

    const formatedBalance = Web3.utils.fromWei(balance, "ether");
    const formatBalance = Math.round(formatedBalance * 100) / 100;

    return formatBalance;
  }

  async getSignature(message, accountAddress) {
    let provider = new Web3(window.ethereum);

    const signature = await provider.eth.personal.sign(
      message,
      accountAddress
    );

    return signature;
  }

  async deployContract(
    abi,
    bytecode,
    args = [],
    platform = false
  ) {

    return new Promise(async (resolve, reject) => {

      let provider;

      if (platform) {
        provider = await this.initializePlatformWeb3();
      } else {
        provider = new Web3(window.ethereum);
      }

      const balance = await this.getEthBalance(provider.eth.defaultAccount);
      if (balance < 0.006) {
        throw new Error('Insufficient balance to complete the deployment');
      }
      log('Deploying from account: ', provider.eth.defaultAccount);

      new provider.eth.Contract(abi)
        .deploy({ data: bytecode, arguments: args })
        .send({
          from: provider.eth.defaultAccount,
          // gasLimit: 5000000,
          // gasPrice: 1000000000
          gas: 6000000,
          value: 0
        }).then((res) => {
          log('Contrat deployed to: ', res.options.address);
          resolve(res.options.address)
        }).catch(e => {
          console.error(e);
          reject('Contract deployment failed');
        });

    });

  }

  async initializePlatformWeb3(chainID = null) {
    return new Promise(async (resolve, reject) => {

      try {
        const chainId = chainID || await this.getChain();
        let providerUrl;

        switch (chainId) {
          case 3:
            // Ropsten
            providerUrl = ethereum.ropstenUrl;
            break;
          case 4:
            // Rinkeby
            providerUrl = ethereum.rinkebyUrl;
            break;
          case 5:
            // Goerli
            providerUrl = ethereum.goerliUrl;
            break;
          case 4500:
            // Saysoon
            providerUrl = ethereum.saysoonUrl;
            break;
          case 137:
            // Polygon Mainnet
            providerUrl = ethereum.polygonUrl;
            break;
          case 80001:
            // Polygon Testnet
            providerUrl = ethereum.mumbaiUrl;
            break;
          default:
        }

        const provider = new HDWalletProvider({
          mnemonic: {
            phrase: ethereum.mnemonicPhrase
          },
          providerOrUrl: providerUrl
        });

        // const provider = new Web3.providers.HttpProvider(providerUrl);

        const platformWeb3 = new Web3(provider);
        provider.engine.stop();

        // const hasPrefix = ethereum.platformPK.substring(0, 2) === '0x';

        // const account = platformWeb3.eth.accounts.privateKeyToAccount(
        //   '0x' + ethereum.platformPK
        // );

        // const account = platformWeb3.eth.accounts.wallet.add({
        //   privateKey: hasPrefix ? '' : '0x' + ethereum.platformPK,
        //   address: provider.getAddress()
        // });
        // platformWeb3.eth.accounts.wallet.add(account);
        platformWeb3.eth.defaultAccount = provider.getAddress();
        log("platform account", platformWeb3.eth.defaultAccount);
        resolve(platformWeb3);
      } catch (e) {
        reject(e)
      }
    })


  }

  async createContractObject(address, abi, platform = false, chainId = null) {
    return new Promise(async (resolve, reject) => {
      try {
        if (!address || !abi) {
          log('Invalid parameters, createContractObject aborted...');
          reject('Invalid parameters, createContractObject aborted');
        }

        let provider;
        const chainID = chainId || await this.getChain();

        if (platform) {
          provider = await this.initializePlatformWeb3(chainID);
        } else {
          provider = new Web3(window.ethereum);
        }

        const contract = new provider.eth.Contract(abi, address);

        resolve(contract);
      } catch (e) {
        reject(e);
      }
    });
  }

  async enableEthereum() {
    try {
      const accounts = await window.ethereum.enable();
      return accounts;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async promptAccountChange() {
    return new Promise((resolve, reject) => {
      window.ethereum.request({
        method: "wallet_requestPermissions",
        params: [
          {
            eth_accounts: {}
          }
        ]
      }).then((res) => {
        resolve(res[0]?.caveats?.[0]?.value);
      }).catch(e => {
        reject(e);
      });
    })
  }

  async ethTransfer(recipient, amount) {
    const accounts = await this.getDefaultAccounts();
    let provider = new Web3(window.ethereum);

    const amountInWei = amount * 1000000000000000000;
    return new Promise((resolve, reject) => {
      window.ethereum.request({
        method: 'eth_sendTransaction',
        params: [
          {
            from: accounts[0],
            to: recipient,
            value: Web3.utils.numberToHex(amountInWei)
            // gasPrice: '0x09184e72a000', // optional
            // gas: '0x2710', // optional
          },
        ]
      }).then((txHash) => resolve(txHash))
        .catch((error) => reject(error));
    });

  }
  // ---------------------------- Registry Methods ----------------------------

  // async initializeRegistry() {

  //   const contractsRegistry = await this.createContractObject(
  //     ContractRegistry.networks[10].address,
  //     ContractRegistry.abi,
  //     true,
  //     10
  //   );

  //   return contractsRegistry;

  // }

  async getRegisteredERC721(chainId) {
    const contract = await this.initializeRegistry();
    const result = await contract.methods.getERC721(chainId).call();
    return result;
  }

  async getRegisteredERC20(chainId) {
    const contract = await this.initializeRegistry();
    const result = await contract.methods.getERC20(chainId).call();
    return result;
  }

  async getRegisteredERC1155(chainId) {
    const contract = await this.initializeRegistry();
    const result = await contract.methods.getERC1155(chainId).call();
    return result;
  }
  // ---------------------------- NFT Methods (ERC1155 & ERC721) ----------------------------

  // supported by both standards
  async getName(contract) {
    if (!contract) {
      return Promise.reject('No contract deployed');
    }
    // await this.loadWeb3();

    return new Promise((resolve, reject) => {
      contract.methods.name().call().then((name) => {
        resolve(name);
      }).catch(e => {
        console.error('Could not get name: ', e);
        reject(e);
      });
    })
  }

  // supported by both standards
  async getTotalSupply(contract) {
    if (!contract) {
      return Promise.reject('No contract deployed');
    }
    // await this.loadWeb3();

    return new Promise((resolve, reject) => {
      contract.methods.totalSupply().call().then((supply) => {
        log('Supply is: ', supply);
        resolve(supply);
      }).catch(e => {
        console.error('Could not get total supply: ', e)
        reject(e);
      })
    })
  }

  async getId(contract, assetId) {

    return new Promise((resolve, reject) => {
      contract.methods.getId(assetId).call().then((res) => {
        log('tokenId is: ', res);
        resolve(res);
      }).catch(e => {
        console.error('Could not get tokenId: ', e)
        reject(e);
      })
    })
  }

  async ownerOfAsset(contract, tokenId) {
    return new Promise((resolve, reject) => {
      contract.methods.ownerOf(tokenId).call().then((res) => {
        log(`Owner of asset ${tokenId} is ${res}`);
        resolve(res);
      }).catch(e => {
        console.error('Could not get owner of asset: ', e);
        reject(e);
      })
    })
  }

  // supported only by ERC1155
  async ownerOfMultiAsset(contract, assetId) {
    return new Promise((resolve, reject) => {
      contract.methods.ownerOfAsset(assetId).call().then((res) => {
        log(`Owner of asset ${assetId} is ${res}`);
        resolve(res);
      }).catch(e => {
        console.error('Could not get owner of asset: ', e);
        reject(e);
      });
    });
  }

  // supported only by ERC1155
  async balanceOfMultiOwner(contract, assetId, ownerAddress) {
    return new Promise((resolve, reject) => {
      contract.methods.balanceOfOwner(assetId, ownerAddress).call().then((res) => {
        log(`Balance of owner ${ownerAddress} is ${res}`);
        resolve(res);
      }).catch(e => {
        console.error('Could not get owner of asset: ', e);
        reject(e);
      });
    });
  }

  // supported only by ERC721
  async mintAsset(contract, id) {

    return new Promise(async (resolve, reject) => {
      try {
        const account = await this.getDefaultAccounts();

        const chainId = await this.getChain();
        const gasEstimate = await contract.methods.mint(id).estimateGas({ from: account[0] });
        const nextNonce = '0x' + (await window.web3.eth.getTransactionCount(account[0])).toString(16);
        const web3 = await this.initializePlatformWeb3(chainId);
        const gasPrice = await web3.eth.getGasPrice();

        contract.methods
          .mint(id)
          .send({
            from: account[0],
            gas: gasEstimate,
            gasPrice: gasPrice,
            nonce: nextNonce
            // value: 0
          })
          .once("receipt", (receipt) => {
            resolve({ receipt });
          })
          .once("error", function (error) {
            console.error('Error in minting: ', error);
            reject({ error });
          });
      } catch (e) {
        reject(e);
      }
    });

  }

  // supported only by ERC1155
  async mintMultiAsset(contract, id, amount, data = 0x0) {

    return new Promise(async (resolve, reject) => {
      try {
        const account = await this.getDefaultAccounts();

        const chainId = await this.getChain();
        const gasEstimate = await contract.methods.mint(account[0], id, amount, data).estimateGas({ from: account[0] });
        const nextNonce = '0x' + (await window.web3.eth.getTransactionCount(account[0])).toString(16);
        const web3 = await this.initializePlatformWeb3(chainId);
        const gasPrice = await web3.eth.getGasPrice();

        contract.methods
          .mint(account[0], id, amount, data)
          .send({
            from: account[0],
            gas: gasEstimate,
            gasPrice: gasPrice,
            nonce: nextNonce
            // value: 0
          })
          .once("receipt", (receipt) => {
            resolve({ receipt });
          })
          .once("error", function (error) {
            console.error('Error in minting: ', error);
            reject({ error });
          });

      } catch (e) {
        reject(e);
      }

    });

  }

  // supported by both standards
  async isApprovedForAll(contract, operatee = "") {
    return new Promise(async (resolve, reject) => {

      if (operatee === "") operatee = await this.getDefaultAccounts()[0];
      const chainId = await this.getChain();

      const saysoonMatch = getSaysoonMatchContract();

      contract.methods.isApprovedForAll(operatee, saysoonMatch.networks[chainId].address)
        .call()
        .then((isApproved) => {
          resolve(isApproved);
        })
        .catch(e => {
          reject(e);
        });
    });
  }

  // supported by both standards
  async setApprovalForAll(contract, operator = null) {
    return new Promise(async (resolve, reject) => {
      const accounts = await this.getDefaultAccounts();
      const chainId = await this.getChain();

      const saysoonMatch = getSaysoonMatchContract();
      if (!operator) operator = saysoonMatch.networks[chainId].address;
      const web3 = await this.initializePlatformWeb3(chainId);
      const gasPrice = await web3.eth.getGasPrice();
      const gasEstimate = await contract.methods.setApprovalForAll(saysoonMatch.networks[chainId].address, true).estimateGas({ from: accounts[0] });

      contract.methods.setApprovalForAll(operator, true)
        .send({
          from: accounts[0],
          gas: gasEstimate,
          gasPrice: gasPrice,
          value: 0
        })
        .then((receipt) => {
          resolve(receipt);
        })
        .catch(e => {
          reject(e);
        });
    });
  }

  // supported only by ERC721 --- deprecated
  async transfer(contract, currentOwner, tokenId) {
    log('contract: ', contract);
    return new Promise(async (resolve, reject) => {
      let chainId = await this.getChain();
      let sendPayload;

      switch (chainId) {
        case 137:
          sendPayload = {
            from: ethereum.platformAddress,
            value: 0,
            gas: 300000,
            gasPrice: 5959039914
          };
          break;
        case 4500:
          sendPayload = {
            from: ethereum.platformAddress,
            value: 0,
            gas: 300000,
          }
          break;
        default:
          sendPayload = {
            from: ethereum.platformAddress,
            value: 0,
            gas: 300000,
          }

      }
      const accounts = await this.getDefaultAccounts();
      contract.methods.transferFrom(
        currentOwner,
        accounts[0],
        tokenId
      ).send(sendPayload).once("receipt", (receipt) => {
        resolve({ receipt });
      }).on("error", function (error) {
        console.error('Error in transfer: ', error);
        reject(error);
      });
    });
  }

  // supported only by ERC1155 --- deprecated
  async multiTransfer(contract, currentOwner, tokenId, amount, listingId, data = 0x0) {

    log('contract: ', contract);
    return new Promise(async (resolve, reject) => {
      let chainId = await this.getChain();
      let sendPayload;

      switch (chainId) {
        case 137:
          sendPayload = {
            from: ethereum.platformAddress,
            value: 0,
            gas: 300000,
            gasPrice: 5959039914
          };
          break;
        case 4500:
          sendPayload = {
            from: ethereum.platformAddress,
            value: 0,
            gas: 300000,
          }
          break;
        default:
          sendPayload = {
            from: ethereum.platformAddress,
            value: 0,
            gas: 300000,
          }

      }
      const accounts = await this.getDefaultAccounts();
      contract.methods.transfer(
        currentOwner,
        accounts[0],
        tokenId,
        amount,
        listingId,
        data
      ).send(sendPayload).once("receipt", (receipt) => {
        resolve({ receipt });
      }).on("error", function (error) {
        console.error('Error in transfer: ', error);
        reject(error);
      });
    });
  }

  async matchERC721Order(contract, from, assetId, salePrice) {
    return new Promise(async (resolve, reject) => {
      try {
        const accounts = await this.getDefaultAccounts();

        const salePriceInWei = Web3.utils.toWei(salePrice.toString());
        const chainId = await this.getChain();
        const ERCContractAddr = getContractAddressBasedOnEnv("DigitalAsset", chainId, 10);
        const web3 = await this.initializePlatformWeb3(chainId);
        const gasPrice = await web3.eth.getGasPrice();

        // let gasEstimate = 2000000;

        // if (chainId !== 4500) {
        //   gasEstimate = await contract.methods.matchOrderERC721(
        //     ERCContractAddr,
        //     from,
        //     accounts[0],
        //     assetId,
        //     salePriceInWei).estimateGas({ from: accounts[0] });
        // }

        contract.methods.matchOrderERC721(ERCContractAddr, from, accounts[0], assetId, salePriceInWei)
          .send({
            from: accounts[0],
            // gas: gasEstimate,
            gasPrice: gasPrice,
            value: salePriceInWei
          })
          .then((receipt) => {
            resolve(receipt);
          })
          .catch(e => {
            reject(e);
          });
      } catch (e) {
        reject(e);
      }
    });
  }

  async matchERC1155Order(contract, from, assetId, listingId, salePrice, amount, data = 0x0) {
    return new Promise(async (resolve, reject) => {
      try {
        const accounts = await this.getDefaultAccounts();

        const salePriceInWei = Web3.utils.toWei(salePrice.toString());
        const chainId = await this.getChain();
        const ERCContractAddr = getContractAddressBasedOnEnv("MultiToken", chainId, 10);
        const web3 = await this.initializePlatformWeb3(chainId);
        const gasPrice = await web3.eth.getGasPrice();

        // let gasEstimate = 2000000;

        // if (chainId !== 4500) {
        //   gasEstimate = await contract.methods.matchOrderERC1155(
        //     ERCContractAddr,
        //     from,
        //     accounts[0],
        //     assetId,
        //     listingId,
        //     salePriceInWei,
        //     amount,
        //     data).estimateGas({ from: accounts[0] });
        // }

        contract.methods.matchOrderERC1155(ERCContractAddr, from, accounts[0], assetId, listingId, salePriceInWei, amount, data)
          .send({
            from: accounts[0],
            // gas: gasEstimate,
            gasPrice: gasPrice,
            value: salePriceInWei
          })
          .then((receipt) => {
            resolve(receipt);
          })
          .catch(e => {
            reject(e);
          });
      } catch (e) {
        reject(e);
      }
    });
  }
  // supported by both standards
  // contract should always be initialized with platform's
  // account to make this call
  async grantUserRole(contract, nonceExtra) {
    return new Promise(async (resolve, reject) => {
      const accounts = await this.getDefaultAccounts();

      const chainId = contract.currentProvider.chainId;
      const web3 = await this.initializePlatformWeb3(chainId);
      const transactionCount = await web3.eth.getTransactionCount(ethereum.platformAddress);
      const nextNonce = '0x' + (transactionCount).toString(16);
      const gasPrice = await web3.eth.getGasPrice();
      const gasEstimate = await contract.methods.grantUserRole(accounts[0]).estimateGas({ from: ethereum.platformAddress });

      contract.methods.grantUserRole(accounts[0])
        .send({
          from: ethereum.platformAddress,
          gasPrice: gasPrice, // gas price in wei to use for this transaction, how much each unit of gas is going to cost
          gas: gasEstimate, // the max amount of gas to be used for this transaction, gas * gasPrice = total gas fees
          value: 0,
          nonce: nextNonce
        })
        .once("receipt", (receipt) => {
          resolve({ receipt });
        }).once("error", function (error) {
          console.error('Error in grantUserRole: ', error);
          reject(error);
        });
    });
  }

  async hasRole(contract) {
    return new Promise(async (resolve, reject) => {
      const accounts = await this.getDefaultAccounts();

      contract.methods.hasRole(Web3.utils.keccak256('USER_ROLE'), accounts[0])
        .call()
        .then((role) => {
          resolve(role);
        })
        .catch(e => {
          reject(e);
        });
    });
  }

  async setTokenRoyalty(
    contract,
    ERCStandard,
    assetId,
    receiverOwner,
    receiverCreator,
    receiverPlatform,
    feeNumeratorOwner,
    feeNumeratorCreator,
    feeNumeratorPlatform) {
    return new Promise(async (resolve, reject) => {

      const gasEstimate = await contract.methods.setTokenRoyalty(ERCStandard,
        assetId,
        receiverOwner,
        receiverCreator,
        receiverPlatform,
        feeNumeratorOwner,
        feeNumeratorCreator,
        feeNumeratorPlatform).estimateGas({ from: ethereum.platformAddress });

      const chainId = contract.currentProvider.chainId;
      const web3 = await this.initializePlatformWeb3(chainId);
      const nextNonce = '0x' + (await web3.eth.getTransactionCount(ethereum.platformAddress)).toString(16);
      const gasPrice = await web3.eth.getGasPrice();

      contract.methods.setTokenRoyalty(ERCStandard,
        assetId,
        receiverOwner,
        receiverCreator,
        receiverPlatform,
        feeNumeratorOwner,
        feeNumeratorCreator,
        feeNumeratorPlatform)
        .send({
          from: ethereum.platformAddress,
          gasPrice: gasPrice,
          gas: gasEstimate,
          value: 0,
          nonce: nextNonce
        })
        .once("receipt", (receipt) => {
          resolve(receipt);
        })
        .once("error", (error) => {
          reject(error);
        });
    });
  }

  async getDefaultRoyalty(contract) {
    return new Promise((resolve, reject) => {
      contract.methods.getDefaultRoyalty().call().then((res) => {
        resolve(res);
      }).catch(e => {
        console.error('Could not get default royalty information: ', e);
        reject(e);
      })
    })
  }
  // supported by both standards
  async supportsInterface(contract, interfaceId) {
    return new Promise((resolve, reject) => {
      contract.methods.supportsInterface(interfaceId).call().then((isSupported) => {
        resolve(isSupported);
      }).catch(e => {
        reject(e);
      });
    });
  }

  // ------------- Auction Factory calls -------------

  // create an Auction
  async createAuction(contract, biddingTimeInDays, ERCstandard, nftId, assetId, listingId, amount) {
    return new Promise(async (resolve, reject) => {
      const accounts = await this.getDefaultAccounts();

      const chainId = contract.currentProvider.chainId;
      const web3 = await this.initializePlatformWeb3(chainId);
      const gasPrice = await web3.eth.getGasPrice();
      const gasEstimate = await contract.methods.createAuction(biddingTimeInDays, ERCstandard, nftId, assetId, listingId, amount).estimateGas({ from: accounts[0] });

      contract.methods.createAuction(biddingTimeInDays, ERCstandard, nftId, assetId, listingId, amount)
        .send({
          from: accounts[0],
          gas: gasEstimate,
          gasPrice: gasPrice,
          value: 0
        })
        .then((receipt) => {
          resolve(receipt);
        })
        .catch(e => {
          reject(e);
        });
    });
  }

  // bid on an Auction
  async bid(contract, funds) {
    return new Promise(async (resolve, reject) => {
      const accounts = await this.getDefaultAccounts();

      const chainId = contract.currentProvider.chainId;
      const web3 = await this.initializePlatformWeb3(chainId);
      const gasPrice = await web3.eth.getGasPrice();
      const gasEstimate = await contract.methods.bid(Web3.utils.toWei(Web3.utils.toBN(funds))).estimateGas({ from: accounts[0] });

      contract.methods.bid(Web3.utils.toWei(Web3.utils.toBN(funds)))
        .send({
          from: accounts[0],
          gas: gasEstimate,
          gasPrice: gasPrice,
          value: 0
        })
        .then((receipt) => {
          resolve(receipt);
        })
        .catch(e => {
          reject(e);
        });
    });
  }

  // end an Auction


  // approve weth
  async approveWeth(contract, operator, amount) {
    return new Promise(async (resolve, reject) => {
      const accounts = await this.getDefaultAccounts();

      const chainId = contract.currentProvider.chainId;
      const web3 = await this.initializePlatformWeb3(chainId);
      const gasPrice = await web3.eth.getGasPrice();
      const gasEstimate = await contract.methods.approve(operator, Web3.utils.toWei(Web3.utils.toBN(amount))).estimateGas({ from: accounts[0] });

      contract.methods.approve(operator, Web3.utils.toWei(Web3.utils.toBN(amount)))
        .send({
          from: accounts[0],
          gas: gasEstimate,
          gasPrice: gasPrice,
          value: 0
        })
        .then((receipt) => {
          resolve(receipt);
        })
        .catch(e => {
          reject(e);
        });
    });
  }
}
