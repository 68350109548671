import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const FooterLinkItem = ({ link }) => {
  const { t } = useTranslation();

  const goToLink = (url) => {
    window.open(url, '_blank');
  }

  return (
    <div>
      {link.external ?
        (<Link to="#" onClick={() => { goToLink(link.external) }}>{t(link.label)}</Link>)
        :
        (link.path === "/collections" ? (
          <Link
            to={{
              pathname: "/collections",
            }}
          >
            {t(link.label)}
          </Link>
        ) : (
          <Link to={link.path}>{t(link.label)}</Link>
        ))
      }
    </div>
  );
};

export default FooterLinkItem;
