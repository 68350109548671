import React from "react";

const CertificationGalleryActive = ({ src }) => {
  return (
    <div
      className="ms-5 p-3 text-center"
      style={{
        border: "1px solid rgba(255, 255, 255, 0.1)",
        borderRadius: "0px",
        height: "450px",
      }}
    >
      <img
        src={src}
        style={{
          maxHeight: "100%",
          maxWidth: "100%",
          top: "50%",
          position: "relative",
          transform: "translateY(-50%)",
        }}
        alt="mainImage"
      />
    </div>
  );
};

export default CertificationGalleryActive;
