import React from "react";
import { useTranslation } from "react-i18next";
import { useAssetSelector } from "../provider";
import ProcessingLoader from "./ProcessingLoader";

const BuyProcessingStep = () => {
  const { t } = useTranslation();
  const { asset } = useAssetSelector();

  return (
    <React.Fragment>
      <div className="w_step__title">
        {t("item-details-page.wizard.buy.processing.title")}
      </div>

      <p className="w_step__text mb-4">
        {t("item-details-page.wizard.buy.processing.description", {
          price: asset.price,
        })}
      </p>

      <ProcessingLoader />
    </React.Fragment>
  );
};

export default BuyProcessingStep;
