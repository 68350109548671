import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";
import { retrieveUser } from "../../store/UserSlice";
import Avatar from "./../../assets/img/Esex_simbolo_colore.svg";
import { explorers } from "../../constants/AppSettings";
import { selectPrefixUrl } from "./explorerHelpers";

const ActionItem = ({ item, asset }) => {
  const dispatch = useDispatch();
  const [user, setUser] = useState({});

  /*const fetchUser = useCallback(() => {
   dispatch(retrieveUser(item.bidderId))
      .unwrap()
      .then((originalPromiseResult) => {
        setUser(originalPromiseResult.body.data);
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(rejectedValueOrSerializedError);
      });
  }, [item, dispatch]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);
  */


  return (
    <div className="asset__action asset__action--verified">
      <img src={Avatar} style={{background: "white", padding: "3px"}} alt="avatar" />
      <p>
        {/*
        
        <Trans
          i18nKey="item-details-page.history-item"
          values={{ bid: item.price, created: item.date }}
          components={{ bold: <strong />, break: <br /> }}
        />{" "}
        */}
        {item?.description}
        <br />
        {item?.description && (item.type === "mint" || item.type === "transfer") && <Link className="hyperlinks__color" to="#" onClick={() => window.open(selectPrefixUrl(asset, item))}>Check transaction here</Link>}
        {item?.description && (item.type === "sale") &&
          <React.Fragment>
            <div style={
              {
                fontSize: "10px",
                lineHeight: "12px"
              }
            }>from: {item?.from}</div>

            <div style={
              {
                fontSize: "10px",
                lineHeight: "12px"
              }
            }>to: {item?.to}</div>
          </React.Fragment>
        }
        <Link to="author">{user.username}</Link>
      </p>
    </div>
  );
};

export default ActionItem;
