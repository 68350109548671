import bellImage from "../../assets/Notification IC.png";
import { Dropdown } from "react-bootstrap";
import notificationImage from "../../assets/notification.png";
import { useState, useEffect,useCallback } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { retrieveUser } from "../../store/UserSlice";
import { retrieveNotifications } from "../../store/NotificationSlice";
import { log } from "../../helpers/logger";
const Notifications = () => {
  const [notifNumber, setNotifNumber] = useState(0);
  const dispatch = useDispatch();
  const [username, setUsername] = useState();
  const [notificationList, setNotificationList] = useState([]);

  const styles = {
    numberNotifications: {
      borderRadius: "50%",
      border: "1px solid white",
      backgroundColor: "#6069F6",
      color: "white",
      position: "absolute",
      width: "14px",
      height: "14px",
      fontSize: "8px",
      bottom: "12px",
      left: "10px",
    },
    dropdown: {
      backgroundColor: "#003A5E",
      minWidth: "260px",
      height: "auto",
      border: "1px solid rgba(255, 255, 255, 0.2)",
      boxSizing: "border-box",
      borderRadius: "0px",
    },
    dot: {
      borderRadius: "50%",
      backgroundColor: "#6069F6",
      width: "14px",
      height: "14px",
    },
  };

  const retrieveNotif = useCallback(() => {
    dispatch(retrieveNotifications())
      .unwrap()
      .then((originalPromiseResult) => {
        setNotificationList(originalPromiseResult.body.data);
        setNotifNumber(originalPromiseResult.body.data.length);
      })
      .catch((rejectedValueOrSerializedError) => {
        log(rejectedValueOrSerializedError);
      });
  }, [dispatch]);

  useEffect(() => {
    retrieveNotif();
  }, [retrieveNotif]);

  const resetNotifications = () => {
    const timer = setTimeout(() => {
      setNotificationList(
        notificationList.map((notification) => {
          notification.read = true;
          return notification;
        })
      );
      setNotifNumber(0);
    }, 1000);
    return () => clearTimeout(timer);
  };
  const fetchUserName = (userId) => {
    dispatch(retrieveUser(userId))
      .unwrap()
      .then((originalPromiseResult) => {
        log(originalPromiseResult.body.data);
        setUsername(originalPromiseResult.body.data.username);
      })
      .catch((rejectedValueOrSerializedError) => {
        log(rejectedValueOrSerializedError);
      });
  };

  return (
    <Dropdown
      style={{ backgroundColor: "#003A5E" }}
      className="ml-3"
      onClick={resetNotifications}
    >
      <Dropdown.Toggle
        variant="success"
        id="dropdown-basic"
        style={{ backgroundColor: "#003A5E", border: "none" }}
      >
        <div style={{ position: "relative" }}>
          {notifNumber > 0 && (
            <div style={styles.numberNotifications} className="text-center">
              {notifNumber}
            </div>
          )}
          <img src={bellImage} alt="Bell" />
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu
        className="dropdown-menu-right p-4"
        style={styles.dropdown}
      >
        <div className="d-flex justify-content-between text-white">
          <h4>Notifications</h4>
          <Link
            to="/notifications"
            className="text-white"
            style={{ cursor: "pointer" }}
          >
            <div className="mt-1">See all</div>
          </Link>
        </div>
        {notificationList &&
          notificationList.map((notification) => {
            fetchUserName(notification.ownerId._id);
            return (
              <Dropdown.Item href="#/action-1" className="p-0 mb-1 mt-1">
                <div className="d-flex text-white mt-3">
                  <div className="align-self-center mr-2">
                    <img src={notificationImage} alt="notification" />
                  </div>
                  <div className="align-self-center w-100">
                    <div className="mb-1 mt-1">{notification.title}</div>
                    <div className="d-flex justify-content-between">
                      <div className="mb-1" style={{ fontSize: "13px" }}>
                        @{username}{" "}
                        <span style={{ color: "#bdbdbd", width: "100px" }}>
                          {notification.body}
                        </span>
                      </div>
                      {!notification.read && (
                        <div className="ml-4" style={styles.dot}></div>
                      )}
                    </div>

                    <div style={{ fontSize: "13px", color: "#6164ff" }}>
                      {notification.time}
                    </div>
                  </div>
                </div>
              </Dropdown.Item>
            );
          })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default Notifications;
