import React, { useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { isFunction } from "formik";
import * as Yup from "yup";
import FormikForm from "../../../components/form/FormikForm";
import { TextInput } from "../../../components/form/FormikFields";
import UploadFile from "./UploadFile";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("validation.required.document-name"),
  description: Yup.string().required(
    "validation.required.document-description"
  ),
  file: Yup.mixed().required("validation.required.file"),
});

const formModel = {
  name: "",
  description: "",
  file: null,
};

const DocumentationForm = ({ onSubmit }) => {
  const { t } = useTranslation();
  const [file, setFile] = useState(null);

  const handleSubmit = (values, { resetForm }) => {
    resetForm();
    setFile(null);
    if (isFunction(onSubmit)) onSubmit(values);
  };

  return (
    <FormikForm
      initialValues={formModel}
      validationSchema={validationSchema}
      formDefaultClass="none"
      formClasses="w-100"
      enableReinitialize
      onSubmit={handleSubmit}
    >
      <div>
        <h4 className="sign__title">
          {t("new-certification-page.documentation.upload-documents-title")}
        </h4>

        <p className="text-muted text">
          <Trans
            t={t}
            i18nKey="new-certification-page.documentation.upload-documents-subtitle"
            components={{
              br: <br />,
            }}
          />
        </p>
      </div>

      <Row className="no-gutters w-100">
        <Col className="col-12 col-lg-6">
          <UploadFile file={file} setFile={setFile} />
        </Col>

        <Col className="col-12 col-lg-6">
          <TextInput
            name="name"
            label="form.required.document-name"
            placeholder={t(
              "new-certification-page.documentation.form.placeholder.document-name"
            )}
          />

          <TextInput
            name="description"
            label="form.required.document-description"
            placeholder={t(
              "new-certification-page.documentation.form.placeholder.document-description"
            )}
            inputClass="sign__textarea"
            as="textarea"
            rows="4"
          />
        </Col>
      </Row>

      <Col className="col-12 col-xl-3">
        <Button className="sign__btn" variant="light" type="submit">
          {t("actions.upload-document")}
        </Button>
      </Col>
    </FormikForm>
  );
};

export default DocumentationForm;
