export const getErrorMessage = (error) => {
    let errorMessage = "error-msg.default";
    if (error.error) {
        switch (error.error.code) {
            case 4001:
                errorMessage = "Transaction Rejected";
                break;
            case -32603:
                errorMessage =
                    "Please reset your transaction history through Metamask and try again";
                break;

            default:
                break;
        }
    }

    return errorMessage;
};