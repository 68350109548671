import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ReactComponent as DownArrow } from "../../assets/img/icons/down-arrow.svg";
import { ReactComponent as ThreeDots } from "../../assets/img/icons/three-dots.svg";
import { useState } from "react";
import TopUpModal from "./TopUpModal";
import { useLoader } from "../../providers/loader";

const DropdownNav = ({ title, items, className }) => {
  const { t } = useTranslation();
  const { setLoading, setText } = useLoader();
  const [showModal, setShowModal] = useState(false);

  const openTopUpModal = () => {
    setShowModal(true);
  }

  const hideModal = () => {
    setShowModal(false);
  }

  const goToPage = (url, replace) => {
    setLoading(true);
    setText("Loading...");
    window.open(url, replace ? '_self' : '_blank');
  }

  return (
    <Dropdown className={"header__nav-item cursor-pointer " + className} as="li">
      <Dropdown.Toggle
        id={`dropdown-nav-${title | "0"}`}
        className={
          title && title.length > 0
            ? "header__nav-link"
            : "header__nav-link header__nav-link--menu"
        }
        as="a"
        bsPrefix="c__none"
      >
        {title && title.length > 0 ? (
          <>
            {items?.border && <div className="vl mr-3" style={{ border: "1px solid white", height: "15px" }}>

            </div>}
            {t(title)}

          </>
        ) : (
          <ThreeDots />
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu header__nav-menu" as="ul">
        <TopUpModal show={showModal} hide={hideModal} />

        {items &&
          items.map((item, index) => {
            return (
              <Dropdown.Item key={index} as="li" bsPrefix="c__none">
                {(item.name === "menu.top-up" ?
                  <Link to="#" onClick={openTopUpModal}>{t(item.name)}</Link>
                  :
                  item.external ?
                    (<Link to="#" onClick={() => { goToPage(item.external, item?.replaceUrl) }}>{t(item.name)}</Link>)
                    :
                    (<Link to={item.path}>{t(item.name)}</Link>)
                )}
              </Dropdown.Item>
            );
          })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropdownNav;
