import React, { useCallback, useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import FormikForm from "../../components/form/FormikForm";
import {
  TextInput,
  SingleSelect,
  RadioGroup,
} from "../../components/form/FormikFields";
import ItemPictures from "./ItemPictures";
import { retrieveCollections } from "../../store/CollectionSlice";
import { blockchainsDev, blockchainsProd, categoryOptions, itemTypes } from "../../constants/items";
import { handleChainChange } from "../../store/EthereumSlice";
import { app } from "../../constants/AppSettings";
import { log } from "../../helpers/logger";

const formModel = {
  title: "",
  externalUrl: "",
  description: "",
  category: "",
  collectionId: "",
  blockchain: "",
  price: "",
  saleType: "",
  files: [],
};

const filterCollections = (collections, authUserId) => {
  return collections
    .filter((collection) => collection?.ownerId?.userId._id === authUserId)
    .map((collection) => ({ value: collection?._id, label: collection.title }));
};

const ItemForm = ({ onNextStep }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [hidePrice, setHidePrice] = useState(false);
  const [collectionOptions, setCollectionOptions] = useState([]);
  const { authInfo } = useSelector((state) => state.auth);
  const blockchains = ((app.environment === "prod") || (app.environment === "pre")) ? blockchainsProd : blockchainsDev;

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("validation.required.item-name"),
    description: Yup.string().required("validation.required.description"),
    category: Yup.string()
      .oneOf(
        categoryOptions.map((d) => d.value),
        "validation.required.category"
      )
      .required("validation.required.category"),
    blockchain: Yup.string()
      .oneOf(
        blockchains.map((d) => d.value),
        "validation.required.blockchain"
      )
      .required("validation.required.blockchain"),
    price: (!hidePrice ? Yup.string().required("validation.required.price") : null),
    saleType: Yup.string().required("validation.required.sale-type"),
    files: Yup.array()
      .min(2, "validation.required.min-two-pictures")
      .required("validation.required.min-two-pictures"),
  });


  const handleSubmit = (values) => {
    if (hidePrice) values.blockchain = "";
    onNextStep(values);
  };

  const handleRadio = (value) => {
    if (value.target.value === "NotOnSale") {
      setHidePrice(true);
    } else {
      setHidePrice(false);
    }
  }

  const fetchCollections = useCallback(() => {
    dispatch(retrieveCollections({}))
      .unwrap()
      .then((response) => {
        // console.log(response);
        setCollectionOptions(
          filterCollections(response.body.data, authInfo._id)
        );
      })
      .catch((rejectedValueOrSerializedError) => {
        log(rejectedValueOrSerializedError);
      });
  }, [authInfo, dispatch]);

  useEffect(() => {
    fetchCollections();
  }, [fetchCollections]);


  return (
    <FormikForm
      initialValues={formModel}
      validationSchema={validationSchema}
      formClasses="sign__form--create"
      enableReinitialize
      onSubmit={handleSubmit}
    >
      <div className="w-100 mb-4">
        <ItemPictures certificate={true} />
      </div>

      <h4 className="sign__title">
        {t("new-certification-page.item.item-details")}
      </h4>

      <Row className="w-100">
        <Col className="col-12 col-lg-6">
          <TextInput
            name="title"
            label="form.required.item-name"
            placeholder={t(
              "new-certification-page.item.form.placeholder.item-name"
            )}
          />
        </Col>

        <Col className="col-12 col-lg-6">
          <TextInput
            name="externalUrl"
            label="form.item-external-url"
            placeholder={t(
              "new-certification-page.item.form.placeholder.item-external-url"
            )}
          />
        </Col>

        <Col className="col-12">
          <TextInput
            name="description"
            label="form.required.item-description"
            placeholder={t(
              "new-certification-page.item.form.placeholder.item-description"
            )}
            inputClass="sign__textarea"
            as="textarea"
            rows="4"
          />
        </Col>

        <Col className="col-12 col-lg-6">
          <SingleSelect
            name="category"
            label="form.required.category"
            emptyText="new-certification-page.item.form.placeholder.category"
            options={categoryOptions}
            optionIdPrefix="category"
          // withoutTranslation={true}
          />
        </Col>

        <Col className="col-12 col-lg-6">
          <SingleSelect
            name="collectionId"
            label="form.collection"
            emptyText="new-certification-page.item.form.placeholder.collection"
            options={collectionOptions}
            optionIdPrefix="collection"
            withoutTranslation={true}
          />
        </Col>
      </Row>

      <h4 className="sign__title">
        {t("new-certification-page.item.market-details")}
      </h4>

      <Row className="w-100">
        <Col className="col-12">
          <SingleSelect
            name="blockchain"
            label="form.required.blockchain"
            emptyText="new-certification-page.item.form.placeholder.blockchain"
            options={blockchains}
            optionIdPrefix="blockchain"
            onChange={async (chain) => {
              await dispatch(handleChainChange({ chain })).unwrap();
            }}
            withoutTranslation={true}
          />
        </Col>

        <Col className="col-12 col-lg-6">
          {!hidePrice && <TextInput
            name="price"
            label="form.required.price"
            placeholder={t(
              "new-certification-page.item.form.placeholder.price"
            )}
          />}
        </Col>

        <Col className="col-12 col-lg-6">
          <RadioGroup
            name="saleType"
            label="form.required.sale-type"
            listStyle="void"
            options={itemTypes}
            itemIdPrefix="saleType"
            onChange={async (value) => { handleRadio(value) }}
          />
        </Col>
      </Row>

      <Col className="col-12 col-xl-3">
        <Button className="sign__btn" variant="light" type="submit">
          {t("actions.next")}
        </Button>
      </Col>
    </FormikForm>
  );
};

export default ItemForm;
