import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import Item from "../../components/item/Item";
import { retrieveCertifications } from "../../store/CertificationSlice";

const CertificationList = ({ currentUserProfile }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [certificateList, setCertificationList] = useState([]);

  useEffect(() => {
    const fetchCertifications = async () => {
      try {
        const originalPromiseResult = await dispatch(
          retrieveCertifications({ type: "certificate", ownerId: currentUserProfile })
        ).unwrap();

        const responseDataJSON = JSON.parse(originalPromiseResult.data);
        setCertificationList(responseDataJSON.data);
      } catch (rejectedValueOrSerializedError) {
        console.error(rejectedValueOrSerializedError);
      }
    };

    fetchCertifications();
  }, [id, dispatch]);

  return (
    <Row className="row--grid">
      {certificateList.map((data, index) => (
        <Col key={index} className="col-12 col-sm-6 col-lg-4">
          <Item itemId={data._id} item={data} />
        </Col>
      ))}
    </Row>
  );
};

export default CertificationList;
