import React, { useRef } from "react";
import { isFunction } from "formik";
import { Button } from "react-bootstrap";
import uploadIcon from "../../assets/img/upload_icon.svg";

const Simple = ({ onSelect }) => {
  const inputRef = useRef();

  const handleChange = (event) => {
    if (isFunction(onSelect)) onSelect(event.target.files[0]);

    if (inputRef.current) inputRef.current.value = ""; // Reset input value
  };

  const openSelectDialog = () => {
    if (inputRef.current) inputRef.current.click();
  };

  const droppedFile = (event) => {
    // const dt = new DataTransfer();
    event.preventDefault();
    // console.log('someone dropped something :O ', event.dataTransfer.files[0]);
    onSelect(event.dataTransfer.files[0]);
  }

  return (
    <div onDragOver={(event) => {
      event.preventDefault();
    }}
      onDrop={droppedFile}>
      <input
        ref={inputRef}
        className="d-none"
        type="file"
        name="file"
        onChange={handleChange}
      />

      <Button
        className="px-4"
        variant="light"
        style={{ width: "140px", borderRadius: "0px", backgroundColor: "#4BA4E3" }}
        onClick={openSelectDialog}
      >
        <img src={uploadIcon} width="24px" alt="upload-icon" />
      </Button>
    </div>
  );
};

export default Simple;
