import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import services from "../services";
import { setAuthToken, getEthereumAccount } from "../helpers/auth";
import { log } from "../helpers/logger";
var jwt = require("jsonwebtoken");

export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const loginResponse = await services.UserService.loginUser({
        email,
        password,
      });

      const decodedToken = jwt.decode(
        loginResponse.body.data.token,
        "weriu34sdmkd326842lfjewrmekoifrqwei"
      );
      setAuthToken(`Bearer ${loginResponse.body.data.token}`);

      return {
        userId: decodedToken.user.id,
        loginData: loginResponse.body.data,
      };
    } catch (error) {
      setAuthToken(null);
      return rejectWithValue(error);
    }
  }
);

export const retrieveAuthUser = createAsyncThunk(
  "auth/retrieveAuthUser",
  async ({ userId }, { dispatch, getState, rejectWithValue }) => {
    try {
      const {
        auth: { authActiveProfileId },
      } = getState();

      const response = await services.UserService.retrieveUser(userId);
      const responseData = response.body.data;
      const formatedResponseData = {
        ...responseData,
        profiles: responseData.profiles.map((profile) => ({
          ...profile,
          profileId: profile.profileId._id,
        })),
      };

      if (formatedResponseData.profiles.length > 0) {
        const findProfile = formatedResponseData.profiles.find(
          (profile) => profile.profileId === authActiveProfileId
        );
        const profileId = findProfile
          ? findProfile.profileId
          : formatedResponseData.profiles[0].profileId;
        await dispatch(retrieveAuthProfile({ profileId, userId })).unwrap();
      }

      return formatedResponseData;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createAuthProfile = createAsyncThunk(
  "auth/createAuthProfile",
  async ({ userId, payload }, { rejectWithValue }) => {
    try {
      const response = await services.ProfileService.createProfile(userId, payload);

      return response.body.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const retrieveAuthProfile = createAsyncThunk(
  "auth/retrieveAuthProfile",
  async ({ userId, profileId }, { rejectWithValue }) => {
    try {
      const response = await services.ProfileService.retrieveProfile(userId, profileId);
      return response.body.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateAuthProfile = createAsyncThunk(
  "auth/updateAuthProfile",
  async ({ userId, profileId, payload }, { dispatch, rejectWithValue }) => {
    try {
      const response = await services.ProfileService.updateProfile(
        userId,
        profileId,
        payload
      );

      return response.body.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);
export const createAuthProfileSocial = createAsyncThunk(
  "auth/createAuthProfileSocial",
  async ({ userId, profileId, payload }, { rejectWithValue }) => {
    try {
      const response = await services.ProfileService.createProfileSocial(
        userId,
        profileId,
        payload
      );

      return response.body.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const retrieveAuthProfileSocials = createAsyncThunk(
  "auth/retrieveAuthProfileSocials",
  async ({ userId, profileId }, { rejectWithValue }) => {
    try {
      const response = await services.ProfileService.retrieveProfileSocials(
        userId,
        profileId
      );
      return response.body.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const recoverPassword = createAsyncThunk(
  "auth/recoverPassword",
  async (data, { rejectWithValue }) => {
    try {
      const response = await services.UserService.recoverPassword(data);
      log(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

let initialState = {
  state: "",
  isAuthorized: false,
  userId: "",
  isSetUp: false,
  credentials: "",
  authEthereumAddress: "",
  authEthereumId: "",
  authInfo: {},
  authProfile: {},
  authActiveProfileId: "",
  authSocials: {},
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    SET_CREDENTIALS(state, action) {
      state.credentials = action.payload;
    },
    LOGOUT_USER(state) {
      state.isAuthorized = false;
      state.userId = "";
      state.isSetUp = false;
      state.authInfo = {};
      state.authProfile = {};
      state.authActiveProfileId = "";
      state.authEthereumAddress = "";
      state.authEthereumId = "";
      setAuthToken(null);
    },
    LOGOUT_ETHEREUM_ACCOUNT(state) {
      state.authEthereumAddress = "";
      state.authEthereumId = "";
    },
  },
  extraReducers: {
    /* ---- LOGIN_USER ---- */
    [String(loginUser.pending)]: (state, action) => {
      state.state = "pending";
    },
    [String(loginUser.fulfilled)]: (state, action) => {
      const { userId } = action.payload;
      state.userId = userId;
      state.isAuthorized = true;
      state.isSetUp = false;
      state.state = "success";
    },
    [String(loginUser.rejected)]: (state, action) => {
      state.userId = "";
      state.isAuthorized = false;
      state.isSetUp = false;
      state.state = "error";
    },

    /* ---- RETRIEVE_AUTH_USER ---- */
    [String(retrieveAuthUser.pending)]: (state, action) => { },
    [String(retrieveAuthUser.fulfilled)]: (state, action) => {
      state.authInfo = action.payload;
      const accountInfo = getEthereumAccount(action.payload.identities);
      state.authEthereumAddress = accountInfo.identity;
      state.authEthereumId = accountInfo.id;
    },
    [String(retrieveAuthUser.rejected)]: (state) => {
      state.authInfo = {};
    },

    /* ---- CREATE_AUTH_PROFILE ---- */
    [String(createAuthProfile.pending)]: (state, action) => { },
    [String(createAuthProfile.fulfilled)]: (state, action) => { },
    [String(createAuthProfile.rejected)]: (state, action) => { },

    /* ---- RETRIEVE_AUTH_PROFILE ---- */
    [String(retrieveAuthProfile.pending)]: (state, action) => { },
    [String(retrieveAuthProfile.fulfilled)]: (state, action) => {
      state.authProfile = action.payload;
      state.authActiveProfileId = action.payload._id;
    },
    [String(retrieveAuthProfile.rejected)]: (state) => {
      state.authProfile = {};
      state.authActiveProfileId = "";
    },

    /* ---- UPDATE_AUTH_PROFILE ---- */
    [String(updateAuthProfile.pending)]: (state, action) => { },
    [String(updateAuthProfile.fulfilled)]: (state, action) => { },
    [String(updateAuthProfile.rejected)]: (state, action) => { },

    /* ---- CREATE_AUTH_PROFILE_SOCIAL ---- */
    [String(createAuthProfileSocial.pending)]: (state, action) => { },
    [String(createAuthProfileSocial.fulfilled)]: (state, action) => { },
    [String(createAuthProfileSocial.rejected)]: (state, action) => { },

    /* ---- RETRIEVE_AUTH_PROFILE_SOCIALS ---- */
    [String(retrieveAuthProfileSocials.pending)]: (state, action) => { },
    [String(retrieveAuthProfileSocials.fulfilled)]: (state, action) => { },
    [String(retrieveAuthProfileSocials.rejected)]: (state, action) => { },

    /* ---- RECOVER_PASSWORD ---- */
    [String(recoverPassword.pending)]: (state, action) => { },
    [String(recoverPassword.fulfilled)]: (state, action) => { },
    [String(recoverPassword.rejected)]: (state, action) => { },
  },
});

export const { LOGOUT_USER, SET_CREDENTIALS, LOGOUT_ETHEREUM_ACCOUNT } =
  authSlice.actions;

export default authSlice.reducer;
