import React from "react";
import { Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import leftImage from "../../assets/cornice_01.png";
import rightImage from "../../assets/cornice_02.png";
import { useTranslation, Trans } from "react-i18next";
import {useEffect} from "react"

const Intro = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.getElementById("myVideo").playbackRate = 0.4;
  },[])

  return (
    <div className="home ">
      <video autoPlay loop muted id="myVideo">
        <source
          src="https://dc577oezng2mj.cloudfront.net/hero.webm"
          type="video/webm"
        />
      </video>
      <Container className="pb-0">
        <Row>
          <div className="col-12">
            <div className="home__content home__content--center">
              <div style={{ position: "relative" }}>
                <div className="corniceDiv d-none d-md-block d-lg-block d-xl-block">
                  <img className="cornice" src={leftImage} alt="left" />
                </div>
                <div className="corniceDiv1 d-block d-sm-block d-md-none d-lg-none d-xl-none">
                  <img className="cornice1" src={leftImage} alt="left" />
                </div>

                <div>
                  <h1 style={{margin:"auto"}} className="home__title w-75  d-block d-sm-block d-md-none d-lg-none d-xl-none">
                    <Trans
                      t={t}
                      i18nKey="home-page.intro-title"
                      components={{
                        break: <br />,
                        span: <span />,
                      }}
                    />
                  </h1>
                  <h1 className="home__title d-none d-sm-none d-md-block d-lg-block d-xl-block">
                    <Trans
                      t={t}
                      i18nKey="home-page.intro-title"
                      components={{
                        break: <br />,
                        span: <span />,
                      }}
                    />
                  </h1>
                  <p className="home__text  d-none d-sm-none d-md-block d-lg-block d-xl-block">{t("home-page.intro-subtitle")}</p>
                </div>

                
                <div className="corniceDiv3 d-block d-sm-block d-md-none d-lg-none d-xl-none">
                  <img className="cornice1" src={rightImage} alt="left" />
                </div>

                <div className="corniceDiv2 d-none d-sm-none d-md-block d-lg-block d-xl-block">
                  <img className="cornice" src={rightImage} alt="left" />
                </div>
              </div>

              <div className="home__btns d-none d-sm-none d-md-block d-lg-block d-xl-block">
                <Link to="/explore" className="home__btn">
                  {t("actions.explore")}
                </Link>

                <Link to="/new-item" className="home__btn">
                  {t("actions.create")}
                </Link>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default Intro;
