import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ethereum, explorers } from "../constants/AppSettings";
import services from "../services";
import { getContractAddressBasedOnEnv } from "../services/serviceHelpers";

export const loadWeb3 = createAsyncThunk(
  "ethereum/loadWeb3",
  async ({ ...props }, { rejectWithValue }) => {
    try {
      const response = await services.EthereumService.loadWeb3();

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const initializeContract = createAsyncThunk(
  "ethereum/initializeContract",
  async ({ platform = false, contractName, chainId, at }, { getState, rejectWithValue }) => {
    try {
      const response = await services.EthereumService.initializeContract(platform, contractName, chainId, at);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const mintAsset = createAsyncThunk(
  "ethereum/mint",
  async ({ id }, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { contract } = state.ethereum;
      const response = await services.EthereumService.mintAsset(contract, id);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const mintMultiAsset = createAsyncThunk(
  "ethereum/mintMulti",
  async ({ id, amount }, { getState, rejectWithValue }) => {
    try {
      if (!amount) amount = 1;
      const state = getState();
      const { contract } = state.ethereum;
      const response = await services.EthereumService.mintMultiAsset(contract, id, amount);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getTotalSupply = createAsyncThunk(
  "ethereum/getTotalSupply",
  async ({ ...props }, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { contract } = state.ethereum;
      const response = await services.EthereumService.getTotalSupply(contract);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getId = createAsyncThunk(
  "ethereum/getId",
  async ({ assetId }, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { contract } = state.ethereum;
      const response = await services.EthereumService.getId(contract, assetId);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const ownerOfAsset = createAsyncThunk(
  "ethereum/ownerOfAsset",
  async ({ tokenId }, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { contract } = state.ethereum;
      const response = await services.EthereumService.ownerOfAsset(contract, tokenId);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const ownerOfMultiAsset = createAsyncThunk(
  "ethereum/ownerOfMultiAsset",
  async ({ assetId }, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { contract } = state.ethereum;
      const response = await services.EthereumService.ownerOfMultiAsset(contract, assetId);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const balanceOfMultiOwner = createAsyncThunk(
  "ethereum/balanceOfMultiOwner",
  async ({ assetId, ownerAddress }, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { contract } = state.ethereum;
      const response = await services.EthereumService.balanceOfMultiOwner(contract, assetId, ownerAddress);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const isApprovedForAll = createAsyncThunk(
  "ethereum/isApprovedForAll",
  async ({ operatee }, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { contract } = state.ethereum;
      const response = await services.EthereumService.isApprovedForAll(contract, operatee);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const approveForAll = createAsyncThunk(
  "ethereum/approveForAll",
  async ({ operator }, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { contract } = state.ethereum;
      const response = await services.EthereumService.setApprovalForAll(contract, operator);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const setTokenRoyalty = createAsyncThunk(
  "ethereum/setTokenRoyalty",
  async ({
    ERCStandard,
    assetId,
    receiverOwner,
    receiverCreator,
    receiverPlatform,
    feeNumeratorOwner,
    feeNumeratorCreator,
    feeNumeratorPlatform }, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { contract } = state.ethereum;
      const response = await services.EthereumService.setTokenRoyalty(
        contract,
        ERCStandard,
        assetId,
        receiverOwner,
        receiverCreator,
        receiverPlatform,
        feeNumeratorOwner,
        feeNumeratorCreator,
        feeNumeratorPlatform);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDefaultRoyalty = createAsyncThunk(
  "ethereum/getDefaultRoyalty",
  async ({ }, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { contract } = state.ethereum;
      const response = await services.EthereumService.getDefaultRoyalty(contract);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const supportsInterface = createAsyncThunk(
  "ethereum/supportsInterface",
  async ({ interfaceId }, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { contract } = state.ethereum;
      const response = await services.EthereumService.supportsInterface(contract, interfaceId);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getChain = createAsyncThunk(
  "ethereum/getChain",
  async ({ ...props }, { getState, rejectWithValue }) => {
    try {
      const response = await services.EthereumService.getChain();

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const promptChainChange = createAsyncThunk(
  "ethereum/promptChainChange",
  async ({ chainId, rpcUrl }, { getState, rejectWithValue }) => {
    try {
      const response = await services.EthereumService.promptChainChange(
        chainId,
        rpcUrl
      );

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const handleChainChange = createAsyncThunk(
  "ethereum/handleChainChange",
  async ({ chain }, { getState, rejectWithValue }) => {
    try {
      const currentChain = await services.EthereumService.getChain();
      if (chain === "ropsten") {
        if (currentChain !== 3) {
          await services.EthereumService.promptChainChange(
            "0x3",
            ethereum.ropstenUrl
          );
        }
      } else if (chain === "saysoon") {
        if (currentChain !== 4500) {
          await services.EthereumService.promptChainChange(
            "0x1194",
            ethereum.saysoonUrl
          );
        }
      } else if (chain === "mumbai") {
        if (currentChain !== 80001) {
          await services.EthereumService.promptChainChange(
            "0x13881",
            ethereum.mumbaiUrl
          );
        }
      } else if (chain === "polygon") {
        if (currentChain !== 137) {
          await services.EthereumService.promptChainChange(
            "0x89",
            ethereum.polygonUrl
          );
        }
      } else if (chain === "rinkeby") {
        if (currentChain !== 4) {
          await services.EthereumService.promptChainChange(
            "0x4",
            ethereum.rinkebyUrl
          );
        }
      } else if (chain === "goerli") {
        if (currentChain !== 5) {
          await services.EthereumService.promptChainChange(
            "0x5",
            ethereum.goerliUrl
          );
        }
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getEthereumAccounts = createAsyncThunk(
  "ethereum/getEthereumAccounts",
  async ({ ...props }, { getState, rejectWithValue }) => {
    try {
      const accounts = await services.EthereumService.getDefaultAccounts();

      return accounts;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAccountBalance = createAsyncThunk(
  "ethereum/getAccountBalance",
  async ({ accountAddress, chainId }, { getState, rejectWithValue }) => {
    try {
      const balance = await services.EthereumService.getEthBalance(
        accountAddress,
        chainId
      );

      return balance;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getSignature = createAsyncThunk(
  "ethereum/getSignature",
  async ({ message, accountAddress }, { getState, rejectWithValue }) => {
    try {
      const signature = await services.EthereumService.getSignature(
        message,
        accountAddress
      );

      return signature;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const enableEthereum = createAsyncThunk(
  "ethereum/enableEthereum",
  async (data, { getState, rejectWithValue }) => {
    try {
      const accounts = await services.EthereumService.enableEthereum();

      return accounts;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const promptAccountChange = createAsyncThunk(
  "ethereum/promptAccountChange",
  async ({ ...props }, { getState, rejectWithValue }) => {
    try {
      const selectedAccounts = await services.EthereumService.promptAccountChange();
      // @todo: should handle multiple accounts here

      return selectedAccounts;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const ethTransfer = createAsyncThunk(
  "ethereum/ethTransfer",
  async ({ recipient, amount }, { getState, rejectWithValue }) => {
    try {
      const receipt = await services.EthereumService.ethTransfer(recipient, amount);

      return receipt;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// deprecated
export const tokenTransfer = createAsyncThunk(
  "ethereum/tokenTransfer",
  async ({ from, tokenId }, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { contract } = state.ethereum;
      const receipt = await services.EthereumService.transfer(contract, from, tokenId);

      return receipt;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// deprecated
export const multiTokenTransfer = createAsyncThunk(
  "ethereum/multiTokenTransfer",
  async ({ from, tokenId, amount, listingId }, { getState, rejectWithValue }) => {
    try {
      if (!amount) amount = 1;
      const state = getState();
      const { contract } = state.ethereum;
      const receipt = await services.EthereumService.multiTransfer(contract, from, tokenId, amount, listingId);

      return receipt;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const grantUserRole = createAsyncThunk(
  "ethereum/grantUserRole",
  async ({ nonceExtra = 0 }, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { contract } = state.ethereum;
      const receipt = await services.EthereumService.grantUserRole(contract, nonceExtra);
      return receipt;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const hasRole = createAsyncThunk(
  "ethereum/hasRole",
  async ({ ...props }, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { contract } = state.ethereum;
      const receipt = await services.EthereumService.hasRole(contract);
      return receipt;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const erc721MatchOrder = createAsyncThunk(
  "ethereum/erc721MatchOrder",
  async ({ from, assetId, salePrice }, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { contract } = state.ethereum;
      const receipt = await services.EthereumService.matchERC721Order(contract, from, assetId, salePrice);

      return receipt;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const erc1155MatchOrder = createAsyncThunk(
  "ethereum/erc1155MatchOrder",
  async ({ from, assetId, listingId, salePrice, amount, data }, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { contract } = state.ethereum;
      const receipt = await services.EthereumService.matchERC1155Order(contract, from, assetId, listingId, salePrice, amount, data);

      return receipt;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// ------- Auciton functions -------
export const createAuction = createAsyncThunk(
  "ethereum/createAuction",
  async ({ biddingTimeInDays, ERCstandard, nftId, assetId, listingId, amount }, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { contract } = state.ethereum;
      const receipt = await services.EthereumService.createAuction(contract, biddingTimeInDays, ERCstandard, nftId, assetId, listingId, amount);

      return receipt;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const bid = createAsyncThunk(
  "ethereum/bid",
  async ({ funds }, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { contract } = state.ethereum;
      const receipt = await services.EthereumService.bid(contract, funds);

      return receipt;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// ------- WETH functions -------

export const approveWeth = createAsyncThunk(
  "ethereum/approveWeth",
  async ({ operator, amount }, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { contract } = state.ethereum;
      const receipt = await services.EthereumService.approveWeth(contract, operator, amount);

      return receipt;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

let initialState = {
  ethereumAccountAddress: "",
  accounts: [],
  contract: {},
  chainId: "0x0",
  symbol: 'ETH',
  balance: 0,
  walletInstalled: false,
  walletConnected: false,
  signature: "",
  receipt: {},
  initialized: false,
  state: "",
  error: {},
};

const ethereumSlice = createSlice({
  name: "ethereum",
  initialState,
  reducers: {
    ETHEREUM_RESET_ALL(state) {
      state.ethereumAccountAddress = "";
      state.accounts = [];
      state.contract = {};
      state.balance = 0;
      state.walletInstalled = false;
      state.walletConnected = false;
      state.signature = "";
      state.receipt = {};
      state.initialized = false;
    },
    ETHEREUM_RESET(state) {
      state.ethereumAccountAddress = "";
      state.accounts = [];
      state.contract = {};
      state.balance = 0;
      state.walletConnected = false;
      state.signature = "";
      state.receipt = {};
      state.initialized = false;
    },
    SET_CHAIN(state, action) {
      state.chainId = action.payload;
    },
    SET_SYMBOL(state, action) {
      state.symbol = action.payload;
    },
    SET_INITIALIZED(state, action) {
      state.initialized = action.payload;
    },
    SET_WALLET_INSTALLED(state, action) {
      state.walletInstalled = action.payload;
    },
    SET_WALLET_CONNECTED(state, action) {
      state.walletConnected = action.payload;
    },
    SET_ACCOUNT_ADDRESS(state, action) {
      state.ethereumAccountAddress = action.payload;
    },
  },
  extraReducers: {
    /* ---- LOAD_WEB_3 ---- */
    [String(loadWeb3.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(loadWeb3.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(loadWeb3.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
    /* ---- INITIALIZE_CONTRACT ---- */
    [String(initializeContract.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(initializeContract.fulfilled)]: (state, action) => {
      state.state = "success";
      state.contract = action.payload;
    },
    [String(initializeContract.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
    /* ---- MINT ---- */
    [String(mintAsset.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(mintAsset.fulfilled)]: (state, action) => {
      state.state = "success";
      state.receipt = action.payload.receipt;
    },
    [String(mintAsset.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
    /* ---- MULTI MINT ---- */
    [String(mintMultiAsset.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(mintMultiAsset.fulfilled)]: (state, action) => {
      state.state = "success";
      state.receipt = action.payload.receipt;
    },
    [String(mintMultiAsset.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
    /* ---- TOTAL_SUPPLY ---- */
    [String(getTotalSupply.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(getTotalSupply.fulfilled)]: (state, action) => {
      state.state = "success";
      state.receipt = action.payload.receipt;
    },
    [String(getTotalSupply.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
    /* ---- GET_CHAIN ---- */
    [String(getChain.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(getChain.fulfilled)]: (state, action) => {
      state.state = "success";
      state.receipt = action.payload;
    },
    [String(getChain.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
    /* ---- PROMPT_CHAIN_CHANGE ---- */
    [String(promptChainChange.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(promptChainChange.fulfilled)]: (state, action) => {
      state.state = "success";
      state.receipt = action.payload;
    },
    [String(promptChainChange.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
    /* ---- HANDLE_CHAIN_CHANGE ---- */

    [String(handleChainChange.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(handleChainChange.fulfilled)]: (state, action) => {
      state.state = "success";
      state.receipt = action.payload;
    },
    [String(handleChainChange.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
    /* ---- GET_ETHEREUM_ACCOUNTS_CHANGE ---- */
    [String(getEthereumAccounts.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(getEthereumAccounts.fulfilled)]: (state, action) => {
      state.state = "success";
      state.accounts = action.payload;
      state.ethereumAccountAddress = action.payload[0];
    },
    [String(getEthereumAccounts.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
    /* ---- GET_ACCOUNT_BALANCE ---- */
    [String(getAccountBalance.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(getAccountBalance.fulfilled)]: (state, action) => {
      state.state = "success";
      state.balance = action.payload;
    },
    [String(getAccountBalance.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
    /* ---- GET_SIGNATURE ---- */
    [String(getSignature.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(getSignature.fulfilled)]: (state, action) => {
      state.state = "success";
      state.signature = action.payload;
    },
    [String(getSignature.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
    /* ---- ENABLE_ETHEREUM ---- */
    [String(enableEthereum.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(enableEthereum.fulfilled)]: (state, action) => {
      state.state = "success";
      state.accounts = action.payload;
      state.ethereumAccountAddress = action.payload[0];
    },
    [String(enableEthereum.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
    /* ---- PROMPT_ACCOUNT_CHANGE ---- */
    [String(promptAccountChange.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(promptAccountChange.fulfilled)]: (state, action) => {
      state.state = "success";
      state.receipt = action.payload;
    },
    [String(promptAccountChange.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
    /* ---- GET_ID ---- */
    [String(getId.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(getId.fulfilled)]: (state, action) => {
      state.state = "success";
      state.receipt = action.payload;
    },
    [String(getId.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
    /* ---- IS_APPROVED_FOR_ALL ---- */
    [String(isApprovedForAll.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(isApprovedForAll.fulfilled)]: (state, action) => {
      state.state = "success";
      state.receipt = action.payload;
    },
    [String(isApprovedForAll.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
    /* ---- APPROVE_FOR_ALL ---- */
    [String(approveForAll.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(approveForAll.fulfilled)]: (state, action) => {
      state.state = "success";
      state.receipt = action.payload;
    },
    [String(approveForAll.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
    /* ---- SET_ROYALTY ---- */
    [String(setTokenRoyalty.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(setTokenRoyalty.fulfilled)]: (state, action) => {
      state.state = "success";
      state.receipt = action.payload;
    },
    [String(setTokenRoyalty.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
    /* ----- SUPPORTS_INTERFACE ---- */
    [String(supportsInterface.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(supportsInterface.fulfilled)]: (state, action) => {
      state.state = "success";
      state.receipt = action.payload;
    },
    [String(supportsInterface.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
    /* ---- OWNER_OF_ASSET ---- */
    [String(ownerOfAsset.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(ownerOfAsset.fulfilled)]: (state, action) => {
      state.state = "success";
      state.receipt = action.payload;
    },
    [String(ownerOfAsset.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
    /* ---- OWNER_OF_MULTI_ASSET ---- */
    [String(ownerOfMultiAsset.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(ownerOfMultiAsset.fulfilled)]: (state, action) => {
      state.state = "success";
      state.receipt = action.payload;
    },
    [String(ownerOfMultiAsset.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
    /* ---- BALANCE_OF_MULTI_OWNER ---- */
    [String(balanceOfMultiOwner.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(balanceOfMultiOwner.fulfilled)]: (state, action) => {
      state.state = "success";
      state.receipt = action.payload;
    },
    [String(balanceOfMultiOwner.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
    /* ---- ETH_TRANSFER ---- */
    [String(ethTransfer.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(ethTransfer.fulfilled)]: (state, action) => {
      state.state = "success";
      state.receipt = action.payload;
    },
    [String(ethTransfer.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
    /* ---- GRANT_USER_ROLE ---- */
    [String(grantUserRole.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(grantUserRole.fulfilled)]: (state, action) => {
      state.state = "success";
      state.receipt = action.payload;
    },
    [String(grantUserRole.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
    /* ---- HAS_USER_ROLE ---- */
    [String(hasRole.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(hasRole.fulfilled)]: (state, action) => {
      state.state = "success";
      state.receipt = action.payload;
    },
    [String(hasRole.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
    /* ---- ERC721_ORDER_MATCH ---- */
    [String(erc721MatchOrder.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(erc721MatchOrder.fulfilled)]: (state, action) => {
      state.state = "success";
      state.receipt = action.payload;
    },
    [String(erc721MatchOrder.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
    /* ---- ERC1155_ORDER_MATCH ---- */
    [String(erc1155MatchOrder.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(erc1155MatchOrder.fulfilled)]: (state, action) => {
      state.state = "success";
      state.receipt = action.payload;
    },
    [String(erc1155MatchOrder.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
    /* ---- CREATE_AUCTION ---- */
    [String(createAuction.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(createAuction.fulfilled)]: (state, action) => {
      state.state = "success";
      state.receipt = action.payload;
    },
    [String(createAuction.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
    /* ---- BID ---- */
    [String(bid.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(bid.fulfilled)]: (state, action) => {
      state.state = "success";
      state.receipt = action.payload;
    },
    [String(bid.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
    /* ---- APPROVE WETH ---- */
    [String(approveWeth.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(approveWeth.fulfilled)]: (state, action) => {
      state.state = "success";
      state.receipt = action.payload;
    },
    [String(approveWeth.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    }
  },
});

export const {
  ETHEREUM_RESET_ALL,
  ETHEREUM_RESET,
  SET_CHAIN,
  SET_SYMBOL,
  SET_INITIALIZED,
  SET_WALLET_INSTALLED,
  SET_WALLET_CONNECTED,
  SET_ACCOUNT_ADDRESS,
} = ethereumSlice.actions;

export default ethereumSlice.reducer;
