import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import CollectionItem from "../../components/collectionItem/CollectionItem";
import { retrieveCollections } from "../../store/CollectionSlice";
import { useParams } from "react-router"

const CollectionList = ({ currentUserProfile }) => {
  const dispatch = useDispatch();
  const [collectionList, setCollectionList] = useState([]);
  // const { authInfo } = useSelector((state) => state.auth);
  // let query = useParams();

  useEffect(() => {
    const fetchCollections = async () => {
      try {
        const originalPromiseResult = await dispatch(
          retrieveCollections({ ownerId: currentUserProfile })
        ).unwrap();

        const responseDataJSON = JSON.parse(originalPromiseResult.data);
        setCollectionList(responseDataJSON.data);
      } catch (rejectedValueOrSerializedError) {
        console.error(rejectedValueOrSerializedError);
      }
    };

    fetchCollections();
  }, [dispatch]);

  return (
    <Row className="row--grid">
      {collectionList.map((data, index) => (
        <Col key={index} className="col-12 col-sm-6 col-lg-4">
          <CollectionItem key={index} collection={data} />
        </Col>
      ))}
    </Row>
  );
};

export default CollectionList;
