
import React, { useState } from "react";
// import { useTranslation } from "react-i18next";
import SellTypeSelection from "./SellTypeSelection";
import SellLaterForm from "./SellLaterForm";
import SellNowForm from "./SellNowForm";
import BidNowForm from "./BidNowForm";


const MarketForm = ({ handleAssetCreation, isMinted, standard, chain }) => {
  // const { t } = useTranslation();
  const [sellType, setSellType] = useState("notOnSale");

  const handleTypeSelection = (type) => {
    setSellType(type);
  };

  const handleCreation = (values) => {
    handleAssetCreation(values);
  }

  return (
    <React.Fragment>

      <h4 className="sign__title">
        Market details
      </h4>

      <SellTypeSelection handler={handleTypeSelection} isMinted={isMinted} />

      {sellType === "notOnSale" && <SellLaterForm callback={handleCreation} />}
      {sellType === "onSale" && <SellNowForm callback={handleCreation} isMinted={isMinted} standard={standard} chain={chain}/>}
      {sellType === "onBid" && <BidNowForm callback={handleCreation} />}

    </React.Fragment>

  );

}

export default MarketForm;
