import { isObject } from "./utils";
import { userSocialIcons } from "../constants/socials";
import avatarDefaultImage from "../assets/Profile picture.png";
import bannerDefaultImage from "../assets/img/bannerImage.png";

export { avatarDefaultImage, bannerDefaultImage };

export const constructUserSocials = (socials) =>
  isObject(socials)
    ? Object.keys(socials).map((key) => ({
      path: socials[key],
      icon: userSocialIcons[key] || userSocialIcons.default,
    }))
    : [];

export const getUserProfile = (user, profileIndex = 0) =>
  (user?.profiles?.length > 0 && user.profiles[profileIndex]?.profileId) ||
  null;

export const getUserAvatar = (
  profile,
  avatarSize = "150",
  useDefaultImage = true
) =>
  (profile?.avatar?.sizes && profile.avatar.sizes[avatarSize]?.url) ||
  (useDefaultImage ? avatarDefaultImage : "");

export const getUserBanner = (
  profile,
  bannerSize = "1200",
  useDefaultImage = true
) =>
  (profile?.banner?.sizes && profile.banner.sizes[bannerSize]?.url) ||
  (useDefaultImage ? bannerDefaultImage : "");

export const getUserEthereumAccount = (identities) => {
  let registeredAcount = {};

  if (!identities) return registeredAcount;

  const match = identities.find((identity) => identity.type === "ethereum");

  if (match) {
    registeredAcount.identity = match.identityId;
    registeredAcount.id = match._id;
  }

  return registeredAcount;
};

export const getUserLevel = (authInfo, userLevel) => {

  return authInfo?.identities?.find((identity) => identity.type === userLevel && identity.status === "verified");
}

export const hasAppliedForRole = (authInfo, userLevel) => {

  return authInfo?.identities?.find((identity) => identity.type === userLevel && identity.status !== "verified");
}
