import React, { useEffect } from "react";
import { Switch, Redirect, Route, useLocation } from "react-router-dom";
import ScrollToTop from "./components/router/ScrollToTop";
import AuthMiddleware from "./routes/middlewares/authMiddleware";
import {
  publicRoutes,
  authRoutes,
  publicPaths,
  authPaths,
} from "./routes/allRoutes";
import PublicLayout from "./layouts/PublicLayout";
import AuthLayout from "./layouts/AuthLayout";
import ReactGA from 'react-ga';
import { google } from "./constants/AppSettings";

ReactGA.initialize(google.trackingID);

function App() {
  const location = useLocation();


  useEffect(() => {
    // ReactGA.pageview(window.location.pathname + window.location.search);
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return (
    <React.Fragment>
      <ScrollToTop />

      <Switch>
        <Route path={publicPaths} exact>
          <PublicLayout>
            {publicRoutes.map((route, idx) => (
              <AuthMiddleware
                key={idx}
                path={route.path}
                component={route.component}
                isRouteProtected={false}
                exact
              />
            ))}
          </PublicLayout>
        </Route>

        <Route path={authPaths} exact>
          <AuthLayout>
            {authRoutes.map((route, idy) => (
              <AuthMiddleware
                key={idy}
                path={route.path}
                component={route.component}
                isRouteProtected={true}
                exact
              />
            ))}
          </AuthLayout>
        </Route>

        <Redirect to="/pages-404" />
      </Switch>
    </React.Fragment>
  );
}

export default App;
