import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useField } from "formik";
import uploadIcon from "../../../assets/img/upload_icon.svg";
import fileIcon from "../../../assets/img/Vector-white.png";

const UploadFile = ({ name = "file", file, setFile }) => {
  const { t } = useTranslation();
  const [field, meta, helpers] = useField({ name }); // eslint-disable-line no-unused-vars
  const fileRef = useRef();

  const openUploadDialog = () => {
    if (fileRef.current) fileRef.current.click();
  };

  const handleSelectFile = (event) => {
    setFile(event.target.files[0]);
    helpers.setValue(event.target.files[0]);
    if (fileRef.current) fileRef.current.value = "";
  };

  const handleRemoveFile = () => {
    setFile(null);
    helpers.setValue(null);
  };

  const droppedFile = (event) => {
    event.preventDefault();
    setFile(event.dataTransfer.files[0]);
    helpers.setValue(event.dataTransfer.files[0]);
    if (fileRef.current) fileRef.current.value = "";
  }

  return (
    <div onDragOver={(event) => {
      event.preventDefault();
    }}
      onDrop={droppedFile}
      className="mb-3 mb-lg-0">
      <div
        className="mt-4 w-100 d-flex align-items-center justify-content-center"
        style={{
          position: "relative",
          backgroundColor: "rgba(196, 196, 196, 0.1)",
          height: "230px",
          boxSizing: "border-box",
          borderRadius: "0px",
          border: meta.touched && meta.error && "1px solid var(--error)",
        }}
      >
        {file ? (
          <React.Fragment>
            <button
              style={{
                position: "absolute",
                right: 28,
                top: 8,
                cursor: "pointer",
                color: "white",
              }}
              type="button"
              onClick={handleRemoveFile}
            >
              X
            </button>
            <div className="text-center">
              <img
                src={fileIcon}
                style={{ width: "40px", height: "auto" }}
                alt=""
              />

              <div className="text-white mt-3">{file && file.name}</div>
            </div>
          </React.Fragment>
        ) : (
          <img
            src={uploadIcon}
            style={{ width: "40px", height: "auto", cursor: "pointer" }}
            alt="upload"
            onClick={openUploadDialog}
          />
        )}

        <input
          type="file"
          accept="application/pdf"
          ref={fileRef}
          style={{ display: "none" }}
          onChange={handleSelectFile}
        />
      </div>

      {meta.touched && meta.error && (
        <div
          className="d-block"
          style={{
            color: "var(--error)",
            marginTop: "4px",
            marginLeft: "20px",
            fontSize: "80%",
          }}
        >
          {t(meta.error)}
        </div>
      )}
    </div>
  );
};

export default UploadFile;
