import { log } from "../helpers/logger";
import swaggerProps from "../plugins/swagger-client";

export default class ActivityService {
 
  createActivity(payload) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Activity.createActivity(
          {},
          swaggerProps.buildRequest(payload),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  retrieveActivities(params = {}) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Activity.retrieveActivities(
          params,
          swaggerProps.buildRequest(),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  retrieveActivity(param) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Activity.retrieveActivity(
          { param },
          swaggerProps.buildRequest(),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  deleteActivity(activityId) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Activity.deleteActivity(
          { activityId },
          swaggerProps.buildRequest(),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  updateActivity(activityId,info) {
    log(info)
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Activity.updateActivity(
          {activityId:activityId},
          swaggerProps.buildRequest(info),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}
