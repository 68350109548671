import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";
import UserBanner from "../../components/user/Banner";
import UserInfoCard from "../../components/card/UserInfo";
import NewCollectionForm from "./NewCollectionForm";

const AddCollection = () => {
  const { t } = useTranslation();
  const { authInfo, authProfile } = useSelector((state) => state.auth);

  return (
    <React.Fragment>
      <UserBanner userProfile={authProfile} />

      <Container>
        <Row className="row--grid">
          <Col className="col-12 col-xl-3">
            <UserInfoCard user={authInfo} profile={authProfile} />
          </Col>

          <Col className="col-12 col-xl-9">
          <div className="main__title main__title--create ">
            <h2>
              {/*{t("new-item-page.title")}
              <Steps step={1} />*/}
              New collection
            </h2>

          </div>

            <NewCollectionForm />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default AddCollection;
