import { getAssetCollectionId } from "../../../helpers/asset";
import { sortByDate } from "../../../helpers/utils";
import {
  RESET,
  SET_ASSET,
  SET_BIDS,
  SET_BID_MODAL_OPEN,
  SET_BUY_MODAL_OPEN,
  SET_DESCRIPTION_EDITING,
  SET_LISTING,
  TOGGLE_DESCRIPTION_EDITING,
  TOGGLE_TITLE_EDITING,
  TOGGLE_COLLECTION_EDITING,
  TOGGLE_PRICE_EDITING
} from "./actions";
import initialState from "./initialState";

const filterBidList = (bids, assetId) =>
  bids.filter((bid) => bid.assetId === assetId);

const reducer = (state, action) => {
  switch (action.type) {
    case SET_ASSET:
      return {
        ...state,
        asset: action.asset || initialState.asset,
        assetId: action.asset._id || initialState.assetId,
        collectionId:
          getAssetCollectionId(action.asset) || initialState.collectionId,
        transactions:
          sortByDate(action.asset.transactions, "posted") ||
          initialState.transactions,
      };
    case SET_LISTING:
      return {
        ...state,
        listing: action.listing
      }
    case SET_BIDS:
      return {
        ...state,
        bids: sortByDate(filterBidList(action.bids, action.assetId)),
      };
    case SET_DESCRIPTION_EDITING:
      return {
        ...state,
        descriptionEditing: action.descriptionEditing,
      };
    case TOGGLE_DESCRIPTION_EDITING:
      return {
        ...state,
        descriptionEditing: !state.descriptionEditing,
      };
    case TOGGLE_TITLE_EDITING:
      return {
        ...state,
        titleEditing: !state.titleEditing,
      };
    case TOGGLE_COLLECTION_EDITING:
      return {
        ...state,
        collectionEditing: !state.collectionEditing,
      };
    case TOGGLE_PRICE_EDITING:
      return {
        ...state,
        priceEditing: !state.priceEditing,
      };
    case SET_BID_MODAL_OPEN:
      return {
        ...state,
        bidModalOpen: action.open,
      };
    case SET_BUY_MODAL_OPEN:
      return {
        ...state,
        buyModalOpen: action.open,
      };
    case RESET:
      return {
        ...initialState,
      };
    default:
      throw new Error("Unsupported type");
  }
};

export default reducer;
