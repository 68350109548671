import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import SocialItem from "../../components/social/SocialItem";
import Dropzone from "../../components/uploadFile/Dropzone";
import { useTabSelector } from "./provider";
import { useLoader } from "../../providers/loader";
import { useNotify } from "../../providers/notify";
import {
  retrieveAuthUser,
  createAuthProfile,
  updateAuthProfile,
} from "../../store/AuthSlice";
import UserAvatar from "../../components/user/Avatar";
import {
  constructUserSocials,
  getUserEthereumAccount,
} from "../../helpers/user";
import { ReactComponent as WebIcon } from "../../assets/img/icons/web.svg";
import CopyLink from "../../components/input/CopyLink";
import camera from "../../assets/img/icons/cameraWithoutBackground.svg";
import { log } from "../../helpers/logger";

const AuthUserInfoCard = ({ user }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { showNotification } = useNotify();
  const { setLoading } = useLoader();
  const { isSettingsClicked } = useTabSelector();
  const { userId, authInfo, authProfile } = useSelector((state) => state.auth);
  const [userFullName, setUserFullName] = useState("");
  const [ethereumIdentity, setEthereumIdentity] = useState("");
  const [socials, setSocials] = useState([]);

  useEffect(() => {
    setEthereumIdentity(
      getUserEthereumAccount(authInfo.identities).identity || ""
    );
  }, [authInfo]);

  useEffect(() => {
    setUserFullName(
      `${authProfile.firstname || ""} ${authProfile.lastname || ""}`
    );
    setSocials(constructUserSocials(authProfile.social));
  }, [authProfile]);

  const handleUpload = async (acceptedFiles) => {
    try {
      setLoading(true);
      const newAvatar = acceptedFiles[0];

      if (authProfile._id) {
        await dispatch(
          updateAuthProfile({
            userId,
            profileId: authProfile._id,
            payload: { avatar: newAvatar },
          })
        ).unwrap();
      } else {
        await dispatch(
          createAuthProfile({
            userId,
            payload: { avatar: newAvatar },
          })
        ).unwrap();
      }

      await dispatch(retrieveAuthUser({ userId })).unwrap();

      showNotification({
        type: "success",
        message: "Information Updated",
      });
    } catch (error) {
      console.error(error);
      showNotification({
        type: "error",
        message: "error-msg.default",
      });
    } finally {
      setLoading(false);
    }
  };

  log(authProfile);
  log(authInfo);

  return (
    <div className="author author--page">
      <div className="author__meta">
        {isSettingsClicked ? (
          <section style={{ cursor: "pointer" }}>
            <Dropzone
              accept="image/*"
              className="dropzone author__avatar"
              onUpload={handleUpload}
            >
              <UserAvatar profile={authProfile} avatarClass="author__avatar" />

              <div
                style={{
                  position: "absolute",
                  bottom: "15px",
                  right: "15px",
                  backgroundColor: "#4BA4E3",
                  width: "30px",
                  height: "30px",
                  borderRadius: "0px",
                }}
              >
                <img
                  src={camera}
                  alt="upload"
                  style={{
                    borderRadius: 0,
                    border: "none",
                    width: "18px",
                    position: "absolute",
                    top: "22%",
                    left: "20%",
                  }}
                />
              </div>
            </Dropzone>
          </section>
        ) : (
          <UserAvatar profile={authProfile} avatarClass="author__avatar" />
        )}

        <h1 className="author__name">{userFullName}</h1>

        <h2 className="author__nickname">@{authInfo.username || authProfile.nickname}</h2>

        <p className="author__text">
          {authProfile ? authProfile.description : ""}
        </p>

        <CopyLink linkText={ethereumIdentity} />

        {authInfo.website && (
          <a href="author.html" className="author__link">
            <WebIcon /> {authInfo.website}
          </a>
        )}
        <div className="author__social">
          {socials.map((item, index) => (
            <SocialItem key={index} path={item.path} icon={item.icon} />
          ))}
        </div>

        <div className="author__wrap">
          <div className="author__followers">
            <p>{authInfo.followers}</p>

            <span>{t("followers")}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthUserInfoCard;
