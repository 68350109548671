import React, { useState, useEffect, useCallback } from "react";
import { Accordion, Card } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import downArrow from "../../assets/img/arrow.svg";
import upArrow from "../../assets/img/breadcrumb.svg";
import { blockchains, chainIdMap } from "../../constants/items";
import { getContractAddressBasedOnEnv } from "../../services/serviceHelpers";
import { selectPrefixUrl } from "./explorerHelpers";
import { balanceOfMultiOwner, initializeContract } from "../../store/EthereumSlice";
import { useNotify } from "../../providers/notify";

const AssetExtraInfo = ({ asset }) => {
    const { showNotification } = useNotify();
    const [arrow, setArrow] = useState("down");
    const dispatch = useDispatch();
    const [usersBalance, setUsersBalance] = useState(0);
    const { chainId } = useSelector(state => state.ethereum);
    const { authEthereumAddress, isAuthorized } = useSelector(state => state.auth);
    const contractName = asset.standard == "erc721" ? "DigitalAsset" : "MultiToken";

    const accordionClicked = () => {
        if (arrow == "down") {
            setArrow('up');
        } else {
            setArrow('down');
        }
    }

    const getExplorerUrlWithContract = () => {
        const contractAddress = getContractAddressBasedOnEnv(contractName, chainId);
        const url = selectPrefixUrl(asset, contractAddress, '/address');

        return url;
    }

    const getUserBalance = useCallback(async () => {
        try {
            if (isAuthorized) {
                await dispatch(initializeContract({ platform: true, contractName, chainId: chainIdMap.find((el) => el.label === asset?.blockchain).value })).unwrap();
                let balance = await dispatch(balanceOfMultiOwner({ assetId: asset?._id, ownerAddress: authEthereumAddress })).unwrap();
                setUsersBalance(balance);
            }
        } catch (error) {
            console.error(error);
            showNotification({
                type: "error",
                message: 'Could not get token balance',
            });
        }

    }, [dispatch, balanceOfMultiOwner, initializeContract, authEthereumAddress, asset]);

    useEffect(() => {
        if (asset?.standard == "erc1155") {
            getUserBalance();
        }
    }, [getUserBalance]);

    return (
        <div>
            <Accordion style={{ backgroundColor: "#1B5579", color: "white" }} defaultActiveKey="1">
                <Card className="p-0" style={{ backgroundColor: "#1B5579", color: "white" }}>
                    <Accordion.Toggle
                        as={Card.Header}
                        eventKey="0"
                        onClick={accordionClicked}
                        style={{ backgroundColor: "unset", borderBottom: "unset" }}>
                        <div style={{ fontSize: "15px" }}>
                            <strong>Additional Information</strong>
                            <img style={{ width: "35px" }} src={arrow == "down" ? downArrow : upArrow}></img>
                        </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body className="pb-0">
                            <div className="pt-0">
                                <p>
                                    <span style={{ color: "#bdbdbd" }}>Contract </span>

                                    {(asset?.standard != "NotMinted") ?
                                        <Link to="#"
                                            onClick={() => window.open(getExplorerUrlWithContract())}
                                            className="hyperlinks__color">
                                            {asset?.standard == "erc1155" ? "ERC1155" : "ERC721"}
                                        </Link>
                                        :
                                        <p>Not Minted</p>
                                    }
                                </p>
                                <p>
                                    <span style={{ color: "#bdbdbd" }}> Network </span> {blockchains.find((el) => el.value === asset?.blockchain)?.label}
                                </p>

                                {(asset?.standard != "NotMinted") &&
                                    <p>
                                        <span style={{ color: "#bdbdbd" }}> Total supply of </span> {asset?.totalSupply ? asset.totalSupply : 1}
                                    </p>
                                }

                                {asset.standard == "erc1155" &&
                                    <p>
                                        <span style={{ color: "#bdbdbd" }}> Your balance </span> {usersBalance}
                                    </p>
                                }
                            </div>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </div>
    );
}

export default AssetExtraInfo;