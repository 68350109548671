import React, { useState }  from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Container, Row, Col, Button } from "react-bootstrap";
import * as Yup from "yup";
import { useLoader } from "../../providers/loader";
import { useNotify } from "../../providers/notify";
import FormikForm from "./../../components/form/FormikForm";
import { TextInput } from "./../../components/form/FormikFields";
import { recoverPassword } from "../../store/AuthSlice";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("validation.email")
    .required("validation.required.email"),
});

const formModel = { email: "", agree: false };

const PasswordRecovery = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { setLoading } = useLoader();
  const { showNotification } = useNotify();
  const [messageFlag, setMessageFlag] = useState(false);

  const onSubmit = (values) => {
    setLoading(true);
    dispatch(
      recoverPassword({
        email: values.email,
        mobile: "",
        username: "",
      })
    )
      .unwrap()
      .then(() => {
        setMessageFlag(true);
      })
      .catch((error) => {
        console.error(error);
        showNotification({
          type: "error",
          message: "error-msg.default",
        });
      }).finally(()=>{
        setLoading(false);
      });
  };

  return (
    <Container>
      <Row>
        <Col>
          <div className="sign">
            <div className="sign__content">
              <FormikForm
                initialValues={formModel}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                <div className="text-left text-white w-100 mb-4 pb-1">
                  <h5 style={{ fontSize: "16px" }}>Password recovery</h5>
                  <div style={{ fontSize: "13px", color: "#BDBDBD" }}>
                    <div>Insert your email to continue. </div>
                    You will receive an email containing a link with further
                    instructions on how to recover your password.
                  </div>
                </div>

                <TextInput
                  label="Your email"
                  name="email"
                  placeholder={t("form.email")}
                />

                <Button
                  block
                  variant="light"
                  size="lg"
                  type="submit"
                  className="sign__btn mt-0"
                >
                  Confirm
                </Button>
                {messageFlag && (
                  <div className="mt-3" style={{ fontSize: "13px" }}>
                    <div style={{ color: "#61E295" }}>
                      A recovery link has been sent to this email.
                    </div>
                    <div style={{ color: "#BDBDBD" }}>
                      Click the link you received to proceed with the password
                      recovery process.
                      <div>
                        You can send another email with the button below, in
                        case you didn’t receive the first one.
                      </div>
                    </div>
                  </div>
                )}
              </FormikForm>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default PasswordRecovery;
