import React, { useMemo } from "react";
import { getValidUrl } from "../../helpers/utils";

const SocialItem = ({ path, icon: ComponentIcon }) => {
  const absolutePath = useMemo(() => getValidUrl(path), [path]);

  return (
    <a href={absolutePath} target="_blank" rel="noreferrer">
      <ComponentIcon />
    </a>
  );
};

export default SocialItem;
