import React from "react";
import { Button, Image } from "react-bootstrap";
import { useTranslation, Trans } from "react-i18next";
import metamask from "../../assets/img/metamask.png";

const WalletNotFound = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div className="main__title main__title--center mt-2">

        <Image src={metamask} />

        <div className="text-center" style={{
          fontSize: "16px",
          lineHeight: "24px",
          color: "#BDBDBD"
        }}>
          {/* {t("user-details-page.metamask-not-found-message")} */}
          <Trans
                t={t}
                i18nKey="user-details-page.metamask-not-found-message"
                components={{ span: <span /> }}
              />
        </div>
      </div>

      <div className="d-flex justify-content-center w-100">
        <div className="col-12 col-md-8">
          <Button
            className="sign__btn"
            href="https://metamask.io/download"
            variant="light"
            size="lg"
            block
          >
            {t("login-page.download-metamask")}
          </Button>
        </div>
      </div>
    </React.Fragment >
  );
};

export default WalletNotFound;
