import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Col, Row } from "react-bootstrap";
import AssetItem from "./AssetItem";
import AssetInfo from "./AssetInfo";
import AssetSimilarItems from "./AssetSimilarItems";
import BidModal from "./BidModal";
import BuyModal from "./BuyModal";
import { useAssetMethods, useAssetSelector } from "./provider";
import { getAssetTitle, getAssetType } from "../../helpers/asset";
import { log } from "../../helpers/logger";
import AssetTitle from "./AssetTitle";

const Asset = () => {
  const { assetId } = useParams();
  const { fetchAsset } = useAssetMethods();
  const { asset } = useAssetSelector();
  const [title, setTitle] = useState("");

  useEffect(() => {
    fetchAsset(assetId);
  }, [assetId, fetchAsset]);

  useEffect(() => {
    setTitle(getAssetTitle(asset));
    log('asset type: ', getAssetType(asset));
  }, [asset]);

  return (
    <React.Fragment>
      <Container>
        <div className="main__title main__title--page">
          {/* <h1>{title}</h1> */}
          <AssetTitle asset={asset} />
        </div>

        <Row>
          <Col xl="8">
            <AssetItem asset={asset} />
          </Col>

          <Col xl="4">
            <AssetInfo asset={asset} />
          </Col>
        </Row>

        <AssetSimilarItems />
      </Container>
      <BuyModal />

      <BidModal />
    </React.Fragment>
  );
};

export default Asset;
