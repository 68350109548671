import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useEthereumActions } from "../providers/ethereum";
import { LOGOUT_USER } from "../store/AuthSlice";

export const useLogout = () => {
  const dispatch = useDispatch();
  const { removeEthereumListeners } = useEthereumActions();

  const handleLogout = useCallback(() => {
    dispatch(LOGOUT_USER());
    removeEthereumListeners();
  }, [removeEthereumListeners, dispatch]);

  return { handleLogout };
};

export default useLogout;