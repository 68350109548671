import React from "react";
import { Button } from "react-bootstrap";
import BaseModal from "../modal/Base";

const EmailConfirmationModal = ({ show, closeCallback }) => {

  const okButton = async () => {
    closeCallback();
  }

  const resendEmail = async () => {
    // call the resend email API here
  }

  return (
    <BaseModal centered show={show}>
      {/* <Row className="no-gutters align-items-center"> */}
      <p className="text-white text-center">
        An email has been sent to you. Please follow the steps to complete the upgrade process.
      </p>
      <p className="text-white text-center">
        <Button className="sign__btn w-50" variant="light">Didn't receive the email?</Button>
      </p>
      <p className="text-white text-center pt-4">
        If you have already completed the steps, please allow time for a member of our team to review your submission.
      </p>
      <Button className="sign__btn" variant="light" onClick={okButton}>Ok</Button>
      {/* </Row> */}
    </BaseModal>
  )
}

export default EmailConfirmationModal;