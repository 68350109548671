import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import {
    TextInput,
    SingleSelect,
} from "../../components/form/FormikFields";
import FormikForm from "../../components/form/FormikForm";
import { Row, Col, Button } from "react-bootstrap";
import { handleChainChange, } from "../../store/EthereumSlice";
import { blockchains } from "../../constants/items";
import information from "../../assets/img/information 1.png";


const formModel = {
    saleType: "PutOnBid",
    blockchains: "",
    startingPrice: "",
    reservePrice: "",
    expDate: "",
    expTime: ""
};

const validationSchema = Yup.object().shape({
    startingPrice: Yup.string().required("validation.required.size"),
    expDate: Yup.string().required("validation.required.size"),
    expTime: Yup.string().required("validation.required.size")
});

const BidNowForm = ({ callback }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { symbol } = useSelector((state) => state.ethereum);


    const handleAssetCreation = (values) => {
        callback(values);
    };

    const promptNetworkChange = async (chain) => {
        await dispatch(handleChainChange({ chain })).unwrap();
    };
    return (
        <React.Fragment>
            <FormikForm
                initialValues={formModel}
                validationSchema={validationSchema}
                formClasses="sign__form--create"
                enableReinitialize
                onSubmit={handleAssetCreation}
            >

                <Row className="w-100">
                    <Col className="col-5">
                        <h5 className="font-weight-normal text-light">{t("new-item-page.form.blockchain-label")}</h5>

                        <h6 className="text-muted">
                            {t("new-item-page.form.blockchain-explanation")}
                            <img
                                style={{ marginLeft: "0.5rem", width: "1rem", height: "1rem" }}
                                src={information}
                                alt="pic"
                            />
                        </h6>

                        <SingleSelect
                            name="blockchains"
                            options={blockchains}
                            optionIdPrefix="blockchains"
                            withoutTranslation={true}
                            onChange={async (chain) => {
                                promptNetworkChange(chain);
                            }}
                        />
                    </Col>

                </Row>
                <Row>
                    <Col className="col-12 ">
                        <TextInput
                            name="startingPrice"
                            label="new-item-page.form.starting-price-label"
                            placeholder={`10 ${symbol}`}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col className="col-12 ">
                        {/* <div className="text-white">{t("new-item-page.form.reserve-explanation")}</div> */}
                        <TextInput
                            name="reservePrice"
                            label="new-item-page.form.reserve-price-label"
                            placeholder={`50 ${symbol}`}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col className="col-12 col-lg-6 col-xl-6 col-md-6">
                        <TextInput
                            name="expDate"
                            label="new-item-page.form.exp-date-label"
                            placeholder={t("new-item-page.form.placeholder.exp-date")}
                        />
                    </Col>

                    <Col className="col-12 col-lg-6 col-xl-6 col-md-6">
                        <TextInput
                            name="expTime"
                            label="new-item-page.form.exp-time-label"
                            placeholder={t("new-item-page.form.placeholder.exp-time")}
                        />
                    </Col>
                </Row>
                <Col className="col-12 col-xl-3">
                    <Button className="sign__btn" variant="light" type="submit">
                        {t("create-item")}
                    </Button>
                </Col>

            </FormikForm>
        </React.Fragment>
    );
}

export default BidNowForm;