import React, { useCallback, useEffect, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import BuyProcessingStep from "./BuyProcessingStep";
import BuyCompletedStep from "./BuyCompletedStep";
import { useAssetDispatch, useAssetSelector } from "../provider";
import { useNotify } from "../../../providers/notify";
import { retrieveAsset } from "../../../store/AssetSlice";
import { SET_ASSET } from "../provider/actions";
import { ethTransfer, initializeContract, ownerOfAsset, tokenTransfer, isApprovedForAll, ownerOfMultiAsset, multiTokenTransfer, balanceOfMultiOwner, erc1155MatchOrder, erc721MatchOrder, setTokenRoyalty } from "../../../store/EthereumSlice";
import { getAssetPrice, getAssetTokenId } from "../../../helpers/asset";
import { ethereumAccountStatus } from "../../../helpers/auth";
import { ethereum } from "../../../constants/AppSettings";
import { log } from "../../../helpers/logger";

const wizardSteps = {
  STEP_1: "PROCESSING",
  STEP_2: "COMPLETED",
};

const BuyWizard = ({ onClose }) => {
  const dispatch = useDispatch();
  const assetDispatch = useAssetDispatch();
  const { showNotification } = useNotify();
  const { assetId, asset, listing, buyModalOpen } = useAssetSelector();
  const { authInfo } = useSelector(state => state.auth);
  const { ethereumAccountAddress } = useSelector(state => state.ethereum);
  const [currentStep, setCurrentStep] = useState(1);
  let state = useCallback(() => {
    return ethereumAccountStatus(ethereumAccountAddress, authInfo.identities);
  }, [ethereumAccountAddress, authInfo.identities]);

  const syncWithBackend = async () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve();
      }, 3000);
    })
  }
  const handlePayment = useCallback(async () => {
    try {
      // asset                                                                      // exported from 'useAssetSelector'
      // owner -> getAssetOwner(asset)                                              // import { getUserProfile } from 'helpers/asset'
      // ownerProfile -> getUserProfile(owner)                                      // import { getUserEthereumAccount } from 'helpers/user'
      // ownerEthereumAccount -> getUserEthereumAccount(ownerProfile?.identities)   // import from 'helpers/user'
      // ownerEthereumAddress -> ownerEthereumAccount.identity

      log('authInfo: ', authInfo);
      await new Promise(async (resolve, reject) => {
        try {
          /* Open metamask in order to make the payment. Wait for 'confirm' or 'reject'. */
          // !!! @todo: eventually 'complex' flow should be implemented. Details on http://gl2.omnixell.com/plirio/nft-gallery/-/issues/175#note_6992 !!!
          // --- Placeholder flow ---
          // Step 1: Prompt ether transfer from user who is buying to owner of nft

          // @doing: 
          const contractName = (asset.standard == "erc721" ? "DigitalAsset" : "MultiToken");
          await dispatch(initializeContract({ platform: true, contractName })).unwrap();

          // @todo: change oldOwner to creator, and actualOwner to oldOwner.
          let oldOwner, actualCurrentOwner, platformApproved, balance;
          if (contractName == "MultiToken") {
            // actually the creator of the asset
            oldOwner = await dispatch(ownerOfMultiAsset({ assetId })).unwrap();
            actualCurrentOwner = listing && listing?.ownerAddress ? listing.ownerAddress : oldOwner;
            // actual owner / balance of owner
            balance = await dispatch(balanceOfMultiOwner({ assetId, ownerAddress: actualCurrentOwner })).unwrap();
            log('Balance is:', balance);
            if (!(balance >= listing?.supply)) {
              throw "Owner of token has not enough balance";
            }
          } else {
            actualCurrentOwner = await dispatch(ownerOfAsset({ tokenId: getAssetTokenId(asset) })).unwrap();
          }

          platformApproved = await dispatch(isApprovedForAll({ operatee: actualCurrentOwner })).unwrap();


          if (!platformApproved) {
            throw ({ msg: 'Platform not authorized to sell that. Owner needs to initialize their wallet.', code: 9301 });
          }
          // @todo: add check, user should be on the same network as the NFT
          // maybe if they are in the wrong network the buy button could be grayed
          if (state() !== 2) {
            throw ({ msg: 'Please change to your registered Metamask account.', code: 9302 });
          }

          log('Previous owner: ', actualCurrentOwner);
          // @todo should send the ether to the order contract for each ether transaction,
          // which also calculates and sends the royalties to all parties.


          // await dispatch(ethTransfer({ recipient: actualCurrentOwner, amount: listing?.price ? listing.price : getAssetPrice(asset) })).unwrap();

          // Step 2: Transfer ownership of token from owner to buyer
          const id = getAssetTokenId(asset);
          await dispatch(initializeContract({ contractName: "SaysoonMatch" })).unwrap();
          if (!id) {
            throw "Missing token id in the the transfer process";
            // @todo: IMPORTANT, if it fails here we should refund the buyer. 
            // can not be supported currently. We need the SaySoon Orders contract which is under construction
          }
          if (contractName == "MultiToken") {
            // const receipt = await dispatch(multiTokenTransfer({ from: actualCurrentOwner, tokenId: id, amount: listing.supply, listingId: listing._id })).unwrap();
            const receipt = await dispatch(erc1155MatchOrder(
              {
                from: actualCurrentOwner,
                assetId,
                listingId: listing._id,
                salePrice: listing?.price ? listing.price : getAssetPrice(asset),
                amount: listing.supply
              }
            )).unwrap();
            // @todo: here we should actually wait if we wanted to see the new owner
            await dispatch(initializeContract({ contractName, platform: true })).unwrap();

            balance = await dispatch(balanceOfMultiOwner({ assetId, ownerAddress: actualCurrentOwner })).unwrap();
          } else {
            // const receipt = await dispatch(tokenTransfer({ from: actualCurrentOwner, tokenId: getAssetTokenId(asset) })).unwrap();
            const receipt = await dispatch(erc721MatchOrder({ from: actualCurrentOwner, assetId, salePrice: getAssetPrice(asset) })).unwrap();
            await dispatch(initializeContract({ contractName, platform: true })).unwrap();
            const newOwner = await dispatch(ownerOfAsset({ tokenId: getAssetTokenId(asset) })).unwrap();
            log('New owner: ', newOwner);
          }

          // Step 3: Update new owner in the asset
          // step 3 is executed in that backend along with steps 4, 5 & 6

          // await dispatch(initializeContract({ contractName: "SaysoonMatch", platform: true })).unwrap();
          // const standardId = asset.standard == "erc721" ? 721 : 1155;
          // await dispatch(setTokenRoyalty({
          //   ERCStandard: standardId,
          //   assetId,
          //   receiverOwner: asset?.ownerId?.userId?.identities?.[1]?.identityId,
          //   receiverCreator: asset?.creatorId?.userId?.identities?.[1]?.identityId,
          //   receiverPlatform: ethereum.platformAddress,
          //   feeNumeratorOwner: 9550,
          //   feeNumeratorCreator: 200,
          //   feeNumeratorPlatform: 250
          // })).unwrap();

          // maybe inform the user here with something ?
          await syncWithBackend();
          resolve();
        } catch (e) {
          console.error('Something went wrong with the buying process: ', e);

          reject(e);
        }
      });

      const response = await dispatch(retrieveAsset({ assetId })).unwrap();
      const responseData = response.body.data;
      assetDispatch({
        type: SET_ASSET,
        asset: { ...responseData },
      });
      setCurrentStep((step) => step + 1);
    } catch (error) {
      onClose();

      let message;
      switch (error.code) {
        case 4001:
          message = "You declined the action in your wallet";
          break;
        case 9301:
        case 9302:
          message = error.msg;
          break;
        default:
          message = "error-msg.default";
      }

      showNotification({
        type: "error",
        message,
      });
    }
  }, [assetId, listing, onClose, assetDispatch, dispatch]);

  useEffect(() => {
    // console.log('Listing:', listing);
    handlePayment();
  }, [handlePayment]);

  return (
    <div className="asset__wizard">
      <Tabs
        activeKey={wizardSteps[`STEP_${currentStep}`]}
        transition={false}
        style={{ borderBottom: "0" }}
      >
        <Tab eventKey={wizardSteps.STEP_1}>
          <BuyProcessingStep />
        </Tab>

        <Tab eventKey={wizardSteps.STEP_2}>
          <BuyCompletedStep onClose={onClose} />
        </Tab>
      </Tabs>
    </div>
  );
};

export default BuyWizard;
