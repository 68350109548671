import React from "react";
import { Button, Image } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import metamask from "../../assets/img/metamask.png";
import InvalidMetamask from "./InvalidMetamask";
import InvalidRole from "./InvalidRole";


const InvalidAccount = ({ validMetamaskAccount }) => {
  const history = useHistory();
  const { userId } = useSelector((state) => state.auth);
  const { t } = useTranslation();

  const redirectToProfile = () => {
    history.push(`/user-details/${userId}/settings`);
  };

  return (
    <InvalidMetamask/> 
    // !validMetamaskAccount ? <InvalidMetamask/> : <InvalidRole/>
  );
};

export default InvalidAccount;