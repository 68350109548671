// export const nftMarketplaceList = [
//   { label: "menu.explore", path: "/explore" },
//   { label: "menu.collections", path: "/collections" },
//   { label: "menu.users", path: "/users" },
//   { label: "menu.privacy-policy", path: "#", external: "https://www.iubenda.com/privacy-policy/25046224" },
//   { label: "menu.cookie-policy", path: "#", external: "https://www.iubenda.com/privacy-policy/25046224/cookie-policy" },
//   { label: "menu.terms-and-conditions", path: "#", external: "https://www.saysoon.net/nft-art/terms-and-conditions/" }
// ];

export const infoList = [
  { label: "Via Breguzzo 1", path: "#" },
  { label: "20148 MILANO", path: "#" },
  { label: "PEC saysoonsrl@legalmail.it", path: "#" },
  { label: "REA MI - 2639051", path: "#" },
  { label: "P.IVA 12075590963", path: "#" },
];

// export const exploreList = [
//   [
//     { label: "explore-items.art", path: "/explore/?category=art" },
//     { label: "explore-items.photography", path: "/explore/?category=photography" },
//     { label: "explore-items.games", path: "/explore/?category=games" },
//     { label: "explore-items.music", path: "/explore/?category=music" },
//   ],
//   [
//     { label: "explore-items.domains", path: "/explore/?category=domains" },
//     { label: "explore-items.metaverses", path: "/explore/?category=metaverses" },
//     { label: "explore-items.memes", path: "/explore/?category=memes" },
//   ],
// ];
export const legalList = [
  [
    { label: "menu.privacy-policy", path: "#", external: "https://www.iubenda.com/privacy-policy/25046224" },
    { label: "menu.terms-and-conditions", path: "#", external: "https://www.saysoon.net/nft-art/terms-and-conditions/" }
  ]
];

export const contactsList = [
  { label: "footer.contact", path: "#", external: "https://www.saysoon.net/nft-art/contatti" },
];

export const communityList = [
  /*
  { label: "menu.unitok", path: "#/" },
  { label: "menu.our-blog", path: "#/" },
  { label: "menu.help-center", path: "#/" },
  { label: "menu.contacts", path: "/contact" },
  */
];

export const privacyPolicy = "https://www.iubenda.com/privacy-policy/25046224";