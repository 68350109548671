import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Item from "../../components/item/Item";
import { useAssetSelector } from "./provider";
import { retrieveAssets } from "../../store/AssetSlice";
import { log } from "../../helpers/logger";

const filterAssetList = (assets, assetId) =>
  assets.filter((asset) => asset._id !== assetId);

const AssetSimilarItems = () => {
  const dispatch = useDispatch();
  const { assetId, collectionId } = useAssetSelector();
  const [similarAssets, setSimilarAssets] = useState([]);

  const fetchCollectionAssets = useCallback(
    async (collectionId) => {
      try {
        const response = await dispatch(
          retrieveAssets({ collectionId })
        ).unwrap();

        const responseData = response.body.data;
        setSimilarAssets(filterAssetList(responseData, assetId));
      } catch (error) {
        log(error);
      }
    },
    [assetId, dispatch]
  );

  useEffect(() => {
    if (collectionId) {
      fetchCollectionAssets(collectionId);
    }
  }, [collectionId, fetchCollectionAssets]);

  return (
    <React.Fragment>
      {similarAssets.length > 0 && (
        <React.Fragment>
          <div className="main__title main__title--page">
            <h1>More from this collection</h1>
          </div>

          <section className="row row--grid">
            {similarAssets.map((asset, index) => (
              <div key={index} className="col-12 col-sm-6 col-lg-4 col-xl-3">
                <Item itemId={asset._id} item={asset} />
              </div>
            ))}
          </section>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default React.memo(AssetSimilarItems);
