const getItems = (total = 6) => {
  return [...Array(total)].map((d, index) => {
    return {
      _id: `itemId_${index + 1}`,
      title: `Walking on Air (${index + 1})`,
      user: {
        _id: `userId_${index + 1}`,
        nickname: `@nickname_${index + 1}`,
      },
      userId: `userId_${index + 1}`,
      userNickname: `@nickname_${index + 1}`,
      price: 4.89,
      likes: 189 + index,
      verified: index % 2 === 0,
    };
  });
};
export const mockListings = (total = 6) => {
  return [...Array(total)].map((d, index) => {
    return {
      quantity: index + 1,
      saleType: index % 2 != 0 ? 'onSale' : 'notOnSale',
      pricePerItem: index * 2 + 3,
      ownerId: `user_${index + 1}`

    };
  });
};

export const itemSocial = [
  { value: "socials-1", label: "Instagram" },
  { value: "socials-2", label: "Facebook" },
  { value: "socials-3", label: "Twitter" },
];

export const itemTypes = [
  { value: "NotOnSale", label: "Not on sale" },
  { value: "PutOnSale", label: "Put on sale" },
  { value: "InstantSalePrice", label: "Instant sale price" },
  { value: "UnlockOnePurchased", label: "Unlock one purchased" },
];

export const itemRoyalties = [
  { value: "royalties-1", label: "5%" },
  { value: "royalties-2", label: "10%" },
  { value: "royalties-3", label: "20%" },
];

// export const categoryOptions = [
//   { value: "music", label: "Music" },
//   { value: "art", label: "Art" },
//   { value: "photography", label: "Photography" },
//   { value: "games", label: "Games" },
//   { value: "metaverses", label: "Metaverses" },
//   { value: "domains", label: "Domains" },
//   { value: "memes", label: "Memes" },
// ];

export const categoryOptions = [
  { value: "image", label: "categories.image"},
  { value: "video", label: "categories.video"},
  { value: "gif", label: "categories.gif"},
  { value: "3d", label: "categories.3d"},
  { value: "photography", label: "categories.photography" },
  { value: "certificates", label: "categories.certificates"},
];

export const collectionOptions = [];

export const blockchains = [
  { value: "ethereum", label: "Ethereum" },
  { value: "ropsten", label: "Ropsten" },
  { value: "rinkeby", label: "Rinkeby" },
  { value: "saysoon", label: "Saysoon" },
  { value: "polygon", label: "Polygon" },
  { value: "mumbai", label: "Mumbai" },
  { value: "goerli", label: "Goerli" }
];

export const blockchainsDev = blockchains.filter(el => el.value != "polygon" && el.value != "ethereum");

export const blockchainsProd = blockchains.filter(el => el.value == "saysoon" || el.value == "polygon");

export const ERCstandards = [
  { value: "erc721", label: "ERC721" },
  { value: "erc1155", label: "ERC1155" }
]

// IERC721_METADATA
export const ERC721InterfaceId = 0x5b5e139f;
// IERC1155
export const ERC1155InterfaceId = 0xd9b67a26;

export const chainIdMap = [
  { value: 1, label: "ethereum" },
  { value: 3, label: "ropsten" },
  { value: 4, label: "rinkeby" },
  { value: 4500, label: "saysoon" },
  { value: 80001, label: "mumbai" },
  { value: 137, label: "polygon" },
  { value: 5, label: "goerli" }
];

export const paymentMethods = [
  { value: "paypal", label: "PayPal" },
  { value: "stripe", label: "Stripe" }
]

export default getItems;
