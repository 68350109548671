import React, { useEffect, useCallback, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import FormikForm from "./../../components/form/FormikForm";
import ProfileDetailsForm from "./ProfileDetailsForm";
import ProfileSocialMediaForm from "./ProfileSocialMediaForm";
import { useLoader } from "../../providers/loader";
import { useNotify } from "../../providers/notify";
import Wallet from "./Wallet";
import {
  createAuthProfile,
  updateAuthProfile,
  retrieveAuthUser,
  createAuthProfileSocial,
} from "../../store/AuthSlice";
import { objectSelector } from "../../helpers/utils";

const userInfoModel = {
  username: "",
  email: "",
};
const profileUserModel = {
  nickname: "",
  firstname: "",
  lastname: "",
  description: "",
};
const profileSocialsModel = {
  facebook: "",
  instagram: "",
  linkedin: "",
  twitter: "",
  medium: "",
  behance: "",
  website: "",
  tiktok: "",
};
const initialFormModel = {
  ...userInfoModel,
  ...profileUserModel,
  ...profileSocialsModel,
};

const userSelector = (data, defaultPropValue, skipEmpty = false) =>
  objectSelector(userInfoModel, data, defaultPropValue, skipEmpty);
const profileUserSelector = (data, defaultPropValue, skipEmpty = false) =>
  objectSelector(profileUserModel, data, defaultPropValue, skipEmpty);
const profileSocialsSelector = (data, defaultPropValue, skipEmpty = false) =>
  objectSelector(profileSocialsModel, data, defaultPropValue, skipEmpty);
const isProfileIncomplete = ({
  avatar,
  banner,
  firstname,
  lastname,
  description,
}) => !(avatar && banner && firstname && lastname && description);

const ProfileSettings = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { showNotification } = useNotify();
  const { setLoading } = useLoader();
  const { authInfo, authProfile, userId, authActiveProfileId } = useSelector(
    (state) => state.auth
  );
  const [formModel, setFormModel] = useState(initialFormModel);

  const handleSubmit = useCallback(
    async (values) => {
      try {
        setLoading(true);

        // Should we update the `username` and/or `email`?
        const profilePayload = profileUserSelector(values, undefined, true);
        const socialPayload = profileSocialsSelector(values, undefined, true);

        let profileId = authActiveProfileId;

        if (!profileId) {
          const createProfileResponse = await dispatch(
            createAuthProfile({ userId, payload: profilePayload })
          ).unwrap();

          profileId = createProfileResponse._id;
        } else {
          await dispatch(
            updateAuthProfile({
              userId,
              profileId,
              payload: profilePayload,
            })
          ).unwrap();
        }

        await dispatch(
          createAuthProfileSocial({
            userId,
            profileId,
            payload: socialPayload,
          })
        ).unwrap();

        await dispatch(retrieveAuthUser({ userId })).unwrap();

        showNotification({
          type: "success",
          message: "success-msg.profile-updated",
        });
      } catch (rejectedValueOrSerializedError) {
        console.error(rejectedValueOrSerializedError);
        showNotification({
          type: "error",
          message: "error-msg.default",
        });
      } finally {
        setLoading(false);
      }
    },
    [userId, authActiveProfileId, setLoading, showNotification, dispatch]
  );

  useEffect(() => {
    setFormModel({
      ...userSelector(authInfo, ""),
      ...profileUserSelector(authProfile, ""),
      ...profileSocialsSelector(authProfile.social, ""),
    });
  }, [authInfo, authProfile]);

  return (
    <div className="row row--grid">
      <div className="col-xl-11 col-lg-11 col-md-11 col-sm-12 col-xs-12">
        {isProfileIncomplete(authProfile) && (
          <div
            className="w-100 text-white p-4 mt-3"
            style={{ borderRadius: "0px", background: "#4BA4E3" }}
          >
            <h4 style={{ fontWeight: "600" }}>Complete your profile</h4>
            <div>
              There is still some information missing in you profile. Fill out
              this page to finalize your user.
            </div>
          </div>
        )}

        <Wallet />

        <FormikForm
          initialValues={formModel}
          formClasses="sign__form--profile col-12"
          enableReinitialize
          onSubmit={handleSubmit}
        >
          <ProfileDetailsForm />

          <ProfileSocialMediaForm />

          <div className="d-flex justify-content-center w-100">
            <div className="col-12 col-md-3">
              <Button className="sign__btn" variant="light" type="submit">
                {t("actions.save")}
              </Button>
            </div>
          </div>
        </FormikForm>
      </div>
    </div>
  );
};
export default ProfileSettings;
