import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import services from "../services";

export const createActivity = createAsyncThunk(
  "activity/createActivity",
  async (data , { rejectWithValue }) => {
    try {
      const response = await services.ActivityService.createActivity( data );

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const retrieveActivities = createAsyncThunk(
  "activity/retrieveAcitivities",
  async (params, { rejectWithValue }) => {
    try {
      const response = await services.ActivityService.retrieveActivities(params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const retrieveActivity = createAsyncThunk(
  "activity/retrieveActivity",
  async (param, { rejectWithValue }) => {
    try {
      const response = await services.ActivityService.retrieveActivity(param);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteActivity = createAsyncThunk(
  "activity/deleteActivity",
  async (activityId, { rejectWithValue }) => {
    try {
      const response = await services.ActivityService.deleteActivity(activityId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const updateActivity = createAsyncThunk(
  "activity/updateActivity",
  async ({activityId,info},{ rejectWithValue }) => {
    try {
      const response = await services.ActivityService.updateActivity(activityId,info);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

let initialState = {
  state: "",
  value:"",
  error: {},
};

const activitySlice = createSlice({
  name: "activity",
  initialState,
  reducers: {
    DUMMY(){},
  },
  extraReducers: {
    /* ---- CREATE_USER ---- */
    [String(createActivity.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(createActivity.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(createActivity.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },

    /* ---- GET_USERS ---- */
    [String(retrieveActivities.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(retrieveActivities.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(retrieveActivities.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },

    /* ---- GET_USER ---- */
    [String(retrieveActivity.pending)]: (state, action) => {
        state.state = "loading";
      },
      [String(retrieveActivity.fulfilled)]: (state, action) => {
        state.state = "success";
      },
      [String(retrieveActivity.rejected)]: (state, action) => {
        state.state = "error";
        state.error = action.payload;
      },

      /* ---- UPDATE_USER ---- */
    [String(updateActivity.pending)]: (state, action) => {
        state.state = "loading";
      },
      [String(updateActivity.fulfilled)]: (state, action) => {
        state.state = "success";
      },
      [String(updateActivity.rejected)]: (state, action) => {
        state.state = "error";
        state.error = action.payload;
      },

       /* ---- DELETE_USER ---- */
    [String(deleteActivity.pending)]: (state, action) => {
        state.state = "loading";
      },
      [String(deleteActivity.fulfilled)]: (state, action) => {
        state.state = "success";
      },
      [String(deleteActivity.rejected)]: (state, action) => {
        state.state = "error";
        state.error = action.payload;
      },
      
  },
});

export const {DUMMY} = activitySlice.actions;

export default activitySlice.reducer;
