import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { selectPrefixUrl } from "../../pages/ItemDetails/explorerHelpers";
import UserAvatar from "../user/Avatar";

const HistoryItem = ({ historyItem, asset }) => {
  const [description, setDescription] = useState("");

  useEffect(() => {
    setDescription(historyItem?.description || "");
  });

  return (
    <UserAvatar profile={null} avatarClass="asset__action" verified>
      {/* <p>{description}</p> */}
      <p>
        {/*
        
        <Trans
          i18nKey="item-details-page.history-item"
          values={{ bid: item.price, created: item.date }}
          components={{ bold: <strong />, break: <br /> }}
        />{" "}
        */}
        {historyItem?.description}
        <br />
        {historyItem?.description && (historyItem.type === "mint" || historyItem.type === "transfer") && <Link className="hyperlinks__color" to="#" onClick={() => window.open(selectPrefixUrl(asset, historyItem))}>Check transaction here</Link>}
        {historyItem?.description && (historyItem.type === "sale") &&
          <React.Fragment>
            <div style={
              {
                fontSize: "10px",
                lineHeight: "12px"
              }
            }>from: {historyItem?.from}</div>

            <div style={
              {
                fontSize: "10px",
                lineHeight: "12px"
              }
            }>to: {historyItem?.to}</div>
          </React.Fragment>
        }
        {/* <Link to="author">{asset?.ownerId?.nickname}</Link> */}
      </p>
    </UserAvatar>
  );
};

export default HistoryItem;
