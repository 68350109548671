import React, { useCallback, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
// import { useTranslation } from "react-i18next";
import UserBanner from "../../components/user/Banner";
import UserInfoCard from "../../components/card/UserInfo";
import MarketForm from "../../components/AssetItem/MarketForm";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useNotify } from "../../providers/notify";
import { useLoader } from "../../providers/loader";
import { useHistory } from "react-router-dom";
import { updateAsset, retrieveAsset, updateAssetListing } from "../../store/AssetSlice";
import { initializeContract, mintAsset, isApprovedForAll, approveForAll, mintMultiAsset, setTokenRoyalty, getDefaultRoyalty } from "../../store/EthereumSlice";
import { getErrorMessage } from "../NewItem/error";
import { getAssetStandard } from "../../helpers/asset";
import { ethereum } from "../../constants/AppSettings";
import { getUserLevel, hasAppliedForRole } from "../../helpers/user";
import KycModal from "../../components/KycModal";
import EmailConfirmationModal from "../../components/KycModal/emailConfirmation";

const AssetMarket = () => {
  const dispatch = useDispatch();
  const { authInfo, authProfile, authEthereumAddress } = useSelector((state) => state.auth);
  const { showNotification } = useNotify();
  const { setLoading, setText } = useLoader();
  const [mintedStatus, setMintedStatus] = useState({ minted: false, id: "", chain: "" });
  const [asset, setAsset] = useState({});
  const history = useHistory();
  const { id, listingId } = useParams();
  const [showModalKYC, setShowModalKYC] = useState(false);
  const [showModalEmail, setShowModalEmail] = useState(false);

  const isAssetMinted = useCallback(async () => {
    const asset = await dispatch(retrieveAsset({ assetId: id })).unwrap();
    setAsset(asset);
    if (asset.body.data.tokenId) {
      return { minted: true, id, chain: asset.body.data.blockchain };
    }
    return { minted: false, id };
  }, [dispatch, retrieveAsset]);

  const setRoyalties = useCallback(async (assetId, standard) => {
    try {
      await dispatch(initializeContract({ contractName: "SaysoonMatch", platform: true })).unwrap();
      const royalties = await dispatch(getDefaultRoyalty({})).unwrap();
      await dispatch(setTokenRoyalty({
        ERCStandard: standard,
        assetId,
        receiverOwner: authEthereumAddress,
        receiverCreator: authEthereumAddress,
        receiverPlatform: ethereum.platformAddress,
        feeNumeratorOwner: royalties[0],
        feeNumeratorCreator: royalties[1],
        feeNumeratorPlatform: royalties[2]
      })).unwrap();
    } catch (e) {
      console.error('error in royalties: ', e);
      showNotification({
        type: "error",
        message: 'Royalties failed'
      });
    }
  }, [authEthereumAddress]);


  const closeModalKYC = () => {
    setShowModalKYC(false);
  }

  const closeModalEmail = () => {
    setShowModalEmail(false);
  }

  const handleNFTCreation = async (values) => {
    try {
      setLoading(true);
      const payload = {
        blockchain: values.blockchains || "saysoon",
        price: values.price || 0,
        saleType: values.saleType || "NotOnSale",
        standard: values.ERCstandard || "NotMinted",
        totalSupply: values.supply || 1
      };

      if (payload.saleType === "PutOnBid") {
        // similar to newitem form probably
      }

      if (payload.saleType !== "NotOnSale") {

        if (!getUserLevel(authInfo, "kyc") && payload.standard != "NotMinted") {
          setLoading(false);

          if (hasAppliedForRole(authInfo, "kyc")) {
            setShowModalEmail(true);
          } else {
            setShowModalKYC(true);
            showNotification({ type: "error", message: "Need to be KYC member" });
          }

        } else {
          const assetId = id;
          const isMinted = !!asset?.body?.data?.tokenId;
          const compareStandardValue = isMinted ? asset?.body?.data?.standard : values.ERCstandard;
          const contractName = compareStandardValue.toLowerCase() == "erc721" ? "DigitalAsset" : "MultiToken";
          await dispatch(initializeContract({ contractName })).unwrap();
          const isApproved = await dispatch(isApprovedForAll({ operatee: authEthereumAddress })).unwrap();

          if (!isApproved) {
            setText("Initializing wallet");
            await dispatch(approveForAll({})).unwrap();
          }

          // if it has a tokenId it means that it has already been minted
          // so it is just a matter of updating the asset state
          let finalPayload = {
            saleType: "PutOnSale",
            price: Number(payload.price)
          }

          if (!asset?.body?.data?.tokenId) {
            finalPayload.blockchain = payload.blockchain;
          }

          setText("Placing the NFT on sale");
          if (contractName == "DigitalAsset") {
            await dispatch(
              updateAsset({
                assetId,
                data: { standard: "erc721", ...finalPayload }
              })
            ).unwrap();

            if (!asset.body.data.tokenId) {
              setText("Creating the NFT");
              await dispatch(mintAsset({ id: assetId })).unwrap();

              setText("Finalizing");
              // set the initial royalties for the asset
              await setRoyalties(assetId, 721);
              setText("Created the NFT");
            }
          } else {

            if (!asset.body.data.tokenId) {
              await dispatch(
                updateAsset({
                  assetId,
                  data: { standard: "erc1155", totalSupply: payload.totalSupply, ...finalPayload }
                })
              ).unwrap();
            }
            delete finalPayload.blockchain;
            if (asset.body.data.tokenId) {
              await dispatch(
                updateAssetListing({
                  assetId,
                  listingId,
                  data: finalPayload
                })
              ).unwrap();

            }

            if (!asset.body.data.tokenId) {
              setText("Creating the NFT");
              await dispatch(mintMultiAsset({ id: assetId, amount: values?.supply })).unwrap();
              setText("Finalizing");
              await setRoyalties(assetId, 1155);
              setText("Created the NFT");
            }
          }

          let message;
          if (asset.body.data.type === "certificate") {
            message = "Certificate now on sale"
          } else {
            message = "Asset now on sale"
          }

          showNotification({
            type: "success",
            message,
          });

          if (asset.body.data.type === "certificate") {
            history.push(`/certifications/${asset.body.data._id}`, { isNotifyPersist: true });
          } else {
            history.push(`/items/${asset.body.data._id}`, { isNotifyPersist: true });
          }
        }

      }
    } catch (error) {
      console.error("Error in Asset Market: ", error);
      showNotification({
        type: "error",
        message: getErrorMessage(error)
      });

    } finally {
      setLoading(false);
    }

  }

  useEffect(async () => {
    const result = await isAssetMinted();
    setMintedStatus(result);
  }, [isAssetMinted])

  return (
    <React.Fragment>
      <KycModal show={showModalKYC} closeCallback={closeModalKYC} />
      <EmailConfirmationModal show={showModalEmail} closeCallback={closeModalEmail} />
      <UserBanner userProfile={authProfile} />

      <Container >
        <Row className="row--grid">
          <Col className="col-12 col-xl-3">
            <UserInfoCard user={authInfo} profile={authProfile} />
          </Col>

          <Col className="col-12 col-xl-9">
            <div className="market__form  mt-4" style={{ maxWidth: "100%" }}>
              <MarketForm handleAssetCreation={handleNFTCreation} isMinted={mintedStatus.minted} standard={asset?.body?.data?.standard} chain={mintedStatus.chain} />
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );

}

export default AssetMarket;