export const getErrorMessage = (status) => {
  switch (status) {
    case 401:
      return "error-msg.unauthorized";
    case 404:
      return "error-msg.not-found";
    default:
      return "error-msg.default";
  }
};

export const getDefaultMessage = (type, status) => {
  switch (type) {
    case "success":
      return "success-msg.default";
    case "error":
      return getErrorMessage(status);
    default:
      return "error-msg.default";
  }
};
