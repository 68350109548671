import SwaggerClient from "swagger-client";
import { api } from "../constants/AppSettings";
import { constructRequestHeaders } from "../helpers/requests";

const buildRequest = (requestBody, requestHeader) => {
  return {
    requestBody: requestBody,
    serverVariables: {
      protocol: api.protocol,
      host: api.host,
      port: api.port,
      basePath: api.basePath,
    },
  };
};

const swagger = new SwaggerClient("/api.v1.json", {
  requestInterceptor: (request) => {
    return constructRequestHeaders(request);
  },
  responseInterceptor: (response) => {
    return response;
  },
}).catch((error) => console.error(error));

const swaggerProps = {
  swagger,
  buildRequest,
};

export default swaggerProps;