import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import UserAvatar from "../../components/user/Avatar";
import {
  getAssetCollection,
  getAssetCollectionId,
  getAssetCreatorId,
  getAssetCreatorUsername,
} from "../../helpers/asset";
import { getUserProfile } from "../../helpers/user";
import AssetCollection from "./AssetCollection";

const userDetailsPath = "/user-details";
const collectionDetailsPath = "/collections";

const AssetAuthors = ({ asset }) => {
  const { t } = useTranslation();
  const [creatorId, setCreatorId] = useState("");
  const [creatorProfile, setCreatorProfile] = useState(null);
  const [creatorUsername, setCreatorUsername] = useState(null);
  const [collection, setCollection] = useState(null);
  const [collectionId, setCollectionId] = useState("");

  useEffect(() => {
    setCreatorId(getAssetCreatorId(asset));
    setCreatorProfile(asset.creatorId);
    setCreatorUsername(getAssetCreatorUsername(asset));
    setCollection(getAssetCollection(asset));
    setCollectionId(getAssetCollectionId(asset));
  }, [asset]);

  return (
    <ul className="asset__authors">
      <li>
        <span>{t("item-details-page.creator")}</span>

        <UserAvatar
          profile={creatorProfile}
          avatarClass="card__author"
          verified
        >
          {creatorId && (
            <Link to={`${userDetailsPath}/${creatorId}`}>
              {creatorUsername}
            </Link>
          )}
        </UserAvatar>
      </li>

     { collection && <AssetCollection asset={asset} />}
      {/* {collection && (
        <li>
          <span>{t("item-details-page.collection")}</span>
          <UserAvatar profile={null} avatarClass="card__author">
            {collection && (
              <Link to={`${collectionDetailsPath}/${collectionId}`}>
                {collection.title}
              </Link>
            )}
          </UserAvatar>
        </li>
      )} */}
    </ul>
    
  );
};

export default AssetAuthors;
