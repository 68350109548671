import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useNotify } from "../providers/notify";
import { retrieveAuthUser, LOGOUT_USER } from "../store/AuthSlice";

const AuthWrapper = ({ children }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isAuthorized, userId } = useSelector((state) => state.auth);
  const { showNotification } = useNotify();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAuthUser = async () => {
      try {
        await dispatch(retrieveAuthUser({ userId })).unwrap();
      } catch (rejected) {
        let notificationData = { type: "error" };
        const error = rejected.response.body.error;
        if (error.status === 401) {
          notificationData = {
            type: "warning",
            message: "error-msg.session-expired",
          };
          dispatch(LOGOUT_USER());
          history.push("/login", { isNotifyPersist: true });
        }

        showNotification(notificationData);
      } finally {
        setLoading(false);
      }
    };

    if (isAuthorized) fetchAuthUser();
    else setLoading(false);
  }, [isAuthorized, userId, showNotification, history, dispatch]);

  return <div>{!loading && children}</div>;
};

export default AuthWrapper;
