import React, { useCallback, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import BidItem from "./BidItem";
import useError from "../../hooks/useError";
import { useAssetDispatch, useAssetSelector } from "./provider";
import { retrieveBids } from "../../store/BidSlice";
import { SET_BIDS } from "./provider/actions";
import { getApiRejectedStatus, getApiSuccessBody } from "../../helpers/api";

const AssetTabBid = () => {
  const dispatch = useDispatch();
  const assetDispatch = useAssetDispatch();
  const { handleError } = useError();
  const { assetId, bids } = useAssetSelector();
  const listRef = useRef();

  const fetchBids = useCallback(
    async (assetId) => {
      try {
        const response = await dispatch(retrieveBids()).unwrap();

        assetDispatch({
          type: SET_BIDS,
          bids: getApiSuccessBody(response),
          assetId,
        });
        listRef.current.scrollTo(0, 0);
      } catch (rejected) {
        handleError({ status: getApiRejectedStatus(rejected) });
      }
    },
    [assetDispatch, handleError, dispatch]
  );

  useEffect(() => {
    if (assetId) fetchBids(assetId);
  }, [assetId, fetchBids]);

  return (
    <div ref={listRef} className="asset__actions asset__actions--scroll">
      {bids.map((bid, index) => (
        <BidItem key={index} bid={bid} />
      ))}
    </div>
  );
};

export default React.memo(AssetTabBid);
