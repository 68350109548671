import React, { useCallback } from "react";
import BidWizard from "./wizards/Bid";
import TransactionModal from "./TransactionModal";
import { useAssetDispatch, useAssetSelector } from "./provider";
import { SET_BID_MODAL_OPEN } from "./provider/actions";

const BidModal = () => {
  const assetDispatch = useAssetDispatch();
  const { bidModalOpen } = useAssetSelector();

  const closeModal = useCallback(() => {
    assetDispatch({ type: SET_BID_MODAL_OPEN, open: false });
  }, [assetDispatch]);

  return (
    <TransactionModal show={bidModalOpen}>
      <BidWizard onClose={closeModal} />
    </TransactionModal>
  );
};

export default BidModal;
