import EthereumService from "./EthereumService";
import UserService from "./UserService";
import CollectionService from "./CollectionService";
import AssetService from "./AssetService";
import BidService from "./BidService";
import CertificationService from "./CertificationService";
import ProfileService from "./ProfileService";
import UserAuthService from "./UserAuthService";
import NotificationService from "./NotificationService";
import ActivityService from "./ActivityService";
import StripeService from "./StripeService";
import PaypalService from "./PaypalService";

const services = {
  EthereumService: new EthereumService(),
  UserService: new UserService(),
  AssetService: new AssetService(),
  BidService: new BidService(),
  CollectionService: new CollectionService(),
  CertificationService: new CertificationService(),
  ProfileService: new ProfileService(),
  UserAuthService: new UserAuthService(),
  NotificationService: new NotificationService(),
  ActivityService: new ActivityService(),
  StripeService: new StripeService(),
  PaypalService: new PaypalService()
};

export default services;
