import React from "react";
import ProtectedButton from "../../components/button/ProtectedButton";
import ShareButtons from "./ShareButtons";
import AssetItemImage from "./AssetItemImage";
import AssetItemAudio from "./AssetItemAudio";
import AssetItemVideo from "./AssetItemVideo";
import AssetItemPDF from "./AssetItemPDF";
import { ReactComponent as LikeIcon } from "../../assets/img/icons/like.svg";
import { getAssetType } from "../../helpers/asset";


const AssetItem = ({ asset }) => {
  return (
    <div className="asset__item">
      {(getAssetType(asset) === "image" || getAssetType(asset) === "jpg" || getAssetType(asset) === "gif") &&
        <AssetItemImage asset={asset} />
      }
      {(getAssetType(asset) === "audio") &&
        <AssetItemAudio asset={asset} />
      }
      {(getAssetType(asset) === "video") &&
        <AssetItemVideo asset={asset} />
      }
      {(getAssetType(asset) === "pdf") &&
        <AssetItemPDF asset={asset} />
      }

      <ShareButtons />
{/* 
      <ProtectedButton
        className="asset__likes p-0"
        variant="empty"
        onClick={undefined}
      >
        <LikeIcon />
        <span>{asset.likes}</span>
      </ProtectedButton> */}
    </div>
  );
};

export default AssetItem;
