const initialState = {
  asset: {},
  assetId: "",
  listing: {},
  collectionId: "",
  transactions: [],
  bids: [],
  bidModalOpen: false,
  buyModalOpen: false,
  descriptionEditing: false,
  titleEditing: false,
  collectionEditing: false,
  priceEditing: false
};

export default initialState;
