import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ReduxProvider from "./providers/redux";
import LoaderProvider from "./providers/loader";
import NotifyProvider from "./providers/notify";
import EthereumProvider from "./providers/ethereum";
import AuthWrapper from "./components/AuthWrapper";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import "./i18n";

import "./index.css";
import "./assets/styles/theme.scss";
import "./assets/styles/main.css";
import AssetDetailsProvider from "./pages/ItemDetails/provider";

TimeAgo.addDefaultLocale(en);

const initialOptions = {
  "client-id": "AZbfmfWys8k3oSamt5ZkR9VxAGUsyKaBqk--ovVSijOG8ZNi7Z4MBpWQrGooArGWyaPSFrRqCpAchMaY",
  currency: "EUR",
  intent: "capture"
};

ReactDOM.render(
  <React.StrictMode>
    <LoaderProvider>
      <ReduxProvider>
        <Router>
          <NotifyProvider>
            <AuthWrapper>
              <EthereumProvider>
                <AssetDetailsProvider>
                  <App />
                </AssetDetailsProvider>
              </EthereumProvider>
            </AuthWrapper>
          </NotifyProvider>
        </Router>
      </ReduxProvider>
    </LoaderProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
