import React from "react";
import { Button, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import metamask from "../../assets/img/metamask.png";
import { ETHEREUM_RESET, enableEthereum, promptAccountChange, getSignature } from "../../store/EthereumSlice";
import { LOGOUT_ETHEREUM_ACCOUNT, retrieveAuthUser } from "../../store/AuthSlice";
import { downgradeUserLevel } from "../../store/UserAuthSlice";
import { ethereumAccountStatus, generateId } from "../../helpers/auth";
import Web3 from "web3";
import { log } from "../../helpers/logger";

const enableSigTest = false;

const WalletDisconnect = () => {
  const { t } = useTranslation();
  const { authEthereumAddress, userId, authInfo } = useSelector((state) => state.auth);
  const { ethereumAccountAddress } = useSelector((state) => state.ethereum);
  const dispatch = useDispatch();

  const handleDisconnectWallet = async () => {
    try {
      await dispatch(downgradeUserLevel({ level: "ethereum" })).unwrap().then(async () => {
        dispatch(ETHEREUM_RESET());
        dispatch(LOGOUT_ETHEREUM_ACCOUNT());
        await dispatch(retrieveAuthUser({ userId }));
      })

    } catch (e) {
      console.error(e);
    }

  };

  const testSigSend = async () => {
    const accounts = await dispatch(enableEthereum({})).unwrap();
    const accountAddress = accounts[0];

    log('Account: ', accountAddress);

    const uniqueIdMessage = generateId(8);
    log('uniqueID: ', uniqueIdMessage);

    const message = t("user-details-page.mint-verification-message", {
      uniqueIdMessage,
    });

    // has to be hashed here because ecrecover of sollidity only accepts bytes32 as the hashed input
    const hashedMessage = Web3.utils.sha3(message);

    log('Hashed message: ', hashedMessage);

    const signature = await dispatch(
      getSignature({
        message: hashedMessage,
        accountAddress,
      })
    ).unwrap();

    log('Signature: ', signature);

    // first 32 bytes of signature
    const r = signature.slice(0, 66);
    // second 32 bytes of signature
    const s = '0x' + signature.slice(66, 130);
    // final 1 byte of signature
    const v = parseInt(signature.slice(130, 132), 16);

    log({ r, s, v });


  }

  const loginMetamask = async () => {
    await dispatch(enableEthereum({})).unwrap();
  }

  const changeAccount = async () => {
    await dispatch(promptAccountChange({})).unwrap();
  };

  const requiredAction = () => {

    let state = 2;
    let infoText = "";
    let buttonText = "";
    let callback = () => { };

    state = ethereumAccountStatus(ethereumAccountAddress, authInfo.identities);
    infoText = "user-details-page.metamask-login-prompt";
    buttonText = "user-details-page.metamask-login-button-text";

    switch (state) {
      case 0:
        callback = loginMetamask;
        break;
      case 1:
        callback = changeAccount;
        break;
      default:
        break;
    }
    return (
      <React.Fragment>

        {(state !== 2) &&
          <div className="pb-0 mb-0" >
            <div className="text-center" style={{
              fontSize: "16px",
              lineHeight: "24px",
              color: "#EECC53"
            }}>{t("user-details-page.metamask-action-required")}</div>
            <div className="text-white text-center ">{t(infoText)}</div>

            <div className="main__title main__title--center mt-3 mb-3">
              <div className="col-12 col-md-6">
                <Button className="sign__btn"
                  size="lg"
                  variant="light"
                  block
                  onClick={callback}>{t(buttonText)}</Button>
              </div>
            </div>
          </div>
        }
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {requiredAction()}

      <Image src={metamask} style={{ width: "100px" }} />

      <div className="main__title main__title--center mt-3 mb-3 wallet__container">
        <h3
          className="text-center"
          style={{
            fontSize: "16px",
            lineHeight: "24px",
            color: "#BDBDBD",
            display: "block",
          }}
        >
          <Trans
            t={t}
            i18nKey="user-details-page.metamask-connected-message"
            components={{
              span: <span />,
            }}
          />

          <div className="wallet__container">
            <span className="text-white">
              {t("user-details-page.metamask-address")}
            </span>{" "}
            {authEthereumAddress}
          </div>
        </h3>
      </div>

      <div className="d-flex justify-content-center w-100">
        <div className="col-12 col-md-6">
          <Button
            className="sign__btn"
            variant="light"
            size="lg"
            block
            onClick={handleDisconnectWallet}
          >
            {t("actions.disconnect")}
          </Button>
        </div>
      </div>

      {enableSigTest &&
        <div className="d-flex justify-content-center w-100">
          <div className="col-12 col-md-6">
            <Button
              className="sign__btn"
              variant="light"
              size="lg"
              block
              onClick={testSigSend}
            >
              Test sig
            </Button>
          </div>
        </div>
      }

    </React.Fragment>
  );
};

export default WalletDisconnect;
