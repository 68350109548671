import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { getItemSymbol } from "../../helpers/utils";
// import ProtectedButton from "../button/ProtectedButton";
import UserAvatar from "../user/Avatar";
import ItemImage from "./Image";
// import { updateAsset } from "../../store/AssetSlice";
import { /*getAssetCover,*/ getAssetThumbnail } from "../../helpers/asset";
// import { getUserProfile } from "../../helpers/user";
// import { ReactComponent as LikeIcon } from "../../assets/img/icons/like.svg";
import certificationIcon from "../../assets/img/icons/certification_icon.svg";
import "./image.scss";

const Item = ({ itemId, item }) => {
  // const dispatch = useDispatch();
  const [image, setImage] = useState("");
  // const [likes, setLikes] = useState(item.likes);
  const [creatorProfile, setCreatorProfile] = useState("");
  const [creatorUsername, setCreatorUsername] = useState("");

  const itemDetailsPath = useMemo(
    () =>
      item.type === "certificate"
        ? `/certifications/${itemId}`
        : `/items/${itemId}`,
    [itemId, item]
  );
  const userDetailsPath = useMemo(
    () => (item.ownerId ? `/user-details/${item?.ownerId?.userId?._id}` : ""),
    [item.ownerId]
  );

  /*const handleLike = async () => {
    var tempLikes = parseInt(likes);
    tempLikes += 1;

    setLikes(tempLikes.toString());

    try {
      //const originalPromiseResult// await dispatch(
        updateAsset({
          assetId: item._id,
          data: {
            title: item.title,
            description: item.description,
            owner: item.owner,
            saleType: item.saleType,
            file: item.file,
            price: item.price,
            likes: tempLikes,
          },
        })
      ).unwrap();
    } catch (rejectedValueOrSerializedError) {}
  };*/

  useEffect(() => {
    setCreatorProfile(item?.creatorId);
    setCreatorUsername(item?.creatorId?.nickname || "");
    setImage(getAssetThumbnail(item));
  }, [item]);

  return (
    <div className="mt-3 card_temp">
      <ItemImage
        src={image}
        alt={item.title}
        title={item.title}
        detailsPath={itemDetailsPath}
      />
      <div className="card__title mt-1">
        {item?.type === "certificate" && (
          <img
            className="pr-2"
            alt="certImg"
            src={certificationIcon}
            style={{ transform: "scale(0.7)" }}
          />
        )}
        <Link to={itemDetailsPath}>{item.title}</Link>
      </div>
      <div className="d-flex">
        <UserAvatar profile={creatorProfile} avatarClass="card__author">
          <Link to={userDetailsPath}>{creatorUsername}</Link>
        </UserAvatar>
        <div className="w-100 text-white ml-5">
          <div className="card__price">
            {(item?.saleType === "PutOnSale" ||
              item?.saleType === "NotOnSale") && (
              // <span className="item__card__label">Current price</span>
              <div style={{ fontSize: "13px" }}>Price</div>
            )}
            {item?.saleType === "PutOnBid" && (
              <span style={{ fontSize: "13px" }}>Current bid</span>
            )}
            {item?.saleType === "NotOnSale" ? (
              <div style={{ fontSize: "13px", fontWeight: "bold" }}>
                Not on sale
              </div>
            ) : (
              <div style={{ fontSize: "13px", fontWeight: "bold" }}>
                {item.price} {getItemSymbol(item.blockchain)}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Item;
