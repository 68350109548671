import React, { useEffect, useState } from "react";
import { Button, Image } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import KycModal from "../KycModal";
import { hasAppliedForRole } from "../../helpers/user";

const InvalidRole = () => {
  const history = useHistory();
  const { userId, authInfo } = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [hasApplied, setHasApplied] = useState(false);


  const beginKYC = () => {
    // Should display a modal here
    setShowModal(true);
  };

  const closeModal = () => {
    setHasApplied(true);
    setShowModal(false);
  }

  useEffect(() => {
    setHasApplied(hasAppliedForRole(authInfo, "kyc"));
  }, [authInfo])

  return (
    <React.Fragment>
      <KycModal show={showModal} closeCallback={closeModal} />
      {hasApplied ?
        (
          <div className="sign__form mt-4" style={{ maxWidth: "80%" }}>

            <div className="text-white text-center px-md-4" style={{ paddingBottom: "10%", paddingTop: "5%" }}>
              {/* Please check your email to continue*/}
              {t("new-item-page.invalid-role.text-after")}
            </div>
          </div>)
        :
        (
          <div className="sign__form mt-4" style={{ maxWidth: "80%" }}>
            <div
              className="d-flex flex-column justify-content-center align-items-center"
              style={{ maxWidth: "420px" }}
            >
              <div className="text-white text-center px-md-4" style={{ paddingBottom: "10%", paddingTop: "5%" }}>
                {t("new-item-page.invalid-role.text-before")}
                {/* You need to enlist in the "Know Your Costumer" program in order to participate in selling and buying digital Assets in the SaySoon platform */}
              </div>

              <Button className="sign__btn" variant="light" size="lg" block onClick={beginKYC}>
                {t("new-item-page.invalid-role.button")}
              </Button>

            </div>
          </div>)
      }
    </React.Fragment>
  );
};

export default InvalidRole;