import React, { useState } from "react"
import SellTypeCard from "./SellTypeCard";
import { Row, Col } from "react-bootstrap";


const SellTypeSelection = ({ handler, isMinted }) => {
    const [selected, setSelected] = useState([1, 0, 0]);

    return (
        <React.Fragment>

            <p className="text-muted text">
                Choose how you want to sell this item. <br /> There might be another blurb here with further details about restrictions on file size,<br /> proportions, file formats and other details.
            </p>

            <Row className="justify-content-center">
                { !isMinted &&
                    <Col className="col-auto">
                        <div className="justify-self-center">
                            <SellTypeCard title={"Not on sale"} subtitle={"Don't sell this item"} selected={selected[0]} onClick={() => { setSelected([1, 0, 0]); handler("notOnSale"); }}></SellTypeCard>
                        </div>
                    </Col>
                }
                <Col className="col-auto">
                    <div className="justify-self-center">
                        <SellTypeCard title={"Sell now"} subtitle={"Sell at a fixed price"} selected={selected[1]} onClick={() => { setSelected([0, 1, 0]); handler("onSale"); }}></SellTypeCard>
                    </div>
                </Col>

                { 
                    <Col className="col-auto">
                        <div className="justify-self-center">
                            <SellTypeCard title={"Highest bid"} subtitle={"Auction to the highest bidder"} selected={selected[2]} onClick={() => { setSelected([0, 0, 1]); handler("onBid"); }}></SellTypeCard>
                        </div>
                    </Col>
                }
            </Row>
        </React.Fragment>
    );
}

export default SellTypeSelection;