import React, { useEffect, useState } from "react";
import { Dropdown, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import UserAvatar from "../user/Avatar";
import { ReactComponent as DownArrow } from "../../assets/img/icons/down-arrow.svg";
import { ReactComponent as Profile } from "../../assets/img/icons/profile.svg";
import { ReactComponent as AddNew } from "../../assets/img/icons/add-new.svg";
import { ReactComponent as Settings } from "../../assets/img/icons/settings.svg";
import { ReactComponent as TopUp } from "../../assets/img/icons/top-up.svg";
import { ReactComponent as SignOut } from "../../assets/img/icons/sign-out.svg";
import { ReactComponent as AddNewCertification } from "../../assets/img/icons/certifications.svg";
import useLogout from "../../hooks/useLogout";
import { getAccountBalance } from "../../store/EthereumSlice";
import { useInterval } from "../../hooks/useInterval";
import TopUpModal from "./TopUpModal";

const INTERVAL_TIME_IN_MS = 10000;

const DropdownNav = () => {
  const { t } = useTranslation();
  const { handleLogout } = useLogout();
  const dispatch = useDispatch();
  const {
    userId: authUserId,
    authInfo,
    authProfile,
    isSetUp,
  } = useSelector((state) => state.auth);
  const { balance, symbol, chainId } = useSelector((state) => state.ethereum);
  const { authEthereumAddress } = useSelector(state => state.auth);
  const [showModal, setShowModal] = useState(false);
  const [currencySymbol, setCurrencySymbol] = useState('ETH');

  const getBalance = async () => {
    if (authEthereumAddress) {
      await dispatch(getAccountBalance({ accountAddress: authEthereumAddress }));
    }
  }

  useInterval(async () => {
    await getBalance()
    setCurrencySymbol(symbol);
  }, INTERVAL_TIME_IN_MS);


  const logout = () => {
    handleLogout();
  };

  const openTopUpModal = () => {
    setShowModal(true);
  }

  const hideModal = () => {
    setShowModal(false);
  }

  useEffect(() => {
    setCurrencySymbol(symbol);
  }, [symbol, chainId]);

  return (
    <Dropdown
      className="header__action header__action--profile cursor-pointer"
      bsPrefix="c__none"
    >
      <Dropdown.Toggle id={"dropdown-nav-profile"} as="a" bsPrefix="c__none">
        <UserAvatar
          profile={authProfile}
          avatarClass="header__profile-btn"
          verified={isSetUp}
        >
          <div>
            <p>{authInfo.username}</p>
            <span>{balance} {currencySymbol}</span>
          </div>

          <DownArrow />
        </UserAvatar>
      </Dropdown.Toggle>
      <Dropdown.Menu className="header__profile-menu" as="ul">
        <Dropdown.Item key="profile" as="li" bsPrefix="c__none">
          <Link to={`/user-details/${authUserId}`}>
            <Profile />
            <span>{t("menu.profile")}</span>
          </Link>
        </Dropdown.Item>
        <Dropdown.Item key="topup" as="li" bsPrefix="c__none">

          <Link to={'#'} onClick={openTopUpModal}>
            <TopUp />
            <span >{t("menu.top-up")}</span>
          </Link>
          <TopUpModal show={showModal} hide={hideModal} />
        </Dropdown.Item>
        <Dropdown.Item key="certification" as="li" bsPrefix="c__none">
          <Link to={`/new-certification`}>
            <AddNewCertification />
            <span>{t("menu.add-certification")}</span>
          </Link>
        </Dropdown.Item>

        <Dropdown.Item key="asset" as="li" bsPrefix="c__none">
          <Link to="/new-item">
            <AddNew />
            <span>{t("menu.add-asset")}</span>
          </Link>
        </Dropdown.Item>
        <Dropdown.Item key="collection" as="li" bsPrefix="c__none">
          <Link to="/new-collection">
            <AddNew />
            <span>{t("menu.add-collection")}</span>
          </Link>
        </Dropdown.Item>
        <Dropdown.Item key="settings" as="li" bsPrefix="c__none">
          <Link to={`/user-details/${authUserId}/settings`}>
            <Settings />
            <span>{t("menu.settings")}</span>
          </Link>
        </Dropdown.Item>
        <Dropdown.Item key="logout" as="li" bsPrefix="c__none">
          <Button as="a" bsPrefix="c__none" onClick={logout}>
            <SignOut />
            <span>{t("sign-out")}</span>
          </Button>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropdownNav;
