import React, { useRef, useState } from "react";
import { isFunction } from "formik";
import { Button } from "react-bootstrap";
import thmbnailIcon from "../../assets/img/icons/add-thumbnail.svg";
import { ReactComponent as CloseIcon } from "../../assets/img/icons/close_2.svg";

const ThumbnailPicker = ({ onSelect, onClick = null, selected = false, video = false }) => {
  const inputRef = useRef();
  const [thumbnail, setThumbnail] = useState(null);
  const [file, setFile] = useState(null);

  const handleChange = (event) => {
    setThumbnail(URL.createObjectURL(event.target.files[0]));
    setFile(event.target.files[0]);

    if (isFunction(onSelect)) onSelect(event.target.files[0]);

    if (inputRef.current) inputRef.current.value = ""; // Reset input value
  };

  const handleClick = () => {
    if (isFunction(onClick)) onClick(file);
  }

  const openSelectDialog = () => {
    if (inputRef.current) inputRef.current.click();
  };

  const onRemove = () => {
    setThumbnail(null);
  }

  const droppedFile = (event) => {
    event.preventDefault();
    setThumbnail(URL.createObjectURL(event.dataTransfer.files[0]));
    onSelect(event.dataTransfer.files[0]);
  }

  return (
    <div onDragOver={(event) => {
      event.preventDefault();
    }}
      onDrop={droppedFile}
      onClick={handleClick}>
      <input
        ref={inputRef}
        className="d-none"
        type="file"
        name="file"
        onChange={handleChange}
        accept="image/*"
      />


      {!thumbnail
        ? (<Button
          className="px-4"
          variant="light"
          style={{
            width: "158px",
            height: "186px",
            borderRadius: "0px",
            backgroundColor: "#003A5E"
          }}
          onClick={openSelectDialog}>
          <img src={thmbnailIcon} width="44px" alt="upload-icon" />
          <div className="pt-2 text-white">New Thumbnail</div>
        </Button>)
        : video ? (
          <div className={(selected ? "thumbnailPickerFrame-selected" : "thumbnailPickerFrame")}>
            <button
              style={{
                transition: "none",
                position: "absolute",
                right: 0,
                top: 0,
                cursor: "pointer",
                color: "white",
                borderBottom: `2px solid ${selected ? 'white' : 'rgba(255, 255, 255, 0.1)'}`,
                borderLeft: `2px solid ${selected ? 'white' : 'rgba(255, 255, 255, 0.1)'}`,
                padding: "1px",
                zIndex: "1"
              }}
              type="button"
              onClick={() => onRemove()}
            >
              <CloseIcon />
            </button>
            <div
              className={(selected ? "thumbnailPicker-selected" : "thumbnailPicker")}
              style={{
                position: "relative",
                height: "186px",
                width: "158px",
                background: `url(${thumbnail}) center center / cover no-repeat`,
              }}>

            </div>
          </div>
        )
          : (
            <div style={{
              border: "2px solid white",
              position: "relative",
              height: "186px",
              width: "158px",
              background: `url(${thumbnail}) center center / cover no-repeat`,
              border: "2px solid white"
            }}>
              <button
                style={{
                  transition: "none",
                  position: "absolute",
                  right: 0,
                  top: 0,
                  cursor: "pointer",
                  color: "white",
                  borderBottom: "2px solid white",
                  borderLeft: "2px solid white",
                  padding: "1px",
                }}
                type="button"
                onClick={() => onRemove()}
              >
                <CloseIcon />
              </button>
            </div>
          )
      }

    </div>
  );
};

export default ThumbnailPicker;
