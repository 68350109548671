import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import services from "../services";

export const createStripePayment = createAsyncThunk(
  "stripe/createPayment",
  async (payload, { getState, rejectWithValue }) => {
    try {

      const response = await services.StripeService.createPayment(payload);
      return response?.body?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getStripePayment = createAsyncThunk(
  "stripe/getPayment",
  async ({ paymentId }, { getState, rejectWithValue }) => {
    try {

      const response = await services.StripeService.getPayment(paymentId);
      return response?.body?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const stripePaymentStatus = createAsyncThunk(
  "stripe/paymentStatus",
  async ({ paymentId }, { getState, rejectWithValue }) => {
    try {

      const response = await services.StripeService.getPaymentStatus(paymentId);
      return response?.body?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const stripePaymentCallback = createAsyncThunk(
  "stripe/paymentCallback",
  async (payload, { getState, rejectWithValue }) => {
    try {

      const response = await services.StripeService.paymentCallback(payload);
      return response?.body?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

let initialState = {
  paymentId: "",
  paymentStatus: "",
  callbackResponse: "",
  state: "",
  error: {},
};

const stripeSlice = createSlice({
  name: "stripe",
  initialState,
  reducers: {},
  extraReducers: {
    /* ---- CREATE_PAYMENT ---- */
    [String(createStripePayment.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(createStripePayment.fulfilled)]: (state, action) => {
      state.state = "success";
      state.paymentId = action.payload.paymentId;
    },
    [String(createStripePayment.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
    /* ---- GET_PAYMENT ---- */
    [String(getStripePayment.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(getStripePayment.fulfilled)]: (state, action) => {
      state.state = "success";
      state.paymentStatus = action.payload;
    },
    [String(getStripePayment.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
    /* ---- PAYMENT_STATUS ---- */
    [String(stripePaymentStatus.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(stripePaymentStatus.fulfilled)]: (state, action) => {
      state.state = "success";
      state.paymentStatus = action.payload;
    },
    [String(stripePaymentStatus.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
    /* ---- PAYMENT_CALLBACK ---- */
    [String(stripePaymentCallback.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(stripePaymentCallback.fulfilled)]: (state, action) => {
      state.state = "success";
      state.callbackResponse = action.payload;
    },
    [String(stripePaymentCallback.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    }
  }
});

export default stripeSlice.reducer;