import React from "react";
import docIcon from "../../../assets/img/icons/certification_icon.svg"

const ListItem = ({ file }) => {
  return (
    <div className="mt-3" style={{ color: "white", borderBottom: "1px solid white" }}>
      <div className="d-flex">
        <div style={{ position: "relative" }}>
          {/* <img src={documentation} alt="documentation" /> */}
          <img
            style={{
              // position: "absolute",
              // left: "32%",
              // top: "32%",
            }}
            src={docIcon}
            alt="documentation2"
          />
        </div>
        <h6
          style={{ fontSize: "22px", fontWeight: 600, color: "white" }}
          className="align-self-center ml-3"
        >
          {file.name}
        </h6>
      </div>
      <p
        style={{ width: "65%", fontSize: "16px", fontWeight: 400 }}
        className="mt-3"
      >
        {file.description}
      </p>
    </div>
  );
};

export default ListItem;
