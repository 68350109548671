import swaggerProps from "../plugins/swagger-client";

export default class UserService {
  createProfile(userId, payload) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Profile.createProfile(
          { userId },
          swaggerProps.buildRequest(payload),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  }

  retrieveProfiles(params = {}) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Profile.retrieveProfiles(
          params,
          swaggerProps.buildRequest(),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  retrieveProfile(userId, profileId) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Profile.retrieveProfile(
          { userId: userId, profileId: profileId },
          swaggerProps.buildRequest(),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  updateProfile(userId, profileId, payload) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Profile.updateProfile(
          { userId, profileId },
          swaggerProps.buildRequest(payload),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error(error);
        return Promise.reject(error);
      });
  }

  deleteProfile(profileId) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Profile.deleteProfile(
          { profileId },
          swaggerProps.buildRequest(),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }


  createProfileSocial(userId, profileId, payload) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Profile.createProfileSocial(
          { userId, profileId },
          swaggerProps.buildRequest(payload),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  }

  retrieveProfileSocials(userId, params = {}) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Profile.retrieveProfileSocials(
          { userId, profileId: params },
          swaggerProps.buildRequest(),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}
