export const getAuthToken = () => {
  let authToken = null;
  try {
    const storedValue = localStorage.getItem("GALLERY_NFT_AUTH_TOKEN");
    authToken = storedValue != null ? JSON.parse(storedValue) : null;
  } catch (error) {
    console.error(error);
    authToken = null;
  }

  return authToken;
};

export const setAuthToken = (authToken) => {
  try {
    if (authToken) {
      localStorage.setItem(
        "GALLERY_NFT_AUTH_TOKEN",
        JSON.stringify(authToken)
      );
    } else {
      localStorage.removeItem("GALLERY_NFT_AUTH_TOKEN");
    }
  } catch (error) {
    console.error(error);
  }
};

export const setIsAuthorized = (isAuthorized) => {
  try {
    if (isAuthorized) {
      localStorage.setItem(
        "GALLERY_NFT_IS_AUTHORIZED",
        JSON.stringify(isAuthorized)
      );
    } else {
      localStorage.removeItem("GALLERY_NFT_IS_AUTHORIZED");
    }
  } catch (error) {
    console.error(error);
  }
};

export const isAuthorized = () => {
  let isAuth = null;
  try {
    const storedValue = localStorage.getItem("GALLERY_NFT_IS_AUTHORIZED");
    isAuth = storedValue != null ? JSON.parse(storedValue) : false;
  } catch (error) {
    console.error(error);
    isAuth = false;
  }

  return isAuth;
};

export const getEthereumAccount = (identities) => {
  let registeredAcount = {};

  const match = identities.find((identity) => identity.type === "ethereum");

  if (match) {
    registeredAcount.identity = match.identityId;
    registeredAcount.id = match._id
  }

  return registeredAcount;
};

export const ethereumAccountStatus = (accountAddress, identities) => {
  // console.log('Identities: ', identities);
  // console.log('Account passed: ', accountAddress);
  if (!accountAddress || !identities) return 0;

  const match = identities.find((identity) => identity.type === "ethereum");

  if (match) {
    if ((accountAddress).localeCompare(match.identityId, 'en', { sensitivity: 'accent' }) !== 0) {
      // state 1 - connected but current Metamask account != registered account
      return 1;
    } else {
      // state 2 - connected and current Metamask account == registered account;
      return 2;
    }

  } else {
    // state 0 - not registered
    return 0;
  }

};

// generates a random id of specified length
export const generateId = (length) => {
  let array = new Uint8Array((length || 40) / 2)
  window.crypto.getRandomValues(array);
  return Array.from(array, (dec) => dec.toString(16).padStart(2, "0")).join('');
}