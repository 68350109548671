import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Image } from "react-bootstrap";
import AssetDescriptionForm from "./AssetDescriptionForm";
import {
  useAssetDispatch,
  useAssetSelector,
} from "./provider";
import { TOGGLE_DESCRIPTION_EDITING } from "./provider/actions";
import { getAssetCreatorId } from "../../helpers/asset";
import PencilIcon from "../../assets/img/pencil.svg";

const AssetDescription = ({ asset }) => {
  const { t } = useTranslation();
  const assetDispatch = useAssetDispatch();
  const { authInfo } = useSelector((state) => state.auth);
  const { descriptionEditing } = useAssetSelector();
  const [hasEditPermissions, setHasEditPermissions] = useState(false);

  const toggleEditing = useCallback(() => {
    assetDispatch({
      type: TOGGLE_DESCRIPTION_EDITING,
    });
  }, [assetDispatch]);

  useEffect(() => {
    setHasEditPermissions(authInfo._id === getAssetCreatorId(asset));
  }, [authInfo, asset]);

  return (
    <div className="asset__desc">
      <div className="d-flex align-items-center mb-3">
        {hasEditPermissions && (
          <button className="mr-2" onClick={toggleEditing}>
            <Image
              style={({ width: "18px" }, { height: "18px" })}
              src={PencilIcon}
            ></Image>
          </button>
        )}

        <h2 className="mb-0">{t("item-details-page.description")}</h2>
      </div>

      {hasEditPermissions && descriptionEditing ? (
        <AssetDescriptionForm asset={asset} onSuccess={toggleEditing} />
      ) : (
        <p>{asset.description}</p>
      )}
    </div>
  );
};

export default AssetDescription;
