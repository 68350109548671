import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { Row, Col } from "react-bootstrap";
import ThumbnailPicker from "../../components/uploadFile/Thumbnail";
import { useLoader } from "../../providers/loader";
import {
  VIDEO_DURATION_PERCENTAGE_STEP,
  MAX_VIDEO_DURATION_PERCENTAGE,
  videoInitialState,
  urltoFile,
  videoReducer,
  takeSnapshot
} from "./videoHelpers";


const VideoGrid = ({ videoFiles, onRemove, onSelect = null }) => {
  const { setLoading } = useLoader();
  const videoRef = useRef();
  const canvasRef = useRef();
  const [selectedThumbnail, setSelectedThumbnail] = useState(0);
  const [thumbnails, setThumbnails] = useState([]);
  const [{ metadataLoaded, dataLoaded, suspended }, videoDispatch] = useReducer(
    videoReducer,
    videoInitialState
  );

  const onThumbnailClick = async (e, image, index) => {
    e.preventDefault();
    setSelectedThumbnail(index);
    const urlTofile = await urltoFile(image, `videoThumb_${index}.jpeg`, 'image/jpeg');
    onSelect(urlTofile);
  };

  const thumbnailSelect = (value) => {
    setSelectedThumbnail(0);
    onSelect(value);
  };

  const captureThumbnails = useCallback(async () => {
    try {
      setLoading(true);
      const snapshotList = [];
      for (
        let p = 0;
        p <= MAX_VIDEO_DURATION_PERCENTAGE;
        p += VIDEO_DURATION_PERCENTAGE_STEP
      ) {
        const snapshot = await takeSnapshot(
          videoRef.current,
          canvasRef.current,
          videoRef.current.duration * p
        );
        snapshotList.push(snapshot);
      }
      setThumbnails((t) => [...t, ...snapshotList]);
      videoDispatch({ type: "RESET" });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [videoRef, canvasRef, setLoading, videoDispatch]);

  useEffect(() => {
    if (metadataLoaded && dataLoaded && suspended && videoRef.current)
      captureThumbnails();
  }, [metadataLoaded, dataLoaded, suspended, videoRef, captureThumbnails]);

  useEffect(() => {
    const loadVideo = () => {
      videoRef.current.preload = "metadata";
      videoRef.current.src = videoFiles[videoFiles.length - 1].blob;
      videoRef.current.muted = true;
    };

    if (videoFiles && videoFiles.length > 0) loadVideo();
  }, [videoFiles, videoRef, setLoading]);

  return (
    <>
      <Row className="no-gutters ml-n2">
        {videoFiles.map((video, index) => (
          <Col key={index} className="pl-2 mt-3 col-12 col-md-4">
            <div
              style={{
                border: "2px solid white",
                position: "relative",
                height: "275px",
                width: "399px",
              }}
              className="video_container"
            >
              <video
                className="video__grid"
                width="395"
                height="225"
                controls={true}
                src={video.blob}
              ></video>
              <button
                style={{
                  cursor: "pointer",
                  color: "white",
                  width: "100%",
                  padding: "4px",
                }}
                type="button"
                onClick={() => onRemove(index)}
              >
                Change file
              </button>
            </div>
          </Col>
        ))}
      </Row>
      <Row className="no-gutters ml-n2">
        <Col className="pl-2 mt-3 col-12 col-md-12 text-white">
          <div
            style={{
              fontSize: "18px",
              lineHeight: "19px",
              paddingBottom: "2%",
              paddingTop: "1%",
            }}
          >
            Thumbnail
          </div>
          <div
            style={{
              width: "70%",
              fontSize: "16px",
              lineHeight: "24px",
              paddingBottom: "1%",
            }}
          >
            <span className="text-muted">
              {" "}
              Upload your preferred thumbnail for your video. This thumbnail
              will be the first image shown of your new asset to other users.{" "}
            </span>
          </div>
        </Col>
      </Row>
      <Row className="no-gutters ml-n2">
        <Col className="pl-2 mt-3 col-auto text-white">
          <ThumbnailPicker onClick={thumbnailSelect} onSelect={thumbnailSelect} selected={selectedThumbnail === 0} video={true}></ThumbnailPicker>
        </Col>
        <Col className="pl-2 mt-3 col-auto text-white">
          <Row className="no-gutters">
            {thumbnails != null &&
              thumbnails.map((image, index) => (
                <Col className="pl-2 pb-sm-3 col-auto text-white" key={index + 1}>
                  <div
                    className={
                      "thumbnailFrame " +
                      (selectedThumbnail === index + 1
                        ? "thumbnailFrame-selected"
                        : "")
                    }
                    onClick={(event) => onThumbnailClick(event, image, index + 1)}
                  >
                    <img
                      style={{
                        opacity: "30%",
                        height: "186px",
                        width: "158px",
                        objectFit: "cover",
                        cursor: "pointer",
                      }}
                      src={image}
                    />
                  </div>
                </Col>
              ))}
          </Row>
        </Col>
      </Row>

      <canvas hidden ref={canvasRef} />

      <video
        hidden
        ref={videoRef}
        onLoadedMetadata={() =>
          videoDispatch({
            type: "SET_METADATA_LOADED",
            metadataLoaded: true,
          })
        }
        onLoadedData={() =>
          videoDispatch({ type: "SET_DATA_LOADED", dataLoaded: true })
        }
        onSuspend={() =>
          videoDispatch({ type: "SET_SUSPENDED", suspended: true })
        }
        controls={true}
      />
    </>
  );
};

export default VideoGrid;