import swaggerProps from "../plugins/swagger-client";
export default class StripeService {

  createPayment(payload) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Payment.createPayment(
          { providerId: 'stripe' },
          swaggerProps.buildRequest(payload),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  getPaymentStatus(paymentId) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Payment.retrievePaymentStatus(
          { providerId: 'stripe', paymentId },
          swaggerProps.buildRequest(),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  getPayment(paymentId) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Payment.retrievePayment(
          { providerId: 'stripe', paymentId },
          swaggerProps.buildRequest(),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  paymentCallback(payload) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Utilities.processCallback(
          { callbackService: 'stripe' },
          swaggerProps.buildRequest(payload),
          {}
        );
      })
      .then((response) => {
        return response.payload;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

}