import React, { useState, useEffect, useCallback } from "react";
import { Row, Col, Button } from "react-bootstrap";
import UserAvatar from "../../components/user/Avatar";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import CertificationGallery from "./Gallery";
import CertificationDescription from "./Description";
import CertificationDocuments from "./Documents";
import CertificationHistory from "./History";
import CertificationShare from "./Share";
import { retrieveCertification } from "../../store/CertificationSlice";

import "./index.css";
import TransactionActionButtons from "../ItemDetails/TransactionActionButtons";
import { useAssetMethods, useAssetSelector } from "../ItemDetails/provider";
import BuyModal from "../ItemDetails/BuyModal";
import BidModal from "../ItemDetails/BidModal";
import { getAssetCollection, getAssetCollectionId, getAssetOwnerId } from "../../helpers/asset";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import AssetDeleteButton from "../ItemDetails/AssetDeleteButton";
import { userCardStyles } from "./styles";
import AssetTitle from "../ItemDetails/AssetTitle";
import AssetCollection from "../ItemDetails/AssetCollection";
import AssetPrice from "../ItemDetails/AssetPrice";

const collectionDetailsPath = "/collections";

const CertificationPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const { certificationId } = useParams();
  const { asset } = useAssetSelector();
  const [active, setActive] = useState("");
  const [certification, setCertification] = useState("");
  const [documents, setDocuments] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [ownerId, setOwnerId] = useState("");
  const [saleType, setSaleType] = useState("");
  const { userId } = useSelector(state => state.auth);
  const { fetchAsset } = useAssetMethods();
  const [collection, setCollection] = useState(null);
  const [collectionId, setCollectionId] = useState("");


  const fetchCertification = useCallback(() => {
    dispatch(retrieveCertification({ assetId: certificationId }))
      .unwrap()
      .then((originalPromiseResult) => {
        const responseData = originalPromiseResult.body.data;
        setCertification(responseData);
        setActive(responseData.files[0].file.sizes[1000]?.url);
        setDocuments(responseData.documents || []);
        setTransactions(responseData.transactions || []);
        setOwnerId(getAssetOwnerId(responseData));
        setSaleType(responseData.saleType);
      })
      .catch((rejectedValueOrSerializedError) => {
        console.error(rejectedValueOrSerializedError);
      });
  }, [certificationId, dispatch, asset]);

  const handleSellBtn = (event) => {
    event.preventDefault();

    history.push(`/place-on-market/${certificationId}`, { isNotifyPersist: true });
  }

  useEffect(() => {
    fetchCertification();
    setCollection(getAssetCollection(asset));
    setCollectionId(getAssetCollectionId(asset));
  }, [fetchCertification]);

  useEffect(() => {
    fetchAsset(certificationId);
  }, [certificationId, fetchAsset]);

  return (
    <div className="text-white w-100">
      <div className="p-5">
        {/* <div className="main__title main__title--page mt-0 mb-4"> */}
        {/* <h1>{certification.title}</h1> */}
        <AssetTitle asset={asset}></AssetTitle>
        {/* </div> */}

        <Row className="no-gutters ml-n5">
          <Col className="col-12 col-lg-8 pl-5">
            <CertificationGallery
              certificationData={certification}
              active={active}
              setActive={setActive}
            />
          </Col>

          <Col className="col-12 col-lg-4 pl-5">
            {/* <AssetDetailsProvider> */}
            <TransactionActionButtons />
            <BuyModal />
            <BidModal />
            {/* </AssetDetailsProvider> */}
            {((ownerId === userId) && (saleType === 'NotOnSale')) &&
              <div className="p-2">
                <Row>
                  <Col>
                    <Button className="home__btn home__btn--clr"
                      variant="light"
                      size="lg"
                      onClick={handleSellBtn}
                    >
                      {t('actions.sell')}
                    </Button>
                  </Col>
                  <Col>
                    {(ownerId === userId && !asset?.tokenId) && <AssetDeleteButton asset={asset} />}
                  </Col>
                </Row>
              </div>
            }
            <CertificationDescription certification={certification} />
            { collection && <AssetCollection asset={asset} />}
            {(ownerId === userId && asset?.saleType === "PutOnSale") && <div className="p-2"> <AssetPrice asset={asset} /> </div>}
            {/* {collection && (
              <div style={userCardStyles.card} className="mt-3 p-3 ps-4">
                <h4><span>{t("item-details-page.collection")}</span></h4>
                <UserAvatar profile={null} avatarClass="card__author">
                  {collection && (
                    <Link to={`${collectionDetailsPath}/${collectionId}`}>
                      {collection.title}
                    </Link>
                  )}
                </UserAvatar>
              </div>
            )} */}
          </Col>
        </Row>

        <Row className="no-gutters ml-n5">
          <Col className="col-12 col-md-6 col-lg-4 pl-5">
            <CertificationDocuments documents={documents} />
          </Col>

          <Col className="col-12 col-md-6 col-lg-4 pl-5">
            <CertificationHistory transactions={transactions} asset={asset} />
          </Col>

          <Col className="col-12 col-md-12 col-lg-4 pl-5">
            <CertificationShare certificationId={certificationId} />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CertificationPage;
