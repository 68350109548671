import React from "react";
import { useSelector } from "react-redux";
import WalletConnect from "./WalletConnect";
import WalletDisconnect from "./WalletDisconnect";
import WalletNotFound from "./WalletNotFound";

const Wallet = () => {
  const { walletInstalled, walletConnected } = useSelector(
    (state) => state.ethereum
  );

  return (
    <div className="sign__form mt-4" style={{ maxWidth: "100%" }}>
      <div
        className="d-flex flex-column justify-content-center align-items-center"
        style={{ maxWidth: "480px" }}
      >
        {walletInstalled ? (
          <React.Fragment>
            {walletConnected ? <WalletDisconnect /> : <WalletConnect />}
          </React.Fragment>
        ) : (
          <WalletNotFound />
        )}
      </div>
    </div>
  );
};

export default Wallet;
