import { combineReducers, configureStore } from "@reduxjs/toolkit";

import ethereumReducer from "./EthereumSlice";
import userReducer from "./UserSlice";
import collectionReducer from "./CollectionSlice";
import assetReducer from "./AssetSlice"
import bidReducer from "./BidSlice"
import authReducer from "./AuthSlice"
import profileReducer from "./ProfileSlice";
import userauthReducer from './UserAuthSlice';
import certificationReducer from "./CertificationSlice";
import notificationReducer from "./NotificationSlice";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";
import activityReducer from './ActivitySlice';
import stripeReducer from "./StripeSlice";

const storePersistKeys = {
  user: "nft_user"
};

const reducer = combineReducers({
  ethereum: ethereumReducer,
  user: userReducer,
  collection: collectionReducer,
  asset: assetReducer,
  bid: bidReducer,
  auth: persistReducer({ key: storePersistKeys.user, storage }, authReducer),
  certification: certificationReducer,
  profile: profileReducer,
  userauth: userauthReducer,
  notification: notificationReducer,
  activity: activityReducer,
  stripe: stripeReducer
});

const rootReducer = (state, action) => {
  if (action.type === "store/reset") {
    state = undefined;
  }
  return reducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);

export const resetPersistor = () => {
  persistStore(store);
};

export default store;
