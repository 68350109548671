import React from "react";
import { Trans, useTranslation } from "react-i18next";
import DocumentationListItem from "./ListItem";

const DocumentationList = ({ files }) => {
  const { t } = useTranslation();

  return (
    <div className="mt-5">
      <div>
        <h4 className="sign__title">
          {t(
            "new-certification-page.documentation.uploaded-certification-title"
          )}
        </h4>

        {files.length > 0 ? (
          <div>
            {files.map((file, index) => (
              <DocumentationListItem key={index} file={file} />
            ))}
          </div>
        ) : (
          <p className="text-muted text">
            <Trans
              t={t}
              i18nKey="new-certification-page.documentation.uploaded-certification-subtitle"
              components={{
                br: <br />,
                span: <span></span>,
              }}
            />
          </p>
        )}
      </div>
    </div>
  );
};

export default DocumentationList;
