import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";

const CreatePasswordSuccess = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const handleProceed = () => history.push("/login");

  return (
    <div>
      <h4 className="sign__title mb-2">
        {location.pathname.split("/", 2)[1] === "passwordreset"
          ? t("create-password-page.thank-you-title-reset")
          : t("create-password-page.thank-you-title")}
      </h4>

      <p className="text-muted mb-5" style={{ fontSize: "0.9rem" }}>
        {t("create-password-page.thank-you-subtitle")}
      </p>

      <Button
        block
        variant="light"
        size="lg"
        type="submit"
        className="sign__btn my-0"
        onClick={handleProceed}
      >
        {t("actions.proceed-to-login")}
      </Button>
    </div>
  );
};

export default CreatePasswordSuccess;
