import React from "react";
import { Button, Image } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import metamask from "../../assets/img/metamask.png";


const InvalidMetamask = () => {
  const history = useHistory();
  const { userId } = useSelector((state) => state.auth);
  const { t } = useTranslation();

  const redirectToProfile = () => {
    history.push(`/user-details/${userId}/settings`);
  };

  return (
    <React.Fragment>
      <div className="sign__form mt-4" style={{ maxWidth: "80%" }}>
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ maxWidth: "420px" }}
        >
          <Image src={metamask} style={{ width: "100px" }} />

          <div className="text-white text-center px-md-4" style={{ paddingBottom: "10%", paddingTop: "5%" }}>
            {t("new-item-page.invalid-account.text")}
          </div>

          <Button className="sign__btn" variant="light" size="lg" block onClick={redirectToProfile}>
            {t("new-item-page.invalid-account.button")}
          </Button>

        </div>
      </div>
    </React.Fragment>
  );
};

export default InvalidMetamask;