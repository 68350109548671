import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import ItemImage from "../item/Image";
import UserAvatar from "../user/Avatar";
import CollectionItemAsset from "./CollectionItemAsset";
import { retrieveAssets } from "../../store/AssetSlice";
import { Row } from "react-bootstrap";
import { log } from "../../helpers/logger";

const CollectionItem = ({ collection }) => {
  const dispatch = useDispatch();
  const [collectionAssets, setCollectionAssets] = useState([]);
  const [collectionCover, setCollectionCover] = useState("");
  const [collectionDetailsPath, setCollectionDetailsPath] = useState("");
  const [ownerProfile, setOwnerProfile] = useState("");
  const [ownerUsername, setOwnerUsername] = useState("");
  const [ownerDetailsPath, setOwnerDetailsPath] = useState("");

  useEffect(() => {
    const fetchAssets = async () => {
      try {
        const originalPromiseResult = await dispatch(
          retrieveAssets({ collectionId: collection._id, limit: 3 })
        ).unwrap();

        const responseDataJSON = JSON.parse(originalPromiseResult.data);
        setCollectionAssets(responseDataJSON.data);
      } catch (rejectedValueOrSerializedError) {
        log(rejectedValueOrSerializedError);
      }
    };

    fetchAssets();
  }, [dispatch]);

  useEffect(() => {
    setCollectionCover(collection?.banner?.sizes["1200"]?.url);
    setCollectionDetailsPath(`/collections/${collection?._id}`);
    setOwnerProfile(collection?.ownerId);
    setOwnerUsername(collection?.ownerId?.nickname || "-");
    setOwnerDetailsPath(`/user-details/${(collection?.ownerId?.userId?._id || "-")}`);
  }, [collection]);

  return (
    <div className="new__card">
      <div className="card__border">
        <ItemImage
          coverClass="card__cover__new"
          width="271px"
          height="103px"
          src={collectionCover}
          alt={collection.title}
          title={collection.title}
          detailsPath={collectionDetailsPath}
        />

        <h3 className="card__title pl-3">
          <Link to={collectionDetailsPath}>{collection.title}</Link>
        </h3>

        <UserAvatar
          profile={ownerProfile}
          avatarClass="card__author pl-3"
        >
          <Link to={ownerDetailsPath}> <div style={{
            fontSize: "10px",
            lineHeight: "12px",
            color: "#BDBDBD"
          }} >Creator</div> {ownerUsername} </Link>
        </UserAvatar>

        <div className="card__info">
          <Row className="flex-nowrap align-items-center no-gutters">
            {collectionAssets.map((item, index) => (
              <CollectionItemAsset asset={item} key={index} index={0} />
            ))}
          </Row>
          {collectionAssets.length < 1 && <div
            style={{
              textAlign: "start",
              margin: "auto",
              padding: "21.85px",
              fontSize: "14px",
              lineHeight: "17px",
              color: "#BDBDBD"
            }}>This collection is empty</div>}
        </div>

        <div className="card__info pb-3">
          <div className="card__price pl-3">
            <span>Category</span>
            <span>{collection.category} </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollectionItem;
