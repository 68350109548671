import React from "react";
import { Row, Col } from "react-bootstrap";
import {ReactComponent as CloseIcon} from "../../assets/img/icons/close_2.svg";
import certificationIcon from "../../assets/img/fileIcon.svg";

const PDFGrid = ({ pdfs, onRemove }) => {
  return (
    <Row className="no-gutters ml-n2">
      {pdfs.map((pdf, index) => (
        <Col key={index} className="pl-2 mt-3 col-12 col-md-4">
          <div
            style={{
              border: "2px solid white",
              position: "relative",
              height: "186px",
              width: "200px",
              background: `url(${certificationIcon}) no-repeat`,
            }}
          >
            {/* <img src={certificationIcon}/> */}
            <button
              style={{
                position: "absolute",
                right: 0,
                top: 0,
                cursor: "pointer",
                color: "white",
                borderBottom: "2px solid white",
                borderLeft: "2px solid white",
                padding: "1px",
              }}
              type="button"
              onClick={() => onRemove(index)}
            >
              <CloseIcon />
            </button>
          </div>
        </Col>
      ))}
    </Row>
  );
};

export default PDFGrid;
