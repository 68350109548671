import { explorers } from "../../constants/AppSettings";

export const defaultSuffix = '/tx';

export const selectPrefixUrl = (asset, hItem, suffix = defaultSuffix) => {
    let urlPrefix;

    if (!asset || !asset?.blockchain) {
        return "null";
    }

    switch (asset.blockchain) {
        case "saysoon":
            urlPrefix = `${explorers.saysoon}${suffix}/`;
            break;
        case "ropsten":
            urlPrefix = `${explorers.ropsten}${suffix}/`;
            break;
        case "polygon":
            urlPrefix = `${explorers.polygon}${suffix}/`;
            break;
        case "rinkeby":
            urlPrefix = `${explorers.rinkeby}${suffix}/`;
            break;
        case "mumbai":
            urlPrefix = `${explorers.mumbai}${suffix}/`;
            break;
        case "goerli":
            urlPrefix = `${explorers.goerli}${suffix}/`;
            break;
        case "ethereum":
            urlPrefix = `${explorers.ethereum}${suffix}/`;
            break;
        default:
            urlPrefix = `${explorers.saysoon}${suffix}/`;
    }

    const id = hItem && hItem?.transactionHash ? hItem?.transactionHash : hItem;

    return `${urlPrefix}${id}`;
}