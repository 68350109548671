import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import UserAvatar from "../../components/user/Avatar";
import { useAssetSelector } from "./provider";
import {
  getAssetCreatorId,
  getAssetCreatorUsername,
  getAssetOwnerId,
  getAssetOwnerUsername,
} from "../../helpers/asset";
import { app } from "../../constants/AppSettings";
import { blockchainsDev, blockchainsProd } from "../../constants/items";
import { log } from "../../helpers/logger";


const userDetailsPath = "/user-details";
const initialUserModel = {
  id: "",
  profile: null,
  username: "",
};

const AssetTabDetails = () => {
  const { t } = useTranslation();
  const { asset } = useAssetSelector();
  const [creator, setCreator] = useState(initialUserModel);
  const [owner, setOwner] = useState(initialUserModel);
  const blockchains = ((app.environment === "prod") || (app.environment === "pre")) ? blockchainsProd : blockchainsDev;

  useEffect(() => {
    setCreator({
      id: getAssetCreatorId(asset),
      profile: asset.creatorId,
      username: getAssetCreatorUsername(asset),
    });
    setOwner({
      id: getAssetOwnerId(asset),
      profile: asset.ownerId,
      username: getAssetOwnerUsername(asset),
    });

    log('owner', asset);

  }, [asset]);

  return (
    <React.Fragment>
      <ul className="asset__authors border-top-0 mt-0">
        <li>
          <span>{t("item-details-page.creator")}</span>

          <UserAvatar
            profile={creator.profile}
            avatarClass="card__author"
            verified
          >
            {creator.id && (
              <Link to={`${userDetailsPath}/${creator.id}`}>
                {creator.username}
              </Link>
            )}
          </UserAvatar>

          <div className="mt-2">
            <span> Created on </span>
            <p>{new Date(asset?.createdAt).toLocaleDateString()}</p>
          </div>
        </li>

        <li>
          <span>{t("item-details-page.owner")}</span>

          <UserAvatar profile={owner.profile} avatarClass="card__author" verified>
            {owner.id && (
              <Link to={`${userDetailsPath}/${owner.id}`}>{owner.username}</Link>
            )}
          </UserAvatar>

          <div className="mt-2">
            <span> Owned from </span>
            <p>{new Date(asset?.updatedAt).toLocaleDateString()}</p>
          </div>

        </li>
      </ul>

      {asset?.tokenId &&
        <div className="pt-4">
          <p className="text-white">
            <span style={{ color: "#bdbdbd" }}>Chain</span> {blockchains.find((el) => el.value === asset?.blockchain)?.label}
          </p>
        </div>}
    </React.Fragment>
  );
};

export default React.memo(AssetTabDetails);
