export const getCurrencySymbol = (chainId) => {
  switch (chainId) {
    case "0x1194":
      return 'SAY';
    case "0x89":
      return 'MATIC';
    case "0x13881":
      return 'MATIC';
    default:
      return 'ETH';
  }
}