import React from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
// import { useTranslation } from "react-i18next";
import metamask from "../../assets/metamask_action.svg";
import connectMetaToSay from "../../assets/connectMetamaskToSaysoon.svg";
import { ethereumAccountStatus } from "../../helpers/auth";

const MetamaskMenuStatus = () => {
  // const { t } = useTranslation();
  const { userId, authInfo } = useSelector((state) => state.auth);
  const { ethereumAccountAddress } = useSelector((state) => state.ethereum);
  const status = ethereumAccountStatus(
    ethereumAccountAddress,
    authInfo.identities
  );

  return (
    <React.Fragment>
      {status !== 2 && (
        <div className="d-block d-md-none">
          <div className="card__wrapper text-center p-3 mb-3">
            <Link to={`/user-details/${userId}/settings`}>
              {status === 0 && (
                <img
                  src={metamask}
                  alt="metamask"
                  style={{ maxWidth: "55px", maxHeight: "55px" }}
                />
              )}

              {status === 1 && <img src={connectMetaToSay} alt="metamask" />}

              <div
                className="text-white mt-3"
                style={{
                  fontSize: "13px",
                  lineHeight: "12px",
                }}
              >
                Metamask action required
              </div>
            </Link>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default MetamaskMenuStatus;
