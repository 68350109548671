import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import Item from "../../components/item/Item";
import HomeHeader from "./HomeHeader";
import { retrieveAssets } from "../../store/AssetSlice";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getStripePayment } from "../../store/StripeSlice";
import { getPaypalPayment } from "../../store/PaypalSlice";
import TopUpStateModal from "../TopUp/topUpStateModa";
import { itemsPerScroll } from "../Explore/constants";
import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";

const Home = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [assets, setAssets] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [initialized, setInitialized] = useState(false);
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [stripeState, setStripeState] = useState('cancel');
  const [topUpAmount, setTopUpAmount] = useState(0);
  const gaEventTracker = useAnalyticsEventTracker('Home');

  const hideModal = () => {
    setShowModal(false);
  }

  const getPaymentInfo = (path) => {
    const tokens = path.input.split("/");
    // if (tokens.length === 5) {
    const provider = tokens?.[3];
    const paymentId = tokens?.[4];

    return { provider, paymentId };
    // } else {
    //   console.log('Invalid path');
    //   return {};
    // }
  }

  const getPaymentAmount = async (provider = "paypal", paymentId) => {
    let amount = localStorage.getItem("GALLERY_TOP_UP_AMOUNT");

    switch (provider) {
      case "paypal":
        // amount = await dispatch(getPaypalPayment({ paymentId })).unwrap();
        return amount;
      case "stripe":
        // amount = await dispatch(getStripePayment({ paymentId })).unwrap();
        // return amount?.amount_total/100;
        return amount;
      default:
        throw `Invalid provider "${provider}""`;
    }
  }

  const paymentCheck = async () => {
    let pathSuccess, pathCancel;
    if (pathSuccess = history.location.pathname.match('/payment/success/*/*')) {
      const paymentInfo = getPaymentInfo(pathSuccess);
      // console.log(paymentInfo);
      // @todo: retrieve payment info?
      const amount = await getPaymentAmount(paymentInfo?.provider, paymentInfo?.paymentId);

      setTopUpAmount(amount);
      setShowModal(true);
      setStripeState('success');
    } else if (pathCancel = history.location.pathname.match('/payment/cancel/*/*')) {
      const paymentInfo = getPaymentInfo(pathCancel);
      // console.log(paymentInfo);
      // @todo: retrieve payment info?

      setShowModal(true);
      setStripeState('cancel');
    }
  }


  const fetchAssets = useCallback(
    async (params = { skip: 0, limit: itemsPerScroll }) => {
      try {
        await dispatch(retrieveAssets(params))
          .unwrap()
          .then((response) => {
            const responseData = response.body.data;
            setAssets((assets) => [...assets, ...responseData]);

            const reponseDataLength = responseData.length;
            setHasMore(reponseDataLength === itemsPerScroll);
          });
      } catch (rejectedValueOrSerializedError) {
        console.error(rejectedValueOrSerializedError);
      }
    },
    [dispatch]
  );

  const loadMore = useCallback(() => {
    fetchAssets({ skip: assets.length, limit: itemsPerScroll });
  }, [assets, fetchAssets]);

  useEffect(() => {
    const init = async () => {
      await fetchAssets();
      setInitialized(true);
    };

    init();
    paymentCheck();
  }, [fetchAssets]);

  return (
    <React.Fragment>
      <HomeHeader />
      <Container>
        <section className="row row--grid">
          <TopUpStateModal show={showModal} hide={hideModal} state={stripeState} amount={topUpAmount} />
          <div className="col-12">
            <div className="pl-2 pr-2" style={{ margin: "auto" }}>
              <p
                style={{ fontSize: "16px" }}
                className="home__text text-center mt-4 d-block d-sm-block d-md-none d-lg-none d-xl-none"
              >
                {t("home-page.intro-subtitle")}
              </p>
            </div>
            <div className="home__btns2 d-block d-sm-block d-md-none d-lg-none d-xl-none">
              <Link to="/explore" onClick={() => gaEventTracker('explore')} className="home__btn2">
                {t("actions.explore")}
              </Link>

              <Link to="/new-item" className="home__btn2">
                {t("actions.create")}
              </Link>
            </div>
            <div className="main__title main__title--center">
              <h2>{t("home-page.title")}</h2>
            </div>
          </div>

          {initialized && (
            <Col className="col-12">
              <InfiniteScroll
                dataLength={assets.length}
                hasMore={hasMore}
                style={{ overflowX: "hidden" }}
                next={loadMore}
              >
                <Row className="no-gutters">
                  {assets.map((d, index) => (
                    <div
                      key={index}
                      className="col-10 col-sm-6 col-lg-4 col-xl-3 item__container"
                    >
                      <Item itemId={d._id} item={d} />
                    </div>

                  ))}
                </Row>
              </InfiniteScroll>
            </Col>
          )}
        </section>
      </Container>
    </React.Fragment>
  );
};

export default Home;
