import React from "react";
import AssetAuthors from "./AssetAuthors";
import AssetDescription from "./AssetDescription";
import AssetTabs from "./AssetTabs";
import TransactionActionButtons from "./TransactionActionButtons";
import { useAssetSelector } from "./provider";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAssetOwnerId } from "../../helpers/asset";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import AssetExtraInfo from "./AssetExtraInfo";
import AssetDeleteButton from "./AssetDeleteButton";
import AssetPrice from "./AssetPrice";

const AssetInfo = ({ asset }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { descriptionEditing } = useAssetSelector();
  const { userId, authEthereumAddress } = useSelector(state => state.auth);
  // const [disableList, setDisableList] = useState(false);
  const ownerId = getAssetOwnerId(asset);
  const history = useHistory();
  // const isAssetStandardERC1155 = asset?.standard == "erc1155" ? true : false;

  const handleSellBtn = (event) => {
    event.preventDefault();

    history.push(`/place-on-market/${asset?._id}`, { isNotifyPersist: true });
  };

  return (
    <div className="asset__info">
      <AssetDescription asset={asset} />

      {!descriptionEditing && <AssetAuthors asset={asset} />}

      <React.Fragment>
        {((ownerId === userId) && (asset.saleType === 'NotOnSale')) &&
          <div className="p-2">
            <Row>
              <Col>
                <Button className="home__btn home__btn--clr"
                  variant="light"
                  size="lg"
                  onClick={handleSellBtn}
                >
                  {t('actions.sell')}
                </Button>
              </Col>
              <Col>
                {(ownerId === userId && !asset?.tokenId) &&
                  <AssetDeleteButton asset={asset} />
                }
              </Col>
            </Row>
          </div>
        }
      </React.Fragment>

      <AssetExtraInfo asset={asset} />

      <AssetTabs asset={asset} />

      {(ownerId === userId && asset?.saleType === "PutOnSale") && <AssetPrice asset={asset} />}

      <TransactionActionButtons />
    </div>
  );
};

export default AssetInfo;
