import swaggerProps from "../plugins/swagger-client";

export default class UserService {
  retrieveUsers(params = {}) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.User.retrieveUsers(
          params,
          swaggerProps.buildRequest(),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }


  recoverPassword(data = {}) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Auth.recoverPassword(
          {},
          swaggerProps.buildRequest(data),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }


  retrieveUser(userId) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.User.retrieveUser(
          { userId },
          swaggerProps.buildRequest(),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  createUser(payload) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.User.createUser(
          {},
          swaggerProps.buildRequest(payload),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error(error);
        return Promise.reject(error.response);
      });
  }

  updateUser(userId, info) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.User.updateUser(
          { userId: userId },
          swaggerProps.buildRequest(info),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error(error);
        return Promise.reject(error);
      });
  }

  deleteUser(userId) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.User.deleteUser(
          { userId },
          swaggerProps.buildRequest(),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  loginUser(payload) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Auth.login(
          {},
          swaggerProps.buildRequest({
            ...payload,
            username: "test",
            pin: "pin_test",
          }),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  }

  confirmUser(confirmationService, data) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Auth.confirmUser(
          { confirmationService },
          swaggerProps.buildRequest(data),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  }

  deleteUserIdentity(userId, identityId) {
    return swaggerProps.swagger.then((client) => {
      return client.apis.User.deleteUserIdentity(
        { userId, identityId },
        swaggerProps.buildRequest(),
        {}
      );
    }).then((response) => {
      return response;
    }).catch((error) => {
      return Promise.reject(error.response);
    });
  }
}
