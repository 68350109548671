import React, { useState, useEffect, useCallback } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Item from "../../components/item/Item";
// import Pagination from "../../components/pagination/Pagination";
import ItemsCollectionFiilters from "./ItemsCollectionFiilters";
import { retrieveCollection } from "../../store/CollectionSlice";
import { retrieveAssets } from "../../store/AssetSlice";
import useQueryParams from "../Explore/useQueryParams";
import { useNotify } from "../../providers/notify";
import InfiniteScroll from "react-infinite-scroll-component";
import { initialFilters } from "../Explore/constants";
import { constructFilterParams } from "../Explore/helpers";
import UserBanner from "../../components/user/Banner";
import { log } from "../../helpers/logger";

const itemsPerScroll = 3;

const ItemsCollection = () => {
  const dispatch = useDispatch();
  const { collectionId } = useParams();
  const intialPagination = { skip: 0, limit: itemsPerScroll, collectionId: collectionId };
  const [filters, setFilters] = useState(initialFilters);
  // const [page, setPage] = useState(1);
  const [collectionInfo, setCollectionInfo] = useState({});
  const [collectionOwner, setCollectionOwner] = useState({});
  const [assets, setAssets] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const { showNotification } = useNotify();
  const { queryParams, updateQueryParams } = useQueryParams();


  log('collectionId', collectionId);

  const handleChangeFilter = useCallback((field) => {
    setFilters((filters) => ({ ...filters, ...field }));
  }, []);

  const handleApplyFilter = useCallback(() => {
    updateQueryParams(filters);
  }, [filters, updateQueryParams]);

  const handleClearFilter = () => {
    setFilters(initialFilters);
    // setAppliedFilters(initialFilters);
  };

  const fetchCollection = useCallback(() => {
    dispatch(retrieveCollection(collectionId))
      .unwrap()
      .then((response) => {
        const responseData = response.body.data;
        // banner is on body.data.banner.sizes[1200].url
        setCollectionInfo(responseData);
        setCollectionOwner(responseData.ownerId || {});
      })
      .catch((rejectedValueOrSerializedError) => {
        console.error(rejectedValueOrSerializedError);
      });
  }, [collectionId, dispatch]);

  const fetchAssets = useCallback(
    async (pagination = intialPagination, filters = initialFilters) => {
      try {
        const response = await dispatch(
          retrieveAssets({ ...pagination, ...constructFilterParams(filters) })
        ).unwrap();
        const responseData = response.body.data;

        setAssets((assets) =>
          pagination.skip === 0 ? responseData : [...assets, ...responseData]
        );
        setHasMore(responseData.length === itemsPerScroll);
      } catch (rejected) {
        showNotification({ type: "error", message: "error-msg.default" });
      }
    },
    [showNotification, dispatch]
  );


  const loadMore = useCallback(() => {
    fetchAssets({ skip: assets.length, limit: itemsPerScroll, collectionId }, queryParams);
  }, [assets, queryParams, fetchAssets]);

  useEffect(() => {
    fetchCollection();
    setFilters(queryParams);
    fetchAssets(undefined, queryParams);
    window.scrollTo(0, 0);
  }, [queryParams, fetchAssets]);

  return (
    <React.Fragment>
      <UserBanner userProfile={collectionInfo} />
      <Container>
        <Row className="row--grid">
          <div className="col-12">
            <div className="main__title main__title--page">
              <h1>{collectionInfo.title}</h1>
            </div>
          </div>
        </Row>

        <Row className="">
          <Col className="col-12 col-xl-3 order-xl-1 pt-4">
            <div className="filter-wrap">
              <ItemsCollectionFiilters
                filters={filters}
                owner={collectionOwner}
                description={collectionInfo.description}
                onChange={handleChangeFilter}
                onApply={handleApplyFilter}
                onClear={handleClearFilter}
              />
            </div>
          </Col>

          <Col className="col-12 col-xl-9 order-xl-2">
            <InfiniteScroll
              dataLength={assets.length}
              hasMore={hasMore}
              style={{ overflowX: "hidden" }}
              next={loadMore}
            >
              <Row>
                {assets.map((item, index) => (
                  <Col key={index} sm="6" lg="4">
                    <Item itemId={item._id} item={item} />
                  </Col>
                ))}
              </Row>
            </InfiniteScroll>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default ItemsCollection;
