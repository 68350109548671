import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { getUserAvatar, avatarDefaultImage } from "../../helpers/user";
import Avatar from "./../../assets/img/Esex_simbolo_colore.svg";

const UserAvatar = ({
  profile,
  avatarClass = "",
  alt = "avatar",
  verified = false,
  children,
}) => {
  const [avatar, setAvatar] = useState("");

  useEffect(() => {
    setAvatar(getUserAvatar(profile));
  }, [profile]);

  return (
    <section
      className={`${avatarClass}${verified ? ` ${avatarClass}--verified` : ""}`}
    >
      <Image
        src={profile == null ? Avatar : avatar}
        alt={alt}
        style={profile == null ? { background: "white", padding: "3px" } : { width: "100%", height: "100%", objectFit: "cover" }}
      />

      {children}
    </section>
  );
};

export default UserAvatar;
