import React, { useContext, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Web3 from "web3";
import { ActionsContext } from "./context";
import { confirmUser } from "../../store/UserAuthSlice";
import { retrieveAuthUser } from "../../store/AuthSlice";
import {
  getSignature,
  getAccountBalance,
  getEthereumAccounts,
  SET_INITIALIZED,
  SET_ACCOUNT_ADDRESS,
  SET_WALLET_INSTALLED,
  SET_WALLET_CONNECTED,
  SET_CHAIN,
  SET_SYMBOL,
  getChain,
  grantUserRole,
  initializeContract,
  hasRole,
} from "../../store/EthereumSlice";
import { useNotify } from "../notify";
import { ethereumAccountStatus, generateId } from "../../helpers/auth";
import useHandlers from "./useHandlers";
import { getCurrencySymbol } from "./helpers";
import { getContractBasedOnEnv } from "../../services/serviceHelpers";
import { ethereum } from "../../constants/AppSettings";
import { log } from "../../helpers/logger";
import { useLoader } from "../loader";

const Ethereum = ({ children }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { setLoading, setText } = useLoader();
  const { handleConnect, handleAccountsOnChange, handleChainChange, handleDisconnect } = useHandlers();
  const { isAuthorized, authEthereumAddress, userId, authInfo } = useSelector((state) => state.auth);
  const { walletInstalled } = useSelector((state) => state.ethereum);
  const { showNotification } = useNotify();

  const initialize = useCallback(async (address = authEthereumAddress) => {
    try {
      // console.log("Account is: ", address);
      if (address && (address !== "")) {
        await dispatch(
          getAccountBalance({ accountAddress: address })
        ).unwrap();
        const metamaskAccount = await dispatch(getEthereumAccounts({})).unwrap();

        const chainId = await dispatch(getChain({})).unwrap();

        const chainID = '0x' + chainId.toString(16);
        dispatch(SET_CHAIN(chainID));
        dispatch(SET_SYMBOL(getCurrencySymbol(chainID)));
        // console.log('Metamask account: ', metamaskAccount);
        dispatch(SET_ACCOUNT_ADDRESS(metamaskAccount[0]));
        dispatch(SET_WALLET_CONNECTED(true));
        dispatch(SET_INITIALIZED(true));
      }

    } catch (error) {
      console.error(error);
    }

  }, [authEthereumAddress, dispatch]);

  const switchWalletAccount = useCallback(
    async (accountAddress) => {
      try {
        const status = ethereumAccountStatus(accountAddress, authInfo.identities);

        switch (status) {
          case 0:
            // we don't have an identity
            log('We dont have an ethereum identity');
            log('Account: ', accountAddress)
            const uniqueIdMessage = generateId(8);
            log('uniqueID: ', uniqueIdMessage);

            const signature = await dispatch(
              getSignature({
                message: t("user-details-page.login-metamask-message", {
                  uniqueIdMessage,
                }),
                accountAddress,
              })
            ).unwrap();

            await dispatch(
              confirmUser({
                confirmationService: "ethereum",
                data: {
                  id: uniqueIdMessage,
                  address: accountAddress,
                  signature: signature,
                },
              })
            ).unwrap();

            // this is called to update the authInfo state in the auth store.
            await dispatch(retrieveAuthUser({ userId })).unwrap();

            const erc1155 = getContractBasedOnEnv("MultiToken");
            let ERC1155Networks = Object.keys(erc1155.networks);
            // console.log('ERC1155Networks', ERC1155Networks);

            const erc721 = getContractBasedOnEnv("DigitalAsset");
            let ERC721Networks = Object.keys(erc721.networks);

            if (ethereum.environment == "prod" || ethereum.environment == "pre") {
              ERC721Networks = ["4500", "137"];
              ERC1155Networks = ["4500", "137"];
            }
            // console.log('ERC721Networks', ERC721Networks);
            let erc721curNetworkNum = 1;
            for (let networkId of ERC721Networks) {
              setText(`Step ${erc721curNetworkNum} of ${ERC721Networks.length + ERC1155Networks.length}`);
              await dispatch(initializeContract({ platform: true, contractName: "DigitalAsset", chainId: +networkId })).unwrap();
              let role = await dispatch(hasRole({})).unwrap();
              // console.log(`user for ERC721 in network ${networkId} has role user BEFORE`, role);
              if (!role) {
                await dispatch(grantUserRole({})).unwrap();
              }
              erc721curNetworkNum += 1;
            }

            let erc1155curNetworkNum = erc721curNetworkNum - 1;

            for (let networkId of ERC1155Networks) {
              setText(`Step ${erc1155curNetworkNum} of ${ERC1155Networks.length + ERC721Networks.length}`);
              await dispatch(initializeContract({ platform: true, contractName: "MultiToken", chainId: +networkId })).unwrap();
              let role = await dispatch(hasRole({})).unwrap();
              // console.log(`user for ERC1155 in network ${networkId} has role user BEFORE`, role);
              if (!role) {
                await dispatch(grantUserRole({})).unwrap();
              }
              erc1155curNetworkNum += 1;
            }

            break;

          case 1:
            log('We dont have ethereum acc match');
            showNotification({
              type: "info",
              message: "Please change your Metamask account to a registered account first",
            });

            break;

          case 2:
            log('We have ethereum acc match');
            break;
          default:
            log("Unexpected case");
            break;
        }

        await dispatch(getAccountBalance({ accountAddress })).unwrap();
        dispatch(SET_ACCOUNT_ADDRESS(accountAddress));
        dispatch(SET_WALLET_CONNECTED(true));
        initialize(accountAddress);
      } catch (error) {
        console.error(error);
        dispatch(SET_WALLET_CONNECTED(false));
        showNotification({
          type: "error",
          message: "Wallet linking failed",
        });
        throw error;
      }
    },
    [authInfo.identities, userId, initialize, showNotification, dispatch, t]
  );

  const initEthereumListeners = useCallback(() => {

    try {
      window.ethereum.on("connect", handleConnect);
      window.ethereum.on("disconnect", handleDisconnect);
      window.ethereum.on("accountsChanged", handleAccountsOnChange);
      window.ethereum.on("chainChanged", handleChainChange);
    } catch (e) {
      console.error('Could not initialize Metamask listeners: ', e);
    }

  }, [handleConnect, handleDisconnect, handleAccountsOnChange, handleChainChange]);

  const removeEthereumListeners = useCallback(() => {
    // do we need to keep somehwere if listeners initialized?
    try {
      window.ethereum.removeListener('connect', handleConnect);
      window.ethereum.removeListener('disconnect', handleDisconnect);
      window.ethereum.removeListener('accountsChanged', handleAccountsOnChange);
      window.ethereum.removeListener('chainChanged', handleChainChange);
    } catch (e) {
      log('Something went wrong when trying to remove Metamask listeners: ', e);
    }
  }, [handleConnect, handleDisconnect, handleAccountsOnChange, handleChainChange]);

  useEffect(() => {
    if (walletInstalled && isAuthorized) {
      initialize();
    }
  }, [walletInstalled, isAuthorized, initialize]);

  useEffect(() => {
    if (isAuthorized) {
      if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
        initEthereumListeners();
        dispatch(SET_WALLET_INSTALLED(true));
      } else if (window.web3) {
        window.web3 = new Web3(window.web3.currentProvider);
      } else {
        // maybe add another notification here?
        // window.alert(
        //   "Non-Ethereum browser detected. You should consider trying MetaMask!"
        // );
      }
    }

    return () => { if (window.ethereum && isAuthorized) removeEthereumListeners() }
  }, [isAuthorized, initEthereumListeners, removeEthereumListeners, dispatch]);

  return (
    <ActionsContext.Provider
      value={{
        initialize,
        switchWalletAccount,
        removeEthereumListeners
      }}
    >
      {children}
    </ActionsContext.Provider>
  );
};

export const useEthereumActions = () => useContext(ActionsContext);

export default Ethereum;
