export const publicTabsEnum = {
  WALLET: "wallet",
  CREATED: "created",
  ON_SALE: "on-sale",
  COLLECTIONS: "collections",
};

export const authTabsEnum = {
  CERTIFICATIONS: "certifications",
  MY_ACTIVITY: "my-activity",
  SETTINGS: "settings",
  CHANGE_PASSWORD: "change-password",
};

export const allTabsEnum = { ...publicTabsEnum, ...authTabsEnum };

export const publicTabsList = Object.values(publicTabsEnum);

export const authTabsList = Object.values(authTabsEnum);

export const allTabsList = Object.values(allTabsEnum);
