import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Item from "../../components/item/Item";
import useQueryParams from "../../hooks/useQueryParams";
import { retrieveAssets } from "../../store/AssetSlice";

const GlobalSearch = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const queryParam = useQueryParams();
  const [subArray, setSubArray] = useState([]);
  const [filteredNft, setFilteredNft] = useState([]);
  const [itemsPerScroll, setItemsPerScroll] = useState(6);
  const query = queryParam.get("query");

  const fetchMoreData = () => {
    const tmp = itemsPerScroll;

    setItemsPerScroll(itemsPerScroll + tmp);
    setSubArray(filteredNft.slice(0, tmp));
  };

  useEffect(() => {
    const fetchAssets = async () => {
      try {
        const originalPromiseResult = await dispatch(
          retrieveAssets({ saleType: "PutOnSale" })
        ).unwrap();
        const responseDataJSON = JSON.parse(originalPromiseResult.data);
        const filteredData = responseDataJSON.data.filter(
          (nft) =>
            nft.owner.toLowerCase().includes(query.toLowerCase()) ||
            nft.title.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredNft(filteredData);
      } catch (rejectedValueOrSerializedError) {
        console.error(rejectedValueOrSerializedError);
      }
    };

    fetchAssets();
  }, [query, dispatch]);

  return (
    <Container>
      <Row className="row--grid">
        <div className="col-12">
          <div className="main__title main__title--page">
            <h1>{t("search-page.searched-for", { query })}</h1>
          </div>
        </div>
      </Row>

      <Col className="col-12 col-xl-9 order-xl-1">
        {subArray.length ? (
          <InfiniteScroll
            dataLength={itemsPerScroll}
            next={fetchMoreData}
            hasMore={true}
            style={{ overflowX: "hidden" }}
          >
            <Row>
              {subArray.map((item, index) => (
                <Col key={index} sm="6" lg="4">
                  <Item itemId={item._id} item={item} />
                </Col>
              ))}
            </Row>
          </InfiniteScroll>
        ) : (
          <Row>
            {filteredNft.slice(0, itemsPerScroll).map((item, index) => (
              <Col key={index} sm="6" lg="4">
                <Item itemId={item._id} item={item} />
              </Col>
            ))}
          </Row>
        )}
      </Col>
    </Container>
  );
};

export default GlobalSearch;
