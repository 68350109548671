import React from "react";
import HistoryItem from "./Item";

const HistoryList = ({ list, containerHeight, asset }) => {
  return (
    <div
      className="asset__actions asset__actions--scroll"
      style={{ height: containerHeight }}
    >
      {list &&
        list.map((historyItem, index) => (
          <HistoryItem key={index} asset={asset} historyItem={historyItem} />
        ))}
    </div>
  );
};

export default HistoryList;
