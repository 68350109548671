import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import bannerDefaultImage from "../../assets/img/bg/default-banner.svg";
import avatarDefaultImage from "../../assets/img/avatars/avatar5.jpg";

const UserItem = ({ user }) => {
  const userDetailsPath = useMemo(() => `/user-details/${user._id}`, [user]);
  const [banner, setBanner] = useState("");
  const [avatar, setAvatar] = useState("");
  const [fullName, setFullName] = useState("");
  const [profile, setProfile] = useState({});
  useEffect(() => {
    const profile = user?.profiles[0]?.profileId;
    setProfile(profile);
    setBanner(
      (profile && profile?.banner?.sizes["1200"]?.url) || bannerDefaultImage
    );
    setAvatar(
      (profile && profile?.avatar?.sizes["150"]?.url) || avatarDefaultImage
    );
    setFullName(
      profile ? `${profile.firstname || ""} ${profile.lastname || ""}` : "-"
    );
  }, [user]);

  return (
    <div className="align-self-center author__user">
      <Link to={userDetailsPath} className="author__cover author__cover--bg">
        <img src={banner} alt="not_available" />
      </Link>

      <div className="author__user__meta">
        <Link
          to={userDetailsPath}
          className={`author__avatar${
            user.verified ? " author__avatar--verified" : ""
          }`}
        >
          <img
            src={avatar}
            style={{ width: "60px", height: "60px", objectFit: "cover" }}
            alt="not_available"
          />
        </Link>

        <h3 className="author__name">{fullName}</h3>

        <h3 className="author__nickname">
          <Link to={userDetailsPath}>{<span>@{profile.nickname}</span>}</Link>
        </h3>

        {/* below line was commented since the design does not include a description */}
        {/* <p className="author__text">{user.description}</p> */}
      </div>
    </div>
  );
};

export default UserItem;
