import notificationImage from "../../assets/notification.png";
import {useState,useEffect,useCallback} from "react";
import {useDispatch} from "react-redux";
import React from "react";
import { retrieveNotifications } from "../../store/NotificationSlice";
import { retrieveUser } from "../../store/UserSlice";
import { log } from "../../helpers/logger";
const NotificationsPage = () => {
  const dispatch = useDispatch();
  const [username, setUsername] = useState();
  const [notificationList,setNotificationList] = useState([]);

  const styles = {
    numberNotifications: {
      borderRadius: "50%",
      border: "1px solid white",
      backgroundColor: "#6069F6",
      color: "white",
      position: "absolute",
      width: "14px",
      height: "14px",
      fontSize: "8px",
      bottom: "12px",
      left: "10px",
    },
    dropdown: {
      backgroundColor: "#1B5579",
      maxWidth: "550px",
      height: "auto",
      border: "1px solid rgba(189, 189, 189, 0.2)",
      boxSizing: "border-box",
      borderRadius: "0px",
      margin:'auto',
    },
    dot: {
      borderRadius: "50%",
      backgroundColor: "#6069F6",
      width: "14px",
      height: "14px",
    },
  };

  const retrieveNotif = useCallback(() => {
    dispatch(retrieveNotifications())
      .unwrap()
      .then((originalPromiseResult) => {
        log(originalPromiseResult.body.data);
        setNotificationList(originalPromiseResult.body.data);
      })
      .catch((rejectedValueOrSerializedError) => {
        console.error(rejectedValueOrSerializedError);
      });
  }, [dispatch]);


  useEffect(() => {
    retrieveNotif();
  }, [retrieveNotif]);

  const fetchUserName = (userId) => {
    dispatch(retrieveUser(userId))
      .unwrap()
      .then((originalPromiseResult) => {
        log(originalPromiseResult.body.data);
        setUsername(originalPromiseResult.body.data.username);
      })
      .catch((rejectedValueOrSerializedError) => {
        console.error(rejectedValueOrSerializedError);
      });
  };


  return (
    <React.Fragment >
      <div style={{margin:'100px'}}>
      <div
        className="p-4"
        style={styles.dropdown}
      >
        <div className="d-flex justify-content-center text-white">
          <h4>Notifications</h4>
        </div>
        {notificationList.map((notification) => {
           fetchUserName(notification.ownerId._id);
          return (
            <div className="p-0 mb-1 mt-1">
              <div className="d-flex text-white mt-3">
                <div className="align-self-center mr-2">
                  <img src={notificationImage} alt="notification" />
                </div>
                <div className="align-self-center w-100">
                  <div className="mb-1 mt-1">{notification.title}</div>
                  <div className="d-flex justify-content-between">
                    <div className="mb-1" style={{ fontSize: "13px" }}>
                      @{username}{" "}
                      <span style={{ color: "#bdbdbd" }}>
                        {notification.body}
                      </span>
                    </div>
                    {!notification.read && <div style={styles.dot}></div>}
                  </div>

                  <div style={{ fontSize: "13px", color: "#6164ff" }}>
                    {notification.time}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      </div>
      </React.Fragment>
  );
};

export default NotificationsPage;
