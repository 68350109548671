import React, { useEffect, useState } from "react";
import { Collapse, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DropdownBasic from "../../components/dropdown/DropdownBasic";
import UserAvatar from "../../components/user/Avatar";
import { sortByOptions } from "./constants";
import { Link } from "react-router-dom";
import { log } from "../../helpers/logger";

const ItemsCollectionFilters = ({
  filters,
  owner,
  description,
  onChange,
  onApply,
  onClear,
}) => {
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(false);
  const [ownerProfile, setOwnerProfile] = useState({});

  useEffect(() => {
    log("ownerProfile", owner);
    setOwnerProfile(owner);
  }, [owner]);

  const handleSearch = (e) => {
    onChange({ search: e.target.value });
  };

  const handleSortBy = (e) => {
    onChange({ sortBy: e.target.value });
  };

  return (
    <>
      <Button
        className="filter-wrap__btn"
        variant="empty"
        data-toggle="collapse"
        data-target="#collapseFilter"
        aria-controls="collapseFilter"
        aria-expanded="false"
        onClick={() => setCollapsed(!collapsed)}
      >
        {t(collapsed ? "closeFilters" : "openFilters")}
      </Button>

      <Collapse in={collapsed}>
        <div
          className="filter-wrap__content simple__border"
          id="collapseFilter"
        >
          <div className="filter filter--sticky">
            <div className=" ">
              <span className="text-white">
                {" "}
                <h6>{t("creator")}</h6>
              </span>
              <UserAvatar
                profile={ownerProfile}
                avatarClass="card__author"
                verified
              >
                {owner && (
                  <Link to={`/user-details/${owner._id}`}>
                    {owner.nickname}
                  </Link>
                )}
              </UserAvatar>
            </div>
            <div className="card__info"></div>
            <div className="text-white">{description}</div>
            <div className="card__info"></div>
            <h4 className="filter__title">
              {t("filters")}{" "}
              <button type="button" onClick={onClear}>
                {t("clear-all")}
              </button>
            </h4>

            <div className="filter__group">
              <label className="filter__label">
                {t("explore-page.keyword")}
              </label>
              <input
                value={filters.search}
                type="text"
                className="filter__input__sm"
                placeholder="Keyword"
                onChange={handleSearch}
              />
            </div>

            <div className="filter__group">
              <label className="filter__label">
                {t("explore-page.sort-by")}
              </label>

              <div className="filter__select-wrap">
                <DropdownBasic
                  value={filters.sortBy}
                  items={sortByOptions}
                  title="testing"
                  type="filter"
                  className="filter__select__sm"
                  onChange={handleSortBy}
                />
              </div>
            </div>

            {/* <div className="filter__group">
              <label className="filter__label">
                {t("explore-page.category")}
              </label>
              <CheckboxGroup
                values={filters.categories}
                options={categoryOptions}
                groupAs="ul"
                groupClass="filter__checkboxes"
                itemIdPrefix="filter"
                onChange={handleCategories}
              />
            </div> */}

            <div className="filter__group">
              <Button className="filter__btn" type="button" variant="light" onClick={onApply}>
                {t("apply-filters")}
              </Button>
            </div>
          </div>
        </div>
      </Collapse>
    </>
  );
};

export default ItemsCollectionFilters;
