import React from "react";
import { Row, Button } from "react-bootstrap";
import BaseModal from "../../components/modal/Base";
import successIcon from "../../assets/img/icons/success-icon.svg";
import failureIcon from "../../assets/img/icons/failure-icon.svg";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

const SuccessState = ({ show, hide, amount }) => {
    const { t } = useTranslation();
    const history = useHistory();

    const goHome = () => {
        history.push("/home");
    }

    return (
        <React.Fragment>
            <BaseModal
                show={show}
                onHide={hide}
                noBorder={true}
                title={t('top-up.modal-success', { amount })}
                centered
                size="md"
                bodyClassName="pl-0 pr-0">
                <Row className="no-gutters d-flex justify-content-center">
                    <img src={successIcon}></img>
                </Row>
                <Row className="no-gutters d-flex justify-content-center">
                    <Button onClick={goHome} className="sign__btn mt-4 w-50" variant="light">
                        {t('top-up.modal-back')}
                    </Button>
                </Row>
            </BaseModal>
        </React.Fragment>
    );
}

const FailState = ({ show, hide }) => {
    const { t } = useTranslation();
    const history = useHistory();

    const goHome = () => {
        history.push("/home");
    }

    return (
        <React.Fragment>
            <BaseModal
                show={show}
                onHide={hide}
                noBorder={true}
                title={t('top-up.modal-failure')}
                centered
                size="md"
                bodyClassName="pl-0 pr-0">
                <Row className="no-gutters d-flex justify-content-center">
                    <img src={failureIcon}></img>
                </Row>
                <Row className="no-gutters d-flex justify-content-center">
                    <Button onClick={goHome} className="sign__btn mt-4 w-50" variant="light" >
                        {t('top-up.modal-back')}
                    </Button>
                </Row>
            </BaseModal>
        </React.Fragment>
    );
}

const TopUpStateModal = ({ show, hide, state, amount = 0 }) => {

    return (
        <React.Fragment>
            {state === 'success' ? <SuccessState show={show} hide={hide} amount={amount} /> : <FailState show={show} hide={hide} />}
        </React.Fragment>
    );

}

export default TopUpStateModal;