import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { log } from "../helpers/logger";
import services from "../services";

export const retrieveUsers = createAsyncThunk(
  "user/retrieveUsers",
  async (params, { rejectWithValue }) => {
    try {
      const response = await services.UserService.retrieveUsers(params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const retrieveUser = createAsyncThunk(
  "user/retrieveUser",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await services.UserService.retrieveUser(userId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createUser = createAsyncThunk(
  "user/createUser",
  async (data, { rejectWithValue }) => {
    try {
      const response = await services.UserService.createUser(data);

      return response;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

export const updateUser = createAsyncThunk(
  "user/updateUser",
  async ({ userId, info }, { rejectWithValue }) => {
    log(userId);
    try {
      const response = await services.UserService.updateUser(userId, info);
      return response;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

export const deleteUser = createAsyncThunk(
  "user/deleteUser",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await services.UserService.deleteUser(userId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const retrieveUserProfile = createAsyncThunk(
  "auth/retrieveUserProfile",
  async ({ profileId }, { rejectWithValue }) => {
    try {
      const response = await services.ProfileService.retrieveProfile(profileId);
      return response.body.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteUserIdentity = createAsyncThunk(
  "user/deleteUserIdentity",
  async ({ userId, identityId }, { rejectWithValue }) => {
    try {
      log("Userid: ", userId, " identity: ", identityId);
      const response = await services.UserService.deleteUserIdentity(
        userId,
        identityId
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const confirmUser = createAsyncThunk(
  "user/confirmUser",
  async ({ confirmationService, data }, { rejectWithValue }) => {
    try {
      const response = await services.UserService.confirmUser(
        confirmationService,
        data
      );

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

let initialState = {
  error: {},
  state: "",
  userId: "",
  currentUser: null,
  wallet: 0,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    SET_WALLET(state, action) {
      state.wallet = action.payload;
    },
  },
  extraReducers: {
    /* ---- RETRIEVE_USERS ---- */
    [String(retrieveUsers.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(retrieveUsers.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(retrieveUsers.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },

    /* ---- RETRIEVE_USER ---- */
    [String(retrieveUser.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(retrieveUser.fulfilled)]: (state, action) => {
      state.currentUser = action.payload.body.data;
      state.state = "success";
    },
    [String(retrieveUser.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },

    /* ---- CREATE_USER ---- */
    [String(createUser.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(createUser.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(createUser.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },

    /* ---- UPDATE_USER ---- */
    [String(updateUser.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(updateUser.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(updateUser.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },

    /* ---- DELETE_USER ---- */
    [String(deleteUser.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(deleteUser.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(deleteUser.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },

    /* ---- RETRIEVE_USER_PROFILE ---- */
    [String(retrieveUserProfile.pending)]: (state, action) => {},
    [String(retrieveUserProfile.fulfilled)]: (state, action) => {},
    [String(retrieveUserProfile.rejected)]: (state, action) => {},

    /* ---- DELETE_USER_IDENTITY ---- */
    [String(deleteUserIdentity.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(deleteUserIdentity.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(deleteUserIdentity.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },

    /* ---- CONFIRM_USER ---- */
    [String(confirmUser.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(confirmUser.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(confirmUser.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
  },
});

export const { SET_WALLET } = userSlice.actions;

export default userSlice.reducer;
