import DigitalAssetDev from "../abis_dev/DigitalAsset.json";
import DigitalAssetStg from "../abis_stg/DigitalAsset.json";
import DigitalAssetProd from "../abis_prod/DigitalAsset.json";
import DigitalAssetPre from "../abis_pre_prod/DigitalAsset.json";

import MultiAssetDev from "../abis_dev/MultiTokenDigitalAsset.json";
import MultiAssetStg from "../abis_stg/MultiTokenDigitalAsset.json";
import MultiAssetProd from "../abis_prod/MultiTokenDigitalAsset.json";
import MultiAssetPre from "../abis_pre_prod/MultiTokenDigitalAsset.json";

import { ethereum } from "../constants/AppSettings";
import { log } from "../helpers/logger";

export const getContractBasedOnEnv = (contractName = "DigitalAsset") => {
    switch (ethereum.environment) {
        case "dev":
            return (contractName == "DigitalAsset" ? DigitalAssetDev : MultiAssetDev);
        case "stg":
            return (contractName == "DigitalAsset" ? DigitalAssetStg : MultiAssetStg);
        case "prod":
            return (contractName == "DigitalAsset" ? DigitalAssetProd : MultiAssetProd);
        case "pre":
            return (contractName == "DigitalAsset" ? DigitalAssetPre : MultiAssetPre);
        default:
            throw "Invalid Environment while choosing contract";
    }
}

export const getContractAddressBasedOnEnv = (contractName = "DigitalAsset", chainId, base = 16) => {
    chainId = parseInt(chainId, base);
    log('chainId', chainId);
    switch (ethereum.environment) {
        case "dev":
            return (contractName == "DigitalAsset" ? DigitalAssetDev.networks[chainId].address : MultiAssetDev.networks[chainId].address);
        case "stg":
            return (contractName == "DigitalAsset" ? DigitalAssetStg.networks[chainId].address : MultiAssetStg.networks[chainId].address);
        case "prod":
            return (contractName == "DigitalAsset" ? DigitalAssetProd.networks[chainId].address : MultiAssetProd.networks[chainId].address);
        case "pre":
            return (contractName == "DigitalAsset" ? DigitalAssetPre.networks[chainId].address : MultiAssetPre.networks[chainId].address);
        default:
            throw "Invalid Environment while choosing contract";
    }
}