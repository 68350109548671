import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Checkbox } from "../form/FormikFields";
import FormikForm from "../form/FormikForm";
import BaseModal from "../modal/Base";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { upgradeUserLevel } from "../../store/UserAuthSlice";
import { log } from "../../helpers/logger";
import { useNotify } from "../../providers/notify";
import { retrieveAuthUser } from "../../store/AuthSlice";


const KycModal = ({ show, closeCallback }) => {
  const { userId } = useSelector((state) => state.auth)
  const dispatch = useDispatch();
  const { showNotification } = useNotify();

  const formModel = {
    termsBox: false,
    policyBox: false
  }

  const validationSchema = Yup.object().shape({
    termsBox: Yup.boolean().required("Must accept terms").oneOf([true], "Terms must be accepted."),
    policyBox: Yup.boolean().required("Must accept policy").oneOf([true], "Pivacy policy must be accepted.")
  });

  const handleKYCButton = async (values) => {
    // here call the API to initiate the KYC process
    try {
      const requestUpgrade = await dispatch(upgradeUserLevel({ level: "kyc" })).unwrap();

      log('upgrade result', requestUpgrade);
      await dispatch(retrieveAuthUser({ userId })).unwrap();
      showNotification({ type: "success", message: "Requested to upgrade" });
    } catch (e) {
      showNotification({ type: "error", message: e?.message || "Request failed" });
    } finally {
      // close the modal
      closeCallback();
    }


  }

  return (
    <BaseModal centered show={show}>
      {/* <Row className="no-gutters align-items-center"> */}
      <p className="text-white text-center p-3"> 
        You can not mint until you complete the "Know Your Customer" process. Please apply by filling the form below.
        If you would not like to enroll at this time, please select "Not on sale".
      </p>

      <p className="text-white text-center pl-3 pr-3">
        By enlisting to the "Know Your Costumer" program you agree to the following terms and conditions.
        After successful enrollment you will gain access in selling and buying NFTs
      </p>
      <FormikForm
        initialValues={formModel}
        validationSchema={validationSchema}
        formClasses="sign__form--create"
        enableReinitialize
        onSubmit={handleKYCButton}
      >
        <Checkbox className="sign__group sign__group--checkbox p-0 m-0" name="termsBox" label="Terms and Conditions"></Checkbox>
        <Checkbox className="sign__group sign__group--checkbox p-0 m-0" name="policyBox" label="Privacy Policy"></Checkbox>

        <Button className="sign__btn" variant="light" type="submit">Enlist for the KYC program</Button>
      </FormikForm>
      <Button className="sign__btn w-50" variant="light" onClick={closeCallback}>Cancel</Button>
      {/* </Row> */}
    </BaseModal>
  )
}

export default KycModal;