import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import services from "../services";

export const confirmUser = createAsyncThunk(
  "userauth/confirmUser",
  async ({confirmationService,data}, { rejectWithValue }) => {
    try {
      const response = await services.UserAuthService.confirmUser(confirmationService,data);
      return response;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

export const upgradeUserLevel = createAsyncThunk(
  "uaserauth/upgradeUserLevel",
  async (level, { rejectWithValue }) => {
    try {
      const response = await services.UserAuthService.upgradeUserLevel(level);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const downgradeUserLevel = createAsyncThunk(
  "uaserauth/upgradeUserLevel",
  async (level, { rejectWithValue }) => {
    try {
      const response = await services.UserAuthService.downgradeUserLevel(level);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);


let initialState = {
  state: "",
  value: "",
  error: {},
};

const userAuthSlice = createSlice({
  name: "userauth",
  initialState,
  reducers: {
  },
  extraReducers: {
    /* ---- CREATE_USER ---- */
    [String(confirmUser.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(confirmUser.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(confirmUser.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },

    /* ---- CONFIRM_USER ---- */
    [String(upgradeUserLevel.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(upgradeUserLevel.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(upgradeUserLevel.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },

    },
});

export default userAuthSlice.reducer;
