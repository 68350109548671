import React, { useCallback } from "react";
import { Button, Image } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useEthereumActions } from "../../providers/ethereum";
import { upgradeUserLevel } from "../../store/UserAuthSlice";
import { enableEthereum } from "../../store/EthereumSlice";
import { useLoader } from "../../providers/loader";
import metamask from "../../assets/img/metamask.png";

const WalletConnect = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { setLoading, setText } = useLoader();
  const { switchWalletAccount } = useEthereumActions();

  const handleConnectWallet = useCallback(async () => {
    setText('Linking in progress...');
    setLoading(true);
    try {
      await dispatch(upgradeUserLevel({ level: "ethereum" })).unwrap();
      const accounts = await dispatch(enableEthereum({})).unwrap();
      await switchWalletAccount(accounts[0]);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [switchWalletAccount, dispatch]);

  return (
    <React.Fragment>
      <Image src={metamask} style={{ width: "100px" }} />

      <div className="main__title main__title--center mt-3 mb-3">
        <h3
          className="text-center"
          style={{
            fontSize: "16px",
            maxWidth: "397px",
            lineHeight: "24px",
            color: "#BDBDBD",
            display: "block",
          }}
        >
          <Trans
            t={t}
            i18nKey="user-details-page.metamask-disconnected-message"
            components={{
              span: <span />,
            }}
          />
        </h3>
      </div>

      <div className="d-flex justify-content-center w-100">
        <div className="col-12 col-md-6">
          <Button
            className="sign__btn"
            variant="success"
            style={{ backgroundColor: "var(--success)", color: "white" }}
            size="lg"
            block
            onClick={handleConnectWallet}
          >
            {t("actions.connect")}
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default WalletConnect;
