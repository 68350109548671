import React from "react";
import { Redirect } from "react-router-dom";

import Login from "../pages/Login/Login";
import Register from "../pages/Register/Register";
import PasswordRecovery from "../pages/PasswordRecovery/PasswordRecovery";
import Home from "../pages/Home/Home";
import Explore from "../pages/Explore/Explore";
import ItemDetails from "../pages/ItemDetails/ItemDetails";
import Users from "../pages/Users/Users";
import UserDetails from "../pages/UserDetails/UserDetails";
import Contact from "../pages/Contact/Contact";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";
import PageError from "../pages/PageError/PageError";
import CreatePassword from "../pages/CreatePassword/CreatePassword";
import ItemsCollection from "../pages/ItemsCollection/ItemsCollection";
import AddCollection from "../pages/AddCollection/AddCollection";
import AllCollections from "../pages/AllCollections/AllCollections";
import LoginWallet from "../pages/Login/LoginWallet";
import Test from "../pages/Tests/frontend";
import NewCertification from "../pages/NewCertification";
import GlobalSearch from "../pages/GlobalSearch/GlobalSearch";
import NewItem from "../pages/NewItem/NewItem";
import CreateAndConfirmPassword from "../pages/CreateAndConfirmPassword/CreateAndConfirmPassword";
import CertificationDetails from "../pages/CertificationDetails";
import NotificationsPage from "../pages/NotificationsPage/NotificationsPage";
import AssetMarket from "../pages/ItemDetails/AssetMarket";

const publicRoutes = [
  { path: "/login", component: Login },
  { path: "/notifications", component: NotificationsPage },
  { path: "/login-wallet", component: LoginWallet },
  { path: "/confirmation/:confirmationToken", component: CreatePassword },
  { path: "/passwordreset/:confirmationToken", component: CreatePassword },
  { path: "/confirmPassword", component: CreateAndConfirmPassword },
  { path: "/register", component: Register },
  { path: "/password-recovery", component: PasswordRecovery },
  { path: "/home", component: Home },
  { path: "/explore", component: Explore },
  { path: "/items/:assetId", component: ItemDetails },
  { path: "/collections/:collectionId", component: ItemsCollection },
  { path: "/users", component: Users },
  { path: "/user-details/:id/:routeTab?", component: UserDetails },
  { path: "/contact", component: Contact },
  { path: "/privacy-policy", component: PrivacyPolicy },
  { path: "/pages-404", component: PageError },
  { path: "/test", component: Test },
  { path: "/search", component: GlobalSearch },
  { path: "/new-collection", component: AddCollection },
  { path: "/collections", component: AllCollections },
  { path: "/certifications/:certificationId", component: CertificationDetails },
  { path: "/new-item", component: NewItem },
  { path: "/new-certification", component: NewCertification },
  { path: "/payment/success/:provider/:paymentId?", component: Home },
  { path: "/payment/cancel/:provider/:paymentId?", component: Home },
  { path: "/place-on-market/:id/:listingId?", component: AssetMarket },
  {
    path: "/",
    component: () => <Redirect to="/home" />,
  },
];

const authRoutes = [];

const publicPaths = publicRoutes.map((route) => route.path);

const authPaths = authRoutes.map((route) => route.path);

export { publicRoutes, authRoutes, publicPaths, authPaths };
