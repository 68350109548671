import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CopyLinkInput from "../../components/input/CopyLink";
import UserAvatar from "../../components/user/Avatar";
import { log } from "../../helpers/logger";
import { getUserEthereumAccount } from "../../helpers/user";
import { userCardStyles } from "./styles";

const UserCard = ({ user, role }) => {
  const { t } = useTranslation();
  const [profile, setProfile] = useState(null);
  const [ethereumIdentity, setEthereumIdentity] = useState("");

  useEffect(() => {
    setProfile(user);
    log('user', user)
    setEthereumIdentity(getUserEthereumAccount(user?.userId?.identities).identity || "");
  }, [user]);

  return (
    <div style={userCardStyles.card} className="mt-3 p-3 ps-4">
      <div className="d-flex">
        <UserAvatar
          profile={profile}
          avatarClass="author__role__avatar"
          verified
        >
          <div className="ml-3 mt-1">
            <h6 style={{ fontWeight: 600 }}>{t(role)}</h6>
            <p style={{ color: "#bdbdbd" }}>@{user.nickname}</p>
          </div>
        </UserAvatar>
      </div>

      <CopyLinkInput
        linkText={ethereumIdentity}
        className="certification_author__code"
        inputClassName="p-2"
      />
    </div>
  );
};

export default UserCard;
