import React, { useState, useEffect, useCallback } from "react";
import { Container, Row, Col } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import CollectionsFilters from "./CollectionsFilters";
import CollectionItem from "../../components/collectionItem/CollectionItem";
import useError from "../../hooks/useError";
import { retrieveCollections } from "../../store/CollectionSlice";
import { useNotify } from "../../providers/notify";
import { initialFilters, itemsPerScroll, intialPagination } from "./constants";
import useQueryParams from "./useQueryParams";
import { constructFilterParams } from "./helpers";
import { log } from "../../helpers/logger";

const AllCollections = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {queryParams,updateQueryParams} = useQueryParams();
  const { handleError } = useError();
  const [filters, setFilters] = useState(initialFilters);
  const [hasMore,setHasMore] = useState(false);
  const [collections, setCollections] = useState([]);
  const { showNotification } = useNotify();
  
  const handleChangeFilter = useCallback((field) => {
    setFilters((filters) => ({ ...filters, ...field }));
  }, []);

  const handleApplyFilter = useCallback(() => {
    updateQueryParams(filters);
  }, [filters, updateQueryParams]);

  const handleClearFilter = useCallback(() => {
    /**
     * We have two options:
     * 1) reset filters in UI and wait for user to click on `apply filters` in order to filter the data (ref. `data` === `assets`)
     * 2) on `clear filters`, reset filters and filter the data (ref. `assets`)
     *
     * In both cases, we filter the data as defined in the `initialFilters`
     *
     * Currently, we use the second approach.
     */
    // setFilters(initialFilters);
    updateQueryParams(initialFilters);
  }, [updateQueryParams]);

  const fetchCollections = useCallback(
    async (pagination = intialPagination, filters = initialFilters) => {
      try {
        const response = await dispatch(
          retrieveCollections({ ...pagination, ...constructFilterParams(filters) })
        ).unwrap();
        const responseData = response.body.data;
        log('all collections: ', responseData);
        setCollections((collections) =>
          pagination.skip === 0 ? responseData : [...collections, ...responseData]
        );
        setHasMore(responseData.length === itemsPerScroll);
      } catch (rejected) {
        showNotification({ type: "error", message: "error-msg.default" });
      }
    },
    [showNotification, dispatch]
  );

  const loadMore = useCallback(() => {
    fetchCollections({ skip: collections.length, limit: itemsPerScroll }, queryParams);
  }, [collections, queryParams, fetchCollections]);

  useEffect(() => {
    setFilters(queryParams);
    fetchCollections(undefined, queryParams);
    window.scrollTo(0, 0);
  }, [queryParams, fetchCollections]);

  return (
    <Container>
      <Row className="row--grid">
        <div className="col-12">
          <div className="main__title main__title--page">
            <h1>{t("collections-page.title")}</h1>
          </div>
        </div>
      </Row>

      <Row>
        <Col className="col-12 col-xl-3 order-xl-2">
          <div className="filter-wrap">
            <CollectionsFilters
              filters={filters}
              onChange={handleChangeFilter}
              onApply={handleApplyFilter}
              onClear={handleClearFilter}
            />
          </div>
        </Col>

        <Col className="col-12 col-xl-9 order-xl-1">
          <InfiniteScroll
            dataLength={collections.length}
            hasMore={hasMore}
            style={{ overflowX: "hidden" }}
            next={loadMore}
          >
            <Row>
              {collections.map((item, index) => (
                <Col key={index} sm="6" lg="4">
                  <CollectionItem key={index} collection={item} />
                </Col>
              ))}
            </Row>
          </InfiniteScroll>
        </Col>
      </Row>
    </Container>
  );
};

export default AllCollections;
