import React from "react";
import UserAvatar from "../../components/user/Avatar";

const BidItem = ({ bid }) => {
  return (
    <UserAvatar profile={null} avatarClass="asset__action" verified>
      <p>
        Bid placed for <b>{bid.price} ETH</b>
      </p>
    </UserAvatar>
  );
};

export default BidItem;
