import React, { useMemo } from "react";
import {
  TabContainer,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import CollectionList from "./CollectionList";
import WalletList from "./WalletList";
import OnSaleList from "./OnSaleList";
import CreatedList from "./CreatedList";
import ProfileSettings from "./ProfileSettings";
// import ActivityList from "./ActivityList";
import ProfilePasswordForm from "./ProfilePasswordForm";
import CertificationList from "./CertificationList";
import { useTabSelector } from "./provider";
import { allTabsEnum } from "./constants"

const Profile = ({ currentUserId, currentUserProfile }) => {
  const { t } = useTranslation();
  const { userId } = useSelector((state) => state.auth);
  const { activeTab, handleTabChange } = useTabSelector();

  const tabList = useMemo(() => {
    const list = [
      { key: allTabsEnum.WALLET, label: "wallet", component: WalletList },
      { key: allTabsEnum.CREATED, label: "created", component: CreatedList },
      { key: allTabsEnum.ON_SALE, label: "on-sale", component: OnSaleList },
      { key: allTabsEnum.COLLECTIONS, label: "collections", component: CollectionList },
    ];

    {/* key: allTabsEnum.MY_ACTIVITY, label: "my-activity", component: ActivityList */ }

    if (currentUserId === userId)
      return [
        ...list,
        ...[
          {
            key: allTabsEnum.CERTIFICATIONS,
            label: "certifications",
            component: CertificationList,
          },
          { key: allTabsEnum.SETTINGS, label: "settings", component: ProfileSettings },
          {
            key: allTabsEnum.CHANGE_PASSWORD,
            label: "change-password",
            component: ProfilePasswordForm,
          },
        ],
      ];

    return list;
  }, [currentUserId, userId]);

  const renderComponent = (Component) => <Component currentUserProfile={currentUserProfile} activeTab={activeTab} />;

  return (
    <TabContainer
      activeKey={activeTab}
      mountOnEnter
      onSelect={handleTabChange}
    >
      <div className="profile col-xs-12 col-sm-12 col-md-11 col-lg-11 col-xl-11">
        <Nav as="ul" className="nav-tabs profile__tabs border-bottom-0">
          {tabList.map((tab, index) => (
            <NavItem as="li" key={index}>
              <NavLink className="p-0 mb-0" eventKey={tab.key}>
                {t(tab.label)}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
      </div>

      <TabContent>
        {tabList.map((tab, index) => (
          <TabPane key={index} eventKey={tab.key}>
            {renderComponent(tab.component)}
          </TabPane>
        ))}
      </TabContent>
    </TabContainer>
  );
};

export default Profile;
