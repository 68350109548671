import { useDispatch, useSelector } from "react-redux";
import { useNotify } from "../notify";
import { ethereumAccountStatus } from "../../helpers/auth";
import { useCallback } from "react";
import {
  getAccountBalance,
  getEthereumAccounts,
  SET_INITIALIZED,
  SET_ACCOUNT_ADDRESS,
  SET_WALLET_CONNECTED,
  SET_CHAIN,
  ETHEREUM_RESET,
  SET_SYMBOL,
  getChain
} from "../../store/EthereumSlice";
import { getCurrencySymbol } from "./helpers";


const useConnect = () => {
  const { authEthereumAddress } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const initialize = useCallback(async (address = authEthereumAddress) => {
    try {
      // console.log("Account is: ", address);
      if (address && (address !== "")) {
        await dispatch(
          getAccountBalance({ accountAddress: address })
        ).unwrap();
        const metamaskAccount = await dispatch(getEthereumAccounts({})).unwrap();

        const chainId = await dispatch(getChain({})).unwrap();

        dispatch(SET_CHAIN(chainId.toString(16)));
        // console.log('Metamask account: ', metamaskAccount);
        dispatch(SET_ACCOUNT_ADDRESS(metamaskAccount[0]));
        dispatch(SET_WALLET_CONNECTED(true));
        dispatch(SET_INITIALIZED(true));
      }

    } catch (error) {
      console.log(error);
    }

  }, [authEthereumAddress, dispatch]);



  return (value) => {

    console.log("Metamask Connect");
    initialize();
    // able to send requests
    // not sure when this triggers | UPDATED: on change network from metamask extension
    console.log(value);
  }
};

const useAccountsOnChange = () => {
  const dispatch = useDispatch();
  const { showNotification } = useNotify();
  const { authInfo } = useSelector((state) => state.auth);

  return (accounts) => {
    //when metamask is logged out this event is also triggered and it returns an empty array
    console.log("Account has been selected: ", accounts);
    if (accounts.length > 0) {

      try {
        const ethereumAccountAddress = accounts[0];
        dispatch(SET_ACCOUNT_ADDRESS(ethereumAccountAddress));
        const status = ethereumAccountStatus(ethereumAccountAddress, authInfo.identities);

        switch (status) {
          case 0:
            console.log('No ethereum identity registered');
            break;
          case 1:
            console.log('Please change to a registered account');
            showNotification({
              type: "info",
              message: "Please change to a registered account",
            });

            break;
          case 2:
            console.log('Matching account detected');
            showNotification({
              type: "success",
              message: "Registered account connected",
            });
            break;
          default:
            console.log("Unexpected case");
            break;
        }

      } catch (e) {
        showNotification({
          type: "error",
          message: `Something bad happened ${e}`,
        });
      }
    } else {
      // we perceive an empty account address as being loged out in metamask. This is reflected in the WalletDisconnect component as well
      dispatch(SET_ACCOUNT_ADDRESS(""));
    }

  }
};

const useChainChange = () => {
  const dispatch = useDispatch();

  return (chainId) => {
    // Handle the new chain.
    // Correctly handling chain changes can be complicated.
    // We recommend reloading the page unless you have good reason not to.
    // window.location.reload();
    dispatch(SET_CHAIN(chainId));
    dispatch(SET_SYMBOL(getCurrencySymbol(chainId)));
    console.log("Chain has been changed to: ", chainId);
  }
};

const useDisconnect = () => {
  const dispatch = useDispatch();

  return (value) => {
    console.log("Metamask disconnected");
    dispatch(ETHEREUM_RESET());
  }
};



const useHandlers = () => {
  const handleConnect = useConnect();
  const handleAccountsOnChange = useAccountsOnChange();
  const handleChainChange = useChainChange();
  const handleDisconnect = useDisconnect();

  return {
    handleConnect,
    handleAccountsOnChange,
    handleChainChange,
    handleDisconnect
  }
};

export default useHandlers;