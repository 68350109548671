import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
// import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { Row, Col, Button } from "react-bootstrap";
import FormikForm from "../../components/form/FormikForm";
import { TextInput, SingleSelect } from "../../components/form/FormikFields";
import ItemPictures from "../NewCertification/ItemPictures";
import { useLoader } from "../../providers/loader";
import { useNotify } from "../../providers/notify";
import { createCollection } from "../../store/CollectionSlice";
import { objectSelector } from "../../helpers/utils";
import { categoryOptions } from "../../constants/items";

const validationSchema = Yup.object().shape({
  title: Yup.string().required("validation.required.item-title"),
  description: Yup.string().required("validation.required.description"),

  category: Yup.string()
    .oneOf(
      categoryOptions.map((d) => d.value),
      "validation.required.category"
    )
    .required("validation.required.category"),
});

const subtitles =
  "This image is not an NFT and will be used as the banner for your collection.<br></br>You can only upload one image. You will be able to add or remove NFTs from the gallery after creating it.";

const formModel = {
  title: "",
  category: "",
  description: "",
  externalUrl: "",
  banner: [],
};

const getCollectionPayload = (values) => {
  const formData = {
    ...values,
    banner: values.banner.length > 0 ? values.banner[0] : null,
  };

  return objectSelector(formModel, formData, undefined, true);
};

const NewCollectionForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  // const { t } = useTranslation();
  const { setLoading } = useLoader();
  const { showNotification } = useNotify();

  const handleSubmit = (values) => {
    const payload = getCollectionPayload(values);

    setLoading(true);
    dispatch(createCollection(payload))
      .unwrap()
      .then(() => {
        showNotification({
          type: "success",
          message: "Item created",
        });
        history.push("/collections", { isNotifyPersist: true });
      })
      .catch((rejectedValueOrSerializedError) => {
        console.error(rejectedValueOrSerializedError);
        showNotification({
          type: "error",
          message: "error-msg.default",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <FormikForm
      initialValues={formModel}
      validationSchema={validationSchema}
      formClasses="sign__form--create"
      enableReinitialize
      onSubmit={handleSubmit}
    >
      <div className="w-100 mb-4">
        <ItemPictures comes="Collection" name="banner" subtitle={subtitles} />
      </div>

      <h4 className="sign__title">
        Collection details
        {/*{t("new-certification-page.item.item-details")}*/}
      </h4>
      <Row className="w-100">
        {/* <Col className="col-12">
          <div className="sign__file h-100">
            <div
              onClick={onClickLogo}
              className="d-flex justify-content-center align-items-center"
              style={{
                width: "350px",
                height: "350px",
                backgroundColor: "transparent",
                borderRadius: "50%",
                borderStyle: "dashed",
              }}
            >
              <img
                style={{ width: "100px", height: "100px" }}
                src={picture}
                alt="pic"
              />
            </div>

            <input
              data-name="#file1"
              id="sign__file-upload"
              name="file"
              className="sign__file-upload"
              type="file"
              
              accept="video/mp4,video/x-m4v,video/*,.png,.jpg,.jpeg"
            />
          </div>
        </Col> */}

        {/* <Col className="col-12">
          <div className="sign__file h-100">
            <div
              onClick={onClickLogo}
              className="d-flex justify-content-center align-items-center"
              style={{
                width: "800px",
                height: "400px",
                backgroundColor: "transparent",
                borderStyle: "dashed",
              }}
            >
              <img
                style={{ width: "100px", height: "100px" }}
                src={picture}
                alt="pic"
              />
            </div>

            <input
              data-name="#file1"
              id="sign__file-upload"
              name="file"
              className="sign__file-upload"
              type="file"
              accept="video/mp4,video/x-m4v,video/*,.png,.jpg,.jpeg"
            />
          </div>
        </Col> */}

        <Col className="col-12 col-lg-6">
          <TextInput
            name="title"
            label="Collection name*"
            placeholder="Insert the name of your collection"
          />
        </Col>

        <Col className="col-12 col-lg-6">
          <TextInput
            name="externalUrl"
            label="Collection external URL"
            placeholder="https://opensea.io/assets/treasures-of-the-sea"
          />
        </Col>

        <Col className="col-12">
          <TextInput
            label="Collection description*"
            name="description"
            placeholder="Insert a description for your collection"
            inputClass="sign__textarea"
            as="textarea"
            rows="4"
          />
        </Col>

        <Col className="col-6">
          <SingleSelect
            name="category"
            label="Category*"
            options={categoryOptions}
            optionIdPrefix="category"
            // withoutTranslation={true}
          />
        </Col>
      </Row>
      <Row className="w-100">
        <Col className="col-12 col-xl-3">
          <Button className="sign__btn" variant="light" type="submit">
            Create
          </Button>
        </Col>
      </Row>
    </FormikForm>
  );
};

export default NewCollectionForm;
