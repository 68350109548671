import React, { useCallback, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Item from "../../components/item/Item";
import ExploreFilters from "./ExploreFilters";
import useQueryParams from "./useQueryParams";
import { useNotify } from "../../providers/notify";
import { retrieveAssets } from "../../store/AssetSlice";
import { initialFilters, itemsPerScroll, intialPagination } from "./constants";
import { constructFilterParams } from "./helpers";

const Explore = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { queryParams, updateQueryParams } = useQueryParams();
  const { showNotification } = useNotify();
  const [filters, setFilters] = useState(initialFilters);
  const [assets, setAssets] = useState([]);
  const [hasMore, setHasMore] = useState(false);

  const fetchAssets = useCallback(
    async (pagination = intialPagination, filters = initialFilters) => {
      try {
        const response = await dispatch(
          retrieveAssets({ ...pagination, ...constructFilterParams(filters) })
        ).unwrap();
        const responseData = response.body.data;

        setAssets((assets) =>
          pagination.skip === 0 ? responseData : [...assets, ...responseData]
        );
        setHasMore(responseData.length === itemsPerScroll);
      } catch (rejected) {
        showNotification({ type: "error", message: "error-msg.default" });
      }
    },
    [showNotification, dispatch]
  );

  const handleChangeFilter = useCallback((field) => {
    setFilters((filters) => ({ ...filters, ...field }));
  }, []);

  const handleApplyFilter = useCallback(() => {
    updateQueryParams(filters);
  }, [filters, updateQueryParams]);

  const handleClearFilter = useCallback(() => {
    /**
     * We have two options:
     * 1) reset filters in UI and wait for user to click on `apply filters` in order to filter the data (ref. `data` === `assets`)
     * 2) on `clear filters`, reset filters and filter the data (ref. `assets`)
     *
     * In both cases, we filter the data as defined in the `initialFilters`
     *
     * Currently, we use the second approach.
     */
    // setFilters(initialFilters);
    updateQueryParams(initialFilters);
  }, [updateQueryParams]);

  const loadMore = useCallback(() => {
    fetchAssets({ skip: assets.length, limit: itemsPerScroll }, queryParams);
  }, [assets, queryParams, fetchAssets]);

  useEffect(() => {
    setFilters(queryParams);
    fetchAssets(undefined, queryParams);
    window.scrollTo(0, 0);
  }, [queryParams, fetchAssets]);

  return (
    <Container>
      <Row className="row--grid">
        <div className="col-12">
          <div className="main__title main__title--page">
            <h1>{t("explore-page.title")}</h1>
          </div>
        </div>
      </Row>

      <Row>
        <Col className="col-12 col-xl-3 order-xl-2">
          <div className="filter-wrap">
            <ExploreFilters
              filters={filters}
              onChange={handleChangeFilter}
              onApply={handleApplyFilter}
              onClear={handleClearFilter}
            />
          </div>
        </Col>

        <Col className="col-12 col-xl-9 order-xl-1">
          <InfiniteScroll
            dataLength={assets.length}
            hasMore={hasMore}
            style={{ overflowX: "hidden" }}
            next={loadMore}
          >
            <Row>
              {assets.map((item, index) => (
                <Col key={index} sm="6" lg="4">
                  <Item itemId={item._id} item={item} />
                </Col>
              ))}
            </Row>
          </InfiniteScroll>
        </Col>
      </Row>
    </Container>
  );
};

export default Explore;
