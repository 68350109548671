import React from "react";
import Card from 'react-bootstrap/Card'

const SellTypeCard = ({ title, subtitle, selected, ...props }) => {

    return (
        <React.Fragment>
            <Card className="market__card" style={{ borderColor: `${selected ? "#4BA4E3" : ""} `, width: "18rem", height: "8rem", backgroundColor: "#1B5579" }} {...props} >

                <Card.Text className="font-weight-bold text-light text-center pt-2" style={{ display: "block", marginLeft: "auto", marginRight: "auto" }}>
                    {title}
                </Card.Text>
                <Card.Text className="text-light text-center" style={{ display: "block", marginLeft: "auto", marginRight: "auto" }}>
                    {subtitle}
                </Card.Text>
            </Card>

        </React.Fragment>
    );
}

export default SellTypeCard