import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import services from "../services";

export const createNotification = createAsyncThunk(
  "notification/createNotification",
  async (data , { rejectWithValue }) => {
    try {
      const response = await services.NotificationService.createNotification( data );

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const retrieveNotifications = createAsyncThunk(
  "notification/retrieveNotifications",
  async (params, { rejectWithValue }) => {
    try {
      const response = await services.NotificationService.retrieveNotifications(params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const retrieveNotification = createAsyncThunk(
  "notification/retrieveNotification",
  async (notificationId, { rejectWithValue }) => {
    try {
      const response = await services.NotificationService.retrieveNotification(notificationId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteNotification = createAsyncThunk(
  "notification/deleteNotification",
  async (notificationId, { rejectWithValue }) => {
    try {
      const response = await services.NotificationService.deleteNotification(notificationId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const updateNotification = createAsyncThunk(
  "notification/updateNotification",
  async ({notificationId,info},{ rejectWithValue }) => {
    try {
      const response = await services.NotificationService.updateNotification(notificationId,info);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

let initialState = {
  state: "",
  value:"",
  error: {},
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    DUMMY(){},
  },
  extraReducers: {
    /* ---- CREATE_USER ---- */
    [String(createNotification.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(createNotification.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(createNotification.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },

    /* ---- GET_USERS ---- */
    [String(retrieveNotifications.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(retrieveNotifications.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(retrieveNotifications.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },

    /* ---- GET_USER ---- */
    [String(retrieveNotification.pending)]: (state, action) => {
        state.state = "loading";
      },
      [String(retrieveNotification.fulfilled)]: (state, action) => {
        state.state = "success";
      },
      [String(retrieveNotification.rejected)]: (state, action) => {
        state.state = "error";
        state.error = action.payload;
      },

      /* ---- UPDATE_USER ---- */
    [String(updateNotification.pending)]: (state, action) => {
        state.state = "loading";
      },
      [String(updateNotification.fulfilled)]: (state, action) => {
        state.state = "success";
      },
      [String(updateNotification.rejected)]: (state, action) => {
        state.state = "error";
        state.error = action.payload;
      },

       /* ---- DELETE_USER ---- */
    [String(deleteNotification.pending)]: (state, action) => {
        state.state = "loading";
      },
      [String(deleteNotification.fulfilled)]: (state, action) => {
        state.state = "success";
      },
      [String(deleteNotification.rejected)]: (state, action) => {
        state.state = "error";
        state.error = action.payload;
      },
      
  },
});

export const {DUMMY} = notificationSlice.actions;

export default notificationSlice.reducer;
