import React from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
// import { useTranslation } from "react-i18next";
import metamask from "../../assets/metamask_action.svg";
import connectMetaToSay from "../../assets/connectMetamaskToSaysoon.svg";
import { ethereumAccountStatus } from "../../helpers/auth";

const NavBarStatus = () => {
  // const { t } = useTranslation();
  const { userId, authInfo } = useSelector((state) => state.auth);
  const { ethereumAccountAddress } = useSelector((state) => state.ethereum);
  const status = ethereumAccountStatus(
    ethereumAccountAddress,
    authInfo.identities
  );

  return (
    <React.Fragment>
      {status !== 2 && (
        <div className="d-none d-md-block">
          <div className="simple__border__nav">
            <Link to={`/user-details/${userId}/settings`}>
              <Row className="flex-nowrap align-items-center no-gutters">
                <Col className="col-auto pl-1 pr-1">
                  {status === 0 && (
                    <img
                      src={metamask}
                      alt="metamask"
                      style={{ maxWidth: "55px", maxHeight: "55px" }}
                    />
                  )}
                  {status === 1 && (
                      <img
                        src={connectMetaToSay}
                        alt="metamask"
                        className=""
                        style={{}}
                      />
                  )}
                </Col>

                <Col className="col-auto">
                  <div
                    className="text-white text-left d-none d-lg-block pl-2"
                    style={{ maxWidth: "125px" }}
                  >
                    <div
                      style={{
                        overflowWrap: "break-word",
                        fontSize: "13px",
                        lineHeight: "12px",
                      }}
                    >
                      Metamask action required
                    </div>
                  </div>
                </Col>
              </Row>
            </Link>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default NavBarStatus;
