import React, { useState } from "react";
import { Collapse, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CheckboxGroup from "../../components/checkbox/CheckboxGroup";
import DropdownBasic from "../../components/dropdown/DropdownBasic";
import { categoryOptions, sortByOptions } from "./constants";

const CollectionsFilters = ({ filters, onChange, onApply, onClear }) => {
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(false);

  const handleSearch = (e) => {
    onChange({ search: e.target.value });
  };

  const handleSortBy = (e) => {
    onChange({ sortBy: e.target.value });
  };

  const handleCategories = (values) => {
    onChange({ category: values });
  };

  return (
    <>
      <Button
        className="filter-wrap__btn"
        variant="empty"
        data-toggle="collapse"
        data-target="#collapseFilter"
        aria-controls="collapseFilter"
        aria-expanded="false"
        onClick={() => setCollapsed(!collapsed)}
      >
        {t(collapsed ? "closeFilters" : "openFilters")}
      </Button>

      <Collapse in={collapsed}>
        <div className="filter-wrap__content" id="collapseFilter">
          <div className="filter filter--sticky">
            <h4 className="filter__title">
              {t("filters")}{" "}
              <button type="button" onClick={onClear}>
                {t("clear-all")}
              </button>
            </h4>

            <div className="filter__group">
              <label className="filter__label">
                {t("explore-page.keyword")}
              </label>
              <input
                value={filters.search}
                type="text"
                className="filter__input"
                placeholder="Keyword"
                onChange={handleSearch}
              />
            </div>

            {/* <div className="filter__group">
              <label className="filter__label">
                {t("explore-page.sort-by")}
              </label>

              <div className="filter__select-wrap">
                <DropdownBasic
                  value={filters.sortBy}
                  items={sortByOptions}
                  title="testing"
                  type="filter"
                  onChange={handleSortBy}
                />
              </div>
            </div> */}

            <div className="filter__group">
              <label className="filter__label">
                {t("explore-page.category")}
              </label>
              <CheckboxGroup
                values={filters.category}
                options={categoryOptions}
                groupAs="ul"
                groupClass="filter__checkboxes"
                itemIdPrefix="filter"
                onChange={handleCategories}
              />
            </div>

            <div className="filter__group">
              <Button className="filter__btn" variant="light" type="button" onClick={onApply}>
                {t("apply-filters")}
              </Button>
            </div>
          </div>
        </div>
      </Collapse>
    </>
  );
};

export default CollectionsFilters;
