import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import Item from "../../components/item/Item";
import { log } from "../../helpers/logger";
import { retrieveAssets } from "../../store/AssetSlice";

const filterAssetsByUser = (assets, id) =>
  assets.filter(
    (item) => item.ownerId.userId._id === id && item.saleType === "PutOnSale"
  );

const filterListingsByUser = (listings, id) => {
  const listingsFound = filterAssetsByUser(listings, id);
  // console.log('listingsFound', listingsFound);
  if (listingsFound.length > 0) {
    return true;
  }
  return false;
}

const filterERC1155AssetsByUser = (assets, id) => 
  assets.filter(
    (item) => item.standard === "erc1155" && item.ownerId.userId._id !== id && filterListingsByUser(item?.listings, id)
    );

const OnSaleList = ({ currentUserProfile }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [assetList, setAssetList] = useState([]);

  useEffect(() => {
    const fetchAssets = async () => {
      try {
        const originalPromiseResult = await dispatch(
          retrieveAssets({ saleType: "PutOnSale", ownerId: currentUserProfile })
        ).unwrap();

        const erc1155Assets = await dispatch(
          retrieveAssets({ saleType: "PutOnSale", standard: "erc1155" })
        ).unwrap();

        const responseAllDataJSON = JSON.parse(erc1155Assets.data);

        const filteredResults = filterERC1155AssetsByUser(responseAllDataJSON.data, id);
        log('filteredData', filteredResults);

        const responseDataJSON = JSON.parse(originalPromiseResult.data);
        setAssetList([...filterAssetsByUser(responseDataJSON.data, id), ...filteredResults]);
      } catch (rejectedValueOrSerializedError) {
        console.error(rejectedValueOrSerializedError);
      }
    };

    fetchAssets();
  }, [id, dispatch]);

  return (
    <Row className="row--grid">
      {assetList.map((data, index) => (
        <Col key={index} className="col-12 col-sm-6 col-lg-4">
          <Item itemId={data._id} item={data} />
        </Col>
      ))}
    </Row>
  );
};

export default OnSaleList;
