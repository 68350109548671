import React from "react";
import { Link } from "react-router-dom";
import defaultBanner from "../../assets/default_collection_banner.svg"
import "./image.scss";

const ItemImage = ({
  src,
  alt = "not_available",
  title = "",
  coverClass = "card__cover",
  detailsPath,
  width = "600px",
  height = "300px",
}) => {
  return (
    <Link className={coverClass} to={detailsPath}>
      <div className="position-relative" style={{ height, width }}>
        <div className="item__cover__wrapper">
          <img
            src={src || defaultBanner}
            alt={alt}
            title={title}
            className="item__cover__image"
          />
        </div>
      </div>
    </Link>
  );
};

export default ItemImage;
