import React from "react";
import { Container, Row, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import FormikForm from "./../../components/form/FormikForm";
import { PasswordInput } from "./../../components/form/FormikFields";
import { updateUser } from "../../store/UserSlice";
import Logo from "./../../assets/img/logo.svg";

const CreateAndConfirmPassword = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.user);

  const onSubmit = (values) => {
    const completeForm = {
      username: userState.form.username,
      password: values.password,
      email: userState.form.email,
      status: "activated",
    };
    dispatch(updateUser({ userId: userState.userId, completeForm }))
      .unwrap()
      .then(() => {
        history.push("/home");
      })
      .catch((rejectedValueOrSerializedError) => {
        console.error(rejectedValueOrSerializedError);
      });
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string().required("validation.required.password"),
  });

  const formModel = { password: "" };

  return (
    <Container>
      <Row>
        <div className="sign__content">
          <FormikForm
            initialValues={formModel}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            <Link to="/home" className="sign__logo">
              <img src={Logo} alt="logo" />
            </Link>
            <h4 className="w-100 mb-4 text-center" style={{ color: "#bdbdbd" }}>
              {t("create-password-page.accountConfirmed")}
            </h4>
            <h6
              className="w-100 mb-4"
              style={{ fontSize: "16px", color: "#bdbdbd" }}
            >
              {t("create-password-page.password-label")}
            </h6>
            <PasswordInput name="password" placeholder={t("form.password")} />

            <Button
              block
              variant="light"
              size="lg"
              type="submit"
              className="sign__btn mt-0"
            >
              {t("actions.confirm")}
            </Button>
          </FormikForm>
        </div>
      </Row>
    </Container>
  );
};

export default CreateAndConfirmPassword;
