import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import services from "../services";

export const createProfile = createAsyncThunk(
  "profile/createProfile",
  async ({ userId, payload }, { rejectWithValue }) => {
    try {
      const response = await services.ProfileService.createProfile(userId, payload);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const retrieveProfiles = createAsyncThunk(
  "profile/retrieveProfiles",
  async (params, { rejectWithValue }) => {
    try {
      const response = await services.ProfileService.retrieveProfiles(params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const retrieveProfile = createAsyncThunk(
  "profile/retrieveProfile",
  async ({ userId, profileId }, { rejectWithValue }) => {
    try {
      const response = await services.ProfileService.retrieveProfile(userId, profileId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateProfile = createAsyncThunk(
  "profile/updateProfile",
  async ({ userId, profileId, info }, { rejectWithValue }) => {
    try {
      const response = await services.ProfileService.updateProfile(
        userId,
        profileId,
        info
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteProfile = createAsyncThunk(
  "profile/deleteProfile",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await services.ProfileService.deleteProfile(userId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

let initialState = {
  currentUser: null,
  state: "",
  form: {
    username: "",
    password: "",
    email: "",
  },
  value: "",
  error: {},
  userId: "",
};

const ProfileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    SET_REGISTRATION_FORM(state, action) {
      const form = action.payload;
      state.form.username = form.name;
      state.form.email = form.email;
    },
    SET_USERID(state, action) {
      state.userId = action.payload;
    },
  },
  extraReducers: {
    /* ---- CREATE_USER ---- */
    [String(createProfile.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(createProfile.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(createProfile.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },

    /* ---- GET_USERS ---- */
    [String(retrieveProfiles.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(retrieveProfiles.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(retrieveProfiles.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },

    /* ---- GET_USER ---- */
    [String(retrieveProfile.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(retrieveProfile.fulfilled)]: (state, action) => {
      state.currentUser = action.payload.body.data;
      state.state = "success";
    },
    [String(retrieveProfile.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },

    /* ---- UPDATE_USER ---- */
    [String(updateProfile.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(updateProfile.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(updateProfile.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },

    /* ---- DELETE_USER ---- */
    [String(deleteProfile.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(deleteProfile.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(deleteProfile.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
  },
});

export const { SET_REGISTRATION_FORM, SET_USERID } = ProfileSlice.actions;

export default ProfileSlice.reducer;
