import React from "react";
import { Modal, ModalBody } from "react-bootstrap";

import "./Base.scss";

const BaseModal = ({
  show,
  onHide = null,
  title = "",
  titleFont = "sm",
  noBorder = false,
  scrollable = true,
  bodyClassName = "",
  children,
  ...rest
}) => {
  return (
    <Modal
      show={show}
      scrollable={scrollable}
      className="mfp-wrap my-mfp-zoom-in mfp-ready base__modal"
      bsPrefix="pr-sm-3 none"
      backdropClassName="mfp-bg my-mfp-zoom-in mfp-ready"
      contentClassName="modal_tertiary"
      {...rest}
    >

      {onHide &&
        <Modal.Header onHide={onHide} closeButton style={{
          borderBottom: `${noBorder === false ? '1px solid white' : 'none'} `,
        }}>

          <Modal.Title style={
            {
              fontSize: `${!noBorder && (titleFont == "sm") ? '15px' : '19px'}`,
              lineHeight: `${!noBorder && (titleFont == "sm") ? '19px' : '23px'}`,
              fontWeight: "500",
              color: "white",
              textAlign: "center",
              paddingLeft: "8%",
              width: "100%",
              // fontFamily: "Inter"
            }
          }>
            <span> {title} </span>
          </Modal.Title>
        </Modal.Header>
      }
      <ModalBody className={bodyClassName}>{children}</ModalBody>
    </Modal>
  );
};

export default BaseModal;
