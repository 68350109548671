import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useField } from "formik";
import SimpleUploadFile from "../../components/uploadFile/Simple";
import PicturesGrid from "./PicturesGrid";
import { log } from "../../helpers/logger";

const ItemPictures = ({ name = "files", subtitle, comes, certificate = false }) => {
  const { t } = useTranslation();
  const [field, meta, helpers] = useField({ name });
  const [pictures, setPictures] = useState([]);

  const handleSelect = (file) => {
    // console.log('file: ', getFileType(file));
    setPictures((pictures) => [...pictures, URL.createObjectURL(file)]);
    log(field);
    helpers.setValue([...field.value, file]);
  };

  const handleRemove = (index) => {
    const cpPictures = [...pictures];
    cpPictures.splice(index, 1);
    setPictures(cpPictures);
    const cpFieldValue = [...field.value];
    cpFieldValue.splice(index, 1);
    helpers.setValue(cpFieldValue);
  };

  return (
    <React.Fragment>
      <h4 className="sign__title">
        {comes == "Collection" ? "Banner image" : t("new-certification-page.item.upload-images-title")}

      </h4>

      <p className="text-muted text">
        <Trans
          t={t}
          i18nKey={subtitle || "new-certification-page.item.upload-images-subtitle"}
          components={{
            br: <br />,
          }}
        />
      </p>

      <SimpleUploadFile onSelect={handleSelect} />

      {meta.touched && meta.error && (
        <div
          className="d-block"
          style={{
            color: "var(--error)",
            marginLeft: "20px",
            marginTop: "0.25rem",
            fontSize: "80%",
          }}
        >
          {t(meta.error)}
        </div>
      )}

      <PicturesGrid pictures={pictures} onRemove={handleRemove} certificate={true} />
    </React.Fragment>
  );
};

export default ItemPictures;
