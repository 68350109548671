import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import FormikForm from "./../../components/form/FormikForm";
import { PasswordInput } from "./../../components/form/FormikFields";
import { updateUser } from "../../store/UserSlice";
import { useNotify } from "../../providers/notify";
import { useLoader } from "../../providers/loader";
import {useState} from "react";
import get from "lodash/get";

const validationSchema = Yup.object().shape({
  oldPassword: Yup.string().required("validation.required.old-password"),
  newPassword: Yup.string().required("validation.required.new-password"),
});

const formModel = {
  oldPassword: "",
  password: "",
};

const ProfilePasswordForm = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { showNotification } = useNotify();
  const { setLoading } = useLoader();

  const { authInfo } = useSelector((state) => state.auth);


  const getErrorMessage = (error) => {
    const { status, code } = get(error, "body.error", {
      status: null,
      code: null,
    });
  
    if (status && code) {
      if (status === 400) {
        switch (code) {
          case 720:
            return "error-msg.token-invalid";
          case 721:
            return "error-msg.token-expired";
          default:
            break;
        }
      }
    }
  
    return "error-msg.default";
  };


  const onSubmit = (values) => {

    setLoading(true);
    
    const model = {
      password: values.newPassword,
    };
    
      const info = { ...authInfo, ...model };
      dispatch(updateUser({ userId: authInfo._id, info }))
        .unwrap()
        .then(() => {
          showNotification({
            type: "success",
            message: "Password changed",
          });
        })
        .catch((rejectedValueOrSerializedError) => {
          showNotification({
            type: "error",
            message: getErrorMessage(rejectedValueOrSerializedError),
          });
        })
        .finally(() => {
          setLoading(false);
        });
    
  };

  return (
    <FormikForm
      initialValues={formModel}
      validationSchema={validationSchema}
      formClasses="sign__form--profile"
      enableReinitialize
      onSubmit={onSubmit}
    >
      <Row className="col-md-8">
        <Col className="col-12 col-md-8">
          <h4 className="sign__title">
            {t("user-details-page.change-password")}
          </h4>
        </Col>

        <Col className="col-12 col-md-8">
          <PasswordInput name="oldPassword" label="form.old-password" />
        </Col>

        <Col className="col-12 col-md-8">
          <PasswordInput name="newPassword" label="form.new-password" />
        </Col>

        <Col className="col-12">
          <Button className="sign__btn" variant="light" type="submit">
            {t("change")}
          </Button>
        </Col>
      </Row>
    </FormikForm>
  );
};

export default ProfilePasswordForm;
