import React, { useState } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import FormikForm from "../../components/form/FormikForm";
import {
    TextInput, SingleSelect
} from "../../components/form/FormikFields";
import BaseModal from "../../components/modal/Base";
import SayLogo from "../../assets/img/icons/saysoon_logo.svg";
import EtherLogo from "../../assets/img/icons/ether_logo.svg";
import { useLoader } from "../../providers/loader";
import { useDispatch, useSelector } from "react-redux";
import { createStripePayment } from "../../store/StripeSlice";
import { createPaypalPayment } from "../../store/PaypalSlice";
import { paymentMethods } from "../../constants/items";

import { getAccountBalance } from "../../store/EthereumSlice";
import { app, ethereum } from "../../constants/AppSettings";
import { useNotify } from "../../providers/notify";
import { log } from "../../helpers/logger";
const formModel = {
    amount: "",
    paymentMethod: ""
};

const validationSchema = Yup.object().shape({
    amount: Yup.number().required("validation.required.amount").min(1),
    paymentMethod: Yup.string().oneOf(paymentMethods.map((pm) => pm.value), "validation.required.payment-method").required("validation.required.payment-method")
});

const TopUpModal = ({ show, hide }) => {
    const { t } = useTranslation();
    const { showNotification } = useNotify();
    const { userId, authEthereumAddress } = useSelector(state => state.auth);
    const [selected, setSelected] = useState(0);
    const [hideInput, setHideInput] = useState(true);
    const [showMATICMsg, setShowMATICMsg] = useState(false);
    const [showSAYMsg, setShowSAYMsg] = useState(false);
    const dispatch = useDispatch();
    const { setLoading, setText } = useLoader();

    const openPaymentPage = async (method, payment) => {
        switch (method) {
            case "paypal":
                if (payment?.checkoutUrl) {
                    window.location.href = payment?.checkoutUrl;
                } else {
                    throw "No payment links";
                }
                break;
            case "stripe":
                window.location.href = payment.url;
                break;
            default:
                throw "Invalid payment method";
        }
    }

    const handlePaypalPayment = async (amount) => {
        return dispatch(createPaypalPayment(
            {
                amount: amount,
                currency: "EUR",
                type: "ecommerce",
                description: "Top up payment",
                externalId: "anId",
                sandbox: app.environment == "dev" ? true : false,
                meta: {
                    accountId: userId,
                    ethAddress: authEthereumAddress,
                    type: "topup",
                    chainName: "saysoon"
                }
            }
        )).unwrap();
    }

    const handleStripePayment = async (amount) => {
        return dispatch(createStripePayment(
            {
                amount: amount,
                currency: "EUR",
                type: "ecommerce",
                description: "Top up payment",
                externalId: "anId",
                sandbox: app.environment == "dev" ? true : false,
                meta: {
                    accountId: userId,
                    ethAddress: authEthereumAddress,
                    type: "topup",
                    chainName: "saysoon"
                }
            }
        )).unwrap();
    }

    const handlePaymentMethod = async (method, amount) => {
        localStorage.setItem("GALLERY_TOP_UP_AMOUNT", amount);
        let amountInCents = amount * 100;
        switch (method) {
            case "paypal":
                return handlePaypalPayment(amountInCents);
            case "stripe":
                return handleStripePayment(amountInCents);
            default:
                console.error("Payment Method not recognized");
        }
    }

    const canPlatformFund = async (amount) => {
        const balance = await dispatch(getAccountBalance({ accountAddress: ethereum.platformAddress, chainId: "4500" })).unwrap();
        log('Balance', balance);
        return (amount <= balance);
    }

    const handleSubmit = async (values) => {

        setLoading(true);
        setText('Redirecting');
        try {

            switch (selected) {
                case 1:
                    if (values.paymentMethod === "stripe") {
                        showNotification({
                            type: "info",
                            message: "Currently not supported",
                        });
                        return;
                    }
                    if (!canPlatformFund()) {
                        throw "Not enough platform funds";
                    }
                    const payment = await handlePaymentMethod(values.paymentMethod, values.amount);
                    log('payment: ', payment);
                    // @todo: should edit the successUrl to include the paymentId at the end
                    // and also should be taken from a client/account db
                    // successUrls should not be on tenants
                    await openPaymentPage(values.paymentMethod, payment);
                    break;
                case 2:
                    showNotification({
                        type: "info",
                        message: "To be supported...",
                    });
                    break;
                default:
                    throw "Currency not available";
            }

        } catch (e) {
            console.error('Error occured: ', e);
            showNotification({
                type: "error",
                message: e,
            });
        } finally {
            setLoading(false);
        }

        return;

    }

    const selectSay = (e) => {
        e.preventDefault();
        setSelected(1);
        setHideInput(false);
    }

    const selectEth = (e) => {
        e.preventDefault();
        setSelected(2);
        setShowMATICMsg(true);
        setHideInput(false);
    }

    const extraHide = () => {
        setSelected(0);
        setHideInput(true);
        hide();
    }

    const hideMaticMsg = () => {
        setShowMATICMsg(false);
    }

    const hideSayMsg = () => {
        setShowSAYMsg(false);
    }

    const paymentSelectionAction = (selection) => {
        if (selection == "stripe" && selected == 1) {
            setShowSAYMsg(true);
        }
    }

    return (
        <BaseModal
            show={show}
            onHide={extraHide}
            title="Add currency to your wallet"
            centered
            size="md"
            bodyClassName="pl-0 pr-0">
            <div className="text-white">
                <BaseModal size="sm" centered show={showMATICMsg} onHide={hideMaticMsg} titleFont={"bg"} title={t("top-up.modal-matic-title")}>

                    <div className="text-white p-4" >
                        <Row>
                            <p className="asset__modal__text">
                                {t("top-up.modal-matic-text1")}
                            </p>

                            <p className="asset__modal__text">
                                {t("top-up.modal-matic-text2")}
                            </p>
                        </Row>
                    </div>
                </BaseModal>
                <BaseModal size="sm" centered show={showSAYMsg} onHide={hideSayMsg} titleFont={"bg"} title={t("top-up.modal-say-title")}>

                    <div className="text-white p-4" >
                        <Row>
                            <p className="asset__modal__text" style={{textAlign: "center"}}>
                                {t("top-up.modal-say-text1")}
                            </p>
                        </Row>
                    </div>
                </BaseModal>
                <Row className="no-gutters d-flex justify-content-center">
                    <div className="pb-4">
                        <span style={
                            {
                                fontSize: "12px",
                                lineHeight: "15px",
                                color: "#BDBDBD",
                                // fontFamily: "Inter"
                            }
                        }>{t('top-up.modal-caption')}</span>
                    </div>
                </Row>
                <Row className="no-gutters pb-4">
                    <Col className="col-6 d-flex justify-content-end pr-2">

                        <div onClick={selectSay} className={"box__frame p-2 " + (selected === 1 ? "box__frame__selected" : "")}>
                            <div className="d-flex justify-content-center pt-2">
                                <img className="" src={SayLogo} />
                            </div>
                            <div className="d-flex justify-content-center pt-2">SAY</div>
                        </div>
                    </Col>
                    <Col className="col-6 d-flex justify-content-start pl-2">
                        <div onClick={selectEth} className={"box__frame p-2 " + (selected === 2 ? "box__frame__selected" : "")}>
                            <div className="d-flex justify-content-center pt-2">
                                <img className="" src={EtherLogo} />
                            </div>
                            <div className="d-flex justify-content-center pt-2">ETH</div>
                        </div>
                    </Col>
                </Row>
                <Row className="no-gutters d-flex justify-content-center">
                    <div className={"no__visibility " + (hideInput === false ? "visible" : "no__visibility")}>
                        <Col className="col-12">
                            <p className="text-center">{t('top-up.exchange-rate')}</p>
                        </Col>
                        <div className="pl-4 pt-3 mb-0" style={
                            {
                                fontSize: "14px",
                                lineHeight: "17px",
                                color: "#BDBDBD"
                            }
                        }>
                        </div>
                        <FormikForm
                            initialValues={formModel}
                            validationSchema={validationSchema}
                            formClasses="sign__form--create sign__no__card mt-0 pt-3"
                            enableReinitialize
                            onSubmit={handleSubmit}
                        >
                            <Row>
                                <Col className="col-12">
                                    <TextInput
                                        name="amount"
                                        label="top-up.modal-amount"
                                        placeholder="10 euro"
                                        disabled={selected == 2 || showSAYMsg}
                                    />
                                </Col>
                                <Col className="col-12">
                                    <SingleSelect
                                        name="paymentMethod"
                                        label="top-up.payment-methods"
                                        emptyText="validation.empty-payment-method"
                                        options={paymentMethods}
                                        optionIdPrefix="payment"
                                        onChange={paymentSelectionAction}
                                        withoutTranslation={true}
                                        disabled={selected == 2 || showSAYMsg}
                                    />
                                </Col>

                            </Row>

                            <Col className="col-12">

                                <Row>
                                    <Button disabled={selected == 2 || showSAYMsg} className="sign__btn mt-0" variant="light" type="submit">
                                        {t('top-up.modal-btn')}
                                    </Button>
                                </Row>

                            </Col>
                        </FormikForm>
                    </div>
                </Row>
            </div >
        </BaseModal >
    );
};

export default TopUpModal;
