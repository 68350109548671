import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Item from "../../components/item/Item";
import { retrieveAssets } from "../../store/AssetSlice";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { log } from "../../helpers/logger";

const filterAssetsByUser = (assets, id) =>
  assets.filter(
    (item) => item.ownerId.userId._id === id
  );

const filterListingsByUser = (listings, id) => {
  const listingsFound = filterAssetsByUser(listings, id);
  // console.log('listingsFound', listingsFound);
  if (listingsFound.length > 0) {
    return true;
  }
  return false;
}

const filterERC1155AssetsByUser = (assets, id) =>
  assets.filter(
    (item) => item.standard === "erc1155" && item.ownerId.userId._id !== id && filterListingsByUser(item?.listings, id)
  );

const WalletList = ({ currentUserProfile }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [assetList, setAssetList] = useState([]);
  const { authInfo } = useSelector(state => state.auth);
  let query = useParams();

  useEffect(() => {
    const fetchAssets = async () => {
      try {
        const originalPromiseResult = await dispatch(
          retrieveAssets({ ownerId: currentUserProfile })
        ).unwrap();
        log(JSON.parse(originalPromiseResult.data));

        const erc1155Assets = await dispatch(
          retrieveAssets({ saleType: "PutOnSale", standard: "erc1155" })
        ).unwrap();

        const result = filterERC1155AssetsByUser(JSON.parse(erc1155Assets.data).data, id);
        log('filteredData erc1155:', result);
        setAssetList([ ...JSON.parse(originalPromiseResult.data).data, ...result ]);
      } catch (rejectedValueOrSerializedError) {
        log(rejectedValueOrSerializedError);
      }
    };
    fetchAssets();
  }, [authInfo._id, query.id, dispatch]);

  return (
    <Row className="row--grid">
      {assetList.map((data, jIndex) => (
        <Col key={jIndex} className="col-12 col-sm-6 col-lg-4">
          <Item itemId={data._id} item={data} />
        </Col>
      ))}
    </Row>
  );
};

export default WalletList;
