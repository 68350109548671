import React from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import * as Yup from "yup";
import FormikForm from "./../../components/form/FormikForm";
import { PasswordInput } from "./../../components/form/FormikFields";

const validationSchema = Yup.object().shape({
  password: Yup.string().required("validation.required.password"),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref("password")], "validation.required.match-password")
    .required("validation.required.confirm-new-password"),
});

const formModel = { password: "", passwordConfirmation: "" };

const CreatePasswordForm = ({ onSubmit }) => {
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <FormikForm
      initialValues={formModel}
      validationSchema={validationSchema}
      formDefaultClass="none"
      formClasses="w-100"
      onSubmit={onSubmit}
    >
      <h4 className="sign__title mb-2">
        {location.pathname.split("/", 2)[1] === "passwordreset"
          ? t("create-password-page.password-title-reset")
          : t("create-password-page.password-title")}
      </h4>

      <p className="text-muted mb-5" style={{ fontSize: "0.9rem" }}>
        {location.pathname.split("/", 2)[1] === "passwordreset"
          ? t("create-password-page.password-subtitle-reset")
          : t("create-password-page.password-subtitle")}
      </p>

      <PasswordInput
        name="password"
        label="create-password-page.password-label"
        placeholder={t("form.password")}
      />

      <PasswordInput
        name="passwordConfirmation"
        label="create-password-page.password-confirmation-label"
        placeholder={t("form.password")}
      />

      <Button
        block
        variant="light"
        size="lg"
        type="submit"
        className="sign__btn my-0"
      >
        {t("actions.confirm")}
      </Button>
    </FormikForm>
  );
};

export default CreatePasswordForm;
