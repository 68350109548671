import React, { useEffect, useState } from "react";
import { getAssetMedia, getAssetTitle } from "../../helpers/asset";
import certificationIcon from "../../assets/img/fileIcon.svg";

const AssetItemPDF = ({ asset, modal = false }) => {
    asset.name = asset.title;
    const [assetTitle, setAssetTitle] = useState("");
    const [PDFSrc, setPDFSrc] = useState("");

    const handleOpenDocument = () => {
        const documentUrl = asset?.files?.[0]?.file?.sizes.original.url;
        if (documentUrl) window.open(documentUrl, "_blank");
      };

    useEffect(() => {
        setAssetTitle(getAssetTitle(asset));
        setPDFSrc(getAssetMedia(asset));
    }, [asset]);

    return (
        <div className="asset__img">
            <button onClick={handleOpenDocument} className="mt-3" style={{ color: "#BDBDBD" }}>
                <div className="d-flex">
                    <div className="mr-4" style={{ position: "relative" }}>
                        <img
                            style={{
                                width: "350px",
                            }}
                            src={certificationIcon}
                            alt="doc_icon"
                        />
                    </div>

                    <h6
                        style={{ fontSize: "22px", fontWeight: 600, cursor: "pointer", color: "white" }}
                        className="align-self-center ms-3"
                        onClick={handleOpenDocument}
                    >
                        {document.name}
                    </h6>
                </div>

            </button>
        </div>
    );
};

export default AssetItemPDF;
