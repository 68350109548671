import React, { useEffect, useState } from "react";
import { Button, Image } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Web3 from "web3";
import FormikForm from "./../../components/form/FormikForm";
import { confirmUser, upgradeUserLevel } from "../../store/UserAuthSlice";
import metamask from "../../assets/img/metamask.png";
import { SET_WALLET } from "../../store/UserSlice";
import { log } from "../../helpers/logger";

const formModel = {
  address: "",
};

const LoginWallet = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const [account, setAccount] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [balance, Setbalance] = useState(0);
  const [isWalletInstalled, setIsWalletInstalled] = useState(false);
  const [connected, setConnected] = useState(false);

  const onLoad = async () => {
    if (window.ethereum) {
      log("hello");
      setIsWalletInstalled(true);
      log("web3 is enabled");
    } else {
      log("web3 is not found");
    }
  };

  const handleSignMessage = ({ publicAddress, nonce }) => {
    const web3 = window.web3;
    log("Account in handeSignMessage: ", publicAddress);

    return new Promise((resolve, reject) => {
      web3.eth.personal
        .sign(
          `Sign this message to prove you have access to this wallet and we'll log you in. This won't cost Ether. \n\n To stop hackers from hacking keep this unique ID message they can't guess ${nonce}`,
          publicAddress
        )
        .then((signature) => resolve(signature))
        .then(() => {
          setConnected(true);
          /*dispatch(
            loginUser({ email: "manosmyrnakis@hotmail.com", password: "Manos123!" })
          )
            .unwrap()
            .then((originalPromiseResult) => {
              console.log(originalPromiseResult.body.data);
              history.push("/home");
            })
            .catch((rejectedValueOrSerializedError) => {
              console.log(rejectedValueOrSerializedError);
            });*/
        })
        .catch((e) => {
          reject(e);
        });
    });
  };



  const upgradeUser = () => {
    dispatch(upgradeUserLevel({ level: "ethereum" }))
      .unwrap()
      .then((originalPromiseResult) => {
        log(originalPromiseResult.body.data);
      })
      .catch((rejectedValueOrSerializedError) => {
        console.error(rejectedValueOrSerializedError);
      });
  };
  const confUser = ({ id, address, signature }) => {
    const data = {
      id: id,
      address: address,
      signature: signature,
    };
    dispatch(confirmUser({ confirmationService: "ethereum", data: data }))
      .unwrap()
      .then((originalPromiseResult) => {
        log(originalPromiseResult.body.data);
      })
      .catch((rejectedValueOrSerializedError) => {
        console.error(rejectedValueOrSerializedError);
      });
  };

  const connectWallet = async (event) => {
    if (window.ethereum) {
      upgradeUser();

      const connectedAddress = await window.ethereum.enable();
      setAccount(connectedAddress[0]);
      log(connectedAddress);
      const balance = await window.web3.eth.getBalance(connectedAddress[0]); //Will give value in.
      const formatedBalance = window.web3.utils.fromWei(balance, "ether");
      dispatch(SET_WALLET(formatedBalance));

      Setbalance(formatedBalance);

      handleSignMessage({
        publicAddress: connectedAddress[0],
        nonce: "hard_to_guess",
      }).then((signature) => {
        log("This is not safe: ", signature);
        confUser({
          id: "hard_to_guess",
          address: connectedAddress[0],
          signature: signature,
        });
      });

      // console.log(window.ethereum)
      // window.ethereum.on("connect", (handler) => {

      //   console.log("asdasd")
      //   // able to send requests
      //   // not sure when this triggers
      //   console.log(handler);
      // });
      window.ethereum.on("disconnect", (handler) => {
        log("Metamask disconnected");
      });

      window.ethereum.on("accountsChanged", (accounts) => {
        log("Account has been selected: ", accounts);
        //when metamask is logged out this event is also triggered and it returns an empty array
        setAccount(accounts[0]);
      });

      // window.ethereum.on("chainChanged", (chainId) => {
      //   // Handle the new chain.
      //   // Correctly handling chain changes can be complicated.
      //   // We recommend reloading the page unless you have good reason not to.
      //   console.log("Chain has been changed to: ", chainId);
      //   // window.location.reload();
      // });
    } else if (window.web3) {
      log("asdasd");
      window.web3 = new Web3(window.web3.currentProvider);
    } else {
      window.alert(
        "Non-Ethereum browser detected. You should consider trying MetaMask!"
      );
    }
  };


  useEffect(() => {
    onLoad();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {isWalletInstalled ? (
        <div className="sign">
          <div className="sign__content w-100">
            {connected ? (
              <FormikForm initialValues={formModel}>
                <Image src={metamask} style={{ width: "100px" }}></Image>
                <div className="main__title main__title--center mt-3 mb-3">
                  <h3
                    className="text-center"
                    style={{
                      fontSize: "16px",
                      maxWidth: "500px",
                      lineHeight: "24px",
                      color: "#BDBDBD",
                      display: "block",
                    }}
                  >
                    Your <span className="text-white">Metamask wallet</span> is
                    connected to your account.
                    <div>
                      <span className="text-white">Address:</span> {account}
                    </div>
                  </h3>
                </div>

                <Button
                  block
                  variant="secondary"
                  size="lg"
                  type="button"
                  style={{ backgroundColor: "#C32323" }}
                  className="sign__btn"
                  onClick={() => {
                    connectWallet();
                    // test();
                  }}
                >
                  Disconnect
                  {/* {t("login-page.connect")}*/}
                </Button>
              </FormikForm>
            ) : (
              <FormikForm initialValues={formModel}>
                <Image src={metamask} style={{ width: "100px" }}></Image>
                <div className="main__title main__title--center mt-3 mb-3">
                  <h3
                    className="text-center"
                    style={{
                      fontSize: "16px",
                      maxWidth: "397px",
                      lineHeight: "24px",
                      color: "#BDBDBD",
                      display: "block",
                    }}
                  >
                    Link your{" "}
                    <span className="text-white">Metamask wallet</span> to your
                    Saysoon account to access the full functionalities of the
                    website.
                  </h3>
                </div>

                <Button
                  block
                  variant="primary"
                  size="lg"
                  type="button"
                  className="sign__btn"
                  onClick={() => {
                    connectWallet();
                    // test();
                  }}
                >
                  Connect
                  {/* {t("login-page.connect")}*/}
                </Button>
              </FormikForm>
            )}
          </div>
        </div>
      ) : (
        <div className="sign">
          <div className="sign__content">
            <FormikForm initialValues={formModel}>
              <div className="main__title main__title--center mt-2">
                <h3 className="text-center">
                  You need to install MetaMask to continue
                </h3>
              </div>

              <Image src={metamask}></Image>

              <Button
                block
                href="https://metamask.io/download"
                text="Get Metamask"
                variant="primary"
                size="lg"
                type="button"
                className="sign__btn"
                onClick={() => { }}
              >
                {t("login-page.download-metamask")}
              </Button>
            </FormikForm>
          </div>
        </div>
      )}
    </div>
  );
};

export default LoginWallet;
