import React, { useState, useCallback, useEffect } from "react";
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import UserBanner from "../../components/user/Banner";
import UserInfoCard from "../../components/card/UserInfo";
import NewCertificationItemForm from "./ItemForm";
import NewCertificationDocumentation from "./Documentation";
import { useNotify } from "../../providers/notify";
import { useLoader } from "../../providers/loader";
import {
  createCertification,
  createCertificationFile,
  createCertificationDocument,
  updateCertification,
} from "../../store/CertificationSlice";
import Steps from "../../components/Steps/Steps";
import {
  initializeContract,
  mintAsset,
  getTotalSupply,
  isApprovedForAll,
  approveForAll,
  getDefaultRoyalty,
  setTokenRoyalty,
} from "../../store/EthereumSlice";
import { log } from "../../helpers/logger";
import { ethereum } from "../../constants/AppSettings";
import { updateAsset } from "../../store/AssetSlice";
import { getUserLevel, hasAppliedForRole } from "../../helpers/user";
import { ethereumAccountStatus } from "../../helpers/auth";
import InvalidAccount from "../../components/Metamask/InvalidAccount";
import KycModal from "../../components/KycModal";
import EmailConfirmationModal from "../../components/KycModal/emailConfirmation";
import { retrieveAuthUser } from "../../store/AuthSlice";

const initialFormData = {
  title: "",
  externalUrl: "",
  description: "",
  category: "",
  blockchain: "",
  price: "",
  saleType: "",
  files: [],
  standard: "NotMinted",
  type: "certificate",
};

const wizardSteps = {
  STEP_1: "itemForm",
  STEP_2: "documentationForm",
};

const NewCertification = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { showNotification } = useNotify();
  const { setLoading, setText } = useLoader();
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState(initialFormData);
  const { userId, authInfo, authProfile, authEthereumAddress } = useSelector((state) => state.auth);
  const { ethereumAccountAddress } = useSelector((state) => state.ethereum);
  // const [kycUser, setKycUser] = useState(false);
  const [showModalKYC, setShowModalKYC] = useState(false);
  const [showModalEmail, setShowModalEmail] = useState(false);


  const closeModalKYC = () => {
    setShowModalKYC(false);
  }

  const closeModalEmail = () => {
    setShowModalEmail(false);
  }


  const handleNextStep = async (values) => {
    await dispatch(retrieveAuthUser({userId})).unwrap();

    console.log('values', values);
    if (!getUserLevel(authInfo, "kyc") && values?.blockchain != "") {
      setLoading(false);

      if (hasAppliedForRole(authInfo, "kyc")) {
        setShowModalEmail(true);
      } else {
        setShowModalKYC(true);
        showNotification({ type: "error", message: "Need to be KYC member" });
      }

    } else {
      setFormData((formData) => ({ ...formData, ...values }));
      setCurrentStep((currentStep) => currentStep + 1);
      window.scrollTo(0, 0);
    }

  };

  const handleCreateCertification = async (documents) => {
    try {
      setLoading(true);
      setText('Uploading files');
      const payload = Object.keys(formData)
        .filter((key) => formData[key] !== null && formData[key] !== "")
        .reduce((acc, key) => ({ ...acc, [key]: formData[key] }), {});

      payload.standard = "erc721";
      const images = payload.files;
      let imagePayloads = [];
      delete payload.files;

      const response = await dispatch(createCertification(payload)).unwrap();
      const assetId = response.body.data._id;
      log('assetid', assetId);

      images.forEach((image, index) => {
        imagePayloads.push({
          name: `${image?.name}`,
          description: `image ${index} of certificate ${assetId}`,
          type: "jpg",
          file: image
        });
      });

      let currentImg = 1;
      // upload images one by one
      for (const iPayload of imagePayloads) {
        setText(`Image ${currentImg} of ${imagePayloads.length}`);
        await dispatch(createCertificationFile({ assetId, data: iPayload })).unwrap();
        currentImg += 1;
      }

      // upload the documents
      await uploadCertificationDocuments(assetId, documents);

      // @todo: we need the contract standard here somehow, should be added in the API
      if (payload.saleType != 'NotOnSale') {
        try {
          const contractName = payload.standard == "erc721" ? "DigitalAsset" : "MultiToken";
          log('Contract Name: ', contractName);
          await dispatch(initializeContract({ contractName })).unwrap();
          // @todo: should eventually call aproveForAll on wyvern proxy registration
          const isApproved = await dispatch(isApprovedForAll({ operatee: authEthereumAddress })).unwrap();

          if (!isApproved) {
            setText("Initializing wallet");
            await dispatch(approveForAll({})).unwrap();
          }
          setText('Creating the NFT');
          await dispatch(initializeContract({})).unwrap();
          await dispatch(mintAsset({ id: assetId })).unwrap();
        } catch (e) {
          showNotification({
            type: "error",
            message: "Minting error",
          });
          await dispatch(updateAsset({
            assetId,
            data: { saleType: 'NotOnSale', standard: 'NotMinted' }
          })).unwrap();
          setLoading(false);
          return history.push("/home", { isNotifyPersist: true });
        }

        try {
          await dispatch(initializeContract({ contractName: "SaysoonMatch", platform: true })).unwrap();
          const standardId = payload.standard == "erc721" ? 721 : 1155;
          const royalties = await dispatch(getDefaultRoyalty({})).unwrap();
          log('default royalties', royalties);
          setText("Finalizing");
          await dispatch(setTokenRoyalty({
            ERCStandard: standardId,
            assetId,
            receiverOwner: authEthereumAddress,
            receiverCreator: authEthereumAddress,
            receiverPlatform: ethereum.platformAddress,
            feeNumeratorOwner: royalties[0],
            feeNumeratorCreator: royalties[1],
            feeNumeratorPlatform: royalties[2]
          })).unwrap();
        } catch (e) {
          showNotification({
            type: "error",
            message: "Royalties error",
          });
          // again here I dont know what should happen
          // await dispatch(updateAsset({
          //   assetId,
          //   data: { saleType: 'NotOnSale', standard: 'NotMinted' }
          // })).unwrap();
          setLoading(false);
          return history.push("/home", { isNotifyPersist: true });
        }


      }

      setText('Created the NFT');

      showNotification({
        type: "success",
        message: "success-msg.certification-created",
      });

      history.push(`/certifications/${assetId}`, {
        isNotifyPersist: true,
      });
    } catch (error) {
      if (error.error && error.error.code === 4001) {
        showNotification({
          type: "error",
          message: "Transaction Rejected",
        });
      } else {
        showNotification({
          type: "error",
          message: "error-msg.default",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const uploadCertificationDocuments = async (assetId, documents) => {
    try {
      const totalDocs = documents.length;
      let currentDoc = 1;
      for (const doc of documents) {
        setText(`Document ${currentDoc} of ${totalDocs}`);
        await dispatch(
          createCertificationDocument({ assetId, data: doc })
        ).unwrap();
        currentDoc += 1;
      }
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const shouldDisplay = useCallback(() => {

    const status = ethereumAccountStatus(ethereumAccountAddress, authInfo.identities);
    if (status === 2) {
      return true;
    }
    return false;

  }, [authInfo.identities, ethereumAccountAddress]);

  // useEffect(() => {
  //   if (getUserLevel(authInfo, 'kyc')) {
  //     setKycUser(true);
  //   }
  // }, [authInfo])

  return (
    <React.Fragment>
      <KycModal show={showModalKYC} closeCallback={closeModalKYC} />
      <EmailConfirmationModal show={showModalEmail} closeCallback={closeModalEmail} />
      <UserBanner userProfile={authProfile} />

      <Container>
        {(shouldDisplay()) ?
          (<Row className="row--grid">
            <Col className="col-12 col-xl-3">
              <UserInfoCard user={authInfo} profile={authProfile} />
            </Col>

            <Col className="col-12 col-xl-9">
              <Tabs
                activeKey={wizardSteps[`STEP_${currentStep}`]}
                transition={false}
                style={{ borderBottom: "0" }}
              >
                <Tab eventKey={wizardSteps.STEP_1}>
                  <div className="main__title main__title--create">
                    <h2>
                      {t("new-certification-page.item.title")}
                      <Steps step={1} />
                    </h2>
                  </div>

                  <NewCertificationItemForm onNextStep={handleNextStep} />
                </Tab>

                <Tab eventKey={wizardSteps.STEP_2}>
                  <div className="main__title main__title--create">
                    <h2>
                      {t("new-certification-page.documentation.title")}
                      <Steps step={2} />
                    </h2>
                  </div>

                  <NewCertificationDocumentation
                    onCreateCertification={handleCreateCertification}
                  />
                </Tab>
              </Tabs>
            </Col>
          </Row>) : (<InvalidAccount validMetamaskAccount={shouldDisplay()} />)}
      </Container>
    </React.Fragment>
  );
};

export default NewCertification;
