import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import UserAvatar from "../user/Avatar";
import ProtectedButton from "../button/ProtectedButton";
import SocialItem from "../social/SocialItem";
import {
  constructUserSocials,
  getUserEthereumAccount,
} from "../../helpers/user";
import { ReactComponent as WebIcon } from "../../assets/img/icons/web.svg";
import CopyLink from "../input/CopyLink";

const UserInfo = ({ user = {}, profile = {}, isUserCilckable = true }) => {
  const { t } = useTranslation();
  const { authInfo } = useSelector((state) => state.auth);
  const [isFollowing, setIsFollowing] = useState(false);
  const [userDetailsPath, setUserDetailsPath] = useState("");
  const [userFullName, setUserFullName] = useState("");
  const [ethereumIdentity, setEthereumIdentity] = useState("");
  const [socials, setSocials] = useState([]);

  useEffect(() => {
    if (user) {
      setUserDetailsPath(`/user-details/${user._id}`);
      setEthereumIdentity(
        getUserEthereumAccount(user.identities).identity || ""
      );
    }
  }, [user]);

  useEffect(() => {
    setUserFullName(`${profile.firstname || ""} ${profile.lastname || ""}`);
    setSocials(constructUserSocials(profile.social));
  }, [profile]);

  const toggleFollowing = () => setIsFollowing(!isFollowing);

  return (
    <div className="author author--page">
      <div className="author__meta">
        <UserAvatar profile={profile} avatarClass="author__avatar" />

        <h1 className="author__name">
          {isUserCilckable ? (
            <Link to={userDetailsPath}>{userFullName}</Link>
          ) : (
            userFullName
          )}
        </h1>

        <h2 className="author__nickname">
          {isUserCilckable && user && user._id ? (
            <Link to={userDetailsPath}>{`@${profile.nickname || ""}`}</Link>
          ) : (
            `@${profile.nickname || ""}`
          )}
        </h2>

        <p className="author__text">{profile.description}</p>

        <CopyLink linkText={ethereumIdentity} />

        <a href="author.html" className="author__link">
          <WebIcon /> {user.email}
        </a>

        <div className="author__social">
          {socials.map((item, index) => (
            <SocialItem key={index} path={item.path} icon={item.icon} />
          ))}
        </div>

        <div className="author__wrap">
          <div className="author__followers">
            <p>{user.followers}</p>

            <span>{t("followers")}</span>
          </div>

          {(!user._id || user._id !== authInfo._id) && (
            <React.Fragment>
              {isFollowing ? (
                <ProtectedButton
                  className="author__follow"
                  variant="light"
                  onClick={toggleFollowing}
                >
                  {t("actions.unfollow")}
                </ProtectedButton>
              ) : (
                <ProtectedButton
                  className="author__follow"
                  variant="light"
                  onClick={toggleFollowing}
                >
                  {t("actions.follow")}
                </ProtectedButton>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
