import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import FormikForm from "../../../components/form/FormikForm";
import { TextInput } from "../../../components/form/FormikFields";

const validationSchema = Yup.object().shape({
  bid: Yup.number()
    .typeError("validation.type.bid")
    .moreThan(0, "validation.min.bid")
    .required("validation.required.bid"),
});

const formModel = {
  bid: "",
};

const BidCreateStep = ({ onBidCreation, onClose }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <FormikForm
        initialValues={formModel}
        validationSchema={validationSchema}
        formClasses="sign__group"
        formDefaultClass="none"
        onSubmit={onBidCreation}
      >
        <div className="w_step__title">
          {t("item-details-page.wizard.bid.create.title")}
        </div>

        <p className="w_step__text mb-4">
          {t("item-details-page.wizard.bid.create.description")}
        </p>

        <TextInput name="bid" label="form.required.your-bid" />

        <Row className="w-100 no-gutters">
          <Col className="col-12 col-xl-6 pl-xl-3" xl={{ order: 2 }}>
            <Button className="sign__btn" variant="primary" type="submit">
              {t("actions.ok")}
            </Button>
          </Col>

          <Col className="col-12 col-xl-6" xl={{ order: 1 }}>
            <Button
              className="sign__btn"
              variant="primary"
              type="button"
              onClick={onClose}
            >
              {t("actions.cancel")}
            </Button>
          </Col>
        </Row>
      </FormikForm>
    </React.Fragment>
  );
};

export default BidCreateStep;
