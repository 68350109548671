import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Container, Row } from "react-bootstrap";
import CreatePasswordForm from "./Form";
import CreatePasswordSuccess from "./Success";
import get from "lodash/get";
import { confirmUser } from "../../store/UserSlice";
import { useNotify } from "../../providers/notify";
import { useLoader } from "../../providers/loader";

const getErrorMessage = (error) => {
  const { status, code } = get(error, "body.error", {
    status: null,
    code: null,
  });

  if (status && code) {
    if (status === 400) {
      switch (code) {
        case 720:
          return "error-msg.token-invalid";
        case 721:
          return "error-msg.token-expired";
        default:
          break;
      }
    }
  }

  return "error-msg.default";
};

const CreatePassword = () => {
  const dispatch = useDispatch();
  const { confirmationToken } = useParams();
  const { showNotification } = useNotify();
  const { setLoading } = useLoader();
  const [success, setSuccess] = useState(false);

  const handleSubmit = (values) => {
    setLoading(true);

    const data = {
      token: confirmationToken,
      password: values.password,
      passwordConfirmation: values.passwordConfirmation,
    };

    dispatch(confirmUser({ confirmationService: "email", data }))
      .unwrap()
      .then(() => {
        setSuccess(true);
      })
      .catch((error) => {
        showNotification({
          type: "error",
          message: getErrorMessage(error),
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Container>
      <Row className="justify-content-center" style={{ paddingTop: "25px" }}>
        <div className="sign__form">
          {success ? (
            <CreatePasswordSuccess />
          ) : (
            <CreatePasswordForm onSubmit={handleSubmit} />
          )}
        </div>
      </Row>
    </Container>
  );
};

export default CreatePassword;
