import swaggerProps from "../plugins/swagger-client";

export default class CertificationService {

  createCertification(payload) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Certification.createCertification(
          {},
          swaggerProps.buildRequest(payload),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  createCertificationDocument(certificationId, payload) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Certification.createCertificationDocument(
          { certificationId: certificationId },
          swaggerProps.buildRequest(payload),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  retrieveCertifications(params = {}) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Certification.retrieveCertifications(
          params,
          swaggerProps.buildRequest(),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  retrieveCertification(certificationId) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Certification.retrieveCertification(
          { certificationId },
          swaggerProps.buildRequest(),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  deleteCertification(certificationId) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Certification.deleteCertification(
          { certificationId },
          swaggerProps.buildRequest(),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  updateCertification(certificationId, info) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Certification.updateCertification(
          { certificationId: certificationId },
          swaggerProps.buildRequest(info),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}
