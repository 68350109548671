import { ReactComponent as FacebookIcon } from "../assets/img/social/fb.svg";
import { ReactComponent as InstagramIcon } from "../assets/img/social/instagram.svg";
import { ReactComponent as LinkedInIcon } from "../assets/img/social/linkedin.svg";
import { ReactComponent as TwitterIcon } from "../assets/img/social/twitter.svg";
import { ReactComponent as MediumIcon } from "../assets/img/social/medium.svg";
import { ReactComponent as BehanceIcon } from "../assets/img/social/social-3.svg";
import { ReactComponent as WebIcon } from "../assets/img/icons/web.svg";
import { ReactComponent as TiktokIcon }  from "../assets/img/social/tiktok.svg";

export const userSocialIcons = {
  facebook: FacebookIcon,
  instagram: InstagramIcon,
  linkedin: LinkedInIcon,
  twitter: TwitterIcon,
  medium: MediumIcon,
  behance: BehanceIcon,
  website: WebIcon,
  tiktok: TiktokIcon,
  default: WebIcon,
};
