import React from "react";
import { useSelector } from "react-redux";

import DropdownNav from "../dropdown/DropdownNav";
import NavItem from "./NavItem";

const nftMarketplaceList = [
  { name: "menu.explore", path: "/explore" },
  { name: "menu.users", path: "/users" },
  { name: "menu.collections", path: "/collections" },
  // { name: "menu.privacy-policy", path: "#", external: "https://www.iubenda.com/privacy-policy/25046224" },
];

// const communityList = [
//   { name: "menu.contacts", path: "#", external: "https://www.saysoon.net/nft-art/community" }
// ];


const NavPageList = () => {
  const { userId, isAuthorized } = useSelector(state => state.auth);


  const profileList = [
    { name: "menu.user-profile", path: `/user-details/${userId}` },
    { name: "menu.top-up", path: `/top-up` },
    { name: "menu.add-certification", path: "/new-certification" },
    { name: "menu.add-asset", path: "/new-item" },
    { name: "menu.add-collection", path: "/new-collection" },
    { name: "menu.settings", path: `/user-details/${userId}/settings` }
  ]

  const pages = [
    // { isGrouped: false, name: "menu.home", path: "/home" },
    {
      isGrouped: true,
      name: "menu.nft-marketplace",
      path: "#",
      items: nftMarketplaceList,
      border: false
    },
    {
      isGrouped: false,
      name: "menu.mission",
      path: "#",
      external: "https://www.saysoon.net/nft-art/mission",
      replaceUrl: true
    },
    {
      isGrouped: false,
      name: "menu.community",
      path: "#",
      external: "https://www.saysoon.net/nft-art/community",
      replaceUrl: true
      // items: communityList,
    },
    {
      isGrouped: true,
      name: "menu.profile",
      path: "#",
      items: profileList
    },
    { isGrouped: false, name: "sign-out", path: "none" }
  ];

  return (
    <ul className="header__nav">
      {pages.map((page, index) => (
        <React.Fragment key={index}>
          {page.isGrouped ? (
            (page.name === "menu.profile")
              ? (isAuthorized && <DropdownNav className="header__profile__options" title={page.name} items={page.items} />)
              : <DropdownNav title={page.name} items={page.items} />
          ) : (
            (page.name === "sign-out")
              ? (isAuthorized && <NavItem className="header__profile__options" itemDetails={page} />)
              : <NavItem itemDetails={page} />
          )}
        </React.Fragment>
      ))}
    </ul>
  );
};

export default NavPageList;
