import {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
  useCallback,
} from "react";
import { useSelector } from "react-redux";
import { generatePath, useHistory, useRouteMatch } from "react-router-dom";
import { allTabsEnum, authTabsList, allTabsList } from "../constants";

const Context = createContext(null);

const Provider = ({ children }) => {
  const history = useHistory();
  const routeMatch = useRouteMatch();
  const { userId } = useSelector((state) => state.auth);
  const [activeTab, setActiveTab] = useState("");
  const isSettingsClicked = useMemo(
    () => activeTab === allTabsEnum.SETTINGS,
    [activeTab]
  );

  const handleTabChange = useCallback(
    (tab) => {
      const path = generatePath(routeMatch.path, {
        ...routeMatch.params,
        routeTab: tab,
      });
      history.replace(path);
      setActiveTab(tab);
    },
    [routeMatch, history]
  );

  useEffect(() => {
    const paramsUserId = routeMatch.params.id;
    const paramsTab = routeMatch.params.routeTab;

    if (
      !paramsTab ||
      !allTabsList.includes(paramsTab) ||
      (paramsUserId !== userId && authTabsList.includes(paramsTab))
    )
      handleTabChange(allTabsEnum.WALLET);
    else if (paramsTab !== activeTab) setActiveTab(paramsTab);
  }, [routeMatch, userId, activeTab, handleTabChange]);

  return (
    <Context.Provider value={{ isSettingsClicked, activeTab, handleTabChange }}>
      {children}
    </Context.Provider>
  );
};

export const useTabSelector = () => useContext(Context);

export default Provider;
