export const getAssetCover = (asset, index = 0, assetSize = "1000") =>
  (asset?.files?.length > 0 &&
    asset.files[index]?.file?.sizes &&
    asset.files[index].file.sizes[assetSize]?.url) ||
  "";

export const getAssetType = (asset) =>
  (asset?.files?.length > 0 &&
    asset.files[0]?.type) || "";

export const getAssetThumbnail = (asset) =>
(asset?.files?.length > 0 &&
  asset.files[0]?.thumbnail?.sizes && 
  asset.files[0]?.thumbnail?.sizes[400]?.url) || "";

export const getAssetMedia = (asset) => 
(asset?.files?.length > 0 &&
  asset.files[0]?.file?.sizes && 
  asset.files[0].file.sizes?.original?.url) || "";

export const getAssetTitle = (asset) => asset?.title || "";

export const getAssetCreator = (asset) => asset?.creatorId || null;

export const getAssetCreatorId = (asset) => asset?.creatorId?.userId?._id || "";

export const getAssetCreatorUsername = (asset) =>
  asset?.creatorId?.nickname || "";

export const getAssetOwner = (asset) => asset?.ownerId || null;

export const getAssetOwnerId = (asset) => asset?.ownerId?.userId?._id || "";

export const getAssetStandard = (asset) => asset?.standard || "";

export const getAssetOwnerUsername = (asset) => asset?.ownerId?.nickname || "";

export const getAssetCollection = (asset) => asset?.collectionId || null;

export const getAssetCollectionId = (asset) => asset?.collectionId?._id || "";

export const getAssetPrice = (asset) => asset?.price || null;

export const getAssetTokenId = (asset) => asset?.tokenId || null;

export const getAssetSaleType = (asset) => asset?.saleType || null;
