import React from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { LinkInput } from "../../components/form/FormikFields";
import facebookIcon from "../../assets/img/social/fb.svg";
import instagramIcon from "../../assets/img/social/instagram.svg";
import linkedinIcon from "../../assets/img/social/linkedin.svg";
import twitterIcon from "../../assets/img/social/twitter.svg";
import mediumIcon from "../../assets/img/social/medium.svg";
import behanceIcon from "../../assets/img/social/social-3.svg";
import tiktokIcon from "../../assets/img/social/tiktok.svg";
import websiteIcon from "../../assets/img/icons/web.svg";

const ProfileSocialMediaForm = () => {
  const { t } = useTranslation();

  return (
    <Row>
      <Col className="col-12">
        <h4 className="sign__title pl-2">
          {t("user-details-page.social-media-links")}
        </h4>
      </Col>

      <Col className="col-12 col-md-6">
        <div className="pl-1 pr-1">
          <LinkInput
            className="sign__input pl-5"
            name="facebook"
            label="social.facebook"
            placeholder={t("user-details-page.form-placeholder.facebook")}
            icon={facebookIcon}
            iconAlt="facebook"
          />
        </div>
      </Col>

      <Col className="col-12 col-md-6">
        <div className="pl-1 pr-1">
          <LinkInput
            className="sign__input pl-5"
            name="instagram"
            label="social.instagram"
            placeholder={t("user-details-page.form-placeholder.instagram")}
            icon={instagramIcon}
            iconAlt="instagram"
          />
        </div>
      </Col>

      <Col className="col-12 col-md-6">
        <div className="pl-1 pr-1">
          <LinkInput
            className="sign__input pl-5"
            name="linkedin"
            label="social.linked-in"
            placeholder={t("user-details-page.form-placeholder.linked-in")}
            icon={linkedinIcon}
            iconAlt="linkedin"
          />
        </div>
      </Col>

      <Col className="col-12 col-md-6">
        <div className="pl-1 pr-1">
          <LinkInput
            className="sign__input pl-5"
            name="twitter"
            label="social.twitter"
            placeholder={t("user-details-page.form-placeholder.twitter")}
            icon={twitterIcon}
            iconAlt="twitter"
          />
        </div>
      </Col>

      <Col className="col-12 col-md-6">
        <div className="pl-1 pr-1">
          <LinkInput
            className="sign__input pl-5"
            name="medium"
            label="social.medium"
            placeholder={t("user-details-page.form-placeholder.medium")}
            icon={mediumIcon}
            iconAlt="medium"
          />
        </div>
      </Col>

      <Col className="col-12 col-md-6">
        <div className="pl-1 pr-1">
          <LinkInput
            className="sign__input pl-5"
            name="behance"
            label="social.behance"
            placeholder={t("user-details-page.form-placeholder.behance")}
            icon={behanceIcon}
            iconAlt="behance"
          />
        </div>
      </Col>

      <Col className="col-12 col-md-6">
        <div className="pl-1 pr-1">
          <LinkInput
            className="sign__input pl-5"
            name="website"
            label="social.website"
            placeholder={t("user-details-page.form-placeholder.website")}
            icon={websiteIcon}
            iconAlt="website"
          />
        </div>
      </Col>

      <Col className="col-12 col-md-6">
        <div className="pl-1 pr-1">
          <LinkInput
            className="sign__input pl-5"
            name="tiktok"
            label="social.tiktok"
            placeholder={t("user-details-page.form-placeholder.tiktok")}
            icon={tiktokIcon}
            iconAlt="tiktok"
          />
        </div>
      </Col>
    </Row>
  );
};

export default ProfileSocialMediaForm;
