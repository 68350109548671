import React, { useCallback, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getAssetSaleType, getAssetStandard } from "../../helpers/asset";
import { useAssetDispatch, useAssetSelector } from "./provider";
import { useDispatch, useSelector } from "react-redux";
import { SET_BID_MODAL_OPEN, SET_BUY_MODAL_OPEN } from "./provider/actions";
import { getAssetOwnerId } from "../../helpers/asset";
import { getItemSymbol } from "../../helpers/utils";
import { chainIdMap } from "../../constants/items";
import { getUserLevel } from "../../helpers/user";
import InvalidRole from "../../components/Metamask/InvalidRole";

const TransactionActionButtons = () => {
  const { t } = useTranslation();
  const assetDispatch = useAssetDispatch();
  const dispatch = useDispatch();
  const { asset } = useAssetSelector();
  const { userId, isAuthorized, authInfo } = useSelector((state) => state.auth);
  const { chainId } = useSelector((state) => state.ethereum);
  const [disable, setDisable] = useState(false);

  const openBuyModal = () => {
    assetDispatch({ type: SET_BUY_MODAL_OPEN, open: true });
  };

  const openPlaceBidModal = () => {
    assetDispatch({ type: SET_BID_MODAL_OPEN, open: true });
  };

  const shouldDisable = useCallback(() => {
    let currentChain = parseInt(chainId, 16);
    let match = chainIdMap.find((el) => el.value === currentChain);

    if (match?.label !== asset?.blockchain || !isAuthorized) {
      return true;
    }

    return false;
  }, [asset?.blockchain, chainId, isAuthorized, dispatch]);

  useEffect(() => {
    setDisable(shouldDisable());
  }, [shouldDisable, authInfo]);

  return (
    <React.Fragment>
      {getAssetSaleType(asset) !== "NotOnSale" &&
        getAssetOwnerId(asset) !== userId &&
        getAssetStandard(asset) !== "erc1155" &&
        getUserLevel(authInfo, "kyc") && (
          <div className="asset__btns">
            {getAssetSaleType(asset) === "PutOnSale" && (
              <Button
                type="button"
                variant="light"
                className="asset__btn asset__btn--clr"
                disabled={disable}
                onClick={openBuyModal}
              >
                {t("item-details-page.buy")} - {asset.price}{" "}
                {getItemSymbol(asset.blockchain)}
              </Button>
            )}

            {getAssetSaleType(asset) === "PutOnBid" && (
              <Button
                type="button"
                variant="light"
                className="asset__btn"
                disabled={disable}
                onClick={openPlaceBidModal}
              >
                {t("item-details-page.bid")}
              </Button>
            )}
          </div>
        )
      }
      {!getUserLevel(authInfo, "kyc") && <div className=""> <InvalidRole /></div>}
    </React.Fragment>
  );
};

export default TransactionActionButtons;
