const userCardStyles = {
  card: {
    background: "#003A5E",
    border: "1px solid rgba(255, 255, 255, 0.1)",
    boxSizing: "border-box",
    borderRadius: "0px",
  },
  imageCard: {
    border: "5px solid #FFFFFF",
    boxSizing: " border-box",
    borderRadius: "0px",
    height: 62,
    width: 62,
    position: "relative",
  },
};

export { userCardStyles };
