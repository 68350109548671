import React, { useState } from "react";
import { Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DocumentationForm from "./Form";
import DocumentationList from "./List";

const Documentation = ({ onCreateCertification }) => {
  const { t } = useTranslation();
  const [files, setFiles] = useState([]);
  const [totalFiles, setTotalFiles] = useState(0);

  const handleSubmit = (newFile) => {
    setFiles((files) => [...files, newFile]);
    setTotalFiles((totalFiles) => totalFiles + 1);
  };

  return (
    <div className="sign__form sign__form--create">
      <DocumentationForm onSubmit={handleSubmit} />

      <DocumentationList files={files} />

      <Col className="col-12 col-xl-3 mt-3">
        <Button
          className="sign__btn"
          variant="tertiary"
          type="button"
          style={{ cursor: totalFiles > 0 ? "pointer" : "initial", border: "1px solid white" }}
          disabled={totalFiles === 0}
          onClick={() => onCreateCertification(files)}
        >
          {t("actions.create-certificate")}
        </Button>
      </Col>
    </div>
  );
};

export default Documentation;
