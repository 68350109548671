import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Image } from "react-bootstrap";
import {
  useAssetDispatch,
  useAssetSelector,
} from "./provider";
import { TOGGLE_COLLECTION_EDITING } from "./provider/actions";
import { getAssetCollection, getAssetCollectionId, getAssetCreatorId } from "../../helpers/asset";
import PencilIcon from "../../assets/img/pencil.svg";
import AssetCollectionForm from "./AssetCollectionForm";
import UserAvatar from "../../components/user/Avatar";
import { Link } from "react-router-dom";
import { userCardStyles } from "../CertificationDetails/styles";

const collectionDetailsPath = "/collections";

const AssetCollection = ({ asset }) => {
  const { t } = useTranslation();
  const assetDispatch = useAssetDispatch();
  const { authInfo } = useSelector((state) => state.auth);
  const { collectionEditing } = useAssetSelector();
  const [hasEditPermissions, setHasEditPermissions] = useState(false);
  const [collection, setCollection] = useState(null);
  const [collectionId, setCollectionId] = useState("");

  const toggleEditing = useCallback(() => {
    assetDispatch({
      type: TOGGLE_COLLECTION_EDITING,
    });
  }, [assetDispatch]);

  useEffect(() => {
    setHasEditPermissions(authInfo._id === getAssetCreatorId(asset));
    setCollection(getAssetCollection(asset));
    setCollectionId(getAssetCollectionId(asset));
  }, [authInfo, asset]);

  return (
    <React.Fragment>
      <>
        {hasEditPermissions && asset?.type != "certificate" && (
          <button className="mr-2" onClick={toggleEditing}>
            <Image
              style={({ width: "18px" }, { height: "18px" })}
              src={PencilIcon}
            ></Image>
          </button>
        )}
      </>

      {hasEditPermissions && collectionEditing ? (
        <AssetCollectionForm asset={asset} onSuccess={toggleEditing} />
      ) : (

        (asset?.type === "certificate") ?
          (<div style={userCardStyles.card} className="mt-3 p-3 ps-4">
            <>
              {hasEditPermissions && (
                <button className="mr-2" onClick={toggleEditing}>
                  <Image
                    style={({ width: "18px" }, { height: "18px" })}
                    src={PencilIcon}
                  ></Image>
                </button>
              )}
            </>
            <span>{t("item-details-page.collection")}</span>
            <UserAvatar profile={null} avatarClass="card__author">
              {collection && (
                <Link to={`${collectionDetailsPath}/${collectionId}`}>
                  {collection.title}
                </Link>
              )}
            </UserAvatar>
          </div>)
          :
          (<li>
            <span>{t("item-details-page.collection")}</span>
            <UserAvatar profile={null} avatarClass="card__author">
              {collection && (
                <Link to={`${collectionDetailsPath}/${collectionId}`}>
                  {collection.title}
                </Link>
              )}
            </UserAvatar>
          </li>)
      )}
    </React.Fragment>
  );
};

export default AssetCollection;
