export const categoryOptions = [
  { value: "Art", label: "explore-items.art" },
  { value: "Photography", label: "explore-items.photography" },
  { value: "Games", label: "explore-items.games" },
  { value: "Metaverses", label: "explore-items.metaverses" },
  { value: "Music", label: "explore-items.music" },
  { value: "Domains", label: "explore-items.domains" },
  { value: "Memes", label: "explore-items.memes" },
];

export const sortByOptions = [
  { value: "title", name: "name" },
  { value: "price", name: "price" },
  { value: "createdAt", name: "createdDate" },
];
