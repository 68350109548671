import { useTranslation } from "react-i18next";
import { Nav, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import useLogout from "../../hooks/useLogout";
import { useLoader } from "../../providers/loader";

const NavItem = ({ itemDetails, className }) => {
  const { t } = useTranslation();
  const { setLoading, setText } = useLoader();
  const { handleLogout } = useLogout();

  const logout = () => {
    handleLogout();
  };

  const goToPage = (url, replace) => {
    setLoading(true);
    setText("Loading...");
    window.open(url, replace ? '_self' : '_blank');
  }

  return (
    <Nav className={"header__nav-item mr-auto " + className}>

      {itemDetails?.external ?
        (< Link className="header__nav-link" to="#" onClick={() => { goToPage(itemDetails.external, itemDetails?.replaceUrl) }}>
          <div className="vl mr-3" style={{ border: "1px solid white", height: "15px" }}>

          </div> {t(itemDetails.name)}
        </Link>)
        :
        (!(itemDetails?.path === "none"))
          ?
          (< Link className="header__nav-link" to={itemDetails?.path}>
            {t(itemDetails.name)}
          </Link>)
          : (<Button bsPrefix="c__none" onClick={logout}>
            <span style={{ color: "#bdbdbd" }}>{t("sign-out")}</span>
          </Button>)
      }
    </Nav >
  );
};

export default NavItem;
