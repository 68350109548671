import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Col, Row } from "react-bootstrap";
import FormikForm from "../../components/form/FormikForm";
import { TextInput } from "../../components/form/FormikFields";
import { useLoader } from "../../providers/loader";
import { useAssetDispatch } from "./provider";
import useError from "../../hooks/useError";
import { retrieveAsset, updateAsset } from "../../store/AssetSlice";
import { SET_ASSET, TOGGLE_TITLE_EDITING } from "./provider/actions";
import { getApiRejectedStatus, getApiSuccessBody } from "../../helpers/api";

const initialFormModel = {
  title: "",
};

const AssetTitleForm = ({ asset, onSuccess }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const assetDispatch = useAssetDispatch();
  const { setLoading } = useLoader();
  const { handleError } = useError();
  const [formModel, setFormModel] = useState(initialFormModel);

  const handleSubmit = useCallback(
    async (values) => {
      try {
        setLoading(true);

        await dispatch(
          updateAsset({ assetId: asset._id, data: values })
        ).unwrap();

        const retrieveResponse = await dispatch(
          retrieveAsset({ assetId: asset._id })
        ).unwrap();

        assetDispatch({
          type: SET_ASSET,
          asset: getApiSuccessBody(retrieveResponse),
        });
        onSuccess();
      } catch (rejected) {
        handleError({ status: getApiRejectedStatus(rejected) });
      } finally {
        setLoading(false);
      }
    },
    [asset, onSuccess, setLoading, handleError, assetDispatch, dispatch]
  );

  const closeEdit = useCallback(() => {
    assetDispatch({
      type: TOGGLE_TITLE_EDITING,
    });
  }, [assetDispatch]);

  useEffect(() => {
    setFormModel((data) => ({
      ...data,
      title: asset?.title || "",
    }));
  }, [asset]);

  return (
    <FormikForm
      initialValues={formModel}
      formClasses="sign__form--profile"
      enableReinitialize
      onSubmit={handleSubmit}
    >
      <TextInput
        inputClass="sign__textarea"
        as="textarea"
        name="title"
        rows="1"
      />

      <Row>
        <Col className="col-6">
          <Button className="sign__btn" variant="light" type="submit">
            {t("save")}
          </Button>
        </Col>

        <Col className="col-6">
          <Button className="sign__btn" variant="light" onClick={closeEdit} >
            {t("cancel")}
          </Button>
        </Col>
      </Row>
    </FormikForm>
  );
};

export default AssetTitleForm;
