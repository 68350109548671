import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Image } from "react-bootstrap";
import {
  useAssetDispatch,
  useAssetSelector,
} from "./provider";
import { TOGGLE_TITLE_EDITING } from "./provider/actions";
import { getAssetCreatorId } from "../../helpers/asset";
import PencilIcon from "../../assets/img/pencil.svg";
import AssetTitleForm from "./AssetTitleForm";

const AssetTitle = ({ asset }) => {
  const { t } = useTranslation();
  const assetDispatch = useAssetDispatch();
  const { authInfo } = useSelector((state) => state.auth);
  const { titleEditing } = useAssetSelector();
  const [hasEditPermissions, setHasEditPermissions] = useState(false);

  const toggleEditing = useCallback(() => {
    console.log('e');
    assetDispatch({
      type: TOGGLE_TITLE_EDITING,
    });
  }, [assetDispatch]);

  useEffect(() => {
    setHasEditPermissions(authInfo._id === getAssetCreatorId(asset));
  }, [authInfo, asset]);

  return (
    <React.Fragment>
      <div className="asset__desc ">
        <div className="d-flex align-items-center mb-3">
          {hasEditPermissions && (
            <button className="mr-2" onClick={toggleEditing}>
              <Image
                style={({ width: "18px" }, { height: "18px" })}
                src={PencilIcon}
              ></Image>
            </button>
          )}
          {hasEditPermissions && titleEditing ? (
            <AssetTitleForm asset={asset} onSuccess={toggleEditing} />
          ) : (
            <h1>{asset.title}</h1>
          )}
        </div>

      </div>
    </React.Fragment>
  );
};

export default AssetTitle;
