// export const categoryOptions = [
//   { value: "art", label: "explore-items.art" },
//   { value: "photography", label: "explore-items.photography" },
//   { value: "games", label: "explore-items.games" },
//   { value: "metaverses", label: "explore-items.metaverses" },
//   { value: "music", label: "explore-items.music" },
//   { value: "domains", label: "explore-items.domains" },
//   { value: "memes", label: "explore-items.memes" },
// ];

export const categoryOptions = [
  { value: "image", label: "categories.image" },
  { value: "video", label: "categories.video" },
  { value: "gif", label: "categories.gif" },
  { value: "3d", label: "categories.3d" },
  { value: "photography", label: "categories.photography" },
  { value: "certificates", label: "categories.certificates" },
];

export const sortByOptions = [
  { value: "title", name: "name" },
  { value: "price", name: "price" },
  { value: "createdAt", name: "createdDate" },
];

export const categoryValues = Object.values(categoryOptions).map(
  (category) => category.value
);

export const initialFilters = {
  search: "",
  sortBy: "title",
  category: [],
};

export const itemsPerScroll = 20;

export const intialPagination = { skip: 0, limit: itemsPerScroll };
