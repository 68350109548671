import React from "react";
import { Col, Row } from "react-bootstrap";
import BaseModal from "../../components/modal/Base";
import AssetItemImage from "./AssetItemImage";
import { useAssetSelector } from "./provider";

const TransactionModal = ({ show, children }) => {
  const { asset } = useAssetSelector();

  return (
    <BaseModal show={show} centered size="lg" bodyClassName="p-1">
      <Row className="no-gutters ml-n5 p-md-4">
        <Col className="col-12 col-lg-7 pl-5">
          <div
            className="asset__item border-0 m-0 p-0"
            style={{
              minHeight: "100%",
            }}
          >
            <AssetItemImage asset={asset} modal={true} />
          </div>
        </Col>

        <Col className="col-12 col-lg-5 pl-5 mt-5 m-0 mt-lg-0 text-white">
          {children}
        </Col>
      </Row>
    </BaseModal>
  );
};

export default TransactionModal;
