import React from "react";
import { Image } from "react-bootstrap";
import step1 from "../../assets/step1.svg";
import step2 from "../../assets/step2.svg";

const Steps = ({ step }) => {

    return (
        <React.Fragment>
            <div className="pl-5">
                <Image src={step === 1 ? step1 : step2} style={{ width: "110px" }} />
            </div>
        </React.Fragment >
    );
};

export default Steps;