import React from "react";
import { useTranslation } from "react-i18next";
import CertificationDocumentItem from "./DocumentItem";
const CertificationDocuments = ({ documents }) => {
  const { t } = useTranslation();

  return (
    <div className="card__wrapper mt-4">
      <h1 className="certification__section__title text-truncate">
        {t("documentation")}
      </h1>

      {documents.map((document, index) => (
        <CertificationDocumentItem document={document} key={index} />
      ))}
    </div>
  );
};

export default CertificationDocuments;
