import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { useAssetSelector } from "../provider";

const BuyCompletedStep = ({ onClose }) => {
  const { t } = useTranslation();
  const { asset } = useAssetSelector();

  return (
    <React.Fragment>
      <div className="w_step__title">
        {t("item-details-page.wizard.buy.completed.title")}
      </div>

      <p className="w_step__text mb-4">
        <Trans
          t={t}
          i18nKey="item-details-page.wizard.buy.completed.description"
          values={{ title: asset.title }}
          components={{ bold: <strong />, span: <span /> }}
        />
      </p>

      <Row className="no-gutters justify-content-center mt-5">
        <Col className="col-12 col-xl-6">
          <Button
            className="sign__btn mx-auto"
            variant="light"
            type="button"
            onClick={onClose}
          >
            {t("actions.close")}
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default BuyCompletedStep;
